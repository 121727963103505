import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';
import {
  Avatar,
  Box,
  CardHeader,
  Checkbox,
  FormControlLabel,
  LinearProgress,
  Typography,
  styled,
  useTheme
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Task, TaskPriority } from 'src/CRM/models/task';
import { updateTaskStatus } from 'src/services/nestApi';
import { RootState } from 'src/store';
import Text from 'src/ui-bloom/components/Text';
import { TaskStatus } from '../../../enums/task-status';
import ConfirmDialog from '../../common/confirm-dialog';
import ActionMenuButton from './action-menu-button';

const AvatarWrapperSuccess = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.success.lighter};
      color:  ${theme.colors.success.main};
`
);

const DotLegend = styled('span')(
  ({ theme }) => `
    border-radius: 22px;
    width: ${theme.spacing(1.8)};
    height: ${theme.spacing(1.8)};
    display: inline-block;
    border: 2px solid ${theme.colors.alpha.white[100]};
    margin-right: ${theme.spacing(0.5)};
`
);

const LinearProgressWrapper = styled(LinearProgress)(
  ({ theme }) => `
        flex-grow: 1;
        height: 10px;
        margin: ${theme.spacing(1, 0, 2)};
        
        &.MuiLinearProgress-root {
          background-color: ${theme.colors.alpha.black[10]};
        }
        
        .MuiLinearProgress-bar {
          border-radius: ${theme.general.borderRadiusXl};
        }
`
);
function TaskBlock(props: { task: Task; hideActions?: boolean }) {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const { user } = useSelector((state: RootState) => state.app);
  const [saving, setSaving] = useState(false);
  const [isDone, setIsDone] = useState(
    props.task.status == TaskStatus.Complete
  );
  const [showConfirm, setShowConfirm] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const markAsDone = () => {
    setShowConfirm(true);
  };

  const PriorityDot = (value: string) => {
    if (value == TaskPriority.High) {
      return (
        <>
          <DotLegend
            style={{
              background: `${theme.colors.error.main}`,
              marginLeft: 5
            }}
          />
          <b>High</b>
        </>
      );
    }

    if (value == TaskPriority.Medium) {
      return (
        <>
          <DotLegend
            style={{
              background: `${theme.colors.warning.main}`,
              marginLeft: 5
            }}
          />
          <b>Medium</b>
        </>
      );
    }

    return (
      <>
        <DotLegend
          style={{
            background: `${theme.colors.success.main}`,
            marginRight: 10
          }}
        />
        <b>Low</b>
      </>
    );
  };
  return (
    <Box>
      <CardHeader
        sx={{
          px: 0,
          pt: 0
        }}
        avatar={
          isDone ? (
            <AvatarWrapperSuccess>
              <CheckTwoToneIcon />
            </AvatarWrapperSuccess>
          ) : (
            <FormControlLabel
              control={<Checkbox checked={false} onClick={markAsDone} />}
              label=""
            />
          )
        }
        action={
          props?.hideActions
            ? null
            : (String(user.employeeIds[0]) ===
                String(props.task?.createdBy?.id) ||
                user.isAdmin) && <ActionMenuButton task={props.task} />
        }
        title={t(props.task.title)}
        titleTypographyProps={{
          variant: 'h5',
          color: 'textPrimary'
        }}
      />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start'
        }}
      >
        <Typography variant="subtitle2" gutterBottom>
          {t('Created by')}:{' '}
          <Text color="black">
            <b>{props.task.createdBy?.name}</b>
          </Text>
        </Typography>

        <Typography sx={{ ml: 2 }} variant="subtitle2" gutterBottom>
          {t('on')}:{' '}
          <Text color="black">
            {props.task.createdOn
              ? new Date(props.task.createdOn).toLocaleString()
              : ''}
          </Text>
        </Typography>
      </Box>
      <Box
        sx={{
          mt: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start'
        }}
      >
        <Typography variant="subtitle2" gutterBottom>
          {t('Task type')}:{' '}
          <Text color="black">
            <b>{props.task.taskType}</b>
          </Text>
        </Typography>

        <Typography sx={{ ml: 2 }} variant="subtitle2" gutterBottom>
          {t('Priority')}:{' '}
          <Text color="black">{PriorityDot(props.task.priority)}</Text>
        </Typography>
      </Box>

      <ConfirmDialog
        inProgress={saving}
        open={showConfirm}
        onClose={() => {
          setShowConfirm(false);
        }}
        message="Are you sure you want to mark it as done?"
        onConfirm={async (e) => {
          setSaving(true);
          try {
            await updateTaskStatus(props.task.id, TaskStatus.Complete);
            setShowConfirm(false);
            setIsDone(true);
            setSaving(false);
          } catch (error) {
            console.log(error);
            setSaving(false);
            enqueueSnackbar('Error while updating task status', {
              variant: 'error'
            });
          }
        }}
      />
    </Box>
  );
}
export default TaskBlock;
