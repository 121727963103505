import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { Note } from 'src/CRM/models/note';
import { Task } from 'src/CRM/models/task';
import { ActiveSubscription, UserContext } from '../../services/apiService/response-models';
import { AppState } from '../app-state';

const initialState: AppState = {
  sidebarOpen: false,
  isDrawerOpen: false,
  noteToEdit: undefined,
  taskToEdit: undefined
};

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    //setBrand(
    //    state: AppState,
    //    action: PayloadAction<{ brand: Restaurant }>
    //) {
    //    const { brand } = action.payload;
    //    state.brand = _.cloneDeep(brand);
    //},
    setUser(state: AppState, action: PayloadAction<{ user: UserContext }>) {
      const { user } = action.payload;
      state.user = { ...user };
    },
    setUserSub(state: AppState, action: PayloadAction<{ sub: ActiveSubscription }>) {
      const { sub } = action.payload;
      state.user = { ...state.user, subscription: sub };
    },
    openSidebar(state: AppState): void {
      state.sidebarOpen = true;
    },
    closeSidebar(state: AppState): void {
      state.sidebarOpen = false;
    },
    openDrawerPanel(state: AppState): void {
      state.isDrawerOpen = true;
    },
    closeDrawerPanel(state: AppState): void {
      state.isDrawerOpen = false;
    },
    setNoteToEdit(state: AppState, action: PayloadAction<{ note: Note }>) {
      const { note } = action.payload;
      state.noteToEdit = _.cloneDeep(note);
    },
    setTaskToEdit(state: AppState, action: PayloadAction<{ task: Task }>) {
      const { task } = action.payload;
      state.taskToEdit = _.cloneDeep(task);
    }
  }
});

const { actions, reducer } = slice;

export const {
  setUser,
  setUserSub,
  closeSidebar,
  openSidebar,
  openDrawerPanel,
  closeDrawerPanel,
  setNoteToEdit,
  setTaskToEdit
} = actions;
export default reducer;
