import {
  TextField,
  Autocomplete,
  Card,
  Grid,
  CardHeader,
  Divider,
  useTheme
} from '@mui/material';
import { Field, FieldProps } from 'formik';
import { useTranslation } from 'react-i18next';
import 'react-quill/dist/quill.snow.css';
import DropdownMultiSelect from '../../../components/dropdown-multi-select';
import {
  CodeItem,
  CodeMaster,
  RefData,
  Team
} from 'src/services/apiService/response-models';
import { convertToCodeItems } from '../../../utility/convertToCodeItem';
import { USER_ROLES } from 'src/utility/constants';

function GroupInfo(props: { accountRefData: RefData; teamsList: Team[] }) {
  const teamsOption = props.teamsList.map(({ id, name }) => ({
    codeId: id,
    value: name,
    codeType: name
  }));

  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const jobTitlesList = props.accountRefData.jobTitles;
  const departmentsList = convertToCodeItems(props.accountRefData.departments);
  return (
    <Card>
      <CardHeader title={t("Member's Group Info")} />
      <Divider />

      <Grid sx={{ padding: theme.spacing(2) }} container spacing={3}>
        <Grid item xs={12} md={6}>
          <Field name="departments">
            {({
              field,
              form: {
                isSubmitting,
                setFieldValue,
                setFieldTouched,
                setFieldError
              },
              meta
            }: FieldProps) => (
              <Autocomplete
                sx={{
                  m: 0
                }}
                freeSolo
                selectOnFocus
                clearOnBlur
                multiple={true}
                limitTags={10}
                value={field.value}
                autoSelect
                onChange={(
                  event: any,
                  newValues: string[] | null | CodeItem[]
                ) => {
                  const vals = newValues.map((o) => {
                    if (o?.codeId) {
                      return o;
                    }
                    return {
                      codeId: departmentsList.find((x) => x.value == o?.trim())
                        ?.codeId,
                      value: o?.trim()
                    };
                  });
                  setFieldValue(field.name, vals);
                  // setFieldTouched(field.name, true);
                }}
                isOptionEqualToValue={(option: CodeItem, value: CodeItem) => {
                  return option?.codeId === value?.codeId;
                }}
                getOptionLabel={(option: CodeItem) => {
                  return (
                    option.value ||
                    departmentsList.find((x) => x.codeId == option.codeId)
                      ?.value ||
                    ''
                  );
                }}
                options={departmentsList}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    variant="outlined"
                    placeholder={t('Enter or select departments...')}
                    label={t('Departments')}
                  />
                )}
              />
            )}
          </Field>
          {/* <Field name="departments">
            {({ field, form, meta }: FieldProps) => (
              <DropdownMultiSelect
                label="Department"
                name="departments"
                options={convertToCodeItems(props.accountRefData.departments)}
              />
            )}
          </Field> */}
        </Grid>
        <Grid item xs={12} md={6}>
          <Field name="jobTitle">
            {({
              field,
              form: {
                isSubmitting,
                setFieldValue,
                setFieldTouched,
                setFieldError
              },
              meta
            }: FieldProps) => (
              <Autocomplete
                sx={{
                  m: 0
                }}
                freeSolo
                selectOnFocus
                clearOnBlur
                limitTags={10}
                value={field.value || null}
                autoSelect
                onChange={(event: any, newValue: any) => {
                  const jobTitleId =
                    newValue?.id ||
                    jobTitlesList.find((x) => x.value == newValue)?.id;
                  setFieldValue(field.name, {
                    ...(jobTitleId
                      ? { codeId: jobTitleId }
                      : { value: newValue })
                  });
                  // setFieldTouched(field.name, true);
                }}
                getOptionLabel={(option: any) => {
                  return (
                    option.value ||
                    jobTitlesList.find((x) => x.id == option.codeId)?.value ||
                    ''
                  );
                }}
                isOptionEqualToValue={(
                  option: CodeMaster,
                  value: CodeMaster
                ) => {
                  return option.id === value.id;
                }}
                options={jobTitlesList}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    variant="outlined"
                    placeholder={t('Enter or select job title...')}
                    label={t('Job Title')}
                  />
                )}
              />
            )}
          </Field>
        </Grid>
        <Grid item xs={12} md={6}>
          <DropdownMultiSelect
            label="Team/Group"
            name="teams"
            options={teamsOption}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Field name="userRoles">
            {({ field, form, meta }: FieldProps) => (
              <Autocomplete
                disablePortal
                multiple={true}
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) =>
                  option.roleName == value.roleName
                }
                value={field.value}
                options={USER_ROLES}
                onChange={(event, values) => {
                  form.setFieldValue(field.name, values);
                }}
                renderInput={(params) => (
                  <TextField
                    error={Boolean(
                      form.touched[field.name] && form.errors[field.name]
                    )}
                    helperText={
                      (form.touched[field.name] && form.errors[field.name]) ||
                      'These roles will assigned to the user associated with this member.'
                    }
                    fullWidth
                    {...params}
                    label={t('User role')}
                  />
                )}
              />
            )}
          </Field>
        </Grid>
      </Grid>
    </Card>
  );
}

export default GroupInfo;
