import {
  Avatar,
  Box,
  Button,
  Card,
  Divider,
  Grid,
  LinearProgress,
  Link,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import ShowMoreText from 'react-show-more-text';

import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone';
import { formatDistance } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { LeadItem } from 'src/services/apiService/response-models/data-views';
import cdnService from 'src/services/cdnService';
import parseMyJson from 'src/utility/parseMyJson';
import toLocDate from 'src/utility/toLocDate';
import Activities from '../../../CRM/components/Activities';
import ErrorPanel from '../../../components/error-panel';
import { getLeadById } from '../../../services/apiService';

function LeadDetail() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [lead, setLead] = useState<LeadItem | null>(null);
  const [error, setError] = useState('');

  const getById = async (leadId: string) => {
    try {
      const response = await getLeadById(leadId);
      setLead(response.data);
    } catch (ex) {
      console.error(ex);
      const msg = ex.message ? ex.message : `Failed to load contact by id`;
      setError(msg);
      enqueueSnackbar(t(msg), { variant: 'error' });
    }
  };

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      getById(id).then(() => {
        setIsLoading(false);
      });
    } else {
      navigate('/app/lead/list');
    }
  }, []);

  if (error) {
    return <ErrorPanel title="Critical error" message={error} />;
  }

  if (isLoading || !lead) {
    return <LinearProgress sx={{ mt: 2 }} />;
  }

  return (
    <Grid container>
      <Grid item xs={12} md={3}>
        <LeadSummary lead={lead} />
      </Grid>
      <Grid item xs={12} md={9}>
        <Activities
          entityType="lead"
          entityId={id + ''}
          entityName={lead?.contactMail}
        />
      </Grid>
    </Grid>
  );
}

function LeadSummary({ lead }: { lead: LeadItem }) {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  const onGoback = () => {
    navigate('/app/lead/list');
  };

  const formMetadata = lead.formData?.Metadata
    ? parseMyJson(lead.formData?.Metadata, {})
    : {};

  return (
    <Card
      sx={{
        position: 'relative',
        p: 3,
        pt: 0,
        m: 1
      }}
    >
      <Box display="flex" justifyContent="space-between" mb={2} mt={2}>
        <Tooltip title={'Back to Leads'}>
          <Button
            onClick={onGoback}
            variant="outlined"
            startIcon={<ArrowBackTwoToneIcon />}
            size="small"
          >
            {t('Leads')}
          </Button>
        </Tooltip>
      </Box>
      <Box mb={2} display="flex" alignItems="start">
        <Avatar
          sx={{
            width: 50,
            height: 50,
            mr: 2
          }}
          src={cdnService.toImageUrl(lead.contactImage)}
        />
        <Box>
          <Box>
            <Tooltip title="Go to contact">
              <Link
                variant="h5"
                component={RouterLink}
                to={`/${location.pathname.split('/')[1]}/contact/edit/${
                  lead.contactId
                }`}
              >
                {lead.contactName}
              </Link>
            </Tooltip>
          </Box>

          <Typography
            sx={{
              pt: 1
            }}
            variant="h6"
          >
            {lead.contactMail}
          </Typography>
          <Tooltip title="Go to profile">
            <Link
              variant="subtitle2"
              underline="none"
              component={RouterLink}
              to={`/${location.pathname.split('/')[1]}/cards/edit/${
                lead.cardId
              }`}
            >
              for: {lead.cardLabel}
            </Link>
          </Tooltip>
          <Typography component="div" variant="body2" color="text.secondary">
            on: {new Date(lead.createdOn).toLocaleDateString()}
          </Typography>
          <Typography
            sx={{
              pt: 1
            }}
            variant="h6"
          >
            {'owner: '}
            {lead.employeeName}
          </Typography>
          {
            <Typography
              sx={{
                pt: 1
              }}
              variant="h6"
            >
              <ShowMoreText
                /* Default options */
                lines={1}
                more="Show more"
                less="Show less"
                className="content-css"
                anchorClass="show-more-less-clickable"
                truncatedEndingComponent={'... '}
              >
                {lead.formData?.Remark}
              </ShowMoreText>
            </Typography>
          }
          {formMetadata &&
            Object.keys(formMetadata).map((k) => (
              <Typography
                key={k}
                sx={{
                  pt: 1
                }}
                variant="h6"
              >
                {formMetadata[k].displayName + ' : ' + formMetadata[k].value}
              </Typography>
            ))}
        </Box>
      </Box>
      <Divider />
      <Box
        pl={2}
        py={1}
        pr={1}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography>
          last updated:{' '}
          <b>
            {formatDistance(
              toLocDate(lead.updatedOn || lead.createdOn),
              new Date(),
              {
                addSuffix: true
              }
            )}
          </b>
        </Typography>
      </Box>
    </Card>
  );
}

export default LeadDetail;
