import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';
import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CircularProgress,
  Collapse,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
  styled
} from '@mui/material';
import {
  Field,
  FieldProps,
  Form,
  Formik,
  FormikConfig,
  FormikValues,
  useField
} from 'formik';
import { CheckboxWithLabel, TextField } from 'formik-mui';
import {
  Children,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import * as Yup from 'yup';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { CodeItem } from 'src/BusinessApp/services/models';
import FeatureImageField from 'src/components/FeatureImage/FeatureImageField';
import { CountryCodeSlector } from 'src/components/country-code-selector';
import {
  accountSignup,
  checkAccountExistsByEmail,
  getRefData
} from 'src/services/apiService';
import {
  AccountSignUp,
  AccountTypes,
  RefData
} from 'src/services/apiService/response-models';
import Logo from 'src/ui-bloom/components/LogoSign';
import useRefMounted from 'src/ui-bloom/hooks/useRefMounted';
import parseReqType from 'src/utility/parseReqType';
import { parseToApiErrorMessage } from 'src/utility/parseToApiErrorMessage';
import paymentPlans, { PaymentPlan } from './PaymentPlans';
import EmailVerifyStep from './email-verify-step';
import StripeElement from './stripe-element';

const MainContent = styled(Box)(
  () => `
    height: 100%;
    overflow: auto;
    flex: 1;
`
);

const stripeAppearance = {
  theme: 'night',
  labels: 'floating'
};

const BoxActions = styled(Box)(
  ({ theme }) => `
    background: ${theme.colors.alpha.black[5]}
`
);

const AvatarSuccess = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.success.main};
      color: ${theme.palette.success.contrastText};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};
      box-shadow: ${theme.colors.shadows.success};
      margin-left: auto;
      margin-right: auto;

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

const dateRegExp = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/(19|20)\d\d$/;

const urlRegExp =
  /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
const sleep = (time: number) => new Promise((acc) => setTimeout(acc, time));
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
interface CountryCode {
  dial_code: string;
  name: string;
  code: string;
}

const CardTypes: CodeItem[] = [
  {
    code: 'Gold',
    value: 'Gold'
  },
  {
    code: 'Black',
    value: 'Black'
  },
  {
    code: 'Grey',
    value: 'Grey'
  },
  {
    code: 'White',
    value: 'White'
  }
];

function SignUpWizard() {
  const { t }: { t: any } = useTranslation();
  const isMountedRef = useRefMounted();
  const { enqueueSnackbar } = useSnackbar();
  const [openAlert, setOpenAlert] = useState(true);
  const [isBusiness, setIsBusiness] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentPlan, setPaymentPlan] = useState<PaymentPlan | null>(null);

  const [isSaveInProgress, setIsSaveInProgress] = useState(false);
  const [accountRefData, setAccountRefData] = useState<RefData>({
    socialMediaProviders: []
  });
  const [error, setError] = useState('');

  const [searchParams] = useSearchParams();
  const source = searchParams.get('source');
  const requrl = searchParams.get('requrl');
  const reqtype = searchParams.get('reqtype');
  const reqType = parseReqType(reqtype);

  const loadRefData = useCallback(
    async (callback: (hasError: boolean, data: RefData) => void) => {
      if (isMountedRef.current) {
        const data = await getRefData('socialMediaProvider');
        callback(false, data);
      }
    },
    [isMountedRef]
  );

  useEffect(() => {
    setIsLoading(true);
    loadRefData((hasError, data) => {
      if (data) {
        setAccountRefData({
          socialMediaProviders: data.socialMediaProviders || []
        });
      }
      setIsLoading(false);
    });
    if (source) {
      const plan = paymentPlans.find((o) => o.id == source);
      if (plan) {
        setPaymentPlan(plan);
        setIsBusiness(plan.accountType == AccountTypes.Business ? true : false);
      }
    }
  }, [loadRefData]);

  const smProviderId = (providerName: string): number | null => {
    const code = accountRefData.socialMediaProviders.find(
      (o) => o.code.toLowerCase() == providerName.toLowerCase()
    );
    return code ? code.id : null;
  };
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  return (
    <>
      <Helmet>
        <title>Sign Up @ KiEngage</title>
      </Helmet>
      <MainContent>
        <Container
          sx={{
            my: 4
          }}
          maxWidth="md"
        >
          <Logo />
          <Card
            sx={{
              mt: 3,
              pt: 4
            }}
          >
            <Box px={4}>
              <Typography
                variant="h2"
                sx={{
                  mb: 1
                }}
              >
                {t('Sign up for KiEngage')}
              </Typography>
              <Typography
                variant="h4"
                color="text.secondary"
                fontWeight="normal"
                sx={{
                  mb: 3
                }}
              >
                {t('Fill in the fields below to sign up for an account.')}
              </Typography>
            </Box>

            <FormikStepper
              initialValues={{
                cardType: '',
                firstName: '',
                lastName: '',
                terms: true,
                promo: true,
                password: '',
                password_confirm: '',
                email: '',
                phone: '',
                countryCode: '1',
                businessName: '',
                description: '',
                headline: '',
                logo: '',
                website: '',
                imageUrl: '',
                facebookUrl: '',
                twitterUrl: '',
                alternateContact: '',
                alternateEmail: '',
                isEmailVerified: false,
                verificationCode: ''
              }}
              onSubmit={async (_values) => {
                let isSuccess = false;
                setIsSaveInProgress(true);
                setIsSubmit(true);
                //socialMediaLinks
                let socialMediaLinks = [];

                let codeId = smProviderId('facebook');
                if (codeId) {
                  socialMediaLinks = socialMediaLinks.filter(
                    (o) => codeId != o.socialMediaProviderId
                  );
                  socialMediaLinks.push({
                    id: 0,
                    link: _values.faceBookUrl,
                    socialMediaProviderId: codeId
                  });
                }

                codeId = smProviderId('twitter');
                if (codeId) {
                  socialMediaLinks = socialMediaLinks.filter(
                    (o) => codeId != o.socialMediaProviderId
                  );
                  socialMediaLinks.push({
                    id: 0,
                    link: _values.twitterUrl,
                    socialMediaProviderId: codeId
                  });
                }

                const model: AccountSignUp = {
                  isIndividual: isBusiness ? false : true,
                  firstName: _values.firstName,
                  lastName: _values.lastName,
                  email: _values.email,
                  password: _values.password,
                  contactNumber: _values.phone
                    ? _values.countryCode + '-' + _values.phone
                    : '',
                  businessName: _values.businessName,
                  cardType: _values.cardType,
                  nfcCode: reqType.name == 'nfcmap' ? reqType.value : null,
                  additionalInfo: {
                    description: _values.description,
                    // headline: _values.headline,
                    logo: _values.logo,
                    website: _values.website,
                    imageUrl: _values.imageUrl,
                    dateOfBirth: _values?.dateOfBirthnew
                      ? new Date(_values.dateOfBirth).toISOString()
                      : null,
                    socialMediaLinks: socialMediaLinks
                  },
                  // alternateContact: _values.alternateContact,
                  // alternateEmail: _values.alternateEmail,
                  terms: _values.terms
                  // promo: _values.promo,
                };
                try {
                  await accountSignup(model);
                  enqueueSnackbar(t('Signup successfully'), {
                    variant: 'success'
                  });
                  isSuccess = true;
                } catch (ex) {
                  setIsSaveInProgress(false);
                  console.log(ex);
                  const msg = parseToApiErrorMessage(ex, 'Failed to signup');
                  enqueueSnackbar(t(msg), { variant: 'error' });
                }
                return isSuccess;
              }}
            >
              <FormikStep
                validationSchema={Yup.object().shape({
                  email: Yup.string()
                    .required(t('The email field is required'))
                    .email(
                      t('The email provided should be a valid email address')
                    )
                    .max(255)
                    .test(
                      'Unique Email',
                      'Email already in use',
                      async (value) => {
                        if (!value) return false;
                        const result = await checkAccountExistsByEmail(value);
                        return !result.data;
                      }
                    ),
                  firstName: Yup.string()
                    .max(255)
                    .required(t('The first name field is required')),
                  cardType:
                    reqType.name == 'nfcmap'
                      ? Yup.string().required(t('The cardType is required'))
                      : null,
                  lastName: Yup.string()
                    .max(255)
                    .required(t('The first name field is required')),
                  password: Yup.string()
                    .min(8)
                    .max(255)
                    .required(t('The password field is required')),
                  password_confirm: Yup.string()
                    .oneOf(
                      [Yup.ref('password')],
                      t('Both password fields need to be the same')
                    )
                    .required(t('This field is required')),
                  phone: Yup.string().matches(
                    phoneRegExp,
                    'Phone number is not valid'
                  ),
                  countryCode: Yup.string(),
                  terms: Yup.boolean().oneOf(
                    [true],
                    t('You must agree to our terms and conditions')
                  )
                })}
                label={t('Account Information')}
              >
                <Box p={4}>
                  <Grid container spacing={4}>
                    <Grid item xs={12} md={12}>
                      <Box
                        display="flex"
                        justifyContent={'space-between'}
                        textAlign="center"
                      >
                        <FormControl sx={{ minWidth: 120 }}>
                          <InputLabel id="demo-select-small">
                            I am a/an
                          </InputLabel>
                          <Select
                            labelId="demo-select-small"
                            id="demo-select-small"
                            value={isBusiness ? 1 : 2}
                            label="I am a/an"
                            onChange={(e) => setIsBusiness(e.target.value == 1)}
                            disabled={paymentPlan != null}
                          >
                            <MenuItem value={1}>Business</MenuItem>
                            <MenuItem value={2}>Individual</MenuItem>
                          </Select>
                        </FormControl>

                        <Box textAlign={'left'}>
                          {reqType.name == 'nfcmap' && (
                            <Field name="cardType">
                              {({ field, form, meta }: FieldProps) => (
                                <>
                                  <FormControl sx={{ minWidth: 120 }}>
                                    <InputLabel id="demo-cardType-small">
                                      Card type
                                    </InputLabel>
                                    <Select
                                      labelId="demo-cardType-small"
                                      value={field.value}
                                      onChange={(e) =>
                                        form.setFieldValue(
                                          'cardType',
                                          e.target.value
                                        )
                                      }
                                    >
                                      {CardTypes.map((o) => (
                                        <MenuItem key={o.code} value={o.code}>
                                          {o.value}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                  <FormHelperText
                                    error={Boolean(form.errors.cardType)}
                                  >
                                    Select card type you tapped
                                  </FormHelperText>
                                </>
                              )}
                            </Field>
                          )}
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Field
                        fullWidth
                        name="firstName"
                        component={TextField}
                        label={t('First name')}
                        placeholder={t('Write your first name here...')}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Field
                        fullWidth
                        name="lastName"
                        component={TextField}
                        label={t('Last name')}
                        placeholder={t('Write your last name here...')}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Field
                        fullWidth
                        name="email"
                        component={TextField}
                        label={t('Email')}
                        placeholder={t('Write your email here...')}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} />
                    <Grid item xs={12} md={6}>
                      <Field
                        fullWidth
                        type={showPassword ? 'text' : 'password'}
                        name="password"
                        component={TextField}
                        label={t('Password')}
                        placeholder={t('Enter password here...')}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Field
                        fullWidth
                        type={showPassword ? 'text' : 'password'}
                        name="password_confirm"
                        component={TextField}
                        label={t('Confirm password')}
                        placeholder={t('Confirm password here...')}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Field name="countryCode">
                        {(props) => <CountryCodeSlector {...props} />}
                      </Field>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Field
                        fullWidth
                        name="phone"
                        type="text"
                        component={TextField}
                        label={t('Phone number')}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      {/*<Field*/}
                      {/*    name="promo"*/}
                      {/*    type="checkbox"*/}
                      {/*    component={CheckboxWithLabel}*/}
                      {/*    Label={{*/}
                      {/*        label: t(*/}
                      {/*            'Yes, I want to receive monthly promotional materials.'*/}
                      {/*        )*/}
                      {/*    }}*/}
                      {/*/>*/}
                      {/*<br />*/}
                      <TermsField />
                    </Grid>
                  </Grid>
                </Box>
              </FormikStep>
              <FormikStep
                validationSchema={Yup.object().shape({
                  verificationCode: Yup.string()
                    .max(6)
                    .required(t('The verification code field is required')),
                  isEmailVerified: Yup.boolean()
                    .oneOf([true], 'Please verify code first!')
                    .required(t('Please verify code first!'))
                })}
                label={t('Email Verification')}
              >
                <EmailVerifyStep />
              </FormikStep>
              {isBusiness && (
                <FormikStep
                  validationSchema={Yup.object().shape({
                    businessName: Yup.string()
                      .max(255)
                      .required(t('The buisness name field is required')),
                    website: Yup.string()
                      .max(255)
                      .matches(urlRegExp, 'Enter correct url!'),
                    description: Yup.string().max(255),
                    headline: Yup.string().max(255)
                  })}
                  label={t('Business Details')}
                >
                  <Box p={4}>
                    <Grid container spacing={4}>
                      <Grid item xs={12} md={6}>
                        <FeatureImageField name="logo" title="Business Logo" />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Stack spacing={3}>
                          <Field
                            fullWidth
                            name="businessName"
                            component={TextField}
                            label={t('Business name')}
                          />

                          <Field
                            fullWidth
                            name="description"
                            multiline
                            rows={4}
                            component={TextField}
                            label={t('Business Description')}
                          />
                          <Field
                            fullWidth
                            name="website"
                            type="text"
                            component={TextField}
                            label={t('Website')}
                          />
                        </Stack>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        display={{ xs: 'none', md: 'block' }}
                      />

                      <Grid item xs={12} md={6}></Grid>
                    </Grid>
                  </Box>
                </FormikStep>
              )}

              {!isBusiness && (
                <FormikStep
                  validationSchema={Yup.object().shape({
                    facebookUrl: Yup.string()
                      .max(255)
                      .matches(urlRegExp, 'Enter correct url!'),
                    twitterUrl: Yup.string()
                      .max(255)
                      .matches(urlRegExp, 'Enter correct url!'),
                    dateOfBirth: Yup.string().matches(
                      dateRegExp,
                      'Enter correct date! (MM/DD/YYYY)'
                    )
                  })}
                  label={t('Personal Information')}
                >
                  <Box p={4}>
                    <Grid container spacing={4}>
                      <Grid item xs={12} md={12}>
                        <FeatureImageField
                          name="imageUrl"
                          title="Profile Image"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field
                          fullWidth
                          name="dateOfBirth"
                          component={TextField}
                          label={t('Date of birth (MM/DD/YYYY)')}
                          placeholder={t('Write your date of birth here...')}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field
                          fullWidth
                          name="facebookUrl"
                          component={TextField}
                          label={t('Facebook')}
                          placeholder={t('Write your facebook id here...')}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field
                          fullWidth
                          name="twitterUrl"
                          component={TextField}
                          label={t('Twitter')}
                          placeholder={t('Write your twitter id here...')}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} />
                      <Grid item xs={12} md={6}>
                        <Field
                          fullWidth
                          type="text"
                          name="alternateEmail"
                          component={TextField}
                          label={t('Alternate Email')}
                          placeholder={t('Write a email here...')}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Field
                          fullWidth
                          type="number"
                          name="alternateContact"
                          component={TextField}
                          label={t('Alternate Contact')}
                          placeholder={t('Alternate contact here...')}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </FormikStep>
              )}
              {paymentPlan != null && (
                <FormikStep label="Purchase Plan">
                  <StripeElement
                    smProviderId={smProviderId}
                    isBusiness={isBusiness}
                    planName={paymentPlan.id}
                  />
                </FormikStep>
              )}
              {!paymentPlan && (
                <FormikStep label={t('Complete Registration')}>
                  <Box px={4} py={8}>
                    <Container maxWidth="sm">
                      <AvatarSuccess>
                        <CheckTwoToneIcon />
                      </AvatarSuccess>
                      <Collapse in={openAlert}>
                        <Alert
                          sx={{
                            mt: 3
                          }}
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setOpenAlert(false);
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                          severity="info"
                        >
                          {t(
                            'A welcome mail has been sent to your email address'
                          )}
                        </Alert>
                      </Collapse>
                      <Typography
                        align="center"
                        variant="h5"
                        color="text.primary"
                        fontWeight="bold"
                        sx={{
                          marginTop: 1,
                          mb: 0
                        }}
                      >
                        <Field name="email">
                          {({ field, meta }) =>
                            field.value ? field.value : ''
                          }
                        </Field>
                      </Typography>
                      <Typography
                        align="center"
                        variant="h4"
                        color="text.secondary"
                        fontWeight="normal"
                        sx={{
                          marginTop: 3,
                          mb: 3
                        }}
                      >
                        {t('Click below to sign in & start using your account')}
                      </Typography>

                      <Button fullWidth variant="contained" href={'/'}>
                        {'Continue to sign in'}
                      </Button>
                    </Container>
                  </Box>
                </FormikStep>
              )}
            </FormikStepper>
          </Card>
        </Container>
      </MainContent>
    </>
  );
}

export interface FormikStepProps
  extends Pick<FormikConfig<FormikValues>, 'children' | 'validationSchema'> {
  label: string;
}

export function FormikStep({ children }: FormikStepProps) {
  return <>{children}</>;
}

export function FormikStepper({
  children,
  ...props
}: FormikConfig<FormikValues>) {
  const childrenArray = Children.toArray(
    children
  ) as ReactElement<FormikStepProps>[];
  const [step, setStep] = useState(0);
  const currentChild = childrenArray[step];
  const [completed, setCompleted] = useState(false);
  const { t }: { t: any } = useTranslation();
  const ref = useRef(null);
  const [searchParams] = useSearchParams();
  const source = searchParams.get('source');
  const retUrl = searchParams.get('returl');

  function isLastStep() {
    return step === childrenArray.length - (source?.length > 0 ? 1 : 2);
  }
  useEffect(() => {
    const scrollToTop = () => {
      setTimeout(() => {
        ref.current.scrollIntoView({ behavior: 'smooth' });
      }, 500);
    };
    scrollToTop();
  }, [step]);

  return (
    <Formik
      {...props}
      validationSchema={currentChild.props.validationSchema}
      onSubmit={async (values, helpers) => {
        if (isLastStep()) {
          if (!source?.length) {
            const isSuccess = await props.onSubmit(values, helpers);
            if (isSuccess) {
              setCompleted(true);
              setStep((s) => s + 1);
            }
          } else {
            setCompleted(true);
            setStep((s) => s + 1);
          }
        } else {
          setStep((s) => s + 1);
          helpers.setTouched({});
        }
      }}
    >
      {({ isSubmitting, errors, touched, values }) => {
        return (
          <Form ref={ref} autoComplete="off">
            <Stepper alternativeLabel activeStep={step}>
              {childrenArray.map((child, index) => (
                <Step
                  key={child.props.label}
                  completed={step > index || completed}
                >
                  <StepLabel>{child.props.label}</StepLabel>
                </Step>
              ))}
            </Stepper>

            {currentChild}
            {!completed ? (
              <BoxActions
                p={4}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                {retUrl && step === 0 ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    type="button"
                    onClick={() => window.location.replace(retUrl)}
                  >
                    {t('Go back')}
                  </Button>
                ) : (
                  <Button
                    disabled={isSubmitting || step === 0}
                    variant="outlined"
                    color="primary"
                    size="small"
                    type="button"
                    onClick={() => setStep((s) => s - 1)}
                  >
                    {t('Previous')}
                  </Button>
                )}
                <Button
                  startIcon={
                    isSubmitting ? <CircularProgress size="1rem" /> : null
                  }
                  disabled={
                    (isLastStep() && source?.length > 0) || isSubmitting
                  }
                  variant="contained"
                  color="primary"
                  size="small"
                  type="submit"
                >
                  {isSubmitting
                    ? t('Submitting')
                    : isLastStep()
                    ? source?.length > 0
                      ? t('Finish Payment')
                      : t('Complete Registration')
                    : t('Next step')}
                </Button>
              </BoxActions>
            ) : null}
          </Form>
        );
      }}
    </Formik>
  );
}

function TermsField() {
  const [field, meta, helpers] = useField('terms');
  return (
    <>
      <Field
        name="terms"
        type="checkbox"
        component={CheckboxWithLabel}
        Label={{
          label: (
            <Typography variant="body2">
              {'I accept the'}{' '}
              <Link component="a" href="#">
                {'terms and conditions'}
              </Link>
              .
            </Typography>
          )
        }}
      />
      {Boolean(meta.touched && meta.error) && (
        <FormHelperText error>{meta.error}</FormHelperText>
      )}
    </>
  );
}
export default SignUpWizard;
