import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone';
import {
  Alert,
  Avatar,
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  styled
} from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import PageTitleWrapper from 'src/ui-bloom/components/PageTitleWrapper';
import PageHeader from './PageHeader';

const UploadBox = styled(Box)(
  ({ theme }) => `
    border-radius: ${theme.general.borderRadius};
    padding: ${theme.spacing(2)};
    background: ${theme.colors.alpha.trueWhite[10]};
`
);

const TypographyPrimary = styled(Typography)(
  ({ theme }) => `
    color: ${theme.colors.alpha.trueWhite[100]};
  `
);

const TypographySecondary = styled(Typography)(
  ({ theme }) => `
    color: ${theme.colors.alpha.trueWhite[70]};
  `
);

const DividerContrast = styled(Divider)(
  ({ theme }) => `
    background: ${theme.colors.alpha.trueWhite[10]};
  `
);

const AvatarSuccess = styled(Avatar)(
  ({ theme }) => `
    background: ${theme.colors.success.light};
    width: ${theme.spacing(7)};
    height: ${theme.spacing(7)};
`
);

const AvatarDanger = styled(Avatar)(
  ({ theme }) => `
    background: ${theme.colors.error.light};
    width: ${theme.spacing(7)};
    height: ${theme.spacing(7)};
`
);
const AvatarWrapper = styled(Avatar)(
  ({ theme }) => `
    background: ${theme.colors.alpha.trueWhite[10]};
    width: ${theme.spacing(7)};
    height: ${theme.spacing(7)};
`
);

const BoxUploadWrapper = styled(Box)(
  ({ theme }) => `
    border-radius: ${theme.general.borderRadius};
    padding: ${theme.spacing(2)};
    margin-top: ${theme.spacing(2)};
    background: ${theme.colors.alpha.trueWhite[10]};
    border: 1px dashed ${theme.colors.alpha.trueWhite[30]};
    outline: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: ${theme.transitions.create(['border', 'background'])};

    &:hover {
      background: ${theme.colors.alpha.trueWhite[5]};
      border-color: ${theme.colors.alpha.trueWhite[70]};
    }
`
);

export default function UploadContacts() {
  const { t }: { t: any } = useTranslation();

  const {
    acceptedFiles,
    isDragActive,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps
  } = useDropzone({
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg']
    }
  });

  const files = acceptedFiles.map((file, index) => (
    <ListItem disableGutters component="div" key={index}>
      <ListItemText primary={file.name} />
      <b>{file.size} bytes</b>
      <DividerContrast />
    </ListItem>
  ));

  return (
    <>
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>
      <Grid
        sx={{
          px: 4
        }}
        container
        direction="row"
        spacing={4}
      >
        <Grid item xs={1} md={4}></Grid>
        <Grid item xs={10} md={4} mt={4}>
          <Box
            sx={{
              background:
                'linear-gradient(127.55deg, #141E30 3.73%, #243B55 92.26%)',
              color: '#ffffff'
            }}
          >
            <UploadBox>
              <TypographyPrimary variant="h4" gutterBottom>
                {t('Quick Upload')}
              </TypographyPrimary>
              <TypographySecondary variant="body1">
                {t(
                  'Need a quick upload widgets? Search no more and use this one.'
                )}
              </TypographySecondary>

              <BoxUploadWrapper {...getRootProps()}>
                <input {...getInputProps()} />
                {isDragAccept && (
                  <>
                    <AvatarSuccess variant="rounded">
                      <CheckTwoToneIcon />
                    </AvatarSuccess>
                    <TypographyPrimary
                      sx={{
                        mt: 2
                      }}
                    >
                      {t('Drop the files to start uploading')}
                    </TypographyPrimary>
                  </>
                )}
                {isDragReject && (
                  <>
                    <AvatarDanger variant="rounded">
                      <CloseTwoToneIcon />
                    </AvatarDanger>
                    <TypographyPrimary
                      sx={{
                        mt: 2
                      }}
                    >
                      {t('You cannot upload these file types')}
                    </TypographyPrimary>
                  </>
                )}
                {!isDragActive && (
                  <>
                    <AvatarWrapper variant="rounded">
                      <CloudUploadTwoToneIcon />
                    </AvatarWrapper>
                    <TypographyPrimary
                      sx={{
                        mt: 2
                      }}
                    >
                      {t('Drag & drop files here')}
                    </TypographyPrimary>
                  </>
                )}
              </BoxUploadWrapper>
              {files.length > 0 && (
                <>
                  <Alert
                    sx={{
                      py: 0,
                      mt: 2
                    }}
                    severity="success"
                  >
                    {t('You have uploaded')} <b>{files.length}</b> {t('files')}!
                  </Alert>
                  <DividerContrast
                    sx={{
                      mt: 2
                    }}
                  />
                  <List disablePadding component="div">
                    {files}
                  </List>
                </>
              )}
            </UploadBox>
          </Box>
        </Grid>
        <Grid item xs={1} md={4}></Grid>
      </Grid>
    </>
  );
}
