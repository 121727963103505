import { CardHeader, Grid, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { useTheme } from '@mui/material/styles';
import * as React from 'react';

import { useMemo } from 'react';
import FieldRenderEditorBaseProp from '../_base/FieldRenderEditorBaseProp';
import FooterActions from '../_base/FooterActions';
import readFieldValue from '../_base/readFieldValue';

export interface GoogleReviewType {
  title?: string;
  subTitle?: string;
  url: string;
}

export default function GoogleReviewEditor(props: FieldRenderEditorBaseProp) {
  const theme = useTheme();

  const fieldValue = useMemo(() => {
    const defaultVal: GoogleReviewType = {
      url: ''
    };
    return readFieldValue(props.field, defaultVal);
  }, [props.field]);

  const [title, setTitle] = React.useState<string>(fieldValue.title);
  const [subTitle, setSubTitle] = React.useState<string>(fieldValue.subTitle);
  const [url, setUrl] = React.useState<string>(fieldValue.url);
  const [isFormValid, setIsFormValid] = React.useState(false);

  React.useEffect(() => {
    setIsFormValid((o) => url && url.trim().length > 0);
  }, [url]);

  const onSave = () => {
    props.onSave(
      props.field,
      {
        config: JSON.stringify({
          title,
          subTitle,
          url
        })
      },
      true
    );
  };
  return (
    <Card
      sx={{ overflow: 'hidden', textAlign: 'left', border: 'solid 1px #ccc' }}
    >
      <CardHeader title={props.field.fieldRef.displayName} />

      <Box m={1} mt={0} display="flex" flexDirection={'column'}>
        <form noValidate style={{ width: '100%' }}>
          <Grid sx={{ padding: 0 }} container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                autoFocus={true}
                id={'url'}
                required={true}
                name={'url'}
                label={'Url'}
                fullWidth
                value={url || ''}
                // error={!!validate(field, values[field.name])}
                // helperText={validate(field, values[field.name])}
                onChange={(e) => {
                  setUrl(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                autoFocus={false}
                id={'title'}
                required={false}
                name={'title'}
                label={'Title'}
                fullWidth
                value={title || ''}
                // error={!!validate(field, values[field.name])}
                // helperText={validate(field, values[field.name])}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                autoFocus={false}
                id={'subTitle'}
                required={false}
                name={'subTitle'}
                label={'Subtitle'}
                fullWidth
                value={subTitle || ''}
                // error={!!validate(field, values[field.name])}
                // helperText={validate(field, values[field.name])}
                onChange={(e) => {
                  setSubTitle(e.target.value);
                }}
              />
            </Grid>
          </Grid>
        </form>
      </Box>
      <FooterActions
        isSaveDisabled={!isFormValid}
        onDelete={() => props.onDelete(props.field)}
        onSave={onSave}
        onCancel={props.onCancel}
      />
    </Card>
  );
}
