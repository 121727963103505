import {
  Box,
  CardHeader,
  Card,
  List,
  ListItemAvatar,
  ListItem,
  ListItemText,
  Typography,
  Divider,
  useTheme,
  styled,
  Tooltip,
  IconButton,
  alpha,
  Button,
  LinearProgress,
  Zoom
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import PersonOutlineTwoToneIcon from '@mui/icons-material/PersonOutlineTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import EditTwoTone from '@mui/icons-material/EditTwoTone';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import LeadCategoryEditorDialog from './lead-category-editor-dialog';
import { useCallback, useEffect, useState } from 'react';
import DeleteConfirm from '../../../components/delete-confirm';
import useRefMounted from 'src/ui-bloom/hooks/useRefMounted';
import { EmployeeListItem } from 'src/services/apiService/response-models/data-views';
import {
  getLeadSettingsByClientId,
  saveLeadSettings
} from 'src/services/nestApi';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import {
  LeadAsignment,
  LeadSettings
} from 'src/services/nestApi/responseModels';
import { getEmployeeListItems } from 'src/services/apiService/data-view-service';
import { useSnackbar } from 'notistack';
import { parseToApiErrorMessage } from 'src/utility/parseToApiErrorMessage';
import ErrorPanel from 'src/components/error-panel';

export interface Settings {
  category: string;
  assignToAdmin: boolean;
  assignToOwner: boolean;
  assignTo: number;
}

const DotLegend = styled('span')(
  ({ theme }) => `
      border-radius: 22px;
      width: ${theme.spacing(1.8)};
      height: ${theme.spacing(1.8)};
      display: inline-block;
      margin-right: ${theme.spacing(0.8)};
      border: ${theme.colors.alpha.white[100]} solid 2px;
  `
);

const ListItemWrapper = styled(ListItem)(
  ({ theme }) => `
      position: relative;
      transition: ${theme.transitions.create(['background'])};

      .MuiActionButtons {
            background: ${alpha(theme.colors.alpha.white[100], 0.95)};
            border-radius: ${theme.general.borderRadius};
            opacity: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            visibility: hidden;
            top: 50%;
            margin-top: -${theme.spacing(3.5)};
            position: absolute;
            right: ${theme.spacing(1.5)};
            padding: ${theme.spacing(0.5, 1)};
            transition: ${theme.transitions.create(['visibility', 'opacity'])};

            .MuiIconButton-root {
                border-radius: 100px;
                width: ${theme.spacing(5)};
                height: ${theme.spacing(5)};
                margin: ${theme.spacing(0.5)};
            }
      }

      &:hover {
          background: ${alpha(theme.colors.secondary.main, 0.15)};

          .MuiActionButtons {
            visibility: visible;
            opacity: 1;
      }   
      }
  `
);

function LeadSetting() {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const isMountedRef = useRefMounted();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState('');
  const [employeeListItems, setEmployeeListItems] = useState<
    EmployeeListItem[]
  >([]);
  const [leadSetting, setLeadSetting] = useState<LeadSettings>();
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const { user } = useSelector((state: RootState) => state.app);
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState<string>('');
  const [editLeadCategory, setEditLeadCategory] = useState<Settings>();
  const fetchLeadSettings = useCallback(async () => {
    try {
      const response = await getLeadSettingsByClientId(user.clientAccountId);
      if (isMountedRef.current) {
        setLeadSetting(response.data);
      }
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      setError(err);
      setIsLoading(false);
    }
  }, [isMountedRef]);

  const fetchEmployeeList = useCallback(
    async (
      callback: (
        hasError: boolean,
        employeeListItems: EmployeeListItem[]
      ) => void
    ) => {
      if (isMountedRef.current) {
        const result = await getEmployeeListItems();
        callback(false, result.data);
      }
    },
    [isMountedRef]
  );

  useEffect(() => {
    setIsLoading(true);

    fetchEmployeeList((hasError, empListItems) => {
      if (empListItems) {
        setEmployeeListItems(empListItems || []);
      }
    });
    fetchLeadSettings();
  }, [fetchLeadSettings, fetchEmployeeList]);

  const onDelete = (category: string) => {
    setCategoryToDelete(category);
    setIsDeleteConfirmOpen(true);
  };

  const onDeleteConfirm = async () => {
    try {
      setIsSaving(true);
      const leadSettingsModel: LeadSettings = {
        clientAccountId: user.clientAccountId,
        leadCategories: leadSetting?.leadCategories.filter(
          (c) => c !== categoryToDelete
        ),
        leadAssignment: {
          ...leadSetting?.leadAssignment,
          [categoryToDelete]: undefined
        }
      };
      const res = await saveLeadSettings(leadSettingsModel);
      setLeadSetting(res.data);
      setIsSaving(false);
      setIsDeleteConfirmOpen(false);
      enqueueSnackbar(t('category has been deleted successfully'), {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        },
        TransitionComponent: Zoom
      });
    } catch (error) {
      console.error(error);
      setIsSaving(false);
    }
  };

  const handleSave = async (settings: Settings) => {
    try {
      setIsSaving(true);
      const leadAssignmentMap = {
        ...leadSetting?.leadAssignment,
        [settings.category]: {
          assignToAdmin: settings.assignToAdmin,
          assignToOwner: settings.assignToOwner,
          assignTo: settings.assignTo
        }
      };
      const leadSettingsModel: LeadSettings = {
        clientAccountId: user.clientAccountId,
        leadCategories: [
          ...new Set([...leadSetting?.leadCategories, settings.category])
        ],
        leadAssignment: leadAssignmentMap
      };
      const res = await saveLeadSettings(leadSettingsModel);
      setLeadSetting(res.data);
      setEditLeadCategory(undefined);
      setIsDialogOpen(false);
      enqueueSnackbar(t('A new category has been created successfully'), {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        },
        TransitionComponent: Zoom
      });
    } catch (ex) {
      console.log(ex);
      const msg = parseToApiErrorMessage(ex, 'Failed to save');
      enqueueSnackbar(t(msg), {
        variant: 'error'
      });
      setIsSaving(false);
    }
    setIsSaving(false);
  };
  if (isLoading || !leadSetting)
    return <LinearProgress sx={{ width: '100%' }} />;

  if (!isLoading && error.length) return <ErrorPanel title={error} />;
  return (
    <Card variant="outlined">
      <CardHeader
        sx={{
          p: 2,
          background: `${theme.colors.alpha.black[5]}`
        }}
        disableTypography
        title={
          <Typography
            variant="h4"
            sx={{
              fontSize: `${theme.typography.pxToRem(16)}`
            }}
          >
            {t('Lead Categories')}
          </Typography>
        }
        action={
          <Button
            variant="contained"
            size="small"
            endIcon={<AddTwoToneIcon />}
            color="primary"
            onClick={() => setIsDialogOpen(true)}
          >
            {t('Add Category')}
          </Button>
        }
      />
      <Divider />
      <List disablePadding>
        {leadSetting.leadCategories.map((category) => (
          <>
            <ListItemWrapper
              key={category}
              disableGutters
              sx={{
                alignItems: 'flex-start',
                p: 3.15
              }}
            >
              <Box className="MuiActionButtons">
                <Tooltip arrow placement="top" title={t('Edit lead category')}>
                  <IconButton
                    onClick={() => {
                      const assignTo = employeeListItems.find(
                        (item) =>
                          item.id ===
                          leadSetting.leadAssignment[category].assignTo
                      );
                      setEditLeadCategory({
                        category,
                        ...leadSetting.leadAssignment[category],
                        assignTo: assignTo && {
                          id: assignTo.id,
                          fullName: assignTo.fullName
                        }
                      });
                      setIsDialogOpen(true);
                    }}
                    color="primary"
                  >
                    <EditTwoTone fontSize="small" />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  arrow
                  placement="top"
                  title={t('Delete lead category')}
                >
                  <IconButton onClick={() => onDelete(category)} color="error">
                    <CloseTwoToneIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>
              <ListItemAvatar
                sx={{
                  minWidth: 0
                }}
              >
                <DotLegend
                  style={{
                    background: `${theme.colors.success.main}`
                  }}
                />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography lineHeight={1} gutterBottom variant="h4">
                    {category}
                  </Typography>
                }
                //   secondary={
                //     <Typography
                //       variant="body2"
                //       sx={{
                //         color: `${theme.colors.alpha.black[80]}`
                //       }}
                //     >
                //       {t('Assigned to')}
                //     </Typography>
                //   }
              />
              <Box
                sx={{
                  textAlign: 'right'
                }}
                alignSelf="center"
              >
                <Typography variant="h5">
                  {leadSetting.leadAssignment[category].assignToAdmin
                    ? 'Admin'
                    : leadSetting.leadAssignment[category].assignToOwner
                    ? 'Owner'
                    : employeeListItems.find(
                        (item) =>
                          item.id ===
                          leadSetting.leadAssignment[category].assignTo
                      )?.email}
                </Typography>
                <Typography variant="subtitle2">{t('Assign To')}</Typography>
              </Box>
            </ListItemWrapper>
            <Divider key={category + 'devider'} />
          </>
        ))}
      </List>
      <LeadCategoryEditorDialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onSaved={(settings: Settings) => handleSave(settings)}
        employeeListItems={employeeListItems}
        isSaving={isSaving}
        editLeadCategory={editLeadCategory}
      />
      <DeleteConfirm
        open={isDeleteConfirmOpen}
        message="Lead Category"
        onDeleteConfirm={onDeleteConfirm}
        onClose={() => setIsDeleteConfirmOpen(false)}
      />
    </Card>
  );
}

export default LeadSetting;
