import { useCallback, useEffect, useState } from 'react';
import {
  Typography,
  Container,
  Button,
  Card,
  Box,
  styled,
  CardContent,
  CircularProgress,
  MenuItem,
  Grid,
  LinearProgress,
  CardHeader
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { Select, TextField } from 'formik-mui';
import * as Yup from 'yup';

import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import Logo from 'src/ui-bloom/components/LogoSign';
import {
  deactivateAccount,
  forgotPassword,
  getReasonByToken,
  getUserByToken
} from 'src/services/apiService';
import {
  CodeMaster,
  RefData,
  User
} from 'src/services/apiService/response-models';
import { useSnackbar } from 'notistack';
import { parseToApiErrorMessage } from 'src/utility/parseToApiErrorMessage';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import useRefMounted from 'src/ui-bloom/hooks/useRefMounted';
import oAuth from '../../auth-service';
import ErrorPanel from '../../components/error-panel';

const MainContent = styled(Box)(
  () => `
      height: 100%;
      overflow: auto;
      flex: 1;
  `
);

const SelectBox = styled(Box)(
    () => `
      width: 100%;
      .MuiFormControl-root{
 width: 100%;
}
  `
);

function AccountDeletion() {
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [searchParams] = useSearchParams();
    const queryToken = searchParams.get('qt');

  const [user, setUser] = useState<User>(null);
  const [accountRefData, setAccountRefData] = useState<RefData>({
    accountDelReasons: []
  });
    const [error, setError] = useState('');

    const token = queryToken || oAuth.getToken();

    const loadData = async () => {

        if (!token) {
            setError('Empty token');
            return;
        }

        try {
            const data = await getReasonByToken('AccountDelReason', token);
            setAccountRefData({
                accountDelReasons: data.accountDelReasons || []
            });

            const userRes = await getUserByToken(token);
            setUser(userRes.data);
        } catch (ex) {
            console.error(ex);
            if (
                ex.response &&
                (ex.response.status === 404 || ex.response.status === 401)
            ) {
                setError('User/Account not found');
            }
            const msg = ex.message
                ? ex.message
                : `Failed to load user please reload the page`;
            enqueueSnackbar(t(msg), { variant: 'error' });
            setError(msg);
        }
    }

  useEffect(() => {
    setIsLoading(true);
      loadData().then(() => {
          setIsLoading(false);
      });
  }, []);

    if (error.length) {
        return (<Grid container ><Grid item m={3} xs={12}>
            <ErrorPanel title="Critical error" message={error} />
        </Grid>  </Grid>)
    }
  return isLoading || !user ? (
    <Grid item xs={12}>
      <LinearProgress />
    </Grid>
  ) : (
    <>
      <Helmet>
        <title>Delete Account @ KiEngage</title>
      </Helmet>
      <MainContent>
        <Container
          sx={{
            my: 4
          }}
          maxWidth="md"
        >
          <Logo />
          <Card
            sx={{
              mt: 3,
              pt: 4
            }}
          >
            <Box px={4}>
              <Typography
                variant="h3"
                sx={{
                  mb: 1
                }}
              >
                {t('Delete your account @ KiEngage')}
              </Typography>
              <Typography
                variant="h4"
                color="text.secondary"
                fontWeight="normal"
                sx={{
                  mb: 3
                }}
              >
                {`Hello ${user.fullName}, ` +
                  t('Before you go, please tell us why?')}
              </Typography>
            </Box>
            <CardContent
              sx={{
                px: 4
              }}
            >
              <Box>
                <Formik
                  initialValues={{
                    reason: ''
                  }}
                  validationSchema={Yup.object().shape({
                    reason: Yup.string().required(t('Reason is required'))
                  })}
                  onSubmit={async (values, { setSubmitting }) => {
                    setSubmitting(true);
                    try {
                      // const response = await forgotPassword(values.reason);
                      const response = await deactivateAccount(
                        values.reason,
                        token
                      );

                      if (response.status === 200) {
                        enqueueSnackbar(
                          t(
                            'You account is successfully deleted. you can close this page.'
                          ),
                          {
                            variant: 'success'
                          }
                        );
                      }
                    } catch (err) {
                      const message = parseToApiErrorMessage(
                        err,
                        `Failed to delete your account. Please try again later.`
                      );
                      enqueueSnackbar(message, {
                        variant: 'error'
                      });
                    } finally {
                      setSubmitting(false);
                    }
                  }}
                >
                  {({ errors, handleSubmit, isSubmitting, touched }) => (
                    <Form
                      onSubmit={(event) => {
                        event.preventDefault();
                        if (
                          window.confirm(
                            'Are you sure you wish to delete your account?'
                          )
                        ) {
                          handleSubmit();
                        }
                      }}
                    >
                      {isLoading && (
                        <Grid item xs={12}>
                          <LinearProgress />
                        </Grid>
                      )}
                        <SelectBox sx={{ mb: 3 }}>
                            <Field
                          name="reason"
                          component={Select}
                          SelectProps={{style:'100%'}}
                          label={t('Reason for leaving')}
                                                  >
                          {accountRefData.accountDelReasons.map(
                            (item: CodeMaster) => (
                              <MenuItem key={item.code} value={item.code}>
                                {item.value}
                              </MenuItem>
                            )
                                                          )}
                        </Field>
                        </SelectBox>
                      <Box sx={{ py: 2 }}>
                        <Button
                          size="large"
                          variant="outlined"
                          color="secondary"
                                                      onClick={() => window.location.replace('https://kiengage.com')}
                        >
                          {t('Cancel')}
                        </Button>
                        <Button
                          color="error"
                          disabled={
                            isSubmitting || (errors.reason && touched.reason)
                          }
                          startIcon={
                            isSubmitting ? (
                              <CircularProgress size="1rem" />
                            ) : null
                          }
                          size="large"
                          type="submit"
                          variant="contained"
                          sx={{
                            mx: 1,
                            px: 3
                          }}
                        >
                          {t('Confirm delete')}
                        </Button>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </Box>
            </CardContent>
          </Card>
        </Container>
      </MainContent>
    </>
  );
}

export default AccountDeletion;
