import {
  Avatar,
  Box,
  Button,
  Card,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Typography,
  alpha,
  styled,
  useMediaQuery,
  useTheme
} from '@mui/material';

import ArrowForwardTwoToneIcon from '@mui/icons-material/ArrowForwardTwoTone';
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BusinessProfileRef } from '../../../BusinessApp/services/models';
import { getCardById } from '../../../services/apiService';
import {
  CardTypes,
  DigitalCardListItem
} from '../../../services/apiService/response-models';
import cdnService from '../../../services/cdnService';
import { getPageView } from '../../../services/nestApi';
import { convertCardToProfile } from '../../../utility/convertCardToProfile';
import { getWithExpiry, setWithExpiry } from './utils/set-get-withExpiry';

const CardActions = styled(Box)(
  ({ theme }) => `
    position: absolute;
    right: ${theme.spacing(2)};
    bottom: ${theme.spacing(2)};
    z-index: 7;
    display: flex;
  `
);

const LabelWrapper = styled(Box)(
  ({ theme }) => `
    background: ${theme.palette.success.main};
    color: ${theme.palette.success.contrastText};
    text-transform: uppercase;
    font-size: ${theme.typography.pxToRem(10)};
    font-weight: bold;
    line-height: 23px;
    height: 22px;
    padding: ${theme.spacing(0, 1.2)};
    border-radius: 50px;
  `
);

const AvatarWrapper = styled(Avatar)(
  ({ theme }) => `
        width: ${theme.spacing(10)};
        height: ${theme.spacing(10)};
        box-shadow: ${theme.colors.shadows.primary};
        top: -${theme.spacing(5)};
        position: absolute;
        border: ${theme.colors.alpha.white[100]} solid 3px;
  `
);

const CACHE_KEY = 'get_analytics_TotalViewCardId';

function TotalViewCard(props: { cardItems: DigitalCardListItem[] }) {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  const [totalViews, setTotalViews] = useState('-');
  const [cardId, setCardId] = useState<number | null>(null);
  const [selectCardId, setSelectCardId] = useState<number | null>(null);
  const [cardProfile, setCardProfile] = useState<BusinessProfileRef | null>(
    null
  );
  const [open, setOpen] = useState(false);
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const items = _.orderBy(props.cardItems, (o) => o.digitalCardId);
    const itemsB = items.filter((o) => o.cardType == CardTypes.Business);

    let cardId = 0;

    cardId = getWithExpiry(CACHE_KEY);

    if (cardId) {
      setCardId(cardId);
    } else {
      if (itemsB.length) {
        cardId = itemsB[0].digitalCardId;
      } else if (items.length) {
        cardId = items[0].digitalCardId;
      }

      setCardId(cardId);
    }
  }, []);

  useEffect(() => {
    if (cardId) {
      getCardById(cardId).then((o) => {
        const profile = convertCardToProfile(o.data);
        setCardProfile(profile);
      });
      getPageView(cardId, new Date('2022-1-31'), new Date()).then((o) => {
        setTotalViews(o.data.totalViews + '');
      });
      const minutes = 10;
      setWithExpiry(CACHE_KEY, cardId, 60 * 1000 * minutes);
    }
  }, [cardId]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    setCardId(selectCardId);
    setOpen(false);
  };

  if (!cardId) return <></>;

  const noImage =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII=';

  return (
    <Card
      sx={{
        minWidth: mobile ? 400 : 800,
        transition: `${theme.transitions.create([
          'box-shadow',
          'transform',
          'border-radius'
        ])}`,
        transform: 'translateY(0px)',

        '&:hover': {
          transform: `translateY(-${theme.spacing(1)})`,
          boxShadow: `0 2rem 8rem 0 ${alpha(
            theme.colors.alpha.black[100],
            0.05
          )}, 
                    0 0.6rem 1.6rem ${alpha(
                      theme.colors.alpha.black[100],
                      0.15
                    )}, 
                    0 0.2rem 0.2rem ${alpha(
                      theme.colors.alpha.black[100],
                      0.1
                    )}`
        }
      }}
    >
      <Box
        sx={{
          position: 'relative'
        }}
      >
        <CardMedia
          component="img"
          height={cardProfile?.coverImage ? '250' : '70'}
          src={
            cardProfile?.coverImage
              ? cdnService.toImageUrl(cardProfile?.coverImage)
              : noImage
          }
          alt="..."
        />
        {/*<CardActions*/}
        {/*    sx={{*/}
        {/*        bottom: 'auto',*/}
        {/*        top: `${theme.spacing(2)}`,*/}
        {/*        right: 'auto',*/}
        {/*        left: `${theme.spacing(2)}`*/}
        {/*    }}*/}
        {/*>*/}
        {/*    <LabelWrapper*/}
        {/*        sx={{*/}
        {/*            background: `${theme.colors.gradients.blue1}`,*/}
        {/*            color: `${theme.colors.alpha.trueWhite[100]}`*/}
        {/*        }}*/}
        {/*    >*/}
        {/*        {t('New & Hot')}*/}
        {/*    </LabelWrapper>*/}
        {/*</CardActions>*/}
      </Box>
      <Box
        sx={{
          position: 'relative',
          px: 3,
          pt: 8,
          pb: 3
        }}
      >
        {cardProfile?.image && (
          <AvatarWrapper
            variant="rounded"
            sx={{
              boxShadow: 'none',
              borderWidth: 5
            }}
            src={cdnService.toImageUrl(cardProfile?.image)}
          />
        )}
        <Link
          lineHeight={1.5}
          sx={{
            transition: `${theme.transitions.create(['color'])}`,
            color: `${theme.colors.alpha.black[100]}`,

            '&:hover': {
              color: `${theme.colors.primary.main}`
            }
          }}
          color="text.primary"
          variant="h3"
          underline="none"
        >
          {t(cardProfile?.name)}
        </Link>
        <Typography
          variant="subtitle1"
          sx={{
            display: 'flex',
            alignItems: 'center',
            pt: 1
          }}
        >
          <VisibilityTwoToneIcon
            fontSize="small"
            sx={{
              opacity: 0.7,
              mr: 0.5
            }}
          />
          Total Views
        </Typography>
        <Typography
          variant="h1"
          sx={{
            py: 2
          }}
        >
          {t(totalViews)}
        </Typography>
        <Button
          variant="outlined"
          endIcon={<ArrowForwardTwoToneIcon />}
          onClick={() => setOpen(true)}
          sx={{
            borderWidth: '2px',
            '&:hover': {
              borderWidth: '2px'
            }
          }}
        >
          {t('Change Profile')}
        </Button>
      </Box>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Change profile</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
              width: '100%'
            }}
          >
            <FormControl sx={{ mt: 2, width: '100%' }}>
              <InputLabel htmlFor="max-width">Profile Select..</InputLabel>
              <Select
                fullWidth
                autoFocus
                value={selectCardId || cardId}
                onChange={(e) => {
                  setSelectCardId(e.target.value as number);
                }}
                label="maxWidth"
                inputProps={{
                  name: 'max-width',
                  id: 'max-width'
                }}
              >
                {props.cardItems.map((o) => (
                  <MenuItem key={o.digitalCardId} value={o.digitalCardId}>
                    {o.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={handleConfirm}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}

export default TotalViewCard;
