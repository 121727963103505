import { BusinessProfileRef } from "src/BusinessApp/services/models";
import { DigitalCard, DigitalCardListItem } from "src/services/apiService/response-models";
import { convertCardToProfile } from "./convertCardToProfile";

export function convertCardItemToCard(item: DigitalCardListItem): DigitalCard | null {
  if (!item)
    return null;
  const card: DigitalCard = {
    id: item.digitalCardId,
    name: item.label,
    fields: item.fields || [],
    cardAppItems: [],
    label: item.label,
    cardType: item.cardType,
    description: '',
    isActive: true,
    isOnline: true,
    parentId: item.parentId
  }
  return card;
}

export function convertCardItemToProfile(item: DigitalCardListItem): BusinessProfileRef {
  return convertCardToProfile(convertCardItemToCard(item));
}