export default function toLocDate(dt?: Date | string | null): Date | null {
    if (!dt)
        return null;

    if (typeof (dt) == 'string') {
        if ((dt as string).trim().endsWith('Z'))
            return new Date(dt);
        else
            return new Date(dt + 'Z');

    }

    return dt as Date;
}