import { Theme } from "@mui/material";

const getThemeColor = (theme:Theme, themeColor?:string) => {
    switch (themeColor) {
        case 'black':
            return theme.colors.gradients.black1;
        case 'blue':
            return 'linear-gradient(135deg, #7c80ce 0%, #2b2e5f 100%)';
    }
    return 'linear-gradient(to right, #EAEAEA, #DBDBDB, #F2F2F2, #ADA996)';
};

export default getThemeColor;