import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ReactPlayer from 'react-player/youtube';
import FieldRenderBlockBaseProp from '../_base/FieldRenderBlockBaseProp';
import { MediaPlayerType } from './editor';
import { useMemo } from 'react';
import readFieldValue from '../_base/readFieldValue';
import { Paper } from '@mui/material';

export default function MediaPlayerBlock(props: FieldRenderBlockBaseProp) {
  const theme = useTheme();
  const fieldValue = useMemo(() => {
    const defaultVal: MediaPlayerType = {
      url: ''
    };
    return readFieldValue(props.field, defaultVal);
  }, [props.field]);

  return (
    <Card
      style={{ width: '100%' }}
      sx={{
        backgroundColor: 'inherit',
        border: 'solid 1px #ccc',
        overflow: 'hidden'
      }}
    >
      {(fieldValue.title || fieldValue.subTitle) && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            color:
              props.themeColor === 'light'
                ? `${theme.colors.alpha.black[100]}`
                : `${theme.colors.alpha.trueWhite[100]}`
          }}
        >
          <CardContent sx={{ flex: '1 0 auto', pb: '5px!important' }}>
            {fieldValue.title && (
              <Typography component="div" variant="h5">
                {fieldValue.title}
              </Typography>
            )}
            {fieldValue.subTitle && (
              <Typography
                variant="subtitle1"
                color="text.secondary"
                component="div"
                sx={{
                  color:
                    props.themeColor === 'light'
                      ? `${theme.colors.alpha.black[100]}`
                      : `${theme.colors.alpha.trueWhite[100]}`
                }}
              >
                {fieldValue.subTitle}
              </Typography>
            )}
          </CardContent>
        </Box>
      )}
      {/*<video controls width="250">*/}
      {/*    <source src="/static/sample_960x540.webm" type={"video/webm"}/>*/}
      {/*</video>*/}
      <Box sx={{ width: '100%' }}>
        {!fieldValue.url ? (
          <Paper
            elevation={3}
            sx={{
              height: '200px',
              backgroundColor: '#d1d1d1',
              margin: 'auto',
              textAlign: 'center'
            }}
          >
            <img style={{ margin: '10%' }} src="/static/images/paper.png" />
          </Paper>
        ) : (
          <ReactPlayer
            height={'200px'}
            width={'100%'}
            url={fieldValue.url}
            controls
          />
        )}
      </Box>
    </Card>
  );
}
