import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import ScheduleTwoToneIcon from '@mui/icons-material/ScheduleTwoTone';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  Slide,
  styled,
  TextField,
  Typography
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { formatDistance } from 'date-fns';
import { Field, FieldProps, Formik } from 'formik';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import {
  forwardRef,
  ReactElement,
  Ref,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BusinessProfileRef } from 'src/BusinessApp/services/models';
import * as Yup from 'yup';
import {
  addLeadComment,
  createLead,
  deleteLeadComment,
  getLeadComments
} from '../../../services/apiService';
import { LeadRequest } from '../../../services/apiService/request-models';
import { LeadComment } from '../../../services/apiService/response-models';
import { RootState, useSelector } from '../../../store';

const Input = styled('input')({
  display: 'none'
});

const DividerWrapper = styled(Divider)(
  ({ theme }) => `
      .MuiDivider-wrapper {
        border-radius: ${theme.general.borderRadiusSm};
        text-transform: none;
        background: ${theme.palette.background.default};
        font-size: ${theme.typography.pxToRem(13)};
        color: ${theme.colors.alpha.black[50]};
      }
`
);

const CardWrapperPrimary = styled(Card)(
  ({ theme }) => `
      background: ${theme.colors.primary.main};
      color: ${theme.palette.primary.contrastText};
      padding: ${theme.spacing(2)};
      border-radius: ${theme.general.borderRadiusXl};
      border-top-right-radius: ${theme.general.borderRadius};
      max-width: 380px;
      display: inline-flex;
`
);

const AvatarWrapper = styled(Box)(
  ({ theme }) => `

    position: relative;

    .MuiAvatar-root {
      width: ${theme.spacing(16)};
      height: ${theme.spacing(16)};
    }
`
);

const ButtonUploadWrapper = styled(Box)(
  ({ theme }) => `
    position: absolute;
    width: ${theme.spacing(6)};
    height: ${theme.spacing(6)};
    bottom: -${theme.spacing(2)};
    right: -${theme.spacing(2)};

    .MuiIconButton-root {
      border-radius: 100%;
      background: ${theme.colors.primary.main};
      color: ${theme.palette.primary.contrastText};
      box-shadow: ${theme.colors.shadows.primary};
      width: ${theme.spacing(6)};
      height: ${theme.spacing(6)};
      padding: 0;
  
      &:hover {
        background: ${theme.colors.primary.dark};
      }
    }
`
);

function CreateLeadDialog(props: {
  profiles: BusinessProfileRef[];
  onClose?: () => void;
  onSave: () => void;
}) {
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { user } = useSelector((state: RootState) => state.app);
  const [model, setModel] = useState<LeadComment[]>([]);
  const [isSaveInProgress, setIsSaveInProgress] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const loadComments = async (leadId: string) => {
    try {
      const response = await getLeadComments(leadId);
      setModel(response.data);
    } catch (ex) {
      console.error(ex);
      const msg = ex.message ? ex.message : `Failed to load comments`;
      enqueueSnackbar(t(msg), { variant: 'error' });
    }
    setIsLoading(false);
  };

  const handleCreateClose = (e, reason?: string) => {
    console.log(reason);
    if (reason === 'backdropClick') return;

    props.onClose();
  };

  const onCommentAdded = async (e: {
    comment: string;
    attachement?: string;
  }) => {
    const cm: LeadComment = {
      id: Date.now() + '',
      leadId: '',
      comment: e.comment,
      attachement: e.attachement,
      createdBy: '',
      createdOn: new Date()
    };
    setModel([...model, cm]);
    await addLeadComment(cm);
  };

  // const getDefaultCardId = () => {
  //   if (!props.cardItems.length) return 0;

  //   const match = props.cardItems.find((o) => o.isActive && o.isActiveCard);
  //   if (match) return match.digitalCardId;

  //   return props.cardItems[0].digitalCardId;
  // };
  return (
    <Dialog
      disableEscapeKeyDown
      fullWidth={true}
      maxWidth={'sm'}
      open={true}
      onClose={handleCreateClose}
    >
      <DialogTitle
        sx={{
          p: 3
        }}
      >
        <Typography variant="h4" gutterBottom>
          {t('Add Lead')}
        </Typography>
        <Typography variant="subtitle2">{t('Add new lead')}</Typography>
        <IconButton
          sx={{ position: 'absolute', top: 10, right: 5 }}
          onClick={handleCreateClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Formik
        initialValues={{
          cardId: 0,
          firstName: '',
          lastName: '',
          contactEmail: '',
          contactMobile: '',
          remark: '',
          submit: null
        }}
        validationSchema={Yup.object().shape({
          firstName: Yup.string()
            .max(255)
            .required(t('The first name field is required')),
          lastName: Yup.string()
            .max(255)
            .required(t('The last name field is required')),
          contactEmail: Yup.string()
            .email(t('The email provided should be a valid email address'))
            .max(255)
            .required(t('The email field is required')),
          contactMobile: Yup.string().max(15, t('Contact number is too long')),
          cardId: Yup.number()
            .min(1, t('Please select a profile'))
            .required(t('Please select a profile')),
          remark: Yup.string().max(1000)
        })}
        onSubmit={async (
          _values,
          { resetForm, setErrors, setStatus, setSubmitting }
        ) => {
          try {
            setSubmitting(true);
            var updatedModel: LeadRequest = {
              ..._values,
              source: 'webApp',
              contactName: '',
              formData: {}
            };
            await createLead(updatedModel);
            resetForm();
            setStatus({ success: true });
            setSubmitting(false);
            props.onSave();
          } catch (err) {
            console.error(err);
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent
              dividers
              sx={{
                p: 3
              }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        error={Boolean(touched.firstName && errors.firstName)}
                        fullWidth
                        helperText={touched.firstName && errors.firstName}
                        label={t('First name')}
                        name="firstName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.firstName}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        error={Boolean(touched.lastName && errors.lastName)}
                        fullWidth
                        helperText={touched.lastName && errors.lastName}
                        label={t('Last name')}
                        name="lastName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.lastName}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        error={Boolean(
                          touched.contactEmail && errors.contactEmail
                        )}
                        fullWidth
                        helperText={touched.contactEmail && errors.contactEmail}
                        label={t('Email address')}
                        name="contactEmail"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="email"
                        value={values.contactEmail}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        error={Boolean(
                          touched.contactMobile && errors.contactMobile
                        )}
                        fullWidth
                        helperText={
                          touched.contactMobile && errors.contactMobile
                        }
                        label={t('Contact number')}
                        name="contactMobile"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.contactMobile}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Field name="cardId">
                        {({ field, form, meta }: FieldProps) => (
                          <>
                            <Autocomplete
                              disablePortal
                              getOptionLabel={(option: BusinessProfileRef) =>
                                option.name
                              }
                              value={props.profiles.find(
                                (o) => o.digitalCardId == field.value
                              )}
                              onChange={(
                                event: any,
                                newValue: BusinessProfileRef | null
                              ) => {
                                form.setFieldValue(
                                  field.name,
                                  newValue?.digitalCardId || 0
                                );
                              }}
                              options={props.profiles}
                              renderInput={(params) => (
                                <TextField
                                  fullWidth
                                  {...params}
                                  label={t('Associated Profile')}
                                  helperText={
                                    'Profile for which you received this lead'
                                  }
                                />
                              )}
                            />
                            {meta.error && (
                              <Box>
                                <FormHelperText error={true}>
                                  {meta.error}
                                </FormHelperText>
                              </Box>
                            )}
                          </>
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        multiline
                        rows={3}
                        error={Boolean(touched.remark && errors.remark)}
                        fullWidth
                        helperText={touched.remark && errors.remark}
                        label={t('Remark')}
                        name="remark"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.remark}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions
              sx={{
                p: 3
              }}
            >
              <Button color="secondary" onClick={props.onClose}>
                {t('Cancel')}
              </Button>
              <Button
                type="submit"
                startIcon={
                  isSubmitting ? <CircularProgress size="1rem" /> : null
                }
                disabled={Boolean(errors.submit) || isSubmitting}
                variant="contained"
              >
                {t('Save')}
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
}

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const AvatarError = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

const ButtonError = styled(Button)(
  ({ theme }) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.error.dark};
     }
    `
);

function CommentContent(props: { comments: LeadComment[] }) {
  const { t }: { t: any } = useTranslation();
  const [deleteCommentId, setDeleteCommentId] = useState('');
  const [comments, setComments] = useState<LeadComment[]>([]);

  useEffect(() => {
    setComments(props.comments);
  }, [props.comments]);

  const groups = useMemo(() => {
    return _.groupBy(comments, (e) => {
      const dt = new Date(e.createdOn);
      return (
        dt.toLocaleString('default', { month: 'long' }) +
        ' ' +
        dt.getDate() +
        ' ' +
        dt.getFullYear()
      );
    });
  }, [comments]);

  const closeConfirmDelete = () => {
    setDeleteCommentId('');
  };

  const handleDeleteCompleted = async () => {
    await deleteLeadComment(deleteCommentId);
    const cm = comments.filter((o) => o.id !== deleteCommentId);
    setDeleteCommentId('');
    setComments([...cm]);
  };

  return (
    <>
      <Box p={2}>
        {Object.keys(groups).map((group) => (
          <>
            <DividerWrapper>{group}</DividerWrapper>
            {groups[group].map((item) => (
              <Box
                display="flex"
                alignItems="flex-start"
                justifyContent="flex-end"
                py={3}
                key={item.id}
              >
                <Box
                  display="flex"
                  alignItems="flex-end"
                  flexDirection="column"
                  justifyContent="flex-end"
                  mr={2}
                >
                  <CardWrapperPrimary>{item.comment}</CardWrapperPrimary>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      pt: 1,
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <ScheduleTwoToneIcon
                      sx={{
                        mr: 0.5
                      }}
                      fontSize="small"
                    />
                    {formatDistance(new Date(item.createdOn), new Date(), {
                      addSuffix: true
                    })}
                    , by you
                  </Typography>
                </Box>
                <IconButton
                  onClick={() => setDeleteCommentId(item.id)}
                  aria-label="delete"
                  color="error"
                  size="large"
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            ))}
          </>
        ))}
      </Box>
      <DialogWrapper
        open={Boolean(deleteCommentId.length)}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        onClose={closeConfirmDelete}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          p={5}
        >
          <AvatarError>
            <CloseIcon />
          </AvatarError>

          <Typography
            align="center"
            sx={{
              py: 4,
              px: 6
            }}
            variant="h3"
          >
            {t('Are you sure you want to permanently delete this user account')}
            ?
          </Typography>

          <Box>
            <Button
              variant="text"
              size="large"
              sx={{
                mx: 1
              }}
              onClick={closeConfirmDelete}
            >
              {t('Cancel')}
            </Button>
            <ButtonError
              onClick={handleDeleteCompleted}
              size="large"
              sx={{
                mx: 1,
                px: 3
              }}
              variant="contained"
            >
              {t('Delete')}
            </ButtonError>
          </Box>
        </Box>
      </DialogWrapper>
    </>
  );
}

export default CreateLeadDialog;
