import { useState, useEffect, useCallback } from 'react';

import PageHeader from './PageHeader';
import Footer from 'src/ui-bloom/components/Footer';
import PageTitleWrapper from 'src/ui-bloom/components/PageTitleWrapper';

import { Grid } from '@mui/material';
import useRefMounted from 'src/ui-bloom/hooks/useRefMounted';

import Results from './Results';
import { getRefData } from '../../../services/apiService';
import { EmployeeListItem } from '../../../services/apiService/response-models/data-views';
import { RefData } from 'src/services/apiService/response-models';
import { getEmployeeListItems } from 'src/services/apiService/data-view-service';

function SelfReviewList() {
  const [reviewDataRefreshTriggerId, setLeadDataRefreshTriggerId] =
    useState('');
  const isMountedRef = useRefMounted();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  const [accountRefData, setAccountRefData] = useState<RefData>({
    selfReviewStatus: []
  });
  const [employeeListItems, setEmployeeListItems] = useState<
    EmployeeListItem[]
  >([]);

  const loadRefData = useCallback(
    async (
      callback: (
        hasError: boolean,
        data: RefData,
        employeeListItems: EmployeeListItem[]
      ) => void
    ) => {
      if (isMountedRef.current) {
        const [statusData, empListItems] = await Promise.all([
          getRefData('SelfReviewStatus'),
          getEmployeeListItems()
        ]);
        callback(false, statusData, empListItems.data);
      }
    },
    [isMountedRef]
  );

  useEffect(() => {
    loadRefData((hasError, data, empListItems) => {
      if (data) {
        setAccountRefData({
          selfReviewStatus: data.selfReviewStatus || []
        });
      }
      setEmployeeListItems(empListItems || []);
    });
  }, [loadRefData]);

  return (
    <>
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>

      <Grid
        sx={{
          px: 4
        }}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
      >
        <Grid item xs={12}>
          <Results
            isLoading={isLoading}
            accountRefData={accountRefData}
            employeeListItems={employeeListItems}
          />
        </Grid>
      </Grid>
      <Footer />
    </>
  );
}

export default SelfReviewList;
