import { NameItem } from './name-item';

export enum TaskType {
  Todo = 'Todo',
  Call = 'Call',
  Email = 'Email'
}

export enum TaskPriority {
  Low = 'Low',
  Medium = 'Medium',
  High = 'High'
}

export interface UserInfo {
  name?: string;
  email?: string;
  id?: string;
}

export interface TaskComment {
  comment: string;
  commentRich?: string;
  source?: string;
  timestamp?: string;
  createdOn?: Date;
  createdBy?: NameItem;
  updatedOn?: Date;
  updatedBy?: NameItem;
}

export interface AssociatedWithInfo {
  type: string;
  id: string;
  name?: string;
}

export interface Task {
  id?: string;
  title: string;
  dueDate: Date;
  reminderInMin?: number;
  isRecurring?: boolean;
  taskType: TaskType;
  status?: string;
  priority?: TaskPriority;
  assignedTo?: UserInfo;
  note?: string;
  noteRich?: string;
  associations: AssociatedWithInfo[];
  comments?: TaskComment[];
  createdOn?: Date;
  source?: string;
  createdBy?: NameItem;
  updatedOn?: Date;
  updatedBy?: NameItem;
}
