import {
    Avatar,
    Tooltip,
    IconButton,
    Box,
    Button,
    styled,
    InputBase,
    useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import AttachFileTwoToneIcon from '@mui/icons-material/AttachFileTwoTone';
import SendTwoToneIcon from '@mui/icons-material/SendTwoTone';
import { useState } from 'react';

const MessageInputWrapper = styled(InputBase)(
    ({ theme }) => `
    font-size: ${theme.typography.pxToRem(18)};
    padding: ${theme.spacing(1)};
    width: 100%;
`
);

const Input = styled('input')({
    display: 'none'
});

function BottomBar(props: { onAdd: (e: { comment: string, attachement?: string }) => void }) {
    const { t }: { t: any } = useTranslation();
    const theme = useTheme();
    const [isUploading, setIsUploading] = useState(false);
    const [comment, setComment] = useState('');
    const [attachement, setAttachement] = useState('');

    return (
       <>
            <Box flexGrow={1} display="flex">
                <Avatar
                    sx={{ display: { xs: 'none', sm: 'flex' }, mr: 1,mt:1 }}
                   
                />
                <MessageInputWrapper
                    autoFocus
                    placeholder={t('Write your comment here...')}
                    fullWidth
                    multiline
                    rows={2}
                    value={comment}
                    onChange={(e)=>setComment(e.target.value)}
                />
            </Box>
            <Box>
                {/*<Input  accept="image/*" id="messenger-upload-file" type="file" />*/}
                {/*<Tooltip arrow placement="top" title={t('Attach a file')}>*/}
                {/*    <label htmlFor="messenger-upload-file">*/}
                {/*        <IconButton sx={{ mx: 1 }} color="primary" component="span">*/}
                {/*            <AttachFileTwoToneIcon fontSize="small" />*/}
                {/*        </IconButton>*/}
                {/*    </label>*/}
                {/*</Tooltip>*/}
                <Button disabled={!comment.length || isUploading}
                    onClick={() => {
                        props.onAdd({ comment: comment.trim(), attachement: attachement });
                        setComment('');
                        setAttachement('');
                    }}
                    startIcon={<SendTwoToneIcon />} variant="contained">
                    {t('Save')}
                </Button>
            </Box>
        </>
    );
}

export default BottomBar;
