import { useTranslation } from 'react-i18next';

import {
  Grid,
  Typography,
  Button,
  useTheme,
} from '@mui/material';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { EmployeeListItem } from 'src/services/apiService/response-models/data-views';
import {CodeMaster } from 'src/services/apiService/response-models';

function PageHeader(props: {
    onAdd: () => void;
}) {
  const { t }: { t: any } = useTranslation();

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h3" component="h3" gutterBottom>
            {t('Leads')}
          </Typography>
          <Typography variant="subtitle2">
            {t('All recent leads can be found below')}
          </Typography>
        </Grid>
              <Grid item>  <Button onClick={props.onAdd} variant="contained" title="Add new lead"
                  startIcon={<AddTwoToneIcon fontSize="small" />}
              >
                  {'Add'}
              </Button></Grid>
      </Grid>
      
    </>
  );
}

export default PageHeader;
