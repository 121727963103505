import { useContext, useEffect, useRef, useState } from 'react';
import {
  Button,
  Card,
  Grid,
  Box,
  FormControl,
  CardActions,
  Typography,
  Avatar,
  Divider,
  Link,
  Rating,
  OutlinedInput,
  Chip,
  Tooltip,
  AvatarGroup,
  Pagination,
  InputAdornment,
  Menu,
  MenuItem,
  styled,
  useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { formatDistance, subMonths, subDays } from 'date-fns';
import TodayTwoToneIcon from '@mui/icons-material/TodayTwoTone';
import { Link as RouterLink } from 'react-router-dom';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import Text from 'src/ui-bloom/components/Text';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import TabsContainer from './tab-container';
import ActivitiesContext from './activities-context';
import { Task } from '../../models/task';
import { Note } from '../../models/note';
import {
  deleteNote,
  getAllAssociatedNotes,
  getAllAssociatedTask
} from 'src/services/nestApi';
import { EmployeeListItem } from '../../../services/apiService/response-models/data-views';
import { getEmployeeListItems } from '../../../services/apiService/data-view-service';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import useDebounce from '../../../utility/useDebounce';
import parseMyJson from '../../../utility/parseMyJson';
import _ from 'lodash';

const OutlinedInputWrapper = styled(OutlinedInput)(
  ({ theme }) => `
    background-color: ${theme.colors.alpha.white[100]};
    padding-right: ${theme.spacing(0.7)}
`
);

const CRM_EXPANDED = 'crm_activities_expanded2';

export interface ActivitiesProp {
  entityType: 'contact' | 'lead' | 'review';
  entityId: string;
  entityName: string;
}

function Activities(props: ActivitiesProp) {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const [searchKey, setSearchKey] = useState("");
    const debouncedSearch = useDebounce(searchKey, 500);
  const actionRef1 = useRef<any>(null);
  const { user } = useSelector((state: RootState) => state.app);
    const [openMenuOptions, setOpenMenuOptions] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tasks, setTasks] = useState<Task[]>([]);
    const [notes, setNotes] = useState<Note[]>([]);
  const [error, setError] = useState('');
  const [memberItems, setMemberItems] = useState<EmployeeListItem[]>([]);
    const [expandedIds, setExpandedIds] = useState<any>({});

  const loadData = async () => {
      try {
      setIsLoading(true);
      const resTask = await getAllAssociatedTask(
        props.entityId,
        props.entityType
      );
      const resNotes = await getAllAssociatedNotes(
        props.entityId,
        props.entityType
      );

      setTasks(resTask.data.data);
      setNotes(resNotes.data.data);
    } catch (ex) {
      setError('Failed to load data');
    }
  };

  const loadMembers = async () => {
    const res = await getEmployeeListItems();
    setMemberItems(res.data);
  };

  useEffect(() => {
    loadData()
      .then((o) => {
        setIsLoading(false);
      })
      .catch((ex) => {
        setIsLoading(false);
      });

      loadMembers().then(() => { });
      setExpandedIds(parseMyJson(localStorage.getItem(CRM_EXPANDED), {}));
  }, []);


    useEffect(() => {
       
    }, [debouncedSearch]);

    const applyTaskFilter = (): Task[] => {
        const key = searchKey.toLowerCase();
        return tasks.filter(o => {
            let isMatch = false;

            if (o.note && o.note.toLowerCase().includes(key))
                isMatch = true;

            if (o.title.toLowerCase().includes(key))
                isMatch = true;

            return isMatch;
        });
    }

    const applyNoteFilter = (): Note[] => {
        const key = searchKey.toLowerCase();
        return notes.filter(o => {
            let isMatch = false;

            if (o.description && o.description.toLowerCase().includes(key))
                isMatch = true;
           
            return isMatch;
        });
    }

    const filteredTasks = searchKey.length ? applyTaskFilter() : tasks;
    const filteredNotes = searchKey.length ? applyNoteFilter() : notes;

  const options = [
    {
      value: 'collapse',
      text: t('Collapse all')
    },
    {
      value: 'expand',
      text: t('Expand all')
    }
  ];

  const onAddTask = async (t: Task, isEdit: boolean) => {
    const newTasks = [...tasks];

    console.log(t);
    if (isEdit) {
      const i = newTasks.findIndex((o) => o.id == t.id);
      newTasks.splice(i, 1, t);
    } else {
      newTasks.push(t);
    }

    setTasks(newTasks);
  };

  const onAddNote = async (n: Note, isEdit: boolean) => {
    const newNotes = [...notes];

    if (isEdit) {
      const i = newNotes.findIndex((o) => o.id == n.id);
      newNotes.splice(i, 1, n);
    } else {
      newNotes.push(n);
    }

    setNotes(newNotes);
  };

  const onDeleteTask = async (t: Task) => {
    const newTasks = tasks.filter((o) => o.id != t.id);
    setTasks(newTasks);
  };
  const onDeleteNote = async (n: Note) => {
    const newNotes = notes.filter((o) => o.id != n.id);
    setNotes([...newNotes]);
    };

    expandedIds['']
  return (
    <ActivitiesContext.Provider
          value={{
              ...props,
              expandedActivityIds: (expandedIds['activity'] || []),
              expandedNoteIds: (expandedIds['note'] || []),
              expandedTaskIds: (expandedIds['task'] || []),
              currentUser: { email: user.email },
              tasks: filteredTasks,
              notes: filteredNotes,
              memberItems: memberItems,
              addTask: onAddTask,
              addNote: onAddNote,
              deleteNote: onDeleteNote,
              deleteTask: onDeleteTask,
              isLoading,
              onExpand: (sender, id, flag) => {
                  const exp = _.cloneDeep(expandedIds);
                  let ids = exp[sender] || [];
                  exp[sender]  = flag ? [...ids, id] : [...ids];
                  setExpandedIds(exp);
                  localStorage.setItem(CRM_EXPANDED, JSON.stringify(exp));
              }
          }}
    >
      <Grid container justifyContent="center" spacing={0}>
        <Grid item xs={12}>
          <Box
            py={1}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <FormControl variant="outlined" fullWidth>
                <OutlinedInputWrapper
                  type="text"
                                  placeholder={t('Search terms here...')}
                                  onChange={(e) => setSearchKey(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <Button variant="contained" size="small">
                        {t('Search')}
                      </Button>
                    </InputAdornment>
                  }
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchTwoToneIcon />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Box>
            <Box display="flex" alignItems="center">
              <Typography
                variant="subtitle2"
                sx={{
                  pr: 1
                }}
              ></Typography>
              {/*<Button*/}
              {/*  size="small"*/}
              {/*  variant="outlined"*/}
              {/*  ref={actionRef1}*/}
              {/*  onClick={() => setOpenMenuOptions(true)}*/}
              {/*  endIcon={<ExpandMoreTwoToneIcon fontSize="small" />}*/}
              {/*>*/}
              {/*  {'Collapse all'}*/}
              {/*</Button>*/}
              {/*<Menu*/}
              {/*  disableScrollLock*/}
              {/*  anchorEl={actionRef1.current}*/}
              {/*  onClose={() => setOpenMenuOptions(false)}*/}
              {/*  open={openMenuOptions}*/}
              {/*  anchorOrigin={{*/}
              {/*    vertical: 'bottom',*/}
              {/*    horizontal: 'right'*/}
              {/*  }}*/}
              {/*  transformOrigin={{*/}
              {/*    vertical: 'top',*/}
              {/*    horizontal: 'right'*/}
              {/*  }}*/}
              {/*>*/}
              {/*  {options.map((opt) => (*/}
              {/*    <MenuItem*/}
              {/*      key={opt.value}*/}
              {/*      onClick={() => {*/}
              {/*        setOpenMenuOptions(false);*/}
              {/*      }}*/}
              {/*    >*/}
              {/*      {opt.text}*/}
              {/*    </MenuItem>*/}
              {/*  ))}*/}
              {/*</Menu>*/}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TabsContainer />
        </Grid>
      </Grid>
    </ActivitiesContext.Provider>
  );
}

export default Activities;