import {
  Avatar,
  Icon,
  ListItemAvatar,
  ListItemText,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useMemo } from 'react';
import cdnService from 'src/services/cdnService';
import FieldRenderBlockBaseProp from '../_base/FieldRenderBlockBaseProp';
import readFieldValue from '../_base/readFieldValue';

export default function GoogleReviewBlock(props: FieldRenderBlockBaseProp) {
  const theme = useTheme();
  const fieldValue = useMemo(() => {
    return readFieldValue(props.field, { url: '', title: '', subTitle: '' });
  }, [props.field]);

  return (
    <>
      <ListItemAvatar
        sx={{
          mr: 2,
          display: 'flex',
          alignItems: 'center',
          minWidth: 0
        }}
      >
        <Avatar
          variant="rounded"
          sx={{
            background: `${theme.colors.alpha.white[10]}`,
            color: `${theme.colors.gradients.pink2}`,
            width: 64,
            height: 64
          }}
        >
          <Icon>
            <img
              src={cdnService.toCdnUrl(props.field.fieldRef.icon, 'icons')}
              height={25}
              width={25}
            />
          </Icon>
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        sx={{
          color:
            props.themeColor === 'light'
              ? `${theme.colors.alpha.black[70]}`
              : `${theme.colors.alpha.trueWhite[70]}`,
          wordWrap: 'break-word'
        }}
        primary={
          <Typography gutterBottom variant="h4">
            {fieldValue.title}
          </Typography>
        }
        secondary={
          <Typography
            sx={{
              color:
                props.themeColor === 'light'
                  ? `${theme.colors.alpha.black[70]}`
                  : `${theme.colors.alpha.trueWhite[70]}`
            }}
            variant="subtitle2"
          >
            {fieldValue.subTitle}
          </Typography>
        }
      />
    </>
  );
}
