import {
  alpha,
  Box,
  Card,
  Avatar,
  darken,
  Grid,
  CardMedia,
  Typography,
  IconButton,
  Button,
  styled,
  useTheme,
  Skeleton,
  Tooltip,
  Switch,
  FormControlLabel
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import AccountBoxTwoToneIcon from '@mui/icons-material/AccountBoxTwoTone';
import { DigitalCardListItem } from '../../../services/apiService/response-models';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import getThemeColor from '../../../utility/getThemeColor';
import _ from 'lodash';
import DefaultCardThumb from 'src/components/CardThumbs/DefaultCardThumb';

const CardActions = styled(Box)(
  ({ theme }) => `
    position: absolute;
    right: ${theme.spacing(2)};
    bottom: ${theme.spacing(2)};
    z-index: 7;
  `
);

const Label = styled(Box)(
  ({ theme }) => `
    background: ${theme.palette.success.main};
    color: ${theme.palette.success.contrastText};
    text-transform: uppercase;
    font-size: ${theme.typography.pxToRem(10)};
    font-weight: bold;
    line-height: 23px;
    height: 22px;
    padding: ${theme.spacing(0, 1.2)};
    border-radius: 50px;
  `
);

const BgComposed = styled(Box)(
  ({ theme }) => `
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transition: ${theme.transitions.create(['opacity'])};
    z-index: 6;
    opacity: 1;
   
  `
);

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
        width: ${theme.spacing(6)};
        height: ${theme.spacing(6)};
        transition: ${theme.transitions.create(['color', 'transform'])};
        border-radius: 100px;

        .MuiSvgIcon-root {
            transform: scale(1);
            transition: ${theme.transitions.create(['transform'])};
        }

        &:hover {
            .MuiSvgIcon-root {
                transform: scale(1.3);
            }
        }
  `
);

const CardWrapper = styled(Card)(
  ({ theme }) => `

    background: ${theme.colors.gradients.blue1};
    color:  ${theme.colors.alpha.trueWhite[100]};
    border-radius: ${theme.general.borderRadiusXl};
    padding: ${theme.spacing(1)};
    box-shadow: none;

    .MuiCardHeader-root {
      .MuiCardHeader-title {
          color: ${theme.colors.alpha.trueWhite[100]};;
          font-weight: normal;
          padding-bottom: ${theme.spacing(0.5)};
      }
    }
    
    .MuiCardHeader-subheader {
        color:  ${theme.colors.alpha.trueWhite[100]};;
    }

      .MuiActionButtons {
            background: ${alpha(theme.colors.alpha.white[100], 0.95)};
            border-radius: ${theme.general.borderRadius};
            opacity: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            visibility: hidden;
            top: 15%;
            margin-top: -${theme.spacing(3.5)};
            position: absolute;
            right: ${theme.spacing(1.5)};
            padding: ${theme.spacing(0.5, 1)};
            transition: ${theme.transitions.create(['visibility', 'opacity'])};
            z-index:100;
            .MuiIconButton-root {
                border-radius: 100px;
                width: ${theme.spacing(5)};
                height: ${theme.spacing(5)};
                margin: ${theme.spacing(0.5)};
            }
      }

      &:hover {
          .MuiActionButtons {
            visibility: visible;
            opacity: 1;
      }   
      }
  `
);

function CardGridBlock(props: {
  isLoading: boolean;
  items: DigitalCardListItem[];
  onClick: (id: number) => void;
  onDeleteClick: (id: number) => void;
}) {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();

  const minItem = _.minBy(props.items, (o) => o.digitalCardId);
  return (
    <Grid container spacing={4}>
      {props.isLoading &&
        [1, 2, 3, 4, 5, 6].map((o) => (
          <Grid key={o} item xs={12} md={4}>
            <Skeleton variant="rectangular" width={'100%'} height={260} />
          </Grid>
        ))}

      {!props.isLoading &&
        props.items.map((card) => (
          <Grid key={card.digitalCardId} item xs={12} md={4}>
            <DefaultCardThumb
              item={card}
              actionLabel={'Link'}
              showDelete={true}
              onClick={() => props.onClick(card.digitalCardId)}
              onDeleteClick={() => props.onDeleteClick(card.digitalCardId)}
              onSelect={() => props.onClick(card.digitalCardId)}
            />
            {/* <CardWrapper
              sx={{
                height: '260px',
                textAlign: 'center',
                position: 'relative',
                transition: `${theme.transitions.create([
                  'box-shadow',
                  'transform'
                ])}`,
                // background: getThemeColor(theme, card.theme),
                background:
                  'linear-gradient(135deg, rgb(247, 97, 161) 0%, rgb(140, 27, 171) 100%)',
                transform: 'translateY(0px)',

                '&:hover': {
                  transform: `translateY(-${theme.spacing(0.5)})`,
                  boxShadow: `0 2rem 8rem 0 ${alpha(
                    theme.colors.alpha.black[100],
                    0.05
                  )}, 
                        0 0.6rem 1.6rem ${alpha(
                          theme.colors.alpha.black[100],
                          0.15
                        )}, 
                        0 0.2rem 0.2rem ${alpha(
                          theme.colors.alpha.black[100],
                          0.1
                        )}`,

                  '& .MuiBgComposed': {
                    opacity: 1
                  }
                }
              }}
            >
              {card.digitalCardId != minItem?.digitalCardId && (
                <Box className="MuiActionButtons">
                  <Tooltip arrow placement="top" title={t('Delete card')}>
                    <IconButton
                      onClick={() => props.onDeleteClick(card.digitalCardId)}
                      color="error"
                    >
                      <CloseTwoToneIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
              <BgComposed
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                className="MuiBgComposed"
              >
                <Avatar
                  sx={{
                    width: 62,
                    height: 62,
                    mb: 1,
                    border: `${theme.colors.alpha.trueWhite[100]} solid 3px`
                  }}
                />
                <Typography
                  variant="h4"
                  gutterBottom
                  sx={{
                    color: `${theme.colors.alpha.trueWhite[100]}`
                  }}
                >
                  {card.label || '[No label]'}
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{
                    mb: 2,
                    color: `${theme.colors.alpha.trueWhite[70]}`
                  }}
                >
                  {card.description}
                </Typography>
                <Button
                  sx={{
                    px: 2.5,
                    borderRadius: 10,
                    transform: 'scale(1)',
                    transition: `${theme.transitions.create(['all'])}`,
                    boxShadow: `${theme.colors.shadows.info}`,

                    '&:hover': {
                      transform: 'scale(1.05)',
                      boxShadow: `${theme.colors.shadows.info}`
                    },
                    '&:active': {
                      boxShadow: 'none'
                    }
                  }}
                  variant="contained"
                  startIcon={<AccountBoxTwoToneIcon />}
                  color="info"
                  onClick={() => props.onClick(card.digitalCardId)}
                >
                  {t('View card')}
                </Button>
              </BgComposed>

              <CardActions
                sx={{
                  bottom: 'auto',
                  top: `${theme.spacing(2)}`,
                  right: 'auto',
                  left: `${theme.spacing(2)}`
                }}
              >
                <Label
                  sx={{
                    background: `${theme.palette.info.main}`,
                    color: `${theme.palette.info.contrastText}`
                  }}
                >
                  {t(card.cardType)}
                </Label>
              </CardActions>
              {card.isActiveCard === true && (
                <CardActions
                  sx={{
                    bottom: 'auto',
                    top: `${theme.spacing(2)}`
                  }}
                >
                  <Label
                    sx={{
                      background: `${theme.palette.success.main}`,
                      color: `${theme.palette.success.contrastText}`
                    }}
                  >
                    {t('Active Card')}
                  </Label>
                </CardActions>
              )}
            </CardWrapper> */}
          </Grid>
        ))}
    </Grid>
  );
}

export default CardGridBlock;
