import { Box, Grid, TextField, useTheme, Typography, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DigitalCardField,
  CardFormField,
  FieldDataType,
  CardFieldValue
} from '../../../../../services/apiService/response-models';
import { Dictionary } from 'lodash';
import PreField from '../../../models/PreField';
import validate from './validate';

function FormRenderer(props: {
  field: PreField;
  isSubmit: boolean;
  onChange: (
    field: PreField,
    data: Dictionary<string>,
    isValid: boolean
  ) => void;
  afterInit: (isValid: boolean) => void;
}) {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const [isSubmit, setIsSubmit] = useState(false);
  //const [formFields, setFormFields] = useState <CardFormField[]>(props.field.formFields);
  const [values, setValues] = useState<Dictionary<string>>({});

  //  const field = props.field;

    useEffect(() => {
    if (props.field.fieldValues) {
      const vals: Dictionary<string> = props.field.fieldValues.reduce(
        (result, curr, index, array) => ({
          ...result,
          [curr.name]: curr.value
        }),
        {}
      );
      setValues(vals);

      setTimeout(() => {
        props.afterInit(isFormValid(vals));
      });
    }
  }, []);

  useEffect(() => {
    setIsSubmit(props.isSubmit);
  }, [props.isSubmit]);

  const isValid = (e: CardFormField, value: string) => {
      return validate(e, value).length > 0 ? false : true;
  };

  const isFormValid = (vals: Dictionary<string>) => {
    let flag = true;
    props.field.fieldRef.formFields.forEach((f) => {
      if (flag && !isValid(f, vals[f.name])) {
        flag = false;
      }
    });
    return flag;
    };

    const renderField = (field: CardFormField, i: number) => {
        if (field.dataType == FieldDataType.Checkbox) {
            const hasValue = () => values[field.name] == 'true' || values[field.name] == 'false';
            
            let checked = values[field.name] == 'true' ? true : false;

            if (field.defaultValue == 'true' && !hasValue()) {
                checked = true;
            }

            return (
                <FormGroup>

                    <FormControlLabel control={<Checkbox
                        checked={checked}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const vals = { ...values };
                        vals[field.name] = e.target.checked+'';
                        setValues(vals);
                        props.onChange(props.field, vals, isFormValid(vals));
                    }}/>} label={field.displayName} />
                </FormGroup>
                );
        }

        return (<TextField
            autoFocus={i == 0}
            id={field.name}
            required={field.isRequired === true}
            name={field.name}
            label={field.displayName}
            multiline={field.dataType === FieldDataType.TextArea}
            fullWidth
            value={values[field.name] || field.defaultValue || ''}
            maxRows={4}
            error={!!validate(field, values[field.name])}
            helperText={validate(field, values[field.name])}
            onChange={(e) => {
                const vals = { ...values };
                vals[field.name] = e.target.value;
                setValues(vals);
                props.onChange(props.field, vals, isFormValid(vals));
            }}
        />)
    }

  // const attrHash: Dictionary<CardFormField> = field.formFields.reduce((result, curr, index, array) => ({ ...result, [curr.name]: curr }), {});

  return (
    <Box mb={1} display="flex">
      <form noValidate style={{ width: '100%' }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Grid sx={{ padding: theme.spacing(1) }} container spacing={3}>
            {props.field.fieldRef.formFields.map((field,i) => (
                <Grid key={field.name} item xs={12} md={12} lg={12}>
                    {renderField(field, i)}
              </Grid>
            ))}
          </Grid>
        </LocalizationProvider>
      </form>
    </Box>
  );
}

export default FormRenderer;
