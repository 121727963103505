import { CodeItem, CodeMaster} from "../services/apiService/response-models";

export const convertToCodeItems = (items: CodeMaster[]): CodeItem[] => {
    if (!items)
        return [];

    return items.map(o => {
        return {
            codeId: o.id,
            value: o.value,
        } as CodeItem;
    });
};
