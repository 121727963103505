import SaveIcon from '@mui/icons-material/Save';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Drawer,
  Grid,
  IconButton,
  LinearProgress,
  styled,
  useTheme
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import Scrollbar from 'src/ui-bloom/components/Scrollbar';
import PageHeader from './PageHeader';

import Sidebar from './Sidebar';

import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { RootState } from 'src/store';
import extractDialCode from 'src/utility/extractDialCode';
import extractNumber from 'src/utility/extractNumber';
import * as Yup from 'yup';
import {
  checkAccountExistsByEmail,
  createBusinessCardForNewEmployee,
  getEmployeeById,
  getRefData,
  getTeams,
  saveEmployee
} from '../../../services/apiService';
import {
  Address,
  Employee,
  NameItem,
  RefData,
  Team
} from '../../../services/apiService/response-models';
import useRefMounted from '../../../ui-bloom/hooks/useRefMounted';
import Constants, { USER_ROLES } from '../../../utility/constants';
import { parseToApiErrorMessage } from '../../../utility/parseToApiErrorMessage';
import ContactInfo from './ContactInfo';
import GroupInfo from './GroupInfo';
import PersonalDetails from './PersonalDetails';

const DrawerWrapper = styled(Drawer)(
  ({ theme }) => `
    width: 400px;
    flex-shrink: 0;
    z-index: 3;

    & > .MuiPaper-root {
        width: 400px;
        height: calc(100% - ${theme.header.height});
        position: absolute;
        top: ${theme.header.height};
        right: 0;
        z-index: 3;
        background: ${theme.colors.alpha.white[10]};
    }
`
);

const DrawerWrapperMobile = styled(Drawer)(
  ({ theme }) => `
    width: 360px;
    flex-shrink: 0;

  & > .MuiPaper-root {
        width: 360px;
        z-index: 3;
        background: ${theme.colors.alpha.white[30]};
  }
`
);

const MainContentWrapper = styled(Box)(
  () => `
  flex-grow: 1;
`
);

const IconButtonToggle = styled(IconButton)(
  ({ theme }) => `
  width: ${theme.spacing(6)};
  height: ${theme.spacing(6)};
`
);

function CreateEmployee() {
  const theme = useTheme();
  const isMountedRef = useRefMounted();
  const [mobileOpen, setMobileOpen] = useState(false);
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { id: employeeId } = useParams();

  const [isSaveInProgress, setIsSaveInProgress] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const [error, setError] = useState('');
  const [accountRefData, setAccountRefData] = useState<RefData>({
    jobTitles: [],
    departments: [],
    nameTitles: [],
    socialMediaProviders: []
  });
  const [teamsList, setTeamsList] = useState<Team[]>([]);
  const { user } = useSelector((state: RootState) => state.app);

  const [model, setModel] = useState<Employee | null>(null);

  const getById = async (employeeId: number) => {
    try {
      const response = await getEmployeeById(employeeId);
      setIsEdit(true);
      setModel(response.data);
    } catch (ex) {
      console.error(ex);
      const msg = ex.message ? ex.message : `Failed to load employee by id`;
      enqueueSnackbar(t(msg), { variant: 'error' });
      navigate('/app/team/list');
    }
  };

  useEffect(() => {
    if (employeeId && Number(employeeId) > 0) {
      setIsLoading(true);
      getById(Number(employeeId)).then(() => {
        setIsLoading(false);
      });
    } else {
      setModel(null);
      setIsEdit(false);
    }
  }, [employeeId]);

  const loadRefData = useCallback(
    async (callback: (hasError: boolean, data: RefData) => void) => {
      if (isMountedRef.current) {
        const data = await getRefData(
          'jobTitle',
          'department',
          'nameTitle',
          'socialMediaProvider'
        );
        callback(false, data);
      }
    },
    [isMountedRef]
  );

  const loadTeamsList = useCallback(
    async (callback: (hasError: boolean, data: Team[]) => void) => {
      if (isMountedRef.current) {
        try {
          const res = await getTeams();
          callback(false, res.data);
        } catch (ex) {
          const msg = ex.message ? ex.message : `Failed to load teams list`;
          enqueueSnackbar(t(msg), { variant: 'error' });
          setError(msg);
          callback(true, []);
        }
      }
    },
    [isMountedRef]
  );

  useEffect(() => {
    setIsLoading(true);
    loadRefData((hasError, data) => {
      if (data) {
        setAccountRefData({
          departments: data.departments || [],
          jobTitles: data.jobTitles || [],
          nameTitles: data.nameTitles || [],
          socialMediaProviders: data.socialMediaProviders || []
        });
      }
      setIsLoading(false);
    });
    loadTeamsList((hasError, data) => {
      setTeamsList(data);
    });
  }, [loadRefData, loadTeamsList]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const onSave = async (updatedModel: Employee) => {
    let isSuccess = false;
    try {
      setIsSaveInProgress(true);
      const saveRes = await saveEmployee(updatedModel);
      enqueueSnackbar(
        t(`Employee was ${!isEdit ? 'created' : 'updated'} successfully`),
        { variant: 'success' }
      );
      isSuccess = true;

      if (!isEdit) {
        try {
          await createBusinessCardForNewEmployee(saveRes.data.id);
        } catch (ex) {
          console.error('error at createBusinessCardForNewEmployee');
          console.error(ex);
        }
      }
      navigate('/app/team/list/members');
      // navigate('/app/menu/manage/food-item', { replace: true });
      setIsSaveInProgress(false);
    } catch (ex) {
      setIsSaveInProgress(false);
      console.log(ex);
      const msg = parseToApiErrorMessage(
        ex,
        `Failed to ${!isEdit ? 'created' : 'updated'} Employee`
      );
      enqueueSnackbar(t(msg), { variant: 'error' });
    }
    return isSuccess;
  };

  const smProviderId = (providerName: string): number | null => {
    const code = accountRefData.socialMediaProviders.find(
      (o) => o.code.toLowerCase() == providerName.toLowerCase()
    );
    return code ? code.id : null;
  };

  const onSaveClick = () => {
    setIsSubmit(true);
  };

  const sidebarContent = (
    <Scrollbar>
      <Sidebar />
    </Scrollbar>
  );
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const urlRegExp =
    /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;

  const isValidZipRegExp = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

  const address = model?.addresses.find((o) => (o.isPrimary = true));

  const selectedTeamIds = (model?.teams || []).map((team) => team.id);

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          isActive: isEdit ? (model?.isActive === true ? true : false) : true,
          firstName: model?.firstName || '',
          pronouns: model?.pronouns || '',
          middleName: model?.middleName || '',
          lastName: model?.lastName || '',
          imageUrl: model?.imageUrl,
          email: model?.email || '',
          altEmail: model?.altEmail || '',
          contactNumberWork: extractNumber(model?.altContactNumber) || '',
          countryDialCodeWork: extractDialCode(model?.altContactNumber) || '1',
          contactNumberPersonal: extractNumber(model?.contactNumber) || '',
          countryDialCodePersonal: extractDialCode(model?.contactNumber) || '1',
          descriptionRich: model?.descriptionRich || '',
          dateOfBirth: model?.dateOfBirth || null,
          departments: model?.departments || [],
          jobTitle: model?.jobTitle,
          teams: isEdit
            ? (model?.teams || []).map((team) => team.id)
            : teamsList
                .filter((o) => o.name == Constants.DEFAULT_TEAM_NAME)
                .map((o) => o.id),
          linkedInUrl:
            (model?.socialMediaLinks || []).find(
              ({ socialMediaProviderId }) =>
                socialMediaProviderId === smProviderId('linkedIn')
            )?.link || '',
          twitterUrl:
            (model?.socialMediaLinks || []).find(
              ({ socialMediaProviderId }) =>
                socialMediaProviderId === smProviderId('twitter')
            )?.link || '',
          faceBookUrl:
            (model?.socialMediaLinks || []).find(
              ({ socialMediaProviderId }) =>
                socialMediaProviderId === smProviderId('facebook')
            )?.link || '',
          whatsAppUrl:
            (model?.socialMediaLinks || []).find(
              ({ socialMediaProviderId }) =>
                socialMediaProviderId === smProviderId('whatsApp')
            )?.link || '',
          address: {
            addressLine: address?.addressLine || '',
            addressLine2: address?.addressLine2 || '',
            countryCode: address?.countryCode || '',
            stateCode: address?.stateCode || '',
            zipCode: address?.zipCode || ''
          },
          userRoles: (model?.userRoles || []).map((role) =>
            USER_ROLES.find((o) => o.roleName == role)
          )
        }}
        validationSchema={Yup.object().shape({
          firstName: Yup.string().max(250).required(t('The field is required')),
          lastName: Yup.string().max(250).required(t('The field is required')),
          email: Yup.string()
            .max(250)
            .required(t('The email field is required'))
            .email('Not a proper email')
            .test('Unique Email', 'Email already in use', async (value) => {
              if (!value || isEdit) return true;
              const result = await checkAccountExistsByEmail(value);
              return !result.data;
            }),
          alternateEmail: Yup.string().max(250).email('Not a proper email'),
          teams: Yup.array()
            .min(1, 'Choose at least 1 team/group')
            .required(t('Choose at least 1 team/group')),
          contactNumberWork: Yup.string().matches(
            phoneRegExp,
            'Phone number is not valid'
          ),

          contactNumberPersonal: Yup.string().matches(
            phoneRegExp,
            'Phone number is not valid'
          ),
          countryDialCodeWork: Yup.string(),
          countryDialCodePersonal: Yup.string(),
          linkedInUrl: Yup.string().matches(urlRegExp, 'Enter correct url!'),
          twitterUrl: Yup.string().matches(urlRegExp, 'Enter correct url!'),
          faceBookUrl: Yup.string().matches(urlRegExp, 'Enter correct url!'),
          whatsAppUrl: Yup.string().matches(urlRegExp, 'Enter correct url!'),
          zipcode: Yup.string().matches(
            isValidZipRegExp,
            'Enter correct zipcode!'
          ),
          userRoles: Yup.array()
            .min(1, 'Choose at least 1 role')
            .required(t('Choose at least 1 role'))
        })}
        onSubmit={async (
          _values,
          { resetForm, setErrors, setStatus, setSubmitting }
        ) => {
          try {
            const updatedModel: Employee = {
              id: isEdit ? +employeeId : 0,
              ..._values,
              isActive: true,
              teams: _values.teams.map((o) => {
                return { id: o, name: '' } as NameItem;
              }),
              userRoles: _values.userRoles.map(
                (o: any) => o.roleName
              ) as string[]
            };

            ///addresses
            let addresses = model?.addresses ? [...model.addresses] : [];

            addresses = addresses.filter((o) => !o.isPrimary);

            const addr: Address = {
              ..._values.address,
              isPrimary: true
            };

            updatedModel.addresses = [addr, ...addresses];
            /////////

            const dialCode = _values.countryDialCodePersonal
              ? '+' + _values.countryDialCodePersonal + ' '
              : '';
            updatedModel.contactNumber =
              dialCode + _values.contactNumberPersonal;

            const dialCode2 = _values.countryDialCodeWork
              ? '+' + _values.countryDialCodeWork + ' '
              : '';
            updatedModel.altContactNumber =
              dialCode2 + _values.contactNumberWork;

            //socialMediaLinks
            let socialMediaLinks = model?.socialMediaLinks
              ? [...model.socialMediaLinks]
              : [];

            let codeId = smProviderId('facebook');
            if (codeId) {
              socialMediaLinks = socialMediaLinks.filter(
                (o) => codeId != o.socialMediaProviderId
              );
              socialMediaLinks.push({
                id: 0,
                link: _values.faceBookUrl,
                socialMediaProviderId: codeId
              });
            }

            codeId = smProviderId('twitter');
            if (codeId) {
              socialMediaLinks = socialMediaLinks.filter(
                (o) => codeId != o.socialMediaProviderId
              );
              socialMediaLinks.push({
                id: 0,
                link: _values.twitterUrl,
                socialMediaProviderId: codeId
              });
            }

            codeId = smProviderId('whatsapp');
            if (codeId) {
              socialMediaLinks = socialMediaLinks.filter(
                (o) => codeId != o.socialMediaProviderId
              );
              socialMediaLinks.push({
                id: 0,
                link: _values.whatsAppUrl,
                socialMediaProviderId: codeId
              });
            }

            codeId = smProviderId('linkedin');
            if (codeId) {
              socialMediaLinks = socialMediaLinks.filter(
                (o) => codeId != o.socialMediaProviderId
              );
              socialMediaLinks.push({
                id: 0,
                link: _values.linkedInUrl,
                socialMediaProviderId: codeId
              });
            }
            updatedModel.socialMediaLinks = socialMediaLinks;
            //////

            const isSuccess = await onSave(updatedModel);

            if (isSuccess) {
              resetForm();
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err) {
            console.error(err);
            const msg = parseToApiErrorMessage(err, `Failed to save employee`);
            enqueueSnackbar(t(msg), { variant: 'error' });
            setStatus({ success: false });
            setSubmitting(false);
          }
        }}
      >
        {({ handleSubmit, isSubmitting, values }) => (
          <form onSubmit={handleSubmit}>
            <Box mb={3} display="flex">
              <MainContentWrapper>
                <Grid
                  sx={{
                    px: 4
                  }}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="stretch"
                  spacing={4}
                >
                  <Grid item xs={12}>
                    <Box
                      mt={3}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <PageHeader isEdit={isEdit} />
                      <Box
                        component="span"
                        sx={{
                          display: { lg: 'none', xs: 'inline-block' }
                        }}
                      >
                        <IconButtonToggle
                          sx={{
                            ml: 2
                          }}
                          color="primary"
                          onClick={handleDrawerToggle}
                          size="small"
                        >
                          <MenuTwoToneIcon />
                        </IconButtonToggle>
                      </Box>
                    </Box>
                  </Grid>
                  {isLoading && (
                    <Grid item xs={12}>
                      <LinearProgress />
                      loading...
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <PersonalDetails accountRefData={accountRefData} />
                  </Grid>
                  <Grid item xs={12}>
                    <GroupInfo
                      teamsList={teamsList}
                      accountRefData={accountRefData}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ContactInfo isEdit={isEdit} />
                  </Grid>
                  <Grid item xs={12}>
                    <Card
                      sx={{
                        p: 1
                      }}
                    >
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Button
                            type="submit"
                            onClick={() => onSaveClick()}
                            variant="contained"
                            startIcon={
                              isSubmitting || isSaveInProgress ? (
                                <CircularProgress size="1rem" />
                              ) : (
                                <SaveIcon />
                              )
                            }
                            disabled={
                              isLoading || isSubmitting || isSaveInProgress
                            }
                          >
                            Save
                          </Button>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item xs={12}></Grid>
                </Grid>
              </MainContentWrapper>
              <Box
                component="span"
                sx={{
                  display: { lg: 'none', xs: 'inline-block' }
                }}
              >
                <DrawerWrapperMobile
                  variant="temporary"
                  anchor={theme.direction === 'rtl' ? 'left' : 'right'}
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                >
                  {sidebarContent}
                </DrawerWrapperMobile>
              </Box>
              <Box
                component="span"
                sx={{
                  display: { xs: 'none', lg: 'inline-block' }
                }}
              >
                <DrawerWrapper
                  variant="permanent"
                  anchor={theme.direction === 'rtl' ? 'left' : 'right'}
                  open
                >
                  {sidebarContent}
                </DrawerWrapper>
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
}

export default CreateEmployee;
