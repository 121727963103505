import { FC, useContext } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';

import Typography from '@mui/material/Typography';
import React from 'react';
import {
  Box,
  CircularProgress,
  IconButton,
  LinearProgress,
  styled,
  Tooltip
} from '@mui/material';
import MuiAccordionSummary, {
  AccordionSummaryProps
} from '@mui/material/AccordionSummary';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import TaskBlock from '../task-block';
import ActivitiesContext from '../activities-context';
import { formatDistance } from 'date-fns';
import toLocDate from '../../../../utility/toLocDate';

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    justifyContent: 'space-between'
  }
}));

function TasksList() {
    const { tasks, isLoading, currentUser, expandedTaskIds,onExpand } = useContext(ActivitiesContext);

  const handleChange =
      (taskId: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
          onExpand('task', taskId, isExpanded);
    };

  return (
    <div>
      {isLoading && <LinearProgress color="secondary" />}
      {!isLoading && tasks.length == 0 && (
        <Typography variant="subtitle1" gutterBottom>
          No task to display.
        </Typography>
      )}
      {tasks.map((o) => (
        <Accordion
          key={o.id}
              expanded={expandedTaskIds.includes(o.id)}
          onChange={handleChange(o.id)}
        >
          <AccordionSummary
            expandIcon={
              <ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />
            }
            aria-controls="panel1bh-content"
            sx={{ background: '#dfdfdf' }}
            id={'panel' + o + 'bh-header'}
          >
                  <Typography sx={{ marginLeft: 1 }}>
                      Task  {o.assignedTo ? `assigned to ${currentUser.email == o.assignedTo.email ? 'you' : o.assignedTo.name}` : '(unassigned)'}
                  </Typography>
                 
            <Typography sx={{ color: 'text.secondary' }}>
              Due on:{' '}
              {formatDistance(toLocDate(o.dueDate), new Date(), {
                addSuffix: true
              })}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TaskBlock task={o} />
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}

export default TasksList;
