import {
  Divider,
  Slide,
  CardActions,
  Button,
  Tooltip,
  IconButton,
  Grid,
  CircularProgress
} from '@mui/material';
import { Label } from '@mui/icons-material';
import CancelPresentationTwoToneIcon from '@mui/icons-material/CancelPresentationTwoTone';
import DeleteIcon from '@mui/icons-material/Delete';
import React from 'react';

export default function FooterActions(props: {
  isInProgress?: boolean;
  isSaveDisabled?: boolean;
  hideDelete?: boolean;
  onCancel: () => void;
  onDelete: () => void;
  onSave: () => void;
}) {
  const [isDeleteRequested, setIsDeleteRequested] = React.useState(false);

  const handleDelete = () => {
    props.onDelete();
  };

  const onCancel = () => {
    props.onCancel();
  };

  return (
    <>
      <Divider sx={{ mb: 2 }} />
      <Slide
        direction="right"
        in={isDeleteRequested}
        mountOnEnter
        unmountOnExit
      >
        <CardActions>
          <Label>Are you sure?</Label>
          <Button
            sx={{ ml: 1 }}
            size="small"
            onClick={handleDelete}
            variant="outlined"
            color="error"
          >
            Delete
          </Button>
          <Tooltip title="Cancel delete">
            <IconButton
              onClick={() => {
                setIsDeleteRequested(false);
              }}
              sx={{ ml: 1 }}
              aria-label="delete"
            >
              <CancelPresentationTwoToneIcon />
            </IconButton>
          </Tooltip>
        </CardActions>
      </Slide>
      {!isDeleteRequested && (
        <CardActions>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              {props.hideDelete !== true && props.isInProgress !== true && (
                <IconButton
                  color="error"
                  aria-label="delete"
                  onClick={() => {
                    setIsDeleteRequested(true);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </Grid>
            <Grid item>
              <Button size="small" onClick={onCancel} variant="outlined">
                Cancel
              </Button>
              <Button
                disabled={
                  props.isSaveDisabled === true || props.isInProgress === true
                }
                sx={{ ml: 2 }}
                size="small"
                onClick={() => {
                  props.onSave();
                }}
                variant="contained"
                startIcon={
                  props.isInProgress === true ? (
                    <CircularProgress size="1rem" />
                  ) : null
                }
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      )}
    </>
  );
}
