import { createContext } from 'react';
import { EmployeeListItem } from '../../../services/apiService/response-models/data-views';
import { Note } from '../../models/note';
import { Task } from '../../models/task';

export interface ActivitiesContextType {
    currentUser: { email: string },
    entityType: 'contact' | 'lead' | 'review';
    entityId: string;
    entityName: string;
    tasks: Task[];
    notes: Note[];
    memberItems: EmployeeListItem[];
    isLoading?: boolean;
    addTask: (task: Task, isEdit: boolean) => Promise<void>;
    addNote: (notes: Note, isEdit: boolean) => Promise<void>;
    deleteTask: (task: Task) => Promise<void>;
    deleteNote: (notes: Note) => Promise<void>;
    expandedActivityIds: string[];
    expandedNoteIds: string[];
    expandedTaskIds: string[];
    onExpand: (sender:'activity' | 'note' | 'task',id:string, flag: boolean)=>void;
}

const initialState: ActivitiesContextType = {
    currentUser: {
        email: ''
    },
    entityType: 'contact',
    entityId: '',
    entityName: '',
    tasks: [],
    notes: [],
    memberItems: [],
    addTask: () => Promise.resolve(),
    addNote: () => Promise.resolve(),
    deleteTask: () => Promise.resolve(),
    deleteNote: () => Promise.resolve(),
    expandedActivityIds:[],
    expandedNoteIds: [],
    expandedTaskIds: [],
    onExpand: () => { }
};

const ActivitiesContext = createContext<ActivitiesContextType>(initialState);

export default ActivitiesContext;
