import {
    styled,
    Box,
    Card,
    Typography,
    Button,
    Grid,
    CircularProgress,
    FormControlLabel,
    Switch,
    CardContent,
    Paper,
    IconButton,
    Tooltip,
    TextField
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import ContentCopyTwoToneIcon from '@mui/icons-material/ContentCopyTwoTone';
import RotateRightTwoToneIcon from '@mui/icons-material/RotateRightTwoTone';
import { EmployeeAccountSetting } from '../../services/apiService/request-models';
import { getEmployeeAccountSetting, saveEmployeeAccountSetting } from '../../services/apiService';


const defaultValue: EmployeeAccountSetting = {
    sendCredentialEmail: true,
    defaultPassword: 'MxEW74rL$'
}

export default function AccountSetting() {
    const { t }: { t: any } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [setting, setSetting] = useState<EmployeeAccountSetting>(defaultValue);
    const [isSaving, setIsSaving] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            try {
                const res = await getEmployeeAccountSetting();
                setSetting(res.data);
            } catch (ex) {
                console.log(ex);
            }
            setIsLoading(false);
        })();
    }, []);

    const onCopy = () => {
        if (setting.defaultPassword) {
            navigator.clipboard.writeText(setting.defaultPassword);
            enqueueSnackbar(t('Ppassword copied'), {
                variant: 'info'
            });
        }
    }
    const generatePwd = () => {
        const maxLen = 8;

        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < maxLen) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        const settingCopy = _.cloneDeep(setting);
        settingCopy.defaultPassword = result + '$';
        setSetting(settingCopy);
    }

    const onSave = async () => {
        setIsSaving(true);
        try {
            await saveEmployeeAccountSetting(setting);
            enqueueSnackbar(t('Setting saved successfully'), {
                variant: 'success'
            });
        } catch (ex) {
            enqueueSnackbar(t('Failed to save setting'), {
                variant: 'error'
            });
            console.error(ex);
        }
        setIsSaving(false);
    }
    return (
                <Card
                    sx={{
                        m: 1,
                    }}
                >
                    <Box>
                        <Typography
                            variant="h3"
                            sx={{
                                m: 1
                            }}
                        >
                            {t('Account Setting')}
                        </Typography>
                        <Typography
                            variant="h4"
                            color="text.secondary"
                            fontWeight="normal"
                            sx={{
                                m: 1
                            }}
                        >
                            {t('Manage member\'s login-account setting')}
                        </Typography>
            </Box>
            {isLoading && <CardContent>
                <Box sx={{ display: 'flex', p: 5, justifyContent:'center' }}>
                    <CircularProgress />
                </Box>
            </CardContent>}
            {!isLoading && <CardContent>

                <Grid item sx={{ ml: 1 }}>
                    <FormControlLabel
                        value
                        control={
                            <Switch
                                color="primary"
                                checked={setting.sendCredentialEmail}
                                onChange={(e) => {
                                    const settingCopy = _.cloneDeep(setting);
                                    settingCopy.sendCredentialEmail =
                                        e.target.checked;
                                    setSetting(settingCopy);
                                }}
                            />
                        }
                        label={t('Send credential to member\'s email')}
                    />
                </Grid>
                {setting.sendCredentialEmail == false && <Grid item sx={{

                }}>
                    <Box
                        sx={{ p: 1, display: 'flex', alignItems: 'center' }}
                    >
                        <TextField
                            fullWidth
                            InputProps={{
                                readOnly: true,
                            }}
                            disabled
                            value={setting.defaultPassword}
                            required
                            label={t('Default password')}
                            helperText="Default password will be set to member's login when new member is added"
                        />
                        <Tooltip title="Copy password">
                            <IconButton onClick={onCopy} color="primary" sx={{ ml: 1 }} aria-label="directions">
                                <ContentCopyTwoToneIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Regenerate password">
                            <IconButton onClick={generatePwd} color="primary" sx={{ ml: 1 }} aria-label="directions">
                                <RotateRightTwoToneIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Grid>}
                <Grid item sx={{ textAlign: 'right' }}>
                    <Button
                        sx={{
                            mt: 2,
                            width: 'auto'
                        }}
                        color="primary"
                        onClick={onSave}
                        startIcon={
                            isSaving ? <CircularProgress size="1rem" /> : null
                        }
                        disabled={isSaving}
                        type="submit"
                        fullWidth
                        size="large"
                        variant="contained"
                    >
                        {t('Save setting')}
                    </Button>
                </Grid>
            </CardContent>}
                </Card>
    );
}
