import { DigitalCardField } from "../../../../services/apiService/response-models";
import PreField from "../../models/PreField";

const convertFieldToPreField = (e?: DigitalCardField): PreField => {
    if (!e)
        return null;

    return {
        fieldName: e.name,
        fieldRef: e,
        id: e.name + "_1",
        order:0,
        fieldValues: []
    }
}

export default convertFieldToPreField;