import {
  Avatar,
  Box,
  Card,
  CardContent,
  Link,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  styled
} from '@mui/material';

import ContactMailTwoToneIcon from '@mui/icons-material/PortraitTwoTone';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { AccountTypes } from 'src/services/apiService/response-models';
import { RootState } from 'src/store';

const AvatarPrimary = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.primary.main};
      color: ${theme.palette.getContrastText(theme.colors.primary.main)};
      width: ${theme.spacing(8)};
      height: ${theme.spacing(8)};
      box-shadow: ${theme.colors.shadows.primary};
`
);

const CardContentWrapper = styled(CardContent)(
  ({ theme }) => `
     padding: ${theme.spacing(2.5, 3, 3)};
  
     &:last-child {
     padding-bottom: 0;
     }
`
);

function CardsBlock(props: { count: number }) {
  const { t }: { t: any } = useTranslation();
  const { accountType } = useSelector((state: RootState) => state.app.user);

  return (
    <Card>
      <CardContentWrapper>
        <Typography variant="overline" color="text.primary">
          {t(
            accountType === AccountTypes.Business
              ? 'Company Profiles'
              : 'Profiles'
          )}
        </Typography>

        <ListItem
          disableGutters
          sx={{
            my: 1
          }}
          component="div"
        >
          <ListItemAvatar>
            <AvatarPrimary variant="rounded">
              <ContactMailTwoToneIcon fontSize="large" />
            </AvatarPrimary>
          </ListItemAvatar>

          <ListItemText
            primary={props.count ? props.count : '--'}
            primaryTypographyProps={{
              variant: 'h1',
              sx: {
                ml: 2
              },
              noWrap: true
            }}
          />
        </ListItem>
        <ListItem
          disableGutters
          sx={{
            mt: 0.5,
            mb: 1.5
          }}
          component="div"
        >
          <ListItemText
            primary={
              <>
                <Link
                  fontWeight="bold"
                  component={RouterLink}
                  to={
                    accountType === AccountTypes.Business
                      ? '/app/cards/business'
                      : '/app/cards/personal'
                  }
                >
                  {t('See all profiles')}
                </Link>
                <Box
                  component="span"
                  sx={{
                    pl: 0.5
                  }}
                ></Box>
              </>
            }
            primaryTypographyProps={{ variant: 'body2', noWrap: true }}
          />
        </ListItem>
      </CardContentWrapper>
    </Card>
  );
}

export default CardsBlock;
