import {
    Box,
    CardHeader,
    Card,
    ListItemText,
    Typography,
    Avatar,
    List,
    Divider,
    ListItem,
    Link,
    IconButton,
    alpha,
    styled,
    useTheme,
    LinearProgress,
    Tooltip
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import Scrollbar from 'src/ui-bloom/components/Scrollbar';
import { formatDistance, subMinutes } from 'date-fns';
import { useEffect, useState } from 'react';
import { getMyNfcCodes } from '../../services/apiService';
import { NfcCodeMap } from '../../services/apiService/response-models';
import ErrorPanel from '../../components/error-panel';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import InfoPanel from '../../components/info-panel';

const CardContentWrapper = styled(Box)(
    ({ theme }) => `
    background: ${theme.colors.alpha.white[100]};
    border-radius: ${theme.general.borderRadius};
  `
);

const LabelWrapper = styled(Box)(
    ({ theme }) => `
    font-size: ${theme.typography.pxToRem(10)};
    font-weight: bold;
    text-transform: uppercase;
    border-radius: ${theme.general.borderRadiusSm};
    padding: ${theme.spacing(0.9, 1.5, 0.7)};
    line-height: 1;
  `
);

const ListWrapper = styled(List)(
    () => `
    .MuiDivider-root:last-of-type {
        display: none;
    }
  `
);

function NfcMapList() {
    const { t }: { t: any } = useTranslation();
    const theme = useTheme();
    const [maps, setMaps] = useState<NfcCodeMap[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');

    const loadData = async () => {
        setIsLoading(true);
        setError('');
        try {
            const res = await getMyNfcCodes();
            setMaps(res.data);
        } catch (ex) {
            console.log(ex);
            setError('Failed to load data.');
        }
        setIsLoading(false);
    }

    useEffect(() => {
        loadData()
    },[]);

    const buildUrl = (item: NfcCodeMap) => {
        const url = new URL(window.location.href);
        url.searchParams.set('tab', 'nfcMap');
        return '/app/map-nfc-code?nfccode=' + item.nfcCode + '&returl=' + url.href;
    }

    return (
        <Card
            variant="outlined"
            sx={{
                background: `${alpha(theme.colors.alpha.black[100], 0.03)}`
            }}
        >
            <CardContentWrapper
                sx={{
                    mx: 3,
                    mb: 3,
                    height: 344
                }}
            >
                {isLoading && <LinearProgress />}
                {!isLoading && error.length > 0 && <ErrorPanel title="Error" message={error} />}
                {!isLoading && maps.length == 0 && <InfoPanel title="No record to display" message={''} />}
                <Scrollbar>
                    <ListWrapper disablePadding>
                        {maps.map(item => <React.Fragment key={item.nfcCode}>
                        <ListItem
                            sx={{
                                p: 2
                            }}
                        >
                            <ListItemText
                                primary={
                                    <Tooltip title="Click to change mapping">
                                        <Link href={buildUrl(item)} color="text.primary" variant="h4">
                                        {item.nfcCode}
                                    </Link>
                                    </Tooltip>
                                }
                                secondary={
                                    <>
                                        <Box mt={0.7} display="flex" alignItems="center">
                                            <Avatar
                                                sx={{
                                                    mr: 1,
                                                    width: 28,
                                                    height: 28
                                                }}
                                                src="/static/images/avatars/1.jpg"
                                            />
                                            <RouterLink
                                                style={{
                                                    fontSize: `${theme.typography.pxToRem(13)}`
                                                }}
                                                to={"/app/cards/business/edit/" + item.cardId}
                                            >
                                                {item.cardName ?? item.cardApp?.name}
                                            </RouterLink>
                                        </Box>
                                    </>
                                }
                            />
                            <Box alignSelf="flex-start">
                                <LabelWrapper
                                    sx={{
                                        background: `${theme.colors.success.main}`,
                                        color: `${theme.palette.getContrastText(
                                            theme.colors.success.dark
                                        )}`
                                    }}
                                >
                                    {t('Mapped')}
                                </LabelWrapper>
                            </Box>
                        </ListItem>
                        <Divider />
                        </React.Fragment>
                        )}
                    </ListWrapper>
                </Scrollbar>
            </CardContentWrapper>
        </Card>
    );
}

export default NfcMapList;
