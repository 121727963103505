import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PublicIcon from '@mui/icons-material/Public';
import {
  alpha,
  Avatar,
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  LinearProgress,
  Link,
  List,
  ListItem,
  ListItemText,
  styled,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import cdnService from 'src/services/cdnService';
import Label from 'src/ui-bloom/components/Label';
import ErrorPanel from '../../../components/error-panel';
import Activities from '../../../CRM/components/Activities';
import { getContactById } from '../../../services/apiService';
import { Contact } from '../../../services/apiService/response-models';

function ContactDetail() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [contact, setContact] = useState<Contact | null>(null);
  const [error, setError] = useState('');

  const getById = async (contactId: number) => {
    try {
      const response = await getContactById(contactId);
      setContact(response.data);
    } catch (ex) {
      console.error(ex);
      const msg = ex.message ? ex.message : `Failed to load contact by id`;
      setError(msg);
      enqueueSnackbar(t(msg), { variant: 'error' });
    }
  };

  useEffect(() => {
    if (id && Number(id) > 0) {
      setIsLoading(true);
      getById(Number(id)).then(() => {
        setIsLoading(false);
      });
    } else {
      navigate('/app/contact/list');
    }
  }, []);

  if (error) {
    return <ErrorPanel title="Critical error" message={error} />;
  }

  if (isLoading || !contact) {
    return <LinearProgress sx={{ mt: 2 }} />;
  }

  return (
    <Grid container>
      <Grid item xs={12} md={3}>
        <ContactSummary contact={contact} />
      </Grid>
      <Grid item xs={12} md={9}>
        <Activities
          entityType="contact"
          entityId={contact.id + ''}
          entityName={contact.fullName}
        />
      </Grid>
    </Grid>
  );
}

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
        transform: translateY(0px);
        transition: ${theme.transitions.create(['color', 'transform'])};

        .MuiSvgIcon-root {
            transform: scale(1);
            transition: ${theme.transitions.create(['transform'])};
        }

        &:hover {
            transform: translateY(-2px);
    
            .MuiSvgIcon-root {
                transform: scale(1.3);
            }
        }
  `
);

const getAddressDesc = (e: Contact) => {
  if (!e.addresses) return '';

  let address = e.addresses.find((o) => o.isPrimary);
  if (!address && e.addresses.length) address = e.addresses[0];

  if (address) {
    // return only defined property
    const { addressLine, addressLine2, city, state, country, zipCode } =
      address;
    const addressArr = [
      addressLine,
      addressLine2,
      city,
      state,
      country,
      zipCode
    ];
    const addressStr = addressArr.filter((o) => o).join(', ');
    if (addressStr) return addressStr;
  }

  return '';
};

function ContactSummary({ contact }: { contact: Contact }) {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  const socialMediaProviders = [
    {
      id: 1,
      codeType: 'SocialMediaProvider',
      code: 'Twitter',
      value: 'Twitter',
      order: 0,
      isActive: false,
      clientAccountId: null
    },
    {
      id: 2,
      codeType: 'SocialMediaProvider',
      code: 'Facebook',
      value: 'Facebook',
      order: 0,
      isActive: false,
      clientAccountId: null
    },
    {
      id: 3,
      codeType: 'SocialMediaProvider',
      code: 'WhatsApp',
      value: 'WhatsApp',
      order: 0,
      isActive: false,
      clientAccountId: null
    },
    {
      id: 4,
      codeType: 'SocialMediaProvider',
      code: 'LinkedIn',
      value: 'LinkedIn',
      order: 0,
      isActive: false,
      clientAccountId: null
    }
  ];

  const smProviderId = (providerName: string): number | null => {
    const code = socialMediaProviders.find(
      (o) => o.code.toLowerCase() == providerName.toLowerCase()
    );
    return code ? code.id : null;
  };

  const onGoback = () => {
    navigate('/app/contact/list');
  };

  const onEdit = () => {
    navigate('/app/contact/edit/' + contact.id);
  };
  return (
    <Card
      sx={{
        position: 'relative',
        p: 3,
        pt: 0
      }}
    >
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Tooltip title={'Go back'}>
          <Button
            onClick={onGoback}
            variant="outlined"
            startIcon={<ArrowBackTwoToneIcon />}
            size="small"
          >
            {t('Contacts')}
          </Button>
        </Tooltip>
        <Tooltip title={'Edit contact'}>
          <IconButton
            sx={{
              p: 1
            }}
            size="small"
            color="primary"
            onClick={onEdit}
          >
            <EditTwoToneIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
      <Box mb={2} display="flex" alignItems="center">
        <Avatar
          variant="rounded"
          sx={{
            width: 95,
            height: 95
          }}
          src={
            cdnService.toImageUrl(contact.imageUrl) ||
            '/static/images/avatars/3.jpg'
          }
        />
        <Box
          sx={{
            width: '100%'
          }}
          ml={1.5}
        >
          <Link
            href="#"
            color="text.primary"
            underline="none"
            sx={{
              transition: `${theme.transitions.create(['color'])}`,
              fontSize: `${theme.typography.pxToRem(17)}`,

              '&:hover': {
                color: `${theme.colors.primary.main}`
              }
            }}
            variant="h4"
          >
            {contact.fullName}
          </Link>

          <Typography gutterBottom variant="subtitle2"></Typography>
          <Typography gutterBottom variant="subtitle2"></Typography>
          <Box
            display="flex"
            alignItems="center"
            flex={1}
            sx={{
              width: '100%'
            }}
          >
            <Typography
              sx={{
                pl: 1
              }}
              fontWeight="bold"
              variant="body1"
              textAlign="right"
            >
              <Label color={'info'}>{contact.groupTags?.join(',')}</Label>
            </Typography>
          </Box>
        </Box>
      </Box>
      <Typography variant="subtitle2">{contact.description}.</Typography>
      <Box p={1}>
        <Box
          sx={{
            textAlign: 'center'
          }}
        >
          {(contact.socialMediaLinks || []).map((o) => {
            return (
              <Tooltip key={o.id} arrow placement="top" title={o.link}>
                <IconButtonWrapper
                  sx={{
                    boxShadow: `${theme.colors.shadows.primary}`,
                    background: `${theme.colors.primary.main}`,
                    color: `${theme.palette.getContrastText(
                      theme.colors.primary.main
                    )}`,

                    '&:hover': {
                      background: `${theme.colors.primary.main}`,
                      color: `${theme.palette.getContrastText(
                        theme.colors.primary.main
                      )}`
                    },
                    borderRadius: 50,
                    width: theme.spacing(4),
                            height: theme.spacing(4),
                    mx:1
                  }}
                  color="primary"
                  size="large"
                >
                  {smProviderId('facebook') === o.socialMediaProviderId && (
                    <FacebookIcon fontSize="small" />
                  )}
                  {smProviderId('twitter') === o.socialMediaProviderId && (
                    <TwitterIcon fontSize="small" />
                  )}
                  {smProviderId('linkedIn') === o.socialMediaProviderId && (
                    <LinkedInIcon fontSize="small" />
                  )}
                  {smProviderId('whatsApp') === o.socialMediaProviderId && (
                    <WhatsAppIcon fontSize="small" />
                        )}
                {!o.socialMediaProviderId && (
                    <PublicIcon fontSize="small" />
                )}
                </IconButtonWrapper>
              </Tooltip>
            );
          })}
        </Box>
      </Box>
      <Card
        elevation={0}
        sx={{
          background: `${alpha(theme.colors.alpha.black[100], 0.05)}`
        }}
      >
        <List dense>
          <ListItem>
            <ListItemText
              primaryTypographyProps={{
                variant: 'h5'
              }}
              primary={t('Email') + ':'}
            />
            <Typography variant="subtitle1">{contact.email}</Typography>
          </ListItem>
          <ListItem>
            <ListItemText
              primaryTypographyProps={{
                variant: 'h5'
              }}
              primary={t('Contact') + ':'}
            />
            <Typography variant="subtitle1">
              {' '}
              {contact.contactNumber}
            </Typography>
          </ListItem>
          <ListItem>
            <ListItemText
              primaryTypographyProps={{
                variant: 'h5'
              }}
              sx={{ width: '154px' }}
              primary={t('Address') + ':'}
            />
            <Box>
              <Typography variant="subtitle1">
                {getAddressDesc(contact) || t('No address available')}
              </Typography>
            </Box>
          </ListItem>
          <ListItem>
            <ListItemText
              primaryTypographyProps={{
                variant: 'h5'
              }}
              primary={t('Created on') + ':'}
            />
            <Typography variant="subtitle1">
              {new Date(contact.createdOn).toLocaleDateString()}
            </Typography>
          </ListItem>
        </List>
      </Card>
    </Card>
  );
}

export default ContactDetail;
