import { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'src/ui-bloom/utils/axios';

import { Helmet } from 'react-helmet-async';
import PageHeader from './PageHeader';
import Footer from 'src/ui-bloom/components/Footer';
import { CSVLink } from 'react-csv';

import { Button, Grid, LinearProgress } from '@mui/material';
import useRefMounted from 'src/ui-bloom/hooks/useRefMounted';
import PageTitleWrapper from 'src/ui-bloom/components/PageTitleWrapper';

import Results from './Results';
import { Contact } from '../../../services/apiService/response-models';
import { getContacts } from '../../../services/apiService';
import { parseToApiErrorMessage } from '../../../utility/parseToApiErrorMessage';
import { useSnackbar } from 'notistack';

function ContactList() {
  const isMountedRef = useRefMounted();
  const { enqueueSnackbar } = useSnackbar();
  const [isExportClicked, setIsExportClicked] = useState(false);
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [teamDataRefreshTriggerId, setTeamDataRefreshTriggerId] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const refLink = useRef(null);
  const onRefreshData = () => {
    setTeamDataRefreshTriggerId(Date.now() + '');
  };

  const handleExportContacts = () => {
    refLink.current?.link?.click();
  };

  const getData = useCallback(
    async (callback: (hasError: boolean, data: Contact[]) => void) => {
      if (isMountedRef.current) {
        try {
          const res = await getContacts();
          callback(false, res.data);
        } catch (ex) {
          const msg = parseToApiErrorMessage(ex, `Failed to load contact data`);
          enqueueSnackbar(msg, { variant: 'error' });
          setError(msg);
          callback(true, []);
        }
      }
    },
    [isMountedRef]
  );

  useEffect(() => {
    getData((hasError, data) => {
      setContacts(data);
      setIsLoading(false);
    });
  }, [getData]);

  return (
    <>
      <PageTitleWrapper>
        <PageHeader
          handleExportContacts={handleExportContacts}
          refreshData={onRefreshData}
        />

        <CSVLink
          ref={refLink}
          data={contacts}
          filename={'contacts.csv'}
          headers={[
            {
              key: 'fullName',
              label: 'fullName'
            },
            {
              key: 'email',
              label: 'email'
            },
            {
              key: 'descriptionRich',
              label: 'descriptionRich'
            },
            {
              key: 'contactNumber',
              label: 'contactNumber'
            }
          ]}
        />
      </PageTitleWrapper>

      <Grid
        sx={{
          px: 4
        }}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
      >
        <Grid item xs={12}>
          <Results contacts={contacts} isLoading={isLoading} />
        </Grid>
      </Grid>
      <Footer />
    </>
  );
}

export default ContactList;
