import axios, { AxiosResponse } from 'axios';
import { format } from 'date-fns';
import { Note } from 'src/CRM/models/note';
import { Task } from 'src/CRM/models/task';
import { ListingAppInstance } from '../../BusinessApp/services/models';
import { ApiUrls, NEST_API_SECRET_KEY } from '../endpoints';
import {
  GenerateQrCodeRequest,
  GenerateQrCodeResponse,
  QrCodeImagesRequest,
  QrCodeImagesResponse
} from './requestModels';
import PaginatedDataResult, {
  InsightsDataView,
  LeadSettings,
  NotesResponse,
  PaginateResult,
  PaymentIntentResult,
  QRConfig,
  ReviewSaveViewModel,
  Size,
  TaskResponse
} from './responseModels';

export const createPaymentIntent = (
  businessName: string,
  businessEmail: string,
  planName: string
): Promise<AxiosResponse<PaymentIntentResult>> => {
  var model = {
    name: businessName,
    email: businessEmail,
    planName: planName
  };

  const dataUrl = ApiUrls.cardApi + '/create-payment-intent';
  return axios.post(dataUrl, model, {
    headers: {
      apiKey: NEST_API_SECRET_KEY
    }
  });
};

export const sendEmailVerificationCode = (
  email: string
): Promise<AxiosResponse> => {
  const dataUrl =
    ApiUrls.cardApi + '/subscription/sendVerificationCodeMail?email=' + email;
  return axios.get(dataUrl);
};

export const verifyEmail = (
  email: string,
  verificationCode: string
): Promise<
  AxiosResponse<{ isVerified: boolean; isVarificationCodeExpired: boolean }>
> => {
  const dataUrl = ApiUrls.cardApi + '/subscription/verifyEmail';
  return axios.post(dataUrl, {
    email: email,
    verificationCode: verificationCode
  });
};

export const getQrCodeNewImageUrl = (
  appName: string,
  cardIdOrName: number | string,
  q?: string
): string => {
  return (
    ApiUrls.cardApi +
    '/ui-client/QrCode/image/app-name/' +
    appName +
    '/card-id/' +
    cardIdOrName +
    '?q=' +
    q
  );
};

export const generateQrCode = (
  req: GenerateQrCodeRequest
): Promise<AxiosResponse<GenerateQrCodeResponse>> => {
  const dataUrl = ApiUrls.cardApi + '/ui-client/qr';
  return axios.post(dataUrl, req);
};

export const getQrCodeConfig = (
  cardId: number | string,
  appName: string
): Promise<AxiosResponse<QRConfig>> => {
  const dataUrl =
    ApiUrls.cardApi + '/ui-client/qr-config/' + cardId + '/' + appName;
  return axios.get(dataUrl);
};

export const saveQrCodeConfig = (
  req: QRConfig
): Promise<AxiosResponse<GenerateQrCodeResponse>> => {
  const dataUrl = ApiUrls.cardApi + '/ui-client/qr-config';
  return axios.post(dataUrl, req);
};

export const getLeadSettingsByClientId = (
  clientAccountId: number
): Promise<AxiosResponse<LeadSettings>> => {
  const dataUrl = ApiUrls.cardApi + '/lead-settings/' + clientAccountId;
  return axios.get(dataUrl);
};

export const saveLeadSettings = (
  settings: LeadSettings
): Promise<AxiosResponse<LeadSettings>> => {
  const dataUrl = ApiUrls.cardApi + '/lead-settings/save';
  return axios.post(dataUrl, settings);
};

export const getQrImageUrls = (
  items: QrCodeImagesRequest[]
): Promise<AxiosResponse<QrCodeImagesResponse[]>> => {
  const dataUrl = ApiUrls.cardApi + '/ui-client/qr-image-urls';
  return axios.post(dataUrl, items);
};

export const getQrImageUrl = (
  item: QrCodeImagesRequest
): Promise<QrCodeImagesResponse> => {
  return new Promise<QrCodeImagesResponse>((resolve, reject) => {
    getQrImageUrls([item])
      .then((o) => {
        if (o.data.length) resolve(o.data[0]);
        else reject('not found');
      })
      .catch(() => {
        reject('not found');
      });
  });
};

export const createTask = (
  task: Task
): Promise<AxiosResponse<TaskResponse>> => {
  const dataUrl = ApiUrls.cardApi + '/api/crm/v1/task';
  return axios.post(dataUrl, { task });
};

export const updateTask = (
  task: Task
): Promise<AxiosResponse<TaskResponse>> => {
  const dataUrl = ApiUrls.cardApi + '/api/crm/v1/task';
  return axios.put(dataUrl, { task });
};

export const getAllAssociatedTask = (
  entityId: string,
  entityType: string
): Promise<AxiosResponse<PaginatedDataResult<Task>>> => {
  const dataUrl =
    ApiUrls.cardApi + '/api/crm/v1/task/all/' + entityType + '/' + entityId;
  return axios.get(dataUrl);
};

export const createNotes = (
  notes: Note
): Promise<AxiosResponse<NotesResponse>> => {
  const dataUrl = ApiUrls.cardApi + '/api/crm/v1/notes';
  return axios.post(dataUrl, { notes });
};

export const updateNote = (
  notes: Note
): Promise<AxiosResponse<NotesResponse>> => {
  const dataUrl = ApiUrls.cardApi + '/api/crm/v1/notes';
  return axios.put(dataUrl, { notes });
};

export const deleteNote = (
  noteToDeleteId: string
): Promise<AxiosResponse<NotesResponse>> => {
  const dataUrl = ApiUrls.cardApi + '/api/crm/v1/notes/' + noteToDeleteId;
  return axios.delete(dataUrl);
};

export const deleteTask = (
  taskToDeleteId: string
): Promise<AxiosResponse<TaskResponse>> => {
  const dataUrl = ApiUrls.cardApi + '/api/crm/v1/task/' + taskToDeleteId;
  return axios.delete(dataUrl);
};

export const getAllAssociatedNotes = (
  entityId: string,
  entityType: string
): Promise<AxiosResponse<PaginatedDataResult<Note>>> => {
  const dataUrl =
    ApiUrls.cardApi + '/api/crm/v1/notes/all/' + entityType + '/' + entityId;
  return axios.get(dataUrl);
};

export const updateTaskStatus = (
  taskId: string,
  status: string
): Promise<AxiosResponse<TaskResponse>> => {
  const dataUrl = ApiUrls.cardApi + '/api/crm/v1/task/updateTaskStatus';
  return axios.put(dataUrl, { taskId, status });
};

export const getConnectedBusinessApps = (
  digitalCardId: number
): Promise<AxiosResponse<ListingAppInstance[]>> => {
  const dataUrl =
    ApiUrls.cardApi + '/listing-apps/connected-apps/' + digitalCardId;
  return axios.get(dataUrl);
};

export const getAllSizes = (): Promise<AxiosResponse<Size[]>> => {
  const dataUrl = ApiUrls.cardApi + 'item-size/all';
  return axios.get(dataUrl);
};
export const deleteSize = (sizeId: number): Promise<AxiosResponse<boolean>> => {
  const dataUrl = ApiUrls.cardApi + 'item-size/' + sizeId;
  return axios.delete(dataUrl);
};

export const getSizeById = (id: number): Promise<AxiosResponse<Size>> => {
  const dataUrl = ApiUrls.cardApi + 'item-size/id/' + id;
  return axios.get(dataUrl);
};

export const getSizeListView = (): Promise<AxiosResponse<Size[]>> => {
  const dataUrl = ApiUrls.cardApi + 'size/list';
  return axios.get(dataUrl);
};

export const getPageView = (cardId: number, startDate: Date, endDate: Date): Promise<AxiosResponse<InsightsDataView>> => {

  const dateStr = (d: Date) => format(d, 'yyyy-MM-dd');

  //253/2023-01-23/2023-07-29

  const dataUrl = ApiUrls.cardApi + '/insights/get-page-views/' + cardId + "/" + dateStr(startDate) + "/" + dateStr(endDate);
  return axios.get(dataUrl);
};

export const getReviewsByClientId = (
  clientAccountId: number
): Promise<AxiosResponse<PaginateResult<ReviewSaveViewModel>>> => {
  const dataUrl = ApiUrls.cardApi + '/listing-apps/review-app/reviews/' + clientAccountId + '?limit=5000';
  return axios.get(dataUrl);
};
