import { useState, useEffect, useCallback } from 'react';
import axios from 'src/ui-bloom/utils/axios';

import { Helmet } from 'react-helmet-async';
import PageHeader from './PageHeader';
import Footer from 'src/ui-bloom/components/Footer';

import { Grid } from '@mui/material';
import useRefMounted from 'src/ui-bloom/hooks/useRefMounted';
import type { User } from 'src/ui-bloom/models/user';
import PageTitleWrapper from 'src/ui-bloom/components/PageTitleWrapper';

import Results from './Results';
import { useParams } from 'react-router-dom';

function TeamList() {
    const [teamDataRefreshTriggerId, setTeamDataRefreshTriggerId] = useState('');
    const { activeTab } = useParams();
    const onRefreshData = () => {
        setTeamDataRefreshTriggerId(Date.now() + '');
    };

    const [openTeamEditDialogCallback, openTeamEditDialogCallbackSetter] =
        useState(null);

    return (
        <>
            <PageTitleWrapper>
                <PageHeader
                    refreshData={onRefreshData}
                    openTeamEditDialogCallbackSetter={openTeamEditDialogCallbackSetter}
                />
            </PageTitleWrapper>

            <Grid
                sx={{
                    px: 4
                }}
                container
                direction="row"
                justifyContent="center"
                alignItems="stretch"
                spacing={4}
            >
                <Grid item xs={12}>
                    <Results
                        openTeamEditDialogCallback={openTeamEditDialogCallback}
                        teamDataTriggerId={teamDataRefreshTriggerId}
                        activeTab={activeTab}
                    />
                </Grid>
            </Grid>
            <Footer />
        </>
    );
}

export default TeamList;
