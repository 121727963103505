enum ListingAppNames {
  PriceCatalogApp = 'PriceCatalogApp',
  ReviewApp = 'ReviewApp',
  LeadApp = 'LeadApp',
  ExternalApp = 'ExternalApp',
  ScanToCallApp = 'ScanToCallApp',
  BusinessProfile = 'BusinessProfile',
  NfcMapApp = 'NfcMapApp',
  KiFormApp = 'KiFormApp',
}

export default ListingAppNames;