import { BusinessProfileRef } from "../BusinessApp/services/models";
import { CardFieldNames, CardTypes, DigitalCard } from "../services/apiService/response-models";
import { first } from 'lodash';

export function convertCardToProfile(card: DigitalCard): BusinessProfileRef {
  const profile: BusinessProfileRef = {};

  if (card) {
    profile.digitalCardId = card.id;

    profile.qrName = card.name;

    let match = card.fields.find(
      (o) => o.fieldName == CardFieldNames.BusinessName
    );
    if (match) {
      profile.name = first(match.formFieldValues)?.value;
    }

    match = card.fields.find((o) => o.fieldName == CardFieldNames.Headline);
    if (match) {
      profile.caption = first(match.formFieldValues)?.value;
    }

    if (card.cardType == CardTypes.Individual) {
      match = card.fields.find((o) => o.fieldName == CardFieldNames.FullName);
      if (match) {
        profile.name = first(match.formFieldValues)?.value;
      }
    }

    match = card.fields.find((o) => o.fieldName == CardFieldNames.ProfilePic);
    if (match) {
      profile.image = first(match.formFieldValues)?.value;
    }

    match = card.fields.find((o) => o.fieldName == CardFieldNames.CoverPhoto);
    if (match) {
      profile.coverImage = first(match.formFieldValues)?.value;
    }

    match = card.fields.find((o) => o.fieldName == CardFieldNames.Website);

    if (match) {
      profile.website = first(match.formFieldValues)?.value;
    }

    let matches = card.fields.filter(
      (o) => o.fieldName == CardFieldNames.ContactNumber
    );

    if (matches.length) {
      profile.contactNumber = first(matches[0].formFieldValues)?.value;
    }

    if (matches.length > 1) {
      profile.contactNumberAlt = first(matches[1].formFieldValues)?.value;
    }

    matches = card.fields.filter((o) => o.fieldName == CardFieldNames.Email);

    if (matches.length) {
      profile.email = first(matches[0].formFieldValues)?.value;
    }

    if (matches.length > 1) {
      profile.emailAlt = first(matches[1].formFieldValues)?.value;
    }

    match = card.fields.find((o) => o.fieldName == CardFieldNames.JobTitle);
    if (match) {
      profile.jobTitle = first(match.formFieldValues)?.value;
    }

    match = card.fields.find((o) => o.fieldName == CardFieldNames.Department);
    if (match) {
      profile.department = first(match.formFieldValues)?.value;
    }

    //adreess
    match = card.fields.find((o) => o.fieldName == CardFieldNames.Address);
    if (match) {
      profile.address = {
        addressLine:
          match.formFieldValues.find((o) => o.name == 'AddressLine')?.value ||
          '',
        addressLine2:
          match.formFieldValues.find((o) => o.name == 'AddressLine2')
            ?.value || '',
        city:
          match.formFieldValues.find((o) => o.name == 'City')?.value || '',
        state:
          match.formFieldValues.find((o) => o.name == 'State')?.value || '',
        zip:
          match.formFieldValues.find((o) => o.name == 'ZipCode')?.value || '',
        country:
          match.formFieldValues.find((o) => o.name == 'Country')?.value || '',
      };
    }

    //social
    const socialFieldNames = [
      CardFieldNames.Facebook,
      CardFieldNames.LinkedIn,
      CardFieldNames.Twitter,
      CardFieldNames.Instagram,
      CardFieldNames.WhatsApp,
      CardFieldNames.YouTube,
      CardFieldNames.Skype,
      CardFieldNames.Snapchat,
      CardFieldNames.Twitch,
      CardFieldNames.Signal,
      CardFieldNames.Discord,
      CardFieldNames.Telegram,
    ];

    socialFieldNames.forEach((name) => {
      matches = card.fields.filter((o) => o.fieldName == name);

      if (matches.length) {
        profile[name.toLowerCase()] = first(
          matches[0].formFieldValues
        )?.value;
      }
    });
  }

  return profile;
}