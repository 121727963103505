import {
  SyntheticEvent,
  useState,
  useCallback,
  useEffect
} from 'react';
import {
  Box,
  Tab,
  Tabs,
  Dialog,
  styled
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import MembersTab from './members-tab';
import TeamsTab from './teams-tab';
import {
  TeamVM
} from '../../../services/apiService/response-models/data-views';
import { getEmployees, getTeams } from '../../../services/apiService';
import useRefMounted from '../../../ui-bloom/hooks/useRefMounted';
import { RootState, useSelector } from '../../../store';
import { Employee } from 'src/services/apiService/response-models';

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);


const TabsWrapper = styled(Tabs)(
  ({ theme }) => `
    @media (max-width: ${theme.breakpoints.values.md}px) {
      .MuiTabs-scrollableX {
        overflow-x: auto !important;
      }

      .MuiTabs-indicator {
          box-shadow: none;
      }
    }
    `
);


const Results = (props: {
  teamDataTriggerId?: string;
  openTeamEditDialogCallback: () => void;
  activeTab?: string;
}) => {
  const isMountedRef = useRefMounted();
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [isTeamsLoading, setIsTeamsLoading] = useState(true);
  const [isMembersLoading, setIsMembersLoading] = useState(true);
  const [error, setError] = useState('');
  const [currentTab, setCurrentTab] = useState<string>(
    props.activeTab || 'members'
  );
  const [teams, setTeams] = useState<TeamVM[]>([]);
  const [employees, setEmployees] = useState<Employee[]>([]);

  const handleTabsChange = (_event: SyntheticEvent, tabsValue: string) => {
    setCurrentTab(tabsValue as any);
  };

  const tabs = [
    {
      value: 'members',
      label: t('Members')
    },
    {
      value: 'teams',
      label: t('All teams')
    }
  ];

  const getTeamData = useCallback(
    async (callback: (hasError: boolean, data: TeamVM[]) => void) => {
      if (isMountedRef.current) {
        try {
          const res = await getTeams();
          callback(false, res.data);
        } catch (ex) {
          const msg = ex.message ? ex.message : `Failed to load team data`;
          enqueueSnackbar(t(msg), { variant: 'error' });
          setError(msg);
          callback(true, []);
        }
      }
    },
    [isMountedRef]
  );

  const getEmployeeData = useCallback(
    async (callback: (hasError: boolean, data: Employee[]) => void) => {
      if (isMountedRef.current) {
        try {
          const res = await getEmployees();
          callback(false, res.data);
        } catch (ex) {
          const msg = ex.message ? ex.message : `Failed to load employee data`;
          enqueueSnackbar(t(msg), { variant: 'error' });
          setError(msg);
          callback(true, []);
        }
      }
    },
    [isMountedRef]
  );

  useEffect(() => {
    setIsTeamsLoading(true);
    setIsMembersLoading(true);
    getTeamData((hasError, data) => {
      setTeams(data);
      setIsTeamsLoading(false);
    });
    getEmployeeData((hasError, data) => {
      setEmployees(data);
      setIsMembersLoading(false);
    });
  }, [getTeamData, getEmployeeData]);

  useEffect(() => {
    if (props.teamDataTriggerId) {
      setIsTeamsLoading(true);
      getTeamData((hasError, data) => {
        setTeams(data);
        setIsTeamsLoading(false);
      });
    }
  }, [props.teamDataTriggerId]);

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        flexDirection={{ xs: 'column', sm: 'row' }}
        justifyContent={{ xs: 'center', sm: 'space-between' }}
        pb={3}
      >
        <TabsWrapper
          onChange={handleTabsChange}
          scrollButtons="auto"
          textColor="secondary"
          value={currentTab}
          variant="scrollable"
        >
          {tabs.map((tab) => (
            <Tab key={tab.value} value={tab.value} label={tab.label} />
          ))}
        </TabsWrapper>
        {/*<ToggleButtonGroup*/}
        {/*  sx={{*/}
        {/*    mt: { xs: 2, sm: 0 }*/}
        {/*  }}*/}
        {/*  value={toggleView}*/}
        {/*  exclusive*/}
        {/*  onChange={handleViewOrientation}*/}
        {/*>*/}
        {/*  <ToggleButton disableRipple value="table_view">*/}
        {/*    <TableRowsTwoToneIcon />*/}
        {/*  </ToggleButton>*/}
        {/*  <ToggleButton disableRipple value="grid_view">*/}
        {/*    <GridViewTwoToneIcon />*/}
        {/*  </ToggleButton>*/}
        {/*</ToggleButtonGroup>*/}
      </Box>
      {currentTab == 'members' && (
        <MembersTab isLoading={isMembersLoading} employees={employees} />
      )}
      {currentTab == 'teams' && (
        <TeamsTab
          isLoading={isTeamsLoading}
          teams={teams}
          onTeamEditCallback={props.openTeamEditDialogCallback}
        />
      )}
    </>
  );
};

export default Results;
