import {
  styled,
  Box,
  Card,
  Typography,
  Button,
  Grid,
  CircularProgress
} from '@mui/material';
import { Field, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import useRefMounted from '../../ui-bloom/hooks/useRefMounted';
import { TextField as FmTextFiled } from 'formik-mui';
import { changePassword } from '../../services/apiService';
import { useSnackbar } from 'notistack';

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`
);

export default function ChangePassword() {
  const isMountedRef = useRefMounted();
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  return (
        <Card
          sx={{
            m: 1
          }}
        >
          <Box>
            <Typography
              variant="h3"
              sx={{
                m: 1
              }}
            >
              {t('Change Password')}
            </Typography>
            <Typography
              variant="h4"
              color="text.secondary"
              fontWeight="normal"
              sx={{
                m: 1
              }}
            >
              {t('Enter the password to set new password.')}
            </Typography>
          </Box>

          <Formik
            initialValues={{
              password: '',
              rePassword: ''
            }}
            validationSchema={Yup.object().shape({
              password: Yup.string()
                .min(8)
                .max(255)
                .required(t('The password field is required')),
              rePassword: Yup.string()
                .oneOf(
                  [Yup.ref('password')],
                  t('Both password fields need to match')
                )
                .required(t('This field is required'))
            })}
            onSubmit={async (
              values,
              { setErrors, resetForm, setStatus, setSubmitting }
            ) => {
              try {
                setSubmitting(true);
                await changePassword(values.password);
                enqueueSnackbar(t('Passwored changed successfully'), {
                  variant: 'success'
                });
                setStatus({ success: true });
                setSubmitting(false);
                resetForm();
              } catch (err) {
                enqueueSnackbar(t('Failed to change password'), {
                  variant: 'error'
                });
                console.error(err);
                setStatus({ success: false });
                //  setErrors({ submit: err.message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              isSubmitting
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <Grid item padding={2}>
                  <Field
                    fullWidth
                    type={'password'}
                    name="password"
                    component={FmTextFiled}
                    label={t('Password')}
                    placeholder={t('Enter new password here...')}
                  />
                </Grid>
                <Grid item padding={2}>
                  <Field
                    fullWidth
                    type={'password'}
                    name="rePassword"
                    component={FmTextFiled}
                    label={t('Confirm password')}
                    placeholder={t('Confirm password here...')}
                  />
                </Grid>
                <Grid item padding={2}>
                  <Button
                    sx={{
                      mt: 2
                    }}
                    color="primary"
                    startIcon={
                      isSubmitting ? <CircularProgress size="1rem" /> : null
                    }
                    disabled={isSubmitting}
                    type="submit"
                    fullWidth
                    size="large"
                    variant="contained"
                  >
                    {t('Save')}
                  </Button>
                </Grid>
              </form>
            )}
          </Formik>
        </Card>
  );
}
