import { Box, styled, Typography, useTheme } from '@mui/material';
import PreField from '../../models/PreField';
import { getValueDesc } from '../utils/getValueDesc';

const LabelWrapper = styled(Box)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(13)};
    font-weight: bold;
    text-transform: uppercase;
    border-radius: ${theme.general.borderRadiusSm};
    padding: ${theme.spacing(0.9, 1.5, 0.7)};
    line-height: 1;
  `
);

const CompanyNameRenderer = ({
  field,
  themeColor
}: {
  field: PreField;
  themeColor: string;
}) => {
  const theme = useTheme();

  return (
    <Box mt={1}>
      <LabelWrapper
        sx={{
          background: `${theme.colors.warning.main}`,
          color: `${theme.palette.getContrastText(theme.colors.warning.dark)}`
        }}
      >
        {getValueDesc(field)}
      </LabelWrapper>
    </Box>
  );
};

export default CompanyNameRenderer;
