import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  styled,
  Switch,
  TextField,
  Typography,
  Zoom
} from '@mui/material';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import SaveIcon from '@mui/icons-material/Save';
import { User } from '../../../../services/apiService/response-models';
import { parseToApiErrorMessage } from '../../../../utility/parseToApiErrorMessage';
import { getUserById, saveUser } from '../../../../services/apiService';
import MemberSelector from '../../../../components/member-selector';
import { EmployeeListItem } from '../../../../services/apiService/response-models/data-views';
import _ from 'lodash';

const roles = [
  { label: 'Administrator', roleName: 'admin' },
  { label: 'Member', roleName: 'member' }
];

function CreateUserDialog(props: {
  members: EmployeeListItem[];
  userId?: number;
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
}) {
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(props.isOpen);
  const navigate = useNavigate();

  const [model, setModel] = useState<User | null>(null);
  const [selectedEmployee, setSelectedEmployee] =
    useState<EmployeeListItem | null>(null);
  const [isSaveInProgress, setIsSaveInProgress] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const getById = async (userId: number) => {
    try {
      const response = await getUserById(userId);
      setIsEdit(true);
      setModel(response.data);
    } catch (ex) {
      console.error(ex);
      const msg = ex.message ? ex.message : `Failed to load user by id`;
      enqueueSnackbar(t(msg), { variant: 'error' });
      navigate('/app/user/list');
    }
  };

  useEffect(() => {
    if (props.userId && Number(props.userId) > 0) {
      setIsLoading(true);
      getById(Number(props.userId)).then(() => {
        setIsLoading(false);
      });
    }
  }, []);

  const onSave = async (updatedModel: User) => {
    let isSuccess = false;
    try {
      setIsSaveInProgress(true);
      await saveUser(updatedModel);
      enqueueSnackbar(
        t(`User was ${!isEdit ? 'created' : 'updated'} successfully`),
        { variant: 'success' }
      );
      isSuccess = true;
      navigate('/app/user/list');
      setIsSaveInProgress(false);
      handleCreateClose();
      props.onSave();
    } catch (ex) {
      setIsSaveInProgress(false);
      console.log(ex);
      const msg = parseToApiErrorMessage(
        ex,
        `Failed to ${!isEdit ? 'created' : 'updated'} Team`
      );
      enqueueSnackbar(t(msg), { variant: 'error' });
    }
    return isSuccess;
  };

  useEffect(() => {
    setOpen(props.isOpen);
  }, [props.isOpen]);

  const handleCreateUserOpen = () => {
    setOpen(true);
  };

  const handleCreateClose = () => {
    setOpen(false);
    props.onClose();
  };

  const onMemberSelectionChange = (members: EmployeeListItem[]) => {
    if (members.length) {
      setSelectedEmployee(members[0]);
    } else {
      setSelectedEmployee(null);
    }
  };

  const lastName = (fullName: string) => {
    if (fullName) {
      const i = fullName.lastIndexOf(' ');
      if (i) return fullName.substr(i).trim();
    }
    return '';
  };

  const firstName = (fullName: string) => {
    if (fullName) {
      const i = fullName.indexOf(' ');
      if (i) return fullName.substr(0, i).trim();
    }
    return '';
  };

  return (
    <>
      <Dialog open={open} onClose={handleCreateClose}>
        <DialogTitle
          sx={{
            p: 3
          }}
        >
          <Typography variant="h4" gutterBottom>
            {t('Add new user')}
          </Typography>
          <Typography variant="subtitle2">
            {t(
              'Fill in the fields below to create and add a new user to the site'
            )}
          </Typography>
        </DialogTitle>
        <Formik
          initialValues={{
            employeeId: 0,
            email: '',
            userName: '',
            firstName: '',
            lastName: '',
            password: '',
            roles: []
          }}
          validationSchema={Yup.object().shape({
            employeeId: Yup.number()
              .min(1, t('Please select member'))
              .required(t('Please select member')),
            email: Yup.string().required(t('Email is required')),
            userName: Yup.string().required(t('User name is required')),
            password: Yup.string()
              .min(6)
              .max(255)
              .required(t('The password field is required')),
            roles: Yup.array()
              .min(1, t('Role is required'))
              .required(t('Role is required'))
          })}
          onSubmit={async (
            _values,
            { resetForm, setErrors, setStatus, setSubmitting }
          ) => {
            try {
              var isSuccess = await onSave({ ...model, ..._values });
              if (isSuccess) {
                resetForm();
                setStatus({ success: true });
                setSubmitting(false);
              }
            } catch (err) {
              console.error(err);
              setStatus({ success: false });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setFieldValue
          }) => (
            <form onSubmit={handleSubmit}>
              <DialogContent
                dividers
                sx={{
                  p: 3
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        {/* <MemberSelector
                          items={props.members}
                          onChange={(e) => {
                            if (e.length) {
                              const emp = e[0];
                              setFieldValue('email', emp.email);
                              setFieldValue('userName', emp.email);
                              setFieldValue('employeeId', emp.id);
                            } else {
                              setFieldValue('employeeId', 0);
                            }
                            onMemberSelectionChange(e);
                          }}
                          selectedIds={[]}
                        /> */}
                        {Boolean(touched.employeeId && errors.employeeId) && (
                          <FormHelperText error={true}>
                            {errors.employeeId}
                          </FormHelperText>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          error={Boolean(touched.userName && errors.userName)}
                          fullWidth
                          helperText={touched.userName && errors.userName}
                          label={t('User Name')}
                          name="userName"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={selectedEmployee?.email || ''}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          error={Boolean(touched.firstName && errors.firstName)}
                          fullWidth
                          helperText={touched.firstName && errors.firstName}
                          label={t('First name')}
                          name="firstName"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={firstName(selectedEmployee?.fullName)}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          error={Boolean(touched.lastName && errors.lastName)}
                          fullWidth
                          helperText={touched.lastName && errors.lastName}
                          label={t('Last name')}
                          name="lastName"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={lastName(selectedEmployee?.fullName)}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          error={Boolean(touched.password && errors.password)}
                          fullWidth
                          margin="normal"
                          helperText={touched.password && errors.password}
                          label={t('Password')}
                          name="password"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="password"
                          value={values.password}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Autocomplete
                          disablePortal
                          multiple
                          getOptionLabel={(option) => option.label}
                          isOptionEqualToValue={(option, value) =>
                            option.roleName == value.roleName
                          }
                          value={values.roles}
                          options={roles}
                          onChange={(event, values) => {
                            setFieldValue(
                              'roles',
                              values.length ? [_.last(values)] : values
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              error={Boolean(touched.roles && errors.roles)}
                              helperText={touched.roles && errors.roles}
                              fullWidth
                              {...params}
                              label={t('User role')}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions
                sx={{
                  p: 3
                }}
              >
                <Button color="secondary" onClick={handleCreateClose}>
                  {t('Cancel')}
                </Button>
                <Button
                  type="submit"
                  startIcon={
                    isSubmitting ? <CircularProgress size="1rem" /> : null
                  }
                  disabled={isSubmitting}
                  variant="contained"
                >
                  {t('Add new user')}
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}

export default CreateUserDialog;
