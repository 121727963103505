import { CardFieldValue, DigitalCardField } from "../../../../services/apiService/response-models";
import PreField from "../../models/PreField";

const convertCardFieldsToPreFields = (allFields: DigitalCardField[], cardFields: CardFieldValue[]):PreField[]=> {
    const results: PreField[] = [];
    cardFields.forEach(cardField => {
        const field = allFields.find(o => o.id == cardField.fieldId);
        if (field) {
            results.push({
                fieldName: field.name,
                fieldRef: field,
                id: '',
                order: cardField.order,
                fieldValues: cardField.formFieldValues
            });
        } else {
            console.error(cardField.fieldName + ' not found in category field list');
        }
    });

    return results;
}

export default convertCardFieldsToPreFields;