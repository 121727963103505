import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  Paper,
  Typography,
  useTheme
} from '@mui/material';
import {
  LinkAuthenticationElement,
  PaymentElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import { StripePaymentElementOptions } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import numeral from 'numeral';
import ErrorPanel from '../../components/error-panel';
import { PaymentIntentResult } from 'src/services/apiService/response-models';
import { ApiUrls } from 'src/services/endpoints';

function PaymentStep(props: PaymentIntentResult) {
  const stripe = useStripe();
  const elements = useElements();
  const theme = useTheme();
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [secret, setSecret] = useState('');
  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    );

    if (!clientSecret) {
      return;
    }
    setSecret(clientSecret);
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case 'succeeded':
          setMessage('Payment succeeded!');
          break;
        case 'processing':
          setMessage('Your payment is processing.');
          break;
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.');
          break;
        default:
          setMessage('Something went wrong.');
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        // `https://app.kiengage.com/app/payment-success?client=${secret}`
        return_url: encodeURI(ApiUrls.stripePaymentSuccessReturnUrl)
      }
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message);
    } else {
      setMessage('An unexpected error occurred.');
    }

    setIsLoading(false);
  };

  const paymentElementOptions: StripePaymentElementOptions = {
    layout: 'auto'
  };

  return (
    <Box component="div" px={1}>
      <Grid container>
        <Grid item xs={12}>
          <Card
            sx={{
              p: 2,
              pt: 0,
              display: { md: 'flex' },
              alignItems: { md: 'center' }
            }}
          >
            <Box flex={1} p={2} pt={0}>
              <Typography
                variant="h3"
                sx={{
                  my: 1
                }}
              >
                {props.productName}
              </Typography>
              <Typography variant="subtitle2">{props.productDesc}</Typography>
              <Box py={2}>
                <Typography
                  component="span"
                  variant={'h3'}
                  sx={{
                    pr: 2
                  }}
                >
                  ${props.productPrice}
                </Typography>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Paper
                  elevation={3}
                  sx={{ p: 1, width: '100%', background: '#4c4b4b' }}
                >
                  <div id="payment-form" onSubmit={handleSubmit}>
                    <PaymentElement
                      id="payment-element"
                      options={paymentElementOptions}
                    />

                    <Box
                      mt={2}
                      mb={1}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Button
                        onClick={handleSubmit}
                        sx={{
                          px: 2.5,
                          py: 1.5,
                          borderRadius: 10,
                          transform: 'scale(1)',
                          transition: `${theme.transitions.create(['all'])}`,
                          boxShadow: `${theme.colors.shadows.error}`,

                          '&:hover': {
                            transform: 'scale(1.05)',
                            boxShadow: `${theme.colors.shadows.error}`
                          },
                          '&:active': {
                            boxShadow: 'none'
                          }
                        }}
                        variant="contained"
                        color="error"
                        type="button"
                        disabled={isLoading || !stripe || !elements}
                        startIcon={
                          isLoading ? <CircularProgress size="1rem" /> : null
                        }
                      >
                        {'Pay now'}
                      </Button>
                    </Box>
                    {/* Show any error or success messages */}
                    {message && (
                      <div style={{ background: '#fff', margin: '1%' }}>
                        {' '}
                        <ErrorPanel title="Payment error" message={message} />
                      </div>
                    )}
                  </div>
                </Paper>
              </Box>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default PaymentStep;
