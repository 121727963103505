import * as MUIcon from "@mui/icons-material";
import { createSvgIcon, SvgIcon } from "@mui/material";
import { ReactComponent as Snapchat } from "./svg/snapchat.svg";
import { ReactComponent as Twitch } from "./svg/twitch.svg";
import { ReactComponent as Skype } from "./svg/skype.svg";

interface IconProps {
    //  name?: keyof typeof MUIcon;
    name: string;
}
export const Icon: React.FC<IconProps> = ({
    name,
}) => {
    const IconRef = MUIcon[name];
    if (!IconRef) {
        if (name == 'Snapchat') {
            return <SvgIcon>
                <Snapchat />
            </SvgIcon>
        } else if (name == 'Twitch'){
            return <SvgIcon>
                <Twitch />
            </SvgIcon>
        } else if (name == 'Skype') {
            return <SvgIcon>
                <Skype />
            </SvgIcon>
        }
    } 
    
    return (IconRef ? <IconRef /> : <span></span>)
}
