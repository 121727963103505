import { FC, useContext } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';

import Typography from '@mui/material/Typography';
import React from 'react';
import {
  Box,
  IconButton,
  LinearProgress,
  styled,
  Tooltip
} from '@mui/material';
import MuiAccordionSummary, {
  AccordionSummaryProps
} from '@mui/material/AccordionSummary';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

import NoteBlock from '../note-block';
import ActivitiesContext from '../activities-context';
import toLocDate from 'src/utility/toLocDate';
import { formatDistance } from 'date-fns';
import DeleteConfirm from 'src/components/delete-confirm';

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    justifyContent: 'space-between'
  }
}));

function NotesList() {
    const { notes, isLoading, expandedNoteIds, onExpand } = useContext(ActivitiesContext);

  const handleChange =
    (noteId: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        onExpand('note', noteId, isExpanded);
    };

  return (
    <div>
      {isLoading && <LinearProgress color="secondary" />}

      {!isLoading && notes.length == 0 && (
        <Typography variant="subtitle1" gutterBottom>
          No notes to display.
        </Typography>
      )}
      {notes.map((o) => (
        <Accordion
          key={o.id}
          expanded={expandedNoteIds.includes(o.id)}
          onChange={handleChange(o.id)}
        >
          <AccordionSummary
            expandIcon={
              <ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />
            }
            aria-controls="panel1bh-content"
            sx={{ background: '#dfdfdf' }}
            id={'panel' + o.id + 'bh-header'}
          >
            <Box sx={{ marginLeft: 1 }} display="flex">
              <Typography variant="h6">Note&nbsp;</Typography>
              <Typography variant="subtitle1">by {o.createdBy.name}</Typography>
            </Box>
            <Typography sx={{ color: 'text.secondary' }}>
              Added:{' '}
              {formatDistance(toLocDate(o.createdOn), new Date(), {
                addSuffix: true
              })}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <NoteBlock key={o.id} note={o} />
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}

export default NotesList;
