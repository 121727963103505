import { useState } from 'react';
import {
  Typography,
  Container,
  Button,
  Card,
  Box,
  styled,
  CardContent,
  CircularProgress,
  LinearProgress
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import * as Yup from 'yup';

import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import Logo from 'src/ui-bloom/components/LogoSign';
import { forgotPassword } from 'src/services/apiService';
import { RefData } from 'src/services/apiService/response-models';
import { useSnackbar } from 'notistack';
import { parseToApiErrorMessage } from 'src/utility/parseToApiErrorMessage';
import { useNavigate, useSearchParams } from 'react-router-dom';
import oAuth from 'src/auth-service';

const MainContent = styled(Box)(
  () => `
      height: 100%;
      overflow: auto;
      flex: 1;
  `
);

function SignUpLogout() {
  const { t }: { t: any } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const retUrl = searchParams.get('returl');

  const [loading, setLoading] = useState<Boolean>(false);
  const handleLogout = async (): Promise<void> => {
    setLoading(true);
    try {
      await oAuth.logout();
      window.location.reload();
    } catch (err) {
      console.error(err);
    }
  };
  const goToDashaboard = () => {
    navigate('/app/dashboard', { replace: true });
  };

  const goBack = () => {
    window.location.replace(retUrl);
  };

  return (
    <>
      <Helmet>
        <title>Signup @ KiEngage</title>
      </Helmet>
      <MainContent>
        {loading ? (
          <LinearProgress />
        ) : (
          <Container
            sx={{
              my: 4
            }}
            maxWidth="md"
          >
            <Logo />
            <Card
              sx={{
                mt: 3,
                pt: 4
              }}
            >
              <Box px={4}>
                <Typography
                  variant="h3"
                  sx={{
                    mb: 1
                  }}
                >
                  {t(
                    'You are already logged in. Please click below to go to dashboard.'
                  )}
                </Typography>
              </Box>

              <CardContent>
                <Box>
                  {retUrl && (
                    <Button
                      onClick={goBack}
                      color="primary"
                      size="large"
                      type="button"
                      variant="outlined"
                      sx={{
                        mx: 1,
                        px: 3
                      }}
                    >
                      {t('Go back')}
                    </Button>
                  )}
                  <Button
                    onClick={goToDashaboard}
                    color="primary"
                    size="large"
                    type="button"
                    variant="contained"
                    sx={{
                      mx: 1,
                      px: 3
                    }}
                  >
                    {t('Dashoard')}
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Container>
        )}
      </MainContent>
    </>
  );
}

export default SignUpLogout;
