import { Dictionary } from 'lodash';
import { ReactElement } from 'react';
import { CardFieldNames } from '../../../services/apiService/response-models';
import GoogleReviewBlock from '../Google-review/block';
import GoogleReviewEditor from '../Google-review/editor';
import ImageSliderBlock from '../ImageSlider/block';
import ImageSliderEditor from '../ImageSlider/editor';
import MediaPlayerBlock from '../MediaPlayer/block';
import MediaPlayerEditor from '../MediaPlayer/editor';
import FieldRenderBlockBaseProp from './FieldRenderBlockBaseProp';
import FieldRenderEditorBaseProp from './FieldRenderEditorBaseProp';

interface FieldRendererBase {
  renderEditor: (props: FieldRenderEditorBaseProp) => ReactElement;
  renderBlock: (props: FieldRenderBlockBaseProp) => ReactElement;
}

const Build = (): Dictionary<FieldRendererBase> => {
  const registry: Dictionary<FieldRendererBase> = {};

  registry[CardFieldNames.MediaPlayer] = {
    renderEditor: (props: FieldRenderEditorBaseProp): ReactElement => (
      <MediaPlayerEditor {...props} />
    ),
    renderBlock: (props: FieldRenderBlockBaseProp): ReactElement => (
      <MediaPlayerBlock {...props} />
    )
  };

  registry[CardFieldNames.ImageSlider] = {
    renderEditor: (props: FieldRenderEditorBaseProp): ReactElement => (
      <ImageSliderEditor {...props} />
    ),
    renderBlock: (props: FieldRenderBlockBaseProp): ReactElement => (
      <ImageSliderBlock {...props} />
    )
  };
  registry[CardFieldNames.GoogleReview] = {
    renderEditor: (props: FieldRenderEditorBaseProp): ReactElement => (
      <GoogleReviewEditor {...props} />
    ),
    renderBlock: (props: FieldRenderBlockBaseProp): ReactElement => (
      <GoogleReviewBlock {...props} />
    )
  };

  return registry;
};

export default Build();
