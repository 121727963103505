import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Grid,
  Slide,
  Divider,
  Tooltip,
  IconButton,
  InputAdornment,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableContainer,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Tab,
  Tabs,
  TextField,
  Button,
  Typography,
  Dialog,
  Zoom,
  styled,
  Skeleton,
  LinearProgress
} from '@mui/material';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {
  ChangeEvent,
  forwardRef,
  ReactElement,
  Ref,
  useEffect,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import BulkActions from '../BulkActions';
import Label from 'src/ui-bloom/components/Label';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import clsx from 'clsx';
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
import { TeamVM } from '../../../../services/apiService/response-models/data-views';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';
import { parseToApiErrorMessage } from '../../../../utility/parseToApiErrorMessage';
import { deleteTeamById } from '../../../../services/apiService';

interface Filters {
  role?: string;
}

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const ButtonError = styled(Button)(
  ({ theme }) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.error.dark};
     }
    `
);

const AvatarError = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const applyFilters = (
  teams: TeamVM[],
  query: string,
  filters: Filters
): TeamVM[] => {
  return teams.filter((item) => {
    let matches = true;

    if (query) {
      const properties = ['email', 'name', 'department'];
      let containsQuery = false;

      properties.forEach((property) => {
        if (item[property].toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      });

      //if (filters.role && item.role !== filters.role) {
      //    matches = false;
      //}

      if (!containsQuery) {
        matches = false;
      }
    }

    Object.keys(filters).forEach((key) => {
      const value = filters[key];

      if (value && item[key] !== value) {
        matches = false;
      }
    });

    return matches;
  });
};

const applyPagination = (
  teams: TeamVM[],
  page: number,
  limit: number
): TeamVM[] => {
  return teams.slice(page * limit, page * limit + limit);
};

function TeamsTab(props: {
  teams: TeamVM[];
  isLoading: boolean;
  onTeamEditCallback: (teamId: number) => void;
}) {
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(props.isLoading);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(50);
  const [query, setQuery] = useState<string>('');
  const [filters, setFilters] = useState<Filters>({
    role: null
  });

  const [teams, setTeams] = useState<TeamVM[]>(props.teams);
  const [selectedItems, setSelectedmembers] = useState<number[]>([]);
  const filteredmembers = applyFilters(teams, query, filters);
  const paginatedmembers = applyPagination(filteredmembers, page, limit);
  const selectedBulkActions = selectedItems.length > 0;
  const selectedSomemembers =
    selectedItems.length > 0 && selectedItems.length < teams.length;
  const selectedAllmembers = selectedItems.length === teams.length;
  const [idsToDelete, setIdsToDelete] = useState([]);

  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
    event.persist();
    setQuery(event.target.value);
  };

  useEffect(() => {
    setIsLoading(props.isLoading);
    setTeams(props.teams);
  }, [props.isLoading]);

  const handleSelectAllmembers = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    setSelectedmembers(event.target.checked ? teams.map((o) => o.id) : []);
  };

  const handleSelectOneItem = (
    _event: ChangeEvent<HTMLInputElement>,
    employeeId: number
  ): void => {
    if (!selectedItems.includes(employeeId)) {
      setSelectedmembers((prevSelected) => [...prevSelected, employeeId]);
    } else {
      setSelectedmembers((prevSelected) =>
        prevSelected.filter((id) => id !== employeeId)
      );
    }
  };

  const handlePageChange = (_event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const onBulkDelete = () => {
    setIdsToDelete([...selectedItems]);
  };

  const handleDeleteClick = (id: number) => {
    setIdsToDelete([id]);
  };

  const closeConfirmDelete = () => {
    setIdsToDelete([]);
  };

  const handleDeleteCompleted = async () => {
    const ids = [...idsToDelete];
    setIdsToDelete([]);

    if (ids.length > 1) {
      const successIds: number[] = [];

      const deleteAll = async () => {
        for (let index = 0; index < ids.length; index++) {
          const id = ids[index];
          try {
            const success = await deleteTeamById(id);
            if (success) {
              successIds.push(id);
            }
          } catch (ex) {
            console.log(ex);
            const msg = parseToApiErrorMessage(ex, `Failed to delete Team`);
            enqueueSnackbar(t(msg), { variant: 'error' });
          }
        }
      };
      await deleteAll();

      const items = [...teams].filter((o) => !successIds.includes(o.id));
      setTeams(items);
      if (successIds.length) {
        enqueueSnackbar(t('Team(s) have been deleted'), {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
          },
          TransitionComponent: Zoom
        });
      }
    } else {
      try {
        const id = ids[0];
        const success = await deleteTeamById(id);
        if (success) {
          const items = [...teams].filter((o) => o.id != id);
          setTeams(items);
          enqueueSnackbar(t('Team has been deleted'), {
            variant: 'success',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right'
            },
            TransitionComponent: Zoom
          });
        }
      } catch (ex) {
        console.log(ex);
        const msg = parseToApiErrorMessage(ex, `Failed to delete Team`);
        enqueueSnackbar(t(msg), { variant: 'error' });
      }
    }
  };

  return (
    <>
      {isLoading && <LinearProgress />}
      {
        <Card>
          <Box p={2}>
            {!selectedBulkActions && (
              <TextField
                sx={{
                  m: 0
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchTwoToneIcon />
                    </InputAdornment>
                  )
                }}
                onChange={handleQueryChange}
                placeholder={t('Search by name, email or department...')}
                value={query}
                size="small"
                fullWidth
                margin="normal"
                variant="outlined"
              />
            )}
            {selectedBulkActions && <BulkActions onDelete={onBulkDelete} />}
          </Box>

          <Divider />

          {paginatedmembers.length === 0 ? (
            <>
              <Typography
                sx={{
                  py: 10
                }}
                variant="h3"
                fontWeight="normal"
                color="text.secondary"
                align="center"
              >
                {props.isLoading && t('Loading teams...')}
                {!props.isLoading &&
                  t(
                    "We couldn't find any members matching your search criteria"
                  )}
              </Typography>
            </>
          ) : (
            <>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedAllmembers}
                          indeterminate={selectedSomemembers}
                          onChange={handleSelectAllmembers}
                        />
                      </TableCell>
                      <TableCell>{t('Name')}</TableCell>
                      <TableCell>{t('Description')}</TableCell>
                      <TableCell>{t('Active?')}</TableCell>
                      <TableCell align="center">{t('Actions')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedmembers.map((item) => {
                      const ismemberselected = selectedItems.includes(item.id);
                      return (
                        <TableRow
                          hover
                          key={item.id}
                          selected={ismemberselected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={ismemberselected}
                              onChange={(event) =>
                                handleSelectOneItem(event, item.id)
                              }
                              value={ismemberselected}
                            />
                          </TableCell>
                          <TableCell>
                            <Typography variant="h5">{item.name}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{item.description}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography fontWeight="bold">
                              {item.isActive ? 'Yes' : 'No'}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography noWrap>
                              <Tooltip title={t('View')} arrow>
                                <IconButton
                                  onClick={() =>
                                    props.onTeamEditCallback(item.id)
                                  }
                                  component={Button}
                                  color="primary"
                                >
                                  <LaunchTwoToneIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title={t('Delete')} arrow>
                                <IconButton
                                  onClick={() => handleDeleteClick(item.id)}
                                  color="primary"
                                >
                                  <DeleteTwoToneIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box p={2}>
                <TablePagination
                  component="div"
                  count={filteredmembers.length}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  page={page}
                  rowsPerPage={limit}
                  rowsPerPageOptions={[50, 100, 150]}
                />
              </Box>
            </>
          )}
        </Card>
      }
      <DialogWrapper
        open={idsToDelete.length > 0}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        onClose={closeConfirmDelete}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          p={5}
        >
          <AvatarError>
            <CloseIcon />
          </AvatarError>

          <Typography
            align="center"
            sx={{
              py: 4,
              px: 6
            }}
            variant="h3"
          >
            {t(
              'Are you sure you want to permanently delete ' +
                (idsToDelete.length > 1 ? 'selected teams' : ' this team')
            )}
            ?
          </Typography>

          <Box>
            <Button
              variant="text"
              size="large"
              sx={{
                mx: 1
              }}
              onClick={closeConfirmDelete}
            >
              {t('Cancel')}
            </Button>
            <ButtonError
              onClick={handleDeleteCompleted}
              size="large"
              sx={{
                mx: 1,
                px: 3
              }}
              variant="contained"
            >
              {t('Delete')}
            </ButtonError>
          </Box>
        </Box>
      </DialogWrapper>
    </>
  );
}

export default TeamsTab;
