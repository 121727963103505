import { Grid, Typography, Avatar, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';

function PageHeader() {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const { user } = useSelector((state: RootState) => state.app);
  return (
    <Grid container alignItems="center">
      <Grid item>
        <Avatar
          sx={{
            mr: 2,
            width: theme.spacing(8),
            height: theme.spacing(8)
          }}
          variant="rounded"
          alt={user?.userName}
        />
      </Grid>
      <Grid item>
        <Typography variant="h3" component="h3" gutterBottom>
          {t('Kiengage')}!
        </Typography>
        <Typography variant="subtitle2">
          {t('These are your analytics stats for today')},{' '}
          <b>{format(new Date(), 'MMMM dd yyyy')}</b>
        </Typography>
      </Grid>
    </Grid>
  );
}

export default PageHeader;
