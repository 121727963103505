import { Grid, MenuItem, Select, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  DigitalCardListItem
} from 'src/services/apiService/response-models';
import { RootState } from 'src/store';
import Footer from 'src/ui-bloom/components/Footer';
import PageTitleWrapper from 'src/ui-bloom/components/PageTitleWrapper';
// import { getCardItems } from '../../../services/apiService';
// import { getAnalytics } from '../../../services/apiService/data-view-service';
// import { AnalyticsView } from '../../../services/apiService/response-models/data-views';
import { AnalyticsView } from 'src/services/apiService/response-models/data-views';
import PageHeader from './PageHeader';
import AnalyticsCard from './analyticsCard';

const CACHE_KEY = 'get_analytics_data';
function CustomerAnalytics() {
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const user = useSelector((state: RootState) => state.app.user);
  const [data, setData] = useState<AnalyticsView | null>(null);
  const [cardItems, setCardItems] = useState<DigitalCardListItem[]>([]);
  const theme = useTheme();

  const dropdownData = [
    { id: 1, label: ' 1' },
    { id: 2, label: ' 2' },
    { id: 3, label: ' 3' }
    // Add more static options as needed
  ];
  const [cardCount, setCardCount] = useState<number>(0);
  const [selectedOption1, setSelectedOption1] = useState('');
  const [dateRange, setDateRange] = useState('');
  const fetchCardItems = async (selectedOption1) => {
    try {
      // Perform API call based on the selectedOption1
      // Replace this with your actual API call
      const res = await fetch(`/api/cardItems/${selectedOption1}`);
      const data = await res.json();
      setCardItems(data); // Update cardItems state with fetched data
    } catch (error) {
      console.error("Error fetching card items:", error);
      // Handle errors if necessary
    }
  };

  // Function to handle dropdown change
  const handleDropdownChange = async (event) => {
    const option = event.target.value;
    setSelectedOption1(option);
    const count = calculateCardCount(option);
    setCardCount(count);
    await fetchCardItems(option); // Fetch card items based on the selected option
  };
  
  const calculateCardCount = (selectedOption1: string) => {
    let count = parseInt(selectedOption1); // Convert selected option to number
    return count;
  };
  const handleDropdownChange2 = async (event) => {
    const option = event.target.value;
    setDateRange(option);
    // Add your logic here for this dropdown
  };
  // const handleDropdownChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
  //   const option = event.target.value as string;
  //   setSelectedOption1(option);
  //   const count = calculateCardCount(option);
  //   setCardCount(count); // Update cardCount state with calculated count
  // };


  return (
    <>
      <PageTitleWrapper>
        <PageHeader/>
        
      </PageTitleWrapper>
      <Grid
  sx={{
    px: 4
  }}
  container
  direction="row"
  justifyContent="center"
  alignItems="stretch"
  spacing={4}
>

  <Grid item xs={12} md={2.5}>
    <Select 
      value={selectedOption1}
      onChange={handleDropdownChange}
      displayEmpty
      variant="outlined"
      sx={{
        mt: 2, // Adjust margin as needed
        minWidth: '200px',
        mb: 2  // Set the margin-bottom here
      }}
    >
      <MenuItem value="" disabled>
        Select an option
      </MenuItem>
      {dropdownData.map((option) => (
        <MenuItem key={option.id} value={option.id}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  </Grid>
  <Grid item xs={12} md={2.1}>
    <Select 
      value={dateRange}
      onChange={handleDropdownChange2}
      displayEmpty
      variant="outlined"
      sx={{
        mt: 2, // Adjust margin as needed
        minWidth: '200px',
        mb: 2  // Set the margin-bottom here
      }}
    >
      <MenuItem value="" disabled>
        Select Date 
      </MenuItem>
      {dropdownData.map((option) => (
        <MenuItem key={option.id} value={option.id}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  </Grid>


</Grid>

      <Grid
        sx={{
          px: 4
        }}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
      >
        
<Grid item>
  
              <Grid
                container
                spacing={4}
                direction="row"
                justifyContent="center"
                alignItems="stretch"
              >
                <Grid item sm={6} xs={12}>
                  <AnalyticsCard count={selectedOption1} />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <AnalyticsCard count={selectedOption1} />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <AnalyticsCard count={selectedOption1} />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <AnalyticsCard count={selectedOption1} />
                </Grid>
                
                
              </Grid>
            </Grid>

      </Grid>
        
       
      {/* <Grid
        sx={{
          px: 4,
          mt: 4 
        }}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
      >
      <Grid item>
              {cardItems?.length > 0 && <TotalViewCard cardItems={cardItems} />}
            </Grid>
      </Grid>
      <Grid item>
              <Grid
                container
                spacing={4}
                direction="row"
                justifyContent="center"
                alignItems="stretch"
              >
                <Grid item sm={6} xs={12}>
                  <AnalyticsCard count={selectedOption1} />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <AnalyticsCard count={selectedOption1} />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <AnalyticsCard count={selectedOption1} />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <AnalyticsCard count={selectedOption1} />
                </Grid>
                
              </Grid>
            </Grid> */}
      <Footer />
    </>
  );
}

export default CustomerAnalytics;
