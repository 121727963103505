import PageHeader from './PageHeader';
import Footer from 'src/ui-bloom/components/Footer';
import PageTitleWrapper from 'src/ui-bloom/components/PageTitleWrapper';
import {
  forwardRef,
  ReactElement,
  Ref,
  useCallback,
  useEffect,
  useState
} from 'react';
import CardGridBlock from './CardGridBlock';
import {
  Dialog,
  Grid,
  Typography,
  styled,
  Avatar,
  Box,
  Button,
  Slide,
  Zoom,
  Tabs,
  Tab
} from '@mui/material';
import useRefMounted from 'src/ui-bloom/hooks/useRefMounted';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import {
  deleteCardById,
  getCardAppItems,
  getCardItems
} from '../../../services/apiService';
import {
  AccountTypes,
  CardTypes,
  DigitalCardListItem
} from '../../../services/apiService/response-models';
import CloseIcon from '@mui/icons-material/Close';
import { TransitionProps } from '@mui/material/transitions';
import { parseToApiErrorMessage } from '../../../utility/parseToApiErrorMessage';
import { RootState, useSelector } from '../../../store';

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const ButtonError = styled(Button)(
  ({ theme }) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.error.dark};
     }
    `
);

const AvatarError = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

const TabsWrapper = styled(Tabs)(
  ({ theme }) => `
    @media (max-width: ${theme.breakpoints.values.md}px) {
      .MuiTabs-scrollableX {
        overflow-x: auto !important;
      }

      .MuiTabs-indicator {
          box-shadow: none;
      }
    }
    `
);

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function CardsList() {
  const isMountedRef = useRefMounted();
  const navigate = useNavigate();
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { cardType } = useParams();
  const { user } = useSelector((state: RootState) => state.app);
  const [tabValue, setTabValue] = useState('my-cards');
  const [cardItems, setCardItems] = useState<DigitalCardListItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const isIndividualAccount = user.accountType == AccountTypes.Individual;

  const getData = useCallback(
    async (
      callback: (hasError: boolean, data: DigitalCardListItem[]) => void
    ) => {
      if (isMountedRef.current) {
        try {
          const res = await getCardAppItems(true);
          callback(false, res.data);
        } catch (ex) {
          const msg = ex.message ? ex.message : `Failed to load cards data`;
          enqueueSnackbar(t(msg), { variant: 'error' });
          setError(msg);
          callback(true, []);
        }
      }
    },
    [isMountedRef]
  );

  useEffect(() => {
    setIsLoading(true);
    getData((hasError, data) => {
      let cType =
        cardType == 'business' ? CardTypes.Business : CardTypes.Individual;
      if (cardType == 'service') cType = CardTypes.Service;

      const filtered = data.filter((o) => o.cardType == cType);
      setCardItems(filtered);
      setIsLoading(false);
    });
  }, [getData, cardType]);

  const onCardClick = (id: number) => {
    navigate('/app/cards/' + cardType + '/edit/' + id);
  };

  const [idToDelete, setIdToDelete] = useState(0);

  const onCardDeleteClick = (id: number) => {
    setIdToDelete(id);
  };

  const closeConfirmDelete = () => {
    setIdToDelete(0);
  };

  const handleDeleteCompleted = async () => {
    try {
      const success = await deleteCardById(idToDelete);
      if (success) {
        const items = [...cardItems].filter(
          (o) => o.digitalCardId != idToDelete
        );
        setCardItems(items);
        enqueueSnackbar(t('Card has been deleted'), {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
          },
          TransitionComponent: Zoom
        });
      }
      setIdToDelete(0);
    } catch (ex) {
      console.log(ex);
      const msg = parseToApiErrorMessage(ex, `Failed to delete Card`);
      enqueueSnackbar(t(msg), { variant: 'error' });
    }
  };

  const myCardItems = cardItems.filter(
    (o) => o.parentId == user.employeeIds[0]
  );
  const teamCardItems = cardItems.filter(
    (o) => o.parentId !== user.employeeIds[0]
  );

  return (
    <>
      <PageTitleWrapper>
        <PageHeader cardType={cardType} />
      </PageTitleWrapper>
      {cardType == 'business' && (
        <Grid
          sx={{
            px: 4
          }}
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={4}
        >
          {!isLoading && !cardItems.length && (
            <Grid item xs={12}>
              <Typography
                sx={{
                  py: 10
                }}
                variant="h3"
                fontWeight="normal"
                color="text.secondary"
                align="center"
              >
                {t("We couldn't find any data to display")}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <CardGridBlock
              isLoading={isLoading}
              items={cardItems}
              onClick={onCardClick}
              onDeleteClick={onCardDeleteClick}
            />
          </Grid>
        </Grid>
      )}
      {cardType != 'business' && (
        <Grid
          sx={{
            px: 4
          }}
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={4}
        >
          <Grid item xs={12}>
            <TabsWrapper
              onChange={(e, v) => {
                setTabValue(v);
              }}
              scrollButtons="auto"
              textColor="secondary"
              value={tabValue}
              variant="scrollable"
            >
              <Tab value={'my-cards'} label={'My Profiles'} />
              {!isIndividualAccount && (
                <Tab value={'team-cards'} label={'Team Profiles'} />
              )}
            </TabsWrapper>
          </Grid>
          {tabValue == 'my-cards' && (
            <Grid item xs={12}>
              {!isLoading && myCardItems.length == 0 && (
                <Typography
                  sx={{
                    py: 10
                  }}
                  variant="h3"
                  fontWeight="normal"
                  color="text.secondary"
                  align="center"
                >
                  {t('No cards to display')}
                </Typography>
              )}
              <CardGridBlock
                isLoading={isLoading}
                items={myCardItems}
                onClick={onCardClick}
                onDeleteClick={onCardDeleteClick}
              />
            </Grid>
          )}
          {tabValue == 'team-cards' && (
            <Grid item xs={12}>
              {!isLoading && teamCardItems.length == 0 && (
                <Typography
                  sx={{
                    py: 10
                  }}
                  variant="h3"
                  fontWeight="normal"
                  color="text.secondary"
                  align="center"
                >
                  {t('No cards to display')}
                </Typography>
              )}
              <CardGridBlock
                isLoading={isLoading}
                items={teamCardItems}
                onClick={onCardClick}
                onDeleteClick={onCardDeleteClick}
              />
            </Grid>
          )}
        </Grid>
      )}
      <DialogWrapper
        open={idToDelete > 0}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        onClose={closeConfirmDelete}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          p={5}
        >
          <AvatarError>
            <CloseIcon />
          </AvatarError>

          <Typography
            align="center"
            sx={{
              py: 4,
              px: 6
            }}
            variant="h3"
          >
            {t('Are you sure you want to permanently delete this card')}?
          </Typography>

          <Box>
            <Button
              variant="text"
              size="large"
              sx={{
                mx: 1
              }}
              onClick={closeConfirmDelete}
            >
              {t('Cancel')}
            </Button>
            <ButtonError
              onClick={handleDeleteCompleted}
              size="large"
              sx={{
                mx: 1,
                px: 3
              }}
              variant="contained"
            >
              {t('Delete')}
            </ButtonError>
          </Box>
        </Box>
      </DialogWrapper>
    </>
  );
}

export default CardsList;
