import { CardFormField } from "../../../../../services/apiService/response-models";

const emailRegex = new RegExp(
    '^[a-zA-Z0-9.!#$%&�*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$'
);
const urlRegex = new RegExp(
    '^((ft|htt)ps?:\\/\\/)?' +
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
    '((\\d{1,3}\\.){3}\\d{1,3}))' +
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
    '(\\?[;&a-z\\d%_.~+=-]*)?' +
    '(\\#[-a-z\\d_]*)?$',
    'i'
);
const universalPhoneRegex = new RegExp('^(\\+?\\d{1,3}[- ]?)?\\d{10}$');

const validate = (e: CardFormField, value: string):string => {
    let errorMessage  = '';

    if (e.isRequired) {
        if (!value || value.trim().length == 0)
            errorMessage = 'This field can\'t be empty';
    }
    //if no error
    if (!errorMessage)    {
        //validation key
        switch (e.validationKey) {
            case 'email':
                if (!emailRegex.test(value)) {
                    errorMessage = 'This field must be a valid email';
                }
                break;
            case 'phone':
                if (!universalPhoneRegex.test(value)) {
                    errorMessage = 'This field must be a valid contact number';
                }
                break;
            case 'link':
                if (!urlRegex.test(value)) {
                    errorMessage = 'This field must be a valid url';
                }
                break;
        }
    }

    return errorMessage;
};

export default validate;