import { EditTwoTone } from '@mui/icons-material';
import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Link,
  List,
  ListItem,
  ListItemText,
  Rating,
  Stack,
  styled,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import { format, formatDistance } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ReviewSaveModel } from 'src/BusinessApp/services/models/review-app-models';
import { RefData } from 'src/services/apiService/response-models';
import useRefMounted from 'src/ui-bloom/hooks/useRefMounted';
import ErrorPanel from '../../../components/error-panel';
import Activities from '../../../CRM/components/Activities';
import {
  getRefData,
  getReviewAppsDataById
} from '../../../services/apiService';
import Scrollbar from '../../../ui-bloom/components/Scrollbar';
import toLocDate from '../../../utility/toLocDate';
import EditReviewDialog from '../edit-review-dialog';
import { getStatusLabel } from '../list/Results';

const CardContentWrapper = styled(Box)(
  ({ theme }) => `
    background: ${theme.colors.alpha.white[100]};
    border-radius: ${theme.general.borderRadius};
  `
);

const LabelWrapper = styled(Box)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(10)};
    font-weight: bold;
    text-transform: uppercase;
    border-radius: ${theme.general.borderRadiusSm};
    padding: ${theme.spacing(0.9, 1.5, 0.7)};
    line-height: 1;
  `
);

const ListWrapper = styled(List)(
  () => `
    .MuiDivider-root:last-of-type {
        display: none;
    }
  `
);

function ReviewDetail() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [review, setReview] = useState<ReviewSaveModel | null>(null);
  const [error, setError] = useState('');
  const isMountedRef = useRefMounted();
  const [accountRefData, setAccountRefData] = useState<RefData>({
    selfReviewStatus: []
  });
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const loadRefData = useCallback(
    async (callback: (hasError: boolean, data: RefData) => void) => {
      if (isMountedRef.current) {
        const [statusData] = await Promise.all([
          getRefData('SelfReviewStatus')
        ]);
        callback(false, statusData);
      }
    },
    [isMountedRef]
  );

  useEffect(() => {
    loadRefData((hasError, data) => {
      if (data) {
        setAccountRefData({
          selfReviewStatus: data.selfReviewStatus || []
        });
      }
    });
  }, [loadRefData]);

  const getById = async (reviewId: string) => {
    try {
      const response = await getReviewAppsDataById(reviewId);
      response.data.createdOn = toLocDate(response.data.createdOn);
      setReview(response.data);
    } catch (ex) {
      console.error(ex);
      const msg = ex.message ? ex.message : `Failed to load review by id`;
      setError(msg);
      enqueueSnackbar(t(msg), { variant: 'error' });
    }
  };

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      getById(id).then(() => {
        setIsLoading(false);
      });
      // setLead({} as LeadItem);
    } else {
      navigate('/app/review/list');
    }
  }, []);

  if (error) {
    return <ErrorPanel title="Critical error" message={error} />;
  }

  if (isLoading || !review) {
    return <LinearProgress sx={{ mt: 2 }} />;
  }

  return (
    <Grid container>
      <Grid item xs={12} md={3}>
        <ReviewSummary review={review} setEditDialogOpen={setEditDialogOpen} />
      </Grid>
      <Grid item xs={12} md={9}>
        <Activities
          entityType="review"
          entityId={id + ''}
          entityName={review?.name + 'Review'}
        />
      </Grid>

      <EditReviewDialog
        reviewId={id}
        members={[]}
        reviewStatusRefData={accountRefData.selfReviewStatus}
        // employeeId={1} // reviewItemToEdit?.employeeId
        status={review.status}
        isOpen={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        onSave={() => {
          setEditDialogOpen(false);
          setIsLoading(true);
          getById(id).then(() => {
            setIsLoading(false);
          });
        }}
      />
    </Grid>
  );
}

function ReviewSummary({
  review,
  setEditDialogOpen
}: {
  review: ReviewSaveModel;
  setEditDialogOpen: (open: boolean) => void;
}) {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const questions = review.questioniers.filter((q) => q?.answer);

  const onGoback = () => {
    navigate('/app/review/list');
  };

  const onEdit = () => {
    navigate('/app/review/edit/' + review.id);
  };

  const renderAnswer = (ans: string) => {
    console.log(ans);
    switch (ans) {
      case 'very satisfied':
        return (
          <Box alignSelf="flex-start">
            <LabelWrapper
              sx={{
                background: `${theme.colors.success.main}`,
                color: `#fff`,
                width: '75px',
                my: 1
              }}
            >
              {t(ans)}
            </LabelWrapper>
          </Box>
        );
      case 'satisfied':
        return (
          <Box alignSelf="flex-start">
            <LabelWrapper
              sx={{
                background: `${theme.colors.info.main}`,
                color: `#fff`,
                width: '75px',
                my: 1
              }}
            >
              {t(ans)}
            </LabelWrapper>
          </Box>
        );
      default:
        return (
          <Box alignSelf="flex-start">
            <LabelWrapper
              sx={{
                background: `${theme.colors.error.main}`,
                color: `${theme.palette.getContrastText(
                  theme.colors.error.dark
                )}`,
                width: '95px',
                my: 1
              }}
            >
              {t(ans)}
            </LabelWrapper>
          </Box>
        );
    }
  };
  return (
    <Card
      sx={{
        position: 'relative',
        p: 3,
        m: 1
      }}
    >
      <CardActions>
        <Tooltip title="Back to Reviews" placement="top">
          <Button
            onClick={onGoback}
            variant="outlined"
            startIcon={<ArrowBackTwoToneIcon />}
            size="small"
          >
            {t('Reviews')}
          </Button>
        </Tooltip>
      </CardActions>
      <Box mb={1} mt={1} display="flex" alignItems="center">
        <Avatar
          variant="rounded"
          sx={{
            fontSize: `${theme.typography.pxToRem(16)}`,
            background: `${theme.colors.alpha.black[100]}`,
            color: `${theme.palette.getContrastText(
              theme.colors.alpha.black[100]
            )}`,
            borderRadius: `${theme.general.borderRadiusSm}`,
            width: 95,
            height: 95
          }}
        >
          *
        </Avatar>
        <Box
          sx={{
            width: '100%'
          }}
          ml={1.5}
        >
          <Link
            href="#"
            color="text.primary"
            underline="none"
            sx={{
              transition: `${theme.transitions.create(['color'])}`,
              fontSize: `${theme.typography.pxToRem(17)}`,

              '&:hover': {
                color: `${theme.colors.primary.main}`
              }
            }}
            variant="h4"
          >
            {review.profileName}
          </Link>
          <Typography gutterBottom variant="subtitle2">
            {review.profileEmail}
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            flex={1}
            sx={{
              width: '100%'
            }}
          >
            <Rating name="simple-controlled" value={review?.rating} readOnly />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            flex={1}
            sx={{
              width: '100%'
            }}
          >
            <Typography
              sx={{
                mt: 1.5
              }}
              gutterBottom
            >
              Status:
            </Typography>
            {getStatusLabel(review)}
            <Tooltip title={t('View')} arrow>
              <IconButton
                onClick={() => {
                  setEditDialogOpen(true);
                }}
                color="primary"
              >
                <EditTwoTone fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      <Divider
        sx={{
          mt: 1
        }}
      />
      <Typography
        sx={{
          mt: 1.5
        }}
        variant="h5"
      >
        Review from:
      </Typography>
      <Stack sx={{ width: '100%', mb: 1.5 }}>
        <Box mt={0.7} display="flex" alignItems="center">
          <Avatar
            sx={{
              mr: 1,
              width: 28,
              height: 28
            }}
            src="/static/images/avatars/1.jpg"
          />
          <Typography
            sx={{
              fontSize: `${theme.typography.pxToRem(13)}`,
              color: '#2442AF'
            }}
            variant="h6"
          >
            {review.name} {review.email} {review.contactNumber}
          </Typography>
        </Box>
        {review.createdOn && (
          <Box mt={0.7} display="flex" alignItems="center">
            <Typography
              sx={{
                fontSize: `${theme.typography.pxToRem(12)}`,
                pl: 1
              }}
              variant="subtitle2"
            >
              {formatDistance(review.createdOn, new Date(), {
                addSuffix: true
              })}{' '}
              ({format(review.createdOn, 'yyyy-MM-dd HH:mm a')})
            </Typography>
          </Box>
        )}
      </Stack>
      <Divider
        sx={{
          mb: 1
        }}
      />
      <Typography
        sx={{
          my: 1.5
        }}
        variant="h5"
      >
        Additional Remarks:
      </Typography>
      <Typography variant="subtitle2">{review.comment}</Typography>
      {questions.length > 0 && (
        <Typography
          sx={{
            my: 1.5
          }}
          variant="h5"
        >
          Additional feedback:
        </Typography>
      )}

      <CardContentWrapper
        sx={{
          mx: 0,
          mb: 3,
          height: 344
        }}
      >
        <Scrollbar>
          {questions.map((o) => (
            <ListWrapper key={o.code} disablePadding>
              <ListItem
                sx={{
                  p: 0,
                  backgroundColor: 'rgb(243, 246, 249)',
                  my: 2
                }}
              >
                <ListItemText
                  primary={
                    <Link color="text.primary" variant="h6">
                      {o.value}
                    </Link>
                  }
                  secondary={renderAnswer(o.answer?.toLowerCase())}
                />
              </ListItem>
              <Divider />
            </ListWrapper>
          ))}
        </Scrollbar>
      </CardContentWrapper>
    </Card>
  );
}

export default ReviewDetail;
