import { CardHeader, Card, CardContent } from '@mui/material';
import FeatureImageField from 'src/components/FeatureImage/FeatureImageField';

function Sidebar() {
  return (
    <Card sx={{ textAlign: 'center', m: 1 }}>
      <CardHeader title="Profile Image" />
      <CardContent sx={{ p: 0 }}>
        <FeatureImageField name="imageUrl" />
      </CardContent>
    </Card>
  );
}

export default Sidebar;
