import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Skeleton,
  Tooltip,
  Typography,
  styled,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';

import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import ChevronLeftTwoToneIcon from '@mui/icons-material/ChevronLeftTwoTone';
import ChevronRightTwoToneIcon from '@mui/icons-material/ChevronRightTwoTone';
import SaveTwoToneIcon from '@mui/icons-material/SaveTwoTone';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useTranslation } from 'react-i18next';
import WarnConfirm from 'src/components/warn-confirm';
import Text from 'src/ui-bloom/components/Text';
import { Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import DefaultCardThumb from '../../components/CardThumbs/DefaultCardThumb';
import InfoPanel from '../../components/info-panel';
import {
  getCardAppItems,
  lookupNfcMap,
  saveNfcMap
} from '../../services/apiService';
import {
  CardAppItem,
  CardAppNames,
  DigitalCardAppItem,
  NfcCodeMap
} from '../../services/apiService/response-models';
import { parseToApiErrorMessage } from '../../utility/parseToApiErrorMessage';

const AvatarWrapperSuccess = styled(Avatar)(
  ({ theme }) => `
    background-color: ${theme.colors.success.lighter};
    color:  ${theme.colors.success.main};
`
);
const RootWrapper = styled(Card)(
  ({ theme }) => `
    background: ${theme.colors.gradients.blue3};
    color: ${theme.colors.alpha.trueWhite[100]};
    
    .MuiIconButton-root {
      background: ${theme.colors.alpha.trueWhite[10]};
      color: ${theme.colors.alpha.trueWhite[70]};
      
      &:hover {
        background: ${theme.colors.alpha.trueWhite[10]};
        color: ${theme.colors.alpha.trueWhite[100]};
      }
    }
    
    .MuiAvatarGroup-avatar {
      background: ${theme.colors.alpha.trueWhite[10]};
      color: ${theme.colors.alpha.trueWhite[70]} !important;
    }
    
    .MuiList-root {
      margin-bottom: ${theme.spacing(2)};

      .MuiButtonBase-root {
        color: ${theme.colors.alpha.trueWhite[70]};
      
        &:hover, &:active, .active {
            background: ${theme.colors.alpha.trueWhite[10]};
            color: ${theme.colors.alpha.trueWhite[100]};
        }
      }
      
      .MuiListItemAvatar-root {
            background: ${theme.colors.alpha.trueWhite[100]};
            border-radius: 100px;
            margin-right: ${theme.spacing(1.5)};
            width: ${theme.spacing(5)};
            height: ${theme.spacing(5)};
            display: flex;
            align-items: center;
            justify-content: center;
      }
    }
`
);

const ListItemWrapper = styled(ListItemButton)(
  ({ theme }) => `
    border-radius: 0;

    .MuiSwitch-colorPrimary .MuiSwitch-thumb {
      background: ${theme.colors.alpha.trueWhite[50]};
      border-color: ${theme.colors.alpha.trueWhite[30]};
    }

    .MuiSwitch-colorPrimary.Mui-checked .MuiSwitch-thumb {
      background: ${theme.colors.alpha.trueWhite[100]};
      border-color: ${theme.colors.alpha.trueWhite[100]};
    }
    
    .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
        background: ${theme.colors.alpha.trueWhite[100]};
    }
`
);

const SwipeIndicator = styled(IconButton)(
  ({ theme }) => `
    @media (max-width: ${theme.breakpoints.values.sm}px) {
        display: none;
    }
    transition: ${theme.transitions.create(['background', 'color'])};
    color: ${theme.colors.alpha.trueWhite[50]};
    position: absolute;
    width: ${theme.spacing(5)};
    height: ${theme.spacing(5)};
    top: 50%;
    margin-top: ${theme.spacing(-1.5)};
    border-radius: 100px;

    &:hover {
      color: ${theme.colors.alpha.trueWhite[100]};
      background: ${theme.colors.alpha.trueWhite[10]};
    }

    &.MuiSwipe-left {
      left: ${theme.spacing(1.5)};
    }
    
    &.MuiSwipe-right {
      right: ${theme.spacing(1.5)};
    }
`
);

const SwiperWrapper = styled(Box)(
  ({ theme }) => `
    .swiper-pagination {
      .swiper-pagination-bullet {
        background: ${theme.colors.alpha.trueWhite[30]};
        opacity: 1;

        &.swiper-pagination-bullet-active {
          background: ${theme.colors.alpha.trueWhite[100]};
          width: 16px;
          border-radius: 6px;
        }
      }
    }
`
);

const IconButtonDotted = styled(IconButton)(
  ({ theme }) => `
    border-radius: 100px;
    border: 1px dashed ${theme.colors.alpha.trueWhite[100]};
    color: ${theme.colors.alpha.trueWhite[100]};
`
);

function MyCards(props: {
  nfcCode: string;
  returnUrl: string;
  onGoBack: () => void;
}) {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.up('md')) ? false : true;
  const { enqueueSnackbar } = useSnackbar();
  const [cards, setCards] = useState<DigitalCardAppItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  //const [initialSlide, setInitialSlide] = useState(0);
  const [nfcCodeMap, setNfcCodeMap] = useState<NfcCodeMap | null>(null);
  const [selectedCard, setSelectedCard] = useState<DigitalCardAppItem | null>(
    null
  );
  const [selectedCardIndex, setSelectedCardIndex] = useState<number>(0);
  const [linkedCardId, setLinkedCardId] = useState<number | null>(null);
  const [linkedCard, setLinkedCard] = useState<DigitalCardAppItem | null>(null);

  const [selectedCardApp, setSelectedCardApp] = useState<CardAppItem | null>();
  const [isSaveInProgress, setIsSaveInProgress] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const nfcCode = props.nfcCode;

  const getNfcMap = async (
    callback: (hasError: boolean, data: NfcCodeMap) => void
  ) => {
    // if (isMountedRef.current) {
    try {
      const res = await lookupNfcMap(nfcCode);
      callback(false, res.data);
    } catch (ex) {
      const msg =
        parseToApiErrorMessage(ex, `Failed to load nfc map data`) ||
        'Nfc code not found';
      enqueueSnackbar(t(msg), { variant: 'error' });
      setError(msg);
      callback(true, null);
    }
    // }
  };

  const getData = async (
    callback: (hasError: boolean, data: DigitalCardAppItem[]) => void
  ) => {
    // if (isMountedRef.current) {
    try {
      const res = await getCardAppItems(true);
      callback(false, res.data);
    } catch (ex) {
      const msg = parseToApiErrorMessage(ex, `Failed to load cards data`);
      enqueueSnackbar(t(msg), { variant: 'error' });
      setError(msg);
      callback(true, []);
    }
    // }
  };

  useEffect(() => {
    setIsLoading(true);
    getNfcMap((hasError, map) => {
      if (!hasError) {
        setNfcCodeMap(map);
        getData((hasError, data) => {
          setCards(data);

          if (data.length) {
            const match = data.find((o) => o.digitalCardId == map.cardId);
            if (match) {
              //already mapped to a card
              setLinkedCardId(map.cardId || null);
              setLinkedCard({ ...match });
              setSelectedCard(match);

              // const index = data.findIndex(
              //   (o) => o.digitalCardId == map?.cardId
              // );
              // if (index >= 0) {
              //   setInitialSlide(index);
              //   const match = data[index].cardAppItems.find(
              //     (o) => o.cardAppId == map?.cardAppId
              //   );
              //   if (match) {
              //     setSelectedCardApp(match);
              //   }
              // }
            } else {
              setSelectedCard(_.first(data));
            }
          }
          setIsLoading(false);
        });
      } else {
        setIsLoading(false);
      }
    });
  }, []);

  useEffect(() => {
    //updateSelectedCardApp
    if (selectedCard) {
      const index = cards.findIndex(
        (o) => o.digitalCardId == selectedCard.digitalCardId
      );
      if (index >= 0) {
        setSelectedCardIndex(index);
        const card = cards[index];

        if (card.digitalCardId == nfcCodeMap.cardId) {
          const match = card.cardAppItems.find(
            (o) => o.cardAppId == nfcCodeMap.cardAppId
          );
          if (match) {
            setSelectedCardApp(match);
          }
        } else {
          const match = card.cardAppItems.find(
            (o) => o.cardApp.name == CardAppNames.BusinessApp
          );
          if (match) {
            setSelectedCardApp(match);
          }
        }
      }
    }
  }, [cards, selectedCard, nfcCodeMap]);

  const handleSlideChange = (e) => {
    setSelectedCard(cards[e.activeIndex]);
  };
  const onCardAppSwitch = (
    event: ChangeEvent<HTMLInputElement>,
    e: CardAppItem
  ) => {
    setSelectedCardApp(event.target.checked ? e : null);
  };

  const onCardClick = () => {
    if (selectedCard && selectedCard.cardAppItems.length) {
      setSelectedCardApp(selectedCard.cardAppItems[0]);
    }
  };

  const onSaveClick = () => {
    if (linkedCardId && linkedCardId == selectedCard.digitalCardId) {
      enqueueSnackbar(t('No changes made'), { variant: 'info' });
      return;
    }
    if (linkedCardId && linkedCardId !== selectedCard.digitalCardId) {
      setIsConfirmOpen(true);
    } else {
      saveSelection().then(() => {});
    }
  };
  const saveSelection = async () => {
    try {
      // if (!selectedCardApp) {
      //   enqueueSnackbar(t(`No card app selected`), { variant: 'info' });
      //   return;
      // }
      setIsSaveInProgress(true);
      await saveNfcMap(nfcCode, selectedCard.digitalCardId, 1);

      enqueueSnackbar(t(`Selection saved successfully`), {
        variant: 'success'
      });

      props.onGoBack();
    } catch (ex) {
      setIsSaveInProgress(false);
      console.log(ex);
      const msg = parseToApiErrorMessage(ex, `Failed to save selection`);
      enqueueSnackbar(t(msg), { variant: 'error' });
    }
  };

  if (isLoading) return <Loader />;

  const renderHintList = () => {
    const isAlreadyMapped =
      linkedCardId && linkedCardId > 0 && linkedCard != null;

    return (
      <List sx={{ m: 1, px: 1, mb: 0 }} disablePadding>
        {isAlreadyMapped && (
          <ListItem disableGutters>
            <ListItemText
              primary={
                <>
                  {' '}
                  <Text color="success">
                    This NFC card is linked to a member profile:
                  </Text>
                  <span
                    style={{
                      color: '#fff',
                      fontStyle: 'bold',
                      marginLeft: 1
                    }}
                  >
                    {linkedCard.label}
                  </span>{' '}
                  <br />
                  <Text color="info">
                    To link it to other profile, please follow below steps
                  </Text>
                </>
              }
              primaryTypographyProps={{
                variant: 'body1',
                color: 'textPrimary',
                gutterBottom: true,
                noWrap: false
              }}
              secondary={
                <Box sx={{ pl: 1 }}>
                  {mobile ? (
                    <Text color="info">
                      {'(1)'} Swipe left or right to select the profile you want
                      to link.
                    </Text>
                  ) : (
                    <Text color="info">
                      {'(1)'} Use slider to select the profile you want to link.
                    </Text>
                  )}
                  <br />
                  <Text color="info">
                    {'(2)'} Click "Save Selection" and save the linking.
                  </Text>
                </Box>
              }
              secondaryTypographyProps={{ variant: 'body2', noWrap: false }}
            />
          </ListItem>
        )}
        {!isAlreadyMapped && (
          <ListItem disableGutters>
            <ListItemText
              primary={
                <>
                  {' '}
                  <Text color="warning">
                    This NFC card is <b>Not</b> linked to any profile. Please
                    follow below steps
                  </Text>
                </>
              }
              primaryTypographyProps={{
                variant: 'body1',
                color: 'textPrimary',
                gutterBottom: true,
                noWrap: false
              }}
              secondary={
                <>
                  {mobile ? (
                    <Text color="info">
                      {'(1)'} Swipe left or right to select the profile you want
                      to link.
                    </Text>
                  ) : (
                    <Text color="info">
                      {'(1)'} Use slider to select the profile you want to link.
                    </Text>
                  )}
                  <br />
                  <Text color="info">
                    {'(2)'} Click "Save Selection" and save the linking.
                  </Text>
                </>
              }
              secondaryTypographyProps={{ variant: 'body2', noWrap: false }}
            />
          </ListItem>
        )}
      </List>
    );
  };

  return (
    <RootWrapper
      sx={{
        height: '100%'
      }}
    >
      <CardHeader
        title={t('Profile Selector')}
        sx={{ pb: 0 }}
        // action={
        //   <FormControl variant="standard" sx={{ minWidth: 120 }} size="small">
        //     <InputLabel sx={{ color: '#F8F8F8' }} id="demo-select-small-label">
        //       Profile Type
        //     </InputLabel>
        //     <Select
        //       labelId="demo-select-small-label"
        //       value={1}
        //       label="Profile Type"
        //       sx={{ color: '#F8F8F8' }}
        //     >
        //       <MenuItem value={1}>
        //         <em>All (50)</em>
        //       </MenuItem>
        //       <MenuItem value={10}>Company (20)</MenuItem>
        //       <MenuItem value={20}>Member (30)</MenuItem>
        //     </Select>
        //   </FormControl>
        // }
      />
      {!nfcCodeMap && (
        <Paper elevation={3} sx={{ m: 2 }}>
          {' '}
          <InfoPanel title="No data" message="No data to display." />
        </Paper>
      )}
      {nfcCodeMap && (
        <>
          {renderHintList()}

          <SwiperWrapper
            sx={{
              mx: 'auto',
              maxWidth: 600,
              position: 'relative',
              py: 2,
              px: { xs: 2, sm: 8 }
            }}
          >
            <Swiper
              initialSlide={selectedCardIndex}
              onSlideChange={(o) => handleSlideChange(o)}
              spaceBetween={30}
              slidesPerView={1}
              navigation={{
                nextEl: '.MuiSwipe-right',
                prevEl: '.MuiSwipe-left'
              }}
              // @ts-ignore
              modules={[Navigation, Pagination]}
              pagination={{
                clickable: true,
                dynamicBullets: true
              }}
            >
              {!isLoading &&
                cards.map((o) => (
                  <SwiperSlide key={o.digitalCardId}>
                    <DefaultCardThumb
                      item={o}
                      actionLabel={'Link'}
                      onClick={onCardClick}
                      onDeleteClick={() => {}}
                    />
                  </SwiperSlide>
                ))}
            </Swiper>
            <SwipeIndicator className="MuiSwipe-root MuiSwipe-left">
              <ChevronLeftTwoToneIcon />
            </SwipeIndicator>
            <SwipeIndicator className="MuiSwipe-root MuiSwipe-right">
              <ChevronRightTwoToneIcon />
            </SwipeIndicator>
          </SwiperWrapper>
          <Typography variant="h5"></Typography>
          {/* <CardHeader title={t('Card Apps')} />
          <List disablePadding>
            {selectedCard?.cardAppItems.map((item) => (
              <ListItemWrapper
                key={item.id}
                selected={selectedCardApp?.id == item.id}
              >
                <Avatar
                  variant="rounded"
                  sx={{
                    background: `${
                      selectedCardApp?.id == item.id
                        ? theme.colors.success.dark
                        : theme.colors.info.main
                    }`,
                    color: `${theme.palette.getContrastText(
                      theme.colors.info.dark
                    )}`,
                    width: 44,
                    height: 44,
                    mr: 1
                  }}
                >
                  <Icon name={item.cardApp.icon} />
                </Avatar>
                <ListItemText
                  primary={item.cardApp.displayName}
                  primaryTypographyProps={{ variant: 'h5', noWrap: true }}
                />
                <Tooltip arrow title={t('Click to link App')}>
                  <FormControlLabel
                    control={
                      <Switch
                        size="medium"
                        checked={selectedCardApp?.id == item.id}
                        onChange={(e) => onCardAppSwitch(e, item)}
                        color="success"
                        name="interest"
                      />
                    }
                    label=""
                    labelPlacement="start"
                  />
                </Tooltip>
              </ListItemWrapper>
            ))}
          </List> */}
          {/* {selectedCardApp && (
            <CardHeader
              title={
                t(
                  'Link ' +
                    nfcCode +
                    ' to ' +
                    selectedCardApp.cardApp.displayName
                ) +
                ' app (' +
                cardItems.find(
                  (o) => o.digitalCardId == selectedCardApp.digitalCardId
                )?.label +
                ')'
              }
              sx={{ color: theme.colors.success.light }}
            />
          )}
          {!selectedCardApp && (
            <CardHeader
              title={t('Choose app to Link ' + nfcCode + ' ')}
              sx={{ color: theme.colors.warning.light }}
            />
          )} */}
          <Box
            sx={{
              mx: 2,
              mb: 3,
              mt: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Tooltip arrow title={t('Cancel')}>
              <IconButtonDotted
                size="small"
                sx={{
                  ml: 1,
                  p: 1
                }}
                onClick={props.onGoBack}
              >
                &nbsp;Cancel &nbsp;
                <CancelTwoToneIcon />
              </IconButtonDotted>
            </Tooltip>
            <Tooltip arrow title={t('Save selection')}>
              <IconButtonDotted
                size="small"
                sx={{
                  ml: 1,
                  p: 1,
                  color: isSaveInProgress ? '#ccc!important' : ''
                }}
                disabled={isSaveInProgress}
                onClick={onSaveClick}
              >
                &nbsp;Save Selection &nbsp;
                <SaveTwoToneIcon />
              </IconButtonDotted>
            </Tooltip>
          </Box>
        </>
      )}
      <WarnConfirm
        open={isConfirmOpen}
        message="The card is already linked to a profile. Are you sure you change linking and save selection?"
        onConfirm={saveSelection}
        onClose={() => setIsConfirmOpen(false)}
      />
    </RootWrapper>
  );
}

function Loader() {
  return (
    <Card sx={{ m: 2 }}>
      <CardHeader
        avatar={<Skeleton animation="wave" width={40} height={40} />}
        title={
          <Skeleton
            animation="wave"
            height={10}
            width="80%"
            style={{ marginBottom: 6 }}
          />
        }
      />

      <Skeleton sx={{ height: 460 }} animation="wave" variant="rectangular" />

      <CardContent>
        <React.Fragment>
          <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
          <Skeleton animation="wave" height={10} width="80%" />
        </React.Fragment>
      </CardContent>
    </Card>
  );
}

export default MyCards;
