import { Field, FieldProps, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';

import { DesktopDatePicker, DesktopTimePicker } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Zoom,
  lighten,
  styled,
  useTheme
} from '@mui/material';
import { TextField as FmTextFiled } from 'formik-mui';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import { useSelector } from 'react-redux';
import MemberSelector from 'src/components/member-selector';
import { createTask, updateTask } from 'src/services/nestApi';
import { RootState } from 'src/store';
import { parseToApiErrorMessage } from '../../../../utility/parseToApiErrorMessage';
import { Task, TaskPriority, TaskType } from '../../../models/task';
import addDaysToDate from '../../../utils/addDaysToDate';
import ActivitiesContext from '../activities-context';
import AssociationPicker from '../association-picker';

const IconButtonError = styled(IconButton)(
  ({ theme }) => `
     background: ${theme.colors.error.lighter};
     color: ${theme.colors.error.main};

     &:hover {
      background: ${lighten(theme.colors.error.lighter, 0.4)};
     }
`
);

const CardActionsWrapper = styled(Card)(
  ({ theme }) => `
     background: ${theme.colors.alpha.black[5]};
     box-shadow: none;
     margin: 0 ${theme.spacing(3)};
`
);

const EditorWrapper = styled(Box)(
  ({ theme }) => `

    .ql-editor {
      min-height: 100px;
      max-height: 300px;
    }

    .ql-snow .ql-picker {
      color: ${theme.colors.alpha.black[100]};
    }

    .ql-snow .ql-stroke {
      stroke: ${theme.colors.alpha.black[100]};
    }

    .ql-toolbar.ql-snow {
      border-top-left-radius: ${theme.general.borderRadius};
      border-top-right-radius: ${theme.general.borderRadius};
    }

    .ql-toolbar.ql-snow,
    .ql-container.ql-snow {
      border-color: ${theme.colors.alpha.black[30]};
    }

    .ql-container.ql-snow {
      border-bottom-left-radius: ${theme.general.borderRadius};
      border-bottom-right-radius: ${theme.general.borderRadius};
    }

    &:hover {
      .ql-toolbar.ql-snow,
      .ql-container.ql-snow {
        border-color: ${theme.colors.alpha.black[50]};
      }
    }
`
);

const DotLegend = styled('span')(
  ({ theme }) => `
      border-radius: 22px;
      width: ${theme.spacing(1.5)};
      height: ${theme.spacing(1.5)};
      display: inline-block;
      border: ${theme.colors.alpha.white[100]} solid 2px;
      position: relative;

     
  `
);
function TaskEditor(props: {
  task?: Task;
  onSave: (task: Task, isEdit: boolean) => void;
  onCancel: () => void;
}) {
  const context = useContext(ActivitiesContext);
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const { user } = useSelector((state: RootState) => state.app);
  const { enqueueSnackbar } = useSnackbar();
  const [isSaving, setIsSaving] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [error, setError] = useState('');
  const [model, setModel] = useState<Task>({
    title: '',
    associations: [
      {
        id: context.entityId,
        type: context.entityType,
        name: context.entityName
      }
    ],
    dueDate: addDaysToDate(new Date(), 2),
    taskType: TaskType.Todo,
    priority: TaskPriority.Low,
    reminderInMin: -1,
    createdOn: new Date(),
    createdBy: {
      id: String(user.employeeIds[0]),
      name: user.email
    },
    ...props.task
  });

  useEffect(() => {
    if (props.task && props.task.id) {
      setIsEdit(true);
      //  setModel(props.task);
    }
  }, []);

  useEffect(() => {}, []);

  if (!model) return <Box m={5}> Please wait... </Box>;

  return (
    <Box sx={{ maxWidth: 700 }}>
      <Formik
        initialValues={{
          title: model.title || '',
          dueDate: model.dueDate || new Date(),
          reminderInMin: model.reminderInMin,
          taskType: model.taskType,
          priority: model.priority,
          assignedTo: model.assignedTo,
          associations: model.associations || [],
          note: model.note || '',
          noteRich: model.noteRich || ''
        }}
        validationSchema={Yup.object().shape({
          title: Yup.string()
            .max(255)
            .required(t('The title field is required')),
          dueDate: Yup.date().required(),
          reminderInMin: Yup.number(),
          taskType: Yup.string().required(t('Task type is required')),
          priority: Yup.string(),
          assignedTo: Yup.object().shape({
            email: Yup.string(),
            name: Yup.string(),
            id: Yup.string()
          }),
          associations: Yup.array()
            .of(
              Yup.object().shape({
                type: Yup.string(),
                name: Yup.string(),
                id: Yup.string()
              })
            )
            .required('Task must be associcated to entity')
            .min(1, 'fdsf'),
          noteRich: Yup.string(),
          note: Yup.string()
        })}
        onSubmit={async (values, { resetForm, setStatus, setSubmitting }) => {
          try {
            const modelToSave = {
              ...model,
              ...values
            };

            setSubmitting(true);
            setIsSaving(true);
            let resTask: Task = null;
            if (!isEdit) {
              const res = await createTask(modelToSave);
              resTask = res.data.task;
            } else {
              const res = await updateTask(modelToSave);
              resTask = res.data.task;
            }
            enqueueSnackbar(
              t(
                'The task has been successfully ' +
                  (!isEdit ? 'created' : 'updated')
              ),
              {
                variant: 'success',
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center'
                },
                TransitionComponent: Zoom
              }
            );
            props.onSave(resTask, isEdit);
          } catch (err) {
            const msg = parseToApiErrorMessage(
              err,
              'Failed to ' + (isEdit ? 'create' : 'update') + ' task'
            );
            console.error(err);
            enqueueSnackbar(t(msg), {
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center'
              },
              TransitionComponent: Zoom
            });
            setStatus({ success: false });
            setSubmitting(false);
            setIsSaving(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          touched,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <Box p={3}>
              <Typography variant="h4">
                {!isEdit ? t('Create new task') : t('Edit task')}
              </Typography>
            </Box>
            <Divider />
            <Box px={3} py={2}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    name="title"
                    variant="outlined"
                    label={t('Title')}
                    placeholder={t('Enter task here ...')}
                    component={FmTextFiled}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Stack spacing={1} direction="row">
                    <Field name="dueDate">
                      {({ field, form, meta }: FieldProps) => (
                        <DesktopDatePicker
                          label="Due date"
                          inputFormat="MM/dd/yyyy"
                          onChange={(newValue: Date | null) =>
                            form.setFieldValue(field.name, newValue)
                          }
                          value={field.value || null}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      )}
                    </Field>
                    <Field name="dueDate">
                      {({ field, form, meta }: FieldProps) => (
                        <DesktopTimePicker
                          label="Controlled picker"
                          value={field.value || null}
                          onChange={(newValue) =>
                            form.setFieldValue(field.name, newValue)
                          }
                          renderInput={(params) => <TextField {...params} />}
                        />
                      )}
                    </Field>
                    <FormControl
                      variant="filled"
                      sx={{ ml: 1, maxWidth: 150 }}
                      fullWidth
                    >
                      <InputLabel id="reminder-select-filled-label">
                        Reminder
                      </InputLabel>
                      <Field name="reminderInMin">
                        {({ field, form, meta }: FieldProps) => (
                          <Select
                            labelId="reminder-select-filled-label"
                            value={field.value}
                            onChange={(_event) => {
                              form.setFieldValue(
                                field.name,
                                _event.target.value
                              );
                            }}
                          >
                            <MenuItem value={-1}>
                              <em>No reminder</em>
                            </MenuItem>
                            <MenuItem value={0}>{'At due time'}</MenuItem>
                            <MenuItem value={15}>
                              {'15 minutes before'}
                            </MenuItem>
                            <MenuItem value={30}>
                              {'30 minutes before'}
                            </MenuItem>
                            <MenuItem value={60}>{'1 hour before'}</MenuItem>
                            <MenuItem value={1440}>{'1 day before'}</MenuItem>
                            <MenuItem value={10080}>{'1 week before'}</MenuItem>
                          </Select>
                        )}
                      </Field>
                    </FormControl>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack spacing={1} direction="row">
                    <FormControl
                      variant="filled"
                      sx={{ ml: 1, maxWidth: 150 }}
                      fullWidth
                    >
                      <InputLabel id="address-state-select-filled-label">
                        Type
                      </InputLabel>
                      <Field name="taskType">
                        {({ field, form, meta }: FieldProps) => (
                          <Select
                            labelId="taskType-select"
                            id="taskType-select"
                            value={field.value || TaskType.Todo}
                            onChange={(_event) => {
                              form.setFieldValue(
                                field.name,
                                _event.target.value
                              );
                            }}
                          >
                            {Object.keys(TaskType).map((k) => (
                              <MenuItem key={k} value={k}>
                                {TaskType[k]}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      </Field>
                    </FormControl>
                    <FormControl
                      variant="filled"
                      sx={{ ml: 1, maxWidth: 150 }}
                      fullWidth
                    >
                      <InputLabel id="address-state-select-filled-label">
                        Priority
                      </InputLabel>
                      <Field name="priority">
                        {({ field, form, meta }: FieldProps) => (
                          <Select
                            labelId="priority-select"
                            id="priority-select"
                            value={field.value}
                            onChange={(_event) => {
                              form.setFieldValue(
                                field.name,
                                _event.target.value
                              );
                            }}
                          >
                            <MenuItem value={'Low'}>
                              <DotLegend
                                style={{
                                  background: `${theme.colors.success.main}`,
                                  marginRight: 10
                                }}
                              />
                              {'Low'}
                            </MenuItem>
                            <MenuItem value={'Medium'}>
                              <DotLegend
                                style={{
                                  background: `${theme.colors.warning.main}`,
                                  marginRight: 10
                                }}
                              />
                              {'Medium'}
                            </MenuItem>
                            <MenuItem value={'High'}>
                              <DotLegend
                                style={{
                                  background: `${theme.colors.error.main}`,
                                  marginRight: 10
                                }}
                              />
                              {'High'}
                            </MenuItem>
                          </Select>
                        )}
                      </Field>
                    </FormControl>

                    <MemberSelector
                      placeholder="Assign to"
                      items={context.memberItems}
                      onChange={(emp) => {
                        if (emp) {
                          setFieldValue('assignedTo', {
                            id: emp.id,
                            name: emp.fullName,
                            email: emp.email
                          });
                        }
                      }}
                      selectedId={Number(values.assignedTo?.id) || 0}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Field name="associations">
                    {({ field, form, meta }: FieldProps) => (
                      <AssociationPicker
                        associations={model.associations}
                        onChange={(items) => {
                          form.setFieldValue(field.name, items);
                        }}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Field name="noteRich">
                    {({ field, form, meta }: FieldProps) => (
                      <EditorWrapper>
                        <ReactQuill
                          placeholder="About contact"
                          value={field.value}
                          onChange={(content, delta, source, editor) => {
                            const text = editor.getText();
                            const textRich = editor.getHTML();
                            form.setFieldValue(field.name, textRich);
                            form.setFieldValue('note', text);
                          }}
                        />
                      </EditorWrapper>
                    )}
                  </Field>
                </Grid>
              </Grid>
            </Box>
            <CardActionsWrapper
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                p: 2
              }}
            >
              <Box>
                <Button
                  variant="outlined"
                  sx={{
                    mr: 1
                  }}
                  color="secondary"
                  onClick={props.onCancel}
                >
                  {t('Cancel')}
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  startIcon={
                    isSubmitting ? <CircularProgress size="1rem" /> : null
                  }
                  disabled={isSubmitting || isSaving}
                  color="primary"
                >
                  {!isEdit ? t('Add task') : t('Save task')}
                </Button>
              </Box>
            </CardActionsWrapper>
          </form>
        )}
      </Formik>
    </Box>
  );
}

export default TaskEditor;
