import { useState, ChangeEvent } from 'react';

import {
  TextField,
  Grid,
  CardHeader,
  Tab,
  Box,
  Tabs,
  Divider,
  FormControl,
  InputAdornment,
  InputLabel,
  Select,
  Card,
  styled,
  MenuItem
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import { TextField as FmTextFiled } from 'formik-mui';
import { Field, FieldProps } from 'formik';
import usStates from 'src/utility/usStates';
import { CountryCodeSlector } from 'src/components/country-code-selector';

const TabsContainerWrapper = styled(Box)(
  ({ theme }) => `
    background-color: ${theme.colors.alpha.black[5]};
    padding: ${theme.spacing(2)};
  `
);

function ContactInfo(props: { isEdit: boolean }) {
  const { t }: { t: any } = useTranslation();

  const [currentTab, setCurrentTab] = useState<string>('contact');

  const tabs = [
    { value: 'contact', label: t('Contact detail') },
    { value: 'address', label: t('Address') },
    { value: 'social', label: t('Social') }
  ];

  const handleTabsChange = (_event: ChangeEvent<{}>, value: string): void => {
    setCurrentTab(value);
  };

  function UsFlagIcon(props) {
    return <img width="20px" src="/static/images/us.svg" />;
  }

  return (
    <Card>
      <CardHeader title={t("Member's Contact Information")} />
      <Divider />
      <TabsContainerWrapper>
        <Tabs
          onChange={handleTabsChange}
          value={currentTab}
          variant="scrollable"
          scrollButtons="auto"
          textColor="primary"
          indicatorColor="primary"
        >
          {tabs.map((tab) => (
            <Tab key={tab.value} label={tab.label} value={tab.value} />
          ))}
        </Tabs>
      </TabsContainerWrapper>
      <Divider />
      <Box p={3}>
        {currentTab === 'contact' && (
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Field
                fullWidth
                name="email"
                disabled={props.isEdit}
                variant="outlined"
                label={t('Email')}
                placeholder={t('Enter email here ...')}
                component={FmTextFiled}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                fullWidth
                name="altEmail"
                variant="outlined"
                label={t('Alternate email')}
                placeholder={t('Enter email here ...')}
                component={FmTextFiled}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Field fullWidth name="countryDialCodeWork">
                {(props) => <CountryCodeSlector {...props} />}
              </Field>
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                fullWidth
                name="contactNumberWork"
                variant="outlined"
                label={t('Phone number (Work)')}
                placeholder={t('Enter phone here ...')}
                component={FmTextFiled}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Field fullWidth name="countryDialCodePersonal">
                {(props) => <CountryCodeSlector {...props} />}
              </Field>
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                fullWidth
                name="contactNumberPersonal"
                variant="outlined"
                label={t('Mobile number (Personal)')}
                placeholder={t('Enter number here ...')}
                component={FmTextFiled}
              />
            </Grid>
          </Grid>
        )}
        {currentTab === 'address' && (
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Field
                component={FmTextFiled}
                fullWidth
                name="address.addressLine"
                variant="outlined"
                label={t('Address line')}
                placeholder={t('Enter address here ...')}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Field
                component={FmTextFiled}
                fullWidth
                name="address.addressLine2"
                variant="outlined"
                label={t('Address line 2')}
                placeholder={t('Enter address2 here ...')}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Field name="address.countryCode">
                {({ field, form, meta }: FieldProps) => (
                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="country">{t('Country')}</InputLabel>
                    <Select
                      label={t('country')}
                      inputProps={{
                        name: 'country'
                      }}
                      value={field.value}
                      onChange={(_event) => {
                        form.setFieldValue(field.name, _event.target.value);
                      }}
                    >
                      <MenuItem value="">
                        <em>Select...</em>
                      </MenuItem>
                      {[{ code: 'usa', value: 'United States' }].map(
                        ({ value, code }) => (
                          <MenuItem key={code} value={code}>
                            {t(value)}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                )}
              </Field>
            </Grid>
            <Grid item xs={12} md={6}>
              <Field name="address.stateCode">
                {({ field, form, meta }: FieldProps) => (
                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="state">{t('State')}</InputLabel>
                    <Select
                      label={t('state')}
                      inputProps={{
                        name: 'state'
                      }}
                      value={field.value}
                      onChange={(_event) => {
                        form.setFieldValue(field.name, _event.target.value);
                      }}
                    >
                      <MenuItem value="">
                        <em>Select...</em>
                      </MenuItem>
                      {usStates.map(({ value, code }) => (
                        <MenuItem key={code} value={code}>
                          {t(value)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Field>
            </Grid>

            <Grid item xs={6} md={6}>
              <Field
                component={FmTextFiled}
                fullWidth
                name="address.zipCode"
                variant="outlined"
                label={t('Zip code')}
                placeholder={t('Enter zip here ...')}
              />
            </Grid>
            <Grid item xs={6} md={6}></Grid>
          </Grid>
        )}
        {currentTab === 'social' && (
          <Grid container spacing={3}>
            <Grid item xs={6} md={6}>
              <Field
                component={FmTextFiled}
                fullWidth
                name="linkedInUrl"
                variant="outlined"
                label={t('Linked In')}
                placeholder={t('Enter here ...')}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LinkedInIcon />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <Field
                component={FmTextFiled}
                fullWidth
                name="faceBookUrl"
                variant="outlined"
                label={t('Facebook')}
                placeholder={t('Enter here ...')}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FacebookIcon />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <Field
                component={FmTextFiled}
                fullWidth
                name="whatsAppUrl"
                variant="outlined"
                label={t('WhatsApp Number')}
                placeholder={t('Enter here ...')}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <WhatsAppIcon />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <Field
                component={FmTextFiled}
                fullWidth
                name="twitterUrl"
                variant="outlined"
                label={t('Twiter')}
                placeholder={t('Enter here ...')}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <TwitterIcon />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
          </Grid>
        )}
      </Box>
    </Card>
  );
}

export default ContactInfo;
