import axios from 'axios';
import { getUser, logout } from '../services/apiService';
import { AccountTypes, UserContext } from '../services/apiService/response-models';

const STORAGE_KEY = 'init-login-flow-state';

class AuthService {
  public user: UserContext | null = null;

  public getUser() {
    return this.user;
  }

  public setLogInSuccess(token: string) {
    localStorage.setItem(STORAGE_KEY, token);
  }

  public getToken(): string | null {
    return localStorage.getItem(STORAGE_KEY) || null;
  }

  public redirectToHome() {
    let url = window.location.href.toLowerCase();
    const i = url.indexOf('/app');
    localStorage.setItem('referer-path', 'login');
    if (i) {
      url = window.location.href.substr(0, i);
    }
    window.location.replace(url);
  }

  public goBack() {
    const returl = localStorage.getItem('kieazy-login-returl');
    localStorage.removeItem('kieazy-login-requrl');
    localStorage.removeItem('kieazy-login-returl');
    window.location.href = returl;
  }

  public async login(): Promise<UserContext> {
    return new Promise<UserContext>(async (resolve, reject) => {
      const token = localStorage.getItem(STORAGE_KEY);

      if (token) {
        try {
          axios.defaults.headers['Authorization'] = 'Bearer ' + token;
          const res = await getUser();
          if (res && res.data) {
            this.user = res.data;
            resolve(res.data);
          } else {
            reject('error');
          }
        } catch (ex) {
          console.log(ex);
          reject(ex);
        }
      } else {
        reject('token not found.');
      }
    });
  }

  public logout(): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      try {
        const token = localStorage.getItem(STORAGE_KEY);
        if (axios.defaults.headers) {
          axios.defaults.headers['Authorization'] = '';
        }
        const res = await logout(token);
        localStorage.setItem(STORAGE_KEY, '');
        localStorage.setItem('get_analytics_data', '');
        localStorage.removeItem(STORAGE_KEY);
        localStorage.removeItem('get_analytics_data');
        localStorage.removeItem('get_analytics_data');
        resolve(res.data);
      } catch (ex) {
        console.log(ex);
        resolve(false);
      }
    });
  }

  readonly businessAdminEntitlements = [''];

  readonly individualAdminAccEntitlements = [''];

  readonly businessMemberEntitlements = [''];

  public entitled(entitlement: string): boolean {
    let arr = [];

    if (this.user.accountType == AccountTypes.Business) {
      arr = this.user.isAdmin ? this.businessAdminEntitlements : this.businessMemberEntitlements;
    } else if (this.user.accountType == AccountTypes.Individual) {
      arr = this.individualAdminAccEntitlements;
    }

    return arr.includes(entitlement);
  }
}

const oAuth = new AuthService();
export default oAuth;
