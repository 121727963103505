import { Avatar, Box, Button, Dialog, Slide, styled, Typography } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { forwardRef, ReactElement, Ref, useEffect, useState } from "react";
import CloseIcon from '@mui/icons-material/Close';

const DialogWrapper = styled(Dialog)(
    () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const Transition = forwardRef(function Transition(
    props: TransitionProps & { children: ReactElement<any, any> },
    ref: Ref<unknown>
) {
    return <Slide direction="down" ref={ref} {...props} />;
});


const AvatarError = styled(Avatar)(
    ({ theme }) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

const ButtonError = styled(Button)(
    ({ theme }) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.error.dark};
     }
    `
);


function DeleteConfirm(props: { message?: string, id?: string, open: boolean,onClose:()=>void, onDeleteConfirm: (id?: string) => void }) {
    const [open, setOpen] = useState(props.open);

    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    const closeConfirmDelete = () => {
        setOpen(false);
        props.onClose();
    };

    const handleDeleteCompleted = () => {
        props.onDeleteConfirm(props.id);
        setOpen(false);
    };

    return (<DialogWrapper
        open={open} 
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        onClose={closeConfirmDelete}
    >
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            p={5}
        >
            <AvatarError>
                <CloseIcon />
            </AvatarError>

            <Typography
                align="center"
                sx={{
                    py: 4,
                    px: 6
                }}
                variant="h3"
            >
                {'Are you sure you want to permanently delete ' + (props.message || 'record')}
                ?
            </Typography>

            <Box>
                <Button
                    variant="text"
                    size="large"
                    sx={{
                        mx: 1
                    }}
                    onClick={closeConfirmDelete}
                >
                    {'Cancel'}
                </Button>
                <ButtonError
                    onClick={handleDeleteCompleted}
                    size="large"
                    sx={{
                        mx: 1,
                        px: 3
                    }}
                    variant="contained"
                >
                    {'Delete'}
                </ButtonError>
            </Box>
        </Box>
    </DialogWrapper>)
}

export default DeleteConfirm;