import { useState, useEffect, useCallback } from 'react';
import PageHeader from './PageHeader';
import Footer from 'src/ui-bloom/components/Footer';

import { Grid, LinearProgress } from '@mui/material';
import useRefMounted from 'src/ui-bloom/hooks/useRefMounted';
import PageTitleWrapper from 'src/ui-bloom/components/PageTitleWrapper';

import Results from './Results';
import { getUsers } from '../../../services/apiService';
import { User } from '../../../services/apiService/response-models';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { EmployeeListItem } from '../../../services/apiService/response-models/data-views';
import { getEmployeeListItems } from '../../../services/apiService/data-view-service';
import CreateUserDialog from './create-user-dialog';

function UserList() {
  const { t }: { t: any } = useTranslation();
  const isMountedRef = useRefMounted();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [users, setUsers] = useState<User[]>([]);
  const [members, setMembers] = useState<EmployeeListItem[]>([]);
  const [open, setOpen] = useState(false);
  const [isDialogMounted, setIsDialogMounted] = useState(false);

  const getData = useCallback(
    async (callback: (hasError: boolean, data: User[]) => void) => {
      if (isMountedRef.current) {
        try {
          const res = await getUsers();
          callback(false, res.data);
        } catch (ex) {
          const msg = ex.message ? ex.message : `Failed to load user data`;
          enqueueSnackbar(t(msg), { variant: 'error' });
          setError(msg);
          callback(true, []);
        }
      }
    },
    [isMountedRef]
  );

  const getRefData = useCallback(
    async (callback: (hasError: boolean, data: EmployeeListItem[]) => void) => {
      if (isMountedRef.current) {
        try {
          const res = await getEmployeeListItems();
          callback(false, res.data);
        } catch (ex) {
          const msg = ex.message ? ex.message : `Failed to load ref-data`;
          enqueueSnackbar(t(msg), { variant: 'error' });
          setError(msg);
          callback(true, []);
        }
      }
    },
    [isMountedRef]
  );

  useEffect(() => {
    setIsLoading(true);
    getRefData((hasError, members) => {
      setMembers(members);
        getData((hasError, users) => {
        setUsers(users);
        setIsLoading(false);
      });
    });
  }, [getData]);

  const handleCreateUserSuccess = async () => {
    setIsLoading(true);
    try {
      const res = await getUsers();
      setUsers(res.data);
    } catch (ex) {
      console.log(ex);
    }
    setIsLoading(false);
  };

  const handleCreateUserOpen = () => {
    setIsDialogMounted(true);
    setOpen(true);
  };

  const handleCreateUserClose = () => {
    setOpen(false);
    setTimeout(() => setIsDialogMounted(false));
  };

  return (
    <>
      <PageTitleWrapper>
        <PageHeader onCreate={handleCreateUserOpen} />
      </PageTitleWrapper>

      <Grid
        sx={{
          px: 4
        }}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
      >
        <Grid item xs={12}>
          <Results isLoading={isLoading} users={users} />
        </Grid>
      </Grid>
      {isDialogMounted && (
        <CreateUserDialog
          members={members}
          isOpen={open}
          onClose={handleCreateUserClose}
          onSave={handleCreateUserSuccess}
        />
      )}
      <Footer />
    </>
  );
}

export default UserList;
