import { Typography, styled, Box, Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone';
import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone';

const PageTitle = styled(Box)(
  ({ theme }) => `
        padding: ${theme.spacing(4)};
        background: rgba(255, 255, 255, 0.5);
            box-shadow: 0px 2px 4px -3px rgb(34 51 84 / 10%), 0px 5px 12px -4px rgb(34 51 84 / 5%);
`
);

function PageHeader(props: {
  nfcCode: string;
  retUrl?: string;
  onGoBack: () => void;
}) {
  const { t }: { t: any } = useTranslation();

  return (
    <PageTitle>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h3" component="h3" gutterBottom>
            {t('NFC Card Linking')}
          </Typography>
          <Typography variant="subtitle2">
            {t('Link NFC card to a company profile or member profile')}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            onClick={props.onGoBack}
            sx={{
              mt: { xs: 2, sm: 0 }
            }}
            startIcon={
              props.retUrl ? <ArrowBackTwoToneIcon /> : <DashboardTwoToneIcon />
            }
            variant="contained"
          >
            {t(props.retUrl ? 'Go back' : 'Go to dashboard')}
          </Button>
        </Grid>
      </Grid>
    </PageTitle>
  );
}

export default PageHeader;
