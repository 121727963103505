import CircleNotificationsTwoToneIcon from '@mui/icons-material/CircleNotificationsTwoTone';
import CurrencyExchangeTwoToneIcon from '@mui/icons-material/CurrencyExchangeTwoTone';
import ListAltTwoToneIcon from '@mui/icons-material/ListAltTwoTone';
import SettingsIcon from '@mui/icons-material/Settings';
import React from 'react';
import { UserContext } from 'src/services/apiService/response-models';

export interface SettingTab {
  readonly name: string;
  readonly text: string;
  readonly role?: string;
  readonly icon: React.ReactNode;
}

const SettingTabs: SettingTab[] = [
  {
    name: 'general',
    text: 'General',
    icon: <SettingsIcon />
  },
  // {
  //     name: 'nfcMap',
  //     text: 'NFC Code Mapping',
  //     icon: <CreditCardIcon />
  // },
  {
    name: 'notification',
    text: 'Notifications',
    role: 'admin',
    icon: <CircleNotificationsTwoToneIcon />
  },
  {
    name: 'leadSetting',
    text: 'Lead Setting',
    role: 'admin',
    icon: <ListAltTwoToneIcon />
  },
  {
    name: 'mysubscription',
    text: 'Subscription',
    icon: <CurrencyExchangeTwoToneIcon />
  }
];

const getSettingTabs = (user: UserContext) => {
  const userRoles = user.userRoles || [];

  return SettingTabs.filter((o) => !o.role || userRoles.includes(o.role));
};
export default getSettingTabs;
