import CancelPresentationTwoToneIcon from '@mui/icons-material/CancelPresentationTwoTone';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  ClickAwayListener,
  Divider,
  Drawer,
  Fade,
  Grid,
  IconButton,
  Paper,
  Popper,
  Slide,
  styled,
  Tooltip,
  useMediaQuery,
  useTheme
} from '@mui/material';
import _, { Dictionary } from 'lodash';
import { useEffect, useState } from 'react';
import FeatureImage from '../../../../components/FeatureImage';
import FieldRenderEditorBaseProp from '../../../../components/field-renderers/_base/FieldRenderEditorBaseProp';
import FieldsRegistry from '../../../../components/field-renderers/_base/FieldsRegistry';
import { CardFieldNames } from '../../../../services/apiService/response-models';
import Label from '../../../../ui-bloom/components/Label';
import PreField from '../../models/PreField';
import FormRenderer from './form-renderer';

const ArrowSpan = styled('span')(
  ({ theme }) => `
 position: 'absolute';
        fontSize: 7;
        width: '3em';
        height: '3em';

         &:before {
           content: '""';
            margin: 'auto';
            display: 'block';
            width: 0;
            height: 0;
            borderStyle: 'solid';
        }
`
);

function FieldPopper(props: {
  anchorEl: HTMLElement;
  open: boolean;
  field: PreField;
  onCancel: () => void;
  onOk: (field: PreField, formData: Dictionary<string>) => void;
  onChange: (field: PreField, formData: Dictionary<string>) => void;
  onDelete: (field: PreField) => void;
}) {
  const theme = useTheme();
  const [open, setOpen] = useState(props.open);
  const [field, setField] = useState(props.field);
  const [anchorEl, setAnchorEl] = useState(props.anchorEl);
  const [isDeleteRequested, setIsDeleteRequested] = useState(false);
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const [isSubmit, setIsSubmit] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    setField(props.field);
    setAnchorEl(props.anchorEl);
    setOpen(props.open);
  }, [props.open]);

  const onFormInit = (isValid: boolean) => {
    setIsFormValid(isValid);
  };

  const onFormDataChange = (
    e: PreField,
    formData: Dictionary<string>,
    isValid: boolean
  ) => {
    setIsFormValid(isValid);
    setFormData(formData);
  };

  const onImageChange = (e: string) => {
    if (!e) {
      //removed
      handleDelete();
    } else {
      const data = {};
      data[props.field.fieldName] = e;
      setOpen(false);
      props.onOk(field, data);
    }
  };

  const onOk = (valid?: boolean) => {
    setIsSubmit(true);
    //if (_.isEmpty(formData)) {
    //    onCancel();
    //}
    //else
    if (isFormValid) {
      if (_.isEmpty(formData)) {
        onCancel();
      } else {
        setOpen(false);
        props.onOk(field, formData);
      }
    }
  };

  const handleDelete = () => {
    props.onDelete(field);
  };

  const onCancel = () => {
    setOpen(false);
    props.onCancel();
  };

  const handleEnterButton = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      onOk();
    }
  };

  const isPicField =
    props.field.fieldName == CardFieldNames.ProfilePic ||
    props.field.fieldName == CardFieldNames.CoverPhoto;

  const calcWidth = () => {
    if (mobile) return '80%';

    return isPicField ? '220px' : '500px';
  };

  const isNewField =
    props.field.fieldName == CardFieldNames.MediaPlayer ||
    props.field.fieldName == CardFieldNames.ImageSlider ||
    props.field.fieldName == CardFieldNames.GoogleReview;

  const renderNewField = () => {
    const newFieldProp: FieldRenderEditorBaseProp = {
      field: props.field,
      onSave: (e, fdata) => {
        setIsSubmit(true);
        if (_.isEmpty(fdata)) {
          onCancel();
        } else {
          setOpen(false);
          props.onOk(e, fdata);
        }
      },
      onDelete: handleDelete,
      onCancel: onCancel
    };
    return FieldsRegistry[props.field.fieldName].renderEditor(newFieldProp);
  };

  const renderContentCard = (isDrawer: boolean) => {
    return (
      <Card sx={{ padding: 1, pt: 2 }}>
        {isPicField && (
          <>
            <CardContent sx={{ p: 0 }}>
              <CardHeader title={field.fieldRef?.displayName} />
              <FeatureImage
                value={
                  props.field.fieldValues?.length
                    ? props.field.fieldValues[0].value
                    : ''
                }
                onChange={onImageChange}
              />
            </CardContent>
          </>
        )}
        {isNewField && renderNewField()}
        {!isPicField && !isNewField && (
          <>
            <FormRenderer
              field={field}
              isSubmit={false}
              onChange={onFormDataChange}
              afterInit={onFormInit}
            />
            <Divider sx={{ mt: 2, mb: 2 }} />
            <Slide
              direction="right"
              in={isDeleteRequested}
              mountOnEnter
              unmountOnExit
            >
              <CardActions>
                <Label>Are you sure?</Label>
                <Button
                  sx={{ ml: 1 }}
                  size="small"
                  onClick={handleDelete}
                  variant="outlined"
                  color="error"
                >
                  Delete
                </Button>
                <Tooltip title="Cancel delete">
                  <IconButton
                    onClick={() => {
                      setIsDeleteRequested(false);
                    }}
                    sx={{ ml: 1 }}
                    aria-label="delete"
                  >
                    <CancelPresentationTwoToneIcon />
                  </IconButton>
                </Tooltip>
              </CardActions>
            </Slide>
            {!isDeleteRequested && (
              <CardActions>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <IconButton
                      color="error"
                      aria-label="delete"
                      onClick={() => {
                        setIsDeleteRequested(true);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <Button size="small" onClick={onCancel} variant="outlined">
                      Cancel
                    </Button>
                    <Button
                      disabled={!isFormValid}
                      sx={{ ml: 2 }}
                      size="small"
                      onClick={() => {
                        onOk();
                      }}
                      variant="contained"
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </CardActions>
            )}
          </>
        )}
      </Card>
    );
  };

  if (mobile) {
    return (
      <Drawer anchor={'bottom'} open={open} onClose={onCancel}>
        <Box sx={{ width: '100%' }} role="presentation">
          {renderContentCard(true)}
        </Box>
      </Drawer>
    );
  }
  return (
    <Popper
      id="field-popper"
      sx={{ zIndex: 1000, width: calcWidth() }}
      open={open}
      anchorEl={anchorEl}
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [isPicField ? -30 : 0, 50]
          }
        }
      ]}
      placement={!mobile ? 'right-start' : 'top-start'}
      transition
      onKeyDown={handleEnterButton}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper elevation={3}>
            <ClickAwayListener onClickAway={onCancel}>
              {renderContentCard(false)}
            </ClickAwayListener>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
}

export default FieldPopper;
