import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ReactPlayer from 'react-player/youtube';
import { CardHeader, Grid, Paper, TextField } from '@mui/material';

import FieldRenderEditorBaseProp from '../_base/FieldRenderEditorBaseProp';
import { useMemo } from 'react';
import readFieldValue from '../_base/readFieldValue';
import FooterActions from '../_base/FooterActions';

export interface MediaPlayerType {
  title?: string;
  subTitle?: string;
  url: string;
}

export default function MediaPlayerEditor(props: FieldRenderEditorBaseProp) {
  const theme = useTheme();

  const fieldValue = useMemo(() => {
    const defaultVal: MediaPlayerType = {
      url: ''
    };
    return readFieldValue(props.field, defaultVal);
  }, [props.field]);

  const [title, setTitle] = React.useState<string>(fieldValue.title);
  const [subTitle, setSubTitle] = React.useState<string>(fieldValue.subTitle);
  const [url, setUrl] = React.useState<string>(fieldValue.url);

  const onSave = () => {
    props.onSave(
      props.field,
      {
        config: JSON.stringify({
          title,
          subTitle,
          url
        })
      },
      true
    );
  };
  return (
    <Card>
      <CardHeader title={props.field.fieldRef.displayName} />
      <Box mb={1} display="flex" flexDirection={'column'}>
        <form noValidate style={{ width: '100%' }}>
          <Grid sx={{ padding: 0 }} container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              {!url && (
                <Paper
                  elevation={3}
                  sx={{
                    height: '200px',
                    backgroundColor: '#d1d1d1',
                    margin: 'auto',
                    textAlign: 'center'
                  }}
                >
                  <img
                    style={{ margin: '10%' }}
                    src="/static/images/paper.png"
                  />
                </Paper>
              )}
              {url && (
                <ReactPlayer
                  height={'200px'}
                  width={'100%'}
                  url={url}
                  controls
                />
              )}
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                autoFocus={true}
                id={'url'}
                required={true}
                name={'url'}
                label={'Url'}
                fullWidth
                value={url}
                // error={!!validate(field, values[field.name])}
                // helperText={validate(field, values[field.name])}
                onChange={(e) => {
                  fieldValue.url = e.target.value;
                  setUrl(e.target.value);
                  //   props.onChange(
                  //     props.field,
                  //     { title: fieldValue.title },
                  //     true
                  //   );
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                autoFocus={false}
                id={'title'}
                required={false}
                name={'title'}
                label={'Title'}
                fullWidth
                value={title}
                // error={!!validate(field, values[field.name])}
                // helperText={validate(field, values[field.name])}
                onChange={(e) => {
                  fieldValue.title = e.target.value;
                  setTitle(e.target.value);
                  //   props.onChange(
                  //     props.field,
                  //     { title: fieldValue.title },
                  //     true
                  //   );
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                autoFocus={false}
                id={'subTitle'}
                required={false}
                name={'subTitle'}
                label={'Subtitle'}
                fullWidth
                value={subTitle}
                // error={!!validate(field, values[field.name])}
                // helperText={validate(field, values[field.name])}
                onChange={(e) => {
                  fieldValue.subTitle = e.target.value;
                  setSubTitle(e.target.value);
                  //   props.onChange(
                  //     props.field,
                  //     { title: fieldValue.title },
                  //     true
                  //   );
                }}
              />
            </Grid>
          </Grid>
        </form>
      </Box>
      <FooterActions
        isSaveDisabled={!url}
        onDelete={() => props.onDelete(props.field)}
        onSave={onSave}
        onCancel={props.onCancel}
      />
    </Card>
  );
}
