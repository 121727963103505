import React, { useEffect } from 'react';
import { FunctionComponent } from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material';
import { CodeItem } from '../../services/apiService/response-models';
import { useField } from 'formik';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

export interface DropdownMultiProps {
  name: string;
  label: string;
  options: CodeItem[];
  boxAttr?: any;
  disabled?: boolean;
  width?: string;
}

const DropdownMultiSelect: FunctionComponent<DropdownMultiProps> = (props) => {
  const [field, meta, helpers] = useField(props.name);
  const [values, setValues] = React.useState<number[]>(field.value || []);
  const [isAllChecked, setAllChecked] = React.useState(false);

    useEffect(() => {
        setValues(field.value || []);
    }, [field.value]);

  const checkIfAllSelected = (
    selectedCodes: number[],
    items: CodeItem[]
  ): boolean => {
    if (!items || !items.length || !selectedCodes) return false;

    let isAll = true;
    items.forEach((o) => {
      if (isAll && selectedCodes.indexOf(o.codeId) === -1) isAll = false;
    });
    return isAll;
  };
  const handleChange = (event: SelectChangeEvent<number[]>): void => {
    const {
      target: { value }
    } = event;
    const values = typeof value === 'string' ? [] : value;

    let codes = values.filter((o) => o != -1); //filter out 'all'

    if (values.length && values[values.length - 1] == -1) {
      if (isAllChecked) codes = [];
      else {
        codes = props.options.map((o) => o.codeId);
      }
    }

    setValues(codes);
    helpers.setValue(
      props.options.filter((o) => codes.includes(o.codeId)).map((o) => o.codeId)
    );
    setAllChecked(checkIfAllSelected(codes, props.options));
  };

  const isInvalid = meta.touched && meta.error ? true : false;

  return (
    <Box
      sx={{
        display: 'flex'
      }}
      {...props.boxAttr}
    >
      <FormControl
        fullWidth={props.width ? false : true}
        sx={{ width: props.width || null }}
        error={isInvalid}
      >
        <InputLabel
          id={'selector-label' + props.name}
        >{`${props.label}`}</InputLabel>
        <Select
          multiple
          labelId={'selector-label' + props.label}
          id={'selector-' + props.label}
          {...field}
          input={<OutlinedInput label={props.label} />}
          value={values || []}
          renderValue={(selected) =>
            props.options
              .filter((o) => selected.includes(o.codeId))
              .map((o) => o.value)
              .join(', ')
          }
          onChange={handleChange}
          MenuProps={MenuProps}
        >
          {(props.options || []).length > 1 && (
            <MenuItem key={-1} value={-1}>
              <Checkbox checked={isAllChecked} />
              <ListItemText primary={'All'} />
            </MenuItem>
          )}
          {(props.options || []).map((item) => (
            <MenuItem key={item.codeId} value={item.codeId}>
              <Checkbox checked={values.indexOf(item.codeId) > -1} />
              <ListItemText primary={item.value} />
            </MenuItem>
          ))}
        </Select>
        {isInvalid && <FormHelperText>{meta.error}</FormHelperText>}
      </FormControl>
    </Box>
  );
};

export default DropdownMultiSelect;
