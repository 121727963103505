import React, { Suspense } from 'react';
import { RouteObject } from 'react-router';
import { Navigate } from 'react-router-dom';
import BaseLayout from 'src/ui-bloom/layouts/BaseLayout';
import NotFound from '../contents/not-found';
import baseRoutes from './base';

import CardEditor from '../contents/business-cards/card-editor';
import CardsList from '../contents/business-cards/list';
import CreateContact from '../contents/contact/create-contact';
import ContactList from '../contents/contact/list';
import Analytics from '../contents/dashboards/Analytics';
import LeadsList from '../contents/lead/list';
import SelfReviewList from '../contents/self-review/list';
import ApplicationSetting from '../contents/settings';
import CreateEmployee from '../contents/team/create-employee';
import TeamList from '../contents/team/list';
import UserList from '../contents/users/list';
import AccountDeletion from '../pages/account-deletion';
import MapNfcCode from '../pages/map-nfc-code';
import BoxedSidebarLayout from '../ui-bloom/layouts/BoxedSidebarLayout';

import UploadContacts from 'src/contents/contact/upload';
import CustomerAnalytics from 'src/contents/customer';
import LeadDetail from 'src/contents/lead/lead-detail';
import MyCardList from 'src/contents/my-cards';
import ReviewDetail from 'src/contents/self-review/review-detail';
import SignUpLogout from 'src/pages/sign-up/signup-logout';
import ContactDetail from '../contents/contact/contact-detail';
const ListingAppListLazy = React.lazy(
  () => import('../BusinessApp/components/list')
);
const ListingAppEditorLazy = React.lazy(
  () => import('../BusinessApp/components/app-editor')
);

const router: RouteObject[] = [
  {
    path: '',
    element: <Navigate to="app" />
  },
  {
    path: 'app',
    element: <BaseLayout />,
    children: baseRoutes
  },
  {
    path: 'app/sign-up',
    element: <SignUpLogout />
  },

  // Extended Sidebar Layout

  {
    path: 'app',
    element: <BoxedSidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="dashboard" />
      },

      {
        path: 'dashboard',
        children: [
          {
            path: '',
            element: <Analytics />
          }
        ]
      },
      {
        path: 'cards/:cardType',
        element: <CardsList />
      },
      {
        path: 'customers',
        element: <CustomerAnalytics />
      },
      {
        path: 'cards/:cardType/edit/:id',
        element: <CardEditor />
      },
      {
        path: 'cards/edit/:id',
        element: <CardEditor />
      },
      {
        path: 'cards/:cardType/new',
        element: <CardEditor />
      },
      {
        path: 'business-apps',
        element: (
          <Suspense fallback={<div>Loading</div>}>
            <ListingAppListLazy />
          </Suspense>
        )
      },
      {
        path: 'business-apps/:appName/edit/:id',
        element: (
          <Suspense fallback={<div>Loading</div>}>
            <ListingAppEditorLazy />
          </Suspense>
        )
      },
      {
        path: 'my-cards',
        element: <MyCardList />
      },
      {
        path: 'contact',
        children: [
          {
            path: 'list',
            element: <ContactList />
          },
          {
            path: 'new',
            element: <CreateContact />
          },
          {
            path: 'detail/:id',
            element: <ContactDetail />
          },
          {
            path: 'edit/:id',
            element: <CreateContact />
          },
          {
            path: 'upload',
            element: <UploadContacts />
          }
        ]
      },
      {
        path: 'team',
        children: [
          {
            path: 'list',
            element: <TeamList />
          },
          {
            path: 'list/:activeTab',
            element: <TeamList />
          },
          {
            path: 'employee/new',
            element: <CreateEmployee />
          },
          {
            path: 'employee/edit/:id',
            element: <CreateEmployee />
          }
        ]
      },
      {
        path: 'lead',
        children: [
          {
            path: 'list',
            element: <LeadsList />
          },
          {
            path: 'detail/:id',
            element: <LeadDetail />
          }
        ]
      },
      {
        path: 'review',
        children: [
          {
            path: 'list',
            element: <SelfReviewList />
          },
          {
            path: 'detail/:id',
            element: <ReviewDetail />
          }
        ]
      },
      {
        path: 'admin',
        children: [
          {
            path: 'user/list',
            element: <UserList />
          }
        ]
      },
      {
        path: 'setting',
        element: <ApplicationSetting />
      },
      {
        path: 'not-found',
        element: <NotFound />
      },
      {
        path: '*',
        element: <Navigate to="not-found" />
      }
    ]
  },
  {
    path: 'app/map-nfc-code',
    element: <MapNfcCode />
  },
  {
    path: 'app/delete-account',
    element: <AccountDeletion />
  }
];

export default router;
