import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import CardContent from '@mui/material/CardContent';
import ReactPlayer from 'react-player/youtube';
import FieldRenderBlockBaseProp from '../_base/FieldRenderBlockBaseProp';
import { useEffect, useMemo, useState } from 'react';
import readFieldValue from '../_base/readFieldValue';
import {
  Card,
  CardHeader,
  Divider,
  IconButton,
  styled,
  Box,
  useTheme,
  alpha,
  CardMedia,
  CardActions,
  Link,
  Typography,
  Button,
  CardActionArea,
  Paper,
  Skeleton,
  CircularProgress
} from '@mui/material';
import { ImageSliderType } from './editor';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import cdnService from '../../../services/cdnService';
import { getCardById } from '../../../services/apiService';
import parseMyJson from '../../../utility/parseMyJson';
import { CardFieldNames } from '../../../services/apiService/response-models';

const CardActionAreaWrapper = styled(CardActionArea)(
  () => `
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  .MuiTouchRipple-root {
      opacity: .3;
  }

  &:hover {
      .MuiCardActionArea-focusHighlight {
          opacity: .05;
      }
  }
`
);

export default function ImageSliderBlock(props: FieldRenderBlockBaseProp) {
  const theme = useTheme();
  const [fieldValue, setFieldValue] = useState<ImageSliderType>();
  const [error, setError] = useState('');

  useEffect(() => {
    (async () => {
      const defaultVal: ImageSliderType = {
        imageList: []
      };

      const val = readFieldValue(props.field, defaultVal);

      if (val.profileRef?.active === true && val.profileRef?.profileId) {
        const cardRes = await getCardById(+val.profileRef.profileId);
        if (cardRes && cardRes.data) {
          const card = cardRes.data;
          const fields = card.fields.filter(
            (o) => o.fieldName == CardFieldNames.ImageSlider
          );

          let value: ImageSliderType = null;

          fields.forEach((field) => {
            const config = field.formFieldValues?.find(
              (o) => o.name == 'config'
            );
            const valRef: ImageSliderType = parseMyJson(config?.value || '');
            if (valRef && valRef.title == val.profileRef?.sliderTitle) {
              value = valRef;
            }
          });
          //overwrite
          val.imageList = value?.imageList || [];
        }
      }
      setFieldValue(val);
    })();
  }, [props.field]);

  if (!fieldValue) {
    return (
      <Card
        sx={{
          overflow: 'hidden',
          textAlign: 'left',
          border: 'solid 1px #ccc',
          width: '100%',
          backgroundColor: 'inherit'
        }}
      >
        <Box
          sx={{
            height: 200,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <CircularProgress />
        </Box>
      </Card>
    );
  }
  return (
    <Card
      sx={{
        overflow: 'hidden',
        textAlign: 'left',
        border: 'solid 1px #ccc',
        width: '100%',
        backgroundColor: 'inherit'
      }}
    >
      {(fieldValue.title || fieldValue.subTitle) && (
        <CardHeader
          sx={{
            color:
              props.themeColor === 'light'
                ? `${theme.colors.alpha.black[100]}`
                : `${theme.colors.alpha.trueWhite[100]}`,
            '.MuiCardHeader-subheader': {
              color:
                props.themeColor === 'light'
                  ? `${theme.colors.alpha.black[100]}`
                  : `${theme.colors.alpha.trueWhite[100]}`
            }
          }}
          title={fieldValue.title}
          subheader={fieldValue.subTitle}
        />
      )}
      <Box
        px={1}
        pb={1}
        sx={{
          '.swiper-pagination-bullets': {
            bottom: '0 !important'
          }
        }}
      >
        {!fieldValue.imageList.length ? (
          <Paper
            elevation={0}
            sx={{
              height: '100%',
              backgroundColor: '#f5dede',
              margin: 1,
              textAlign: 'center'
            }}
          >
            <ViewCarouselIcon
              fontSize="large"
              color="secondary"
              style={{ margin: '20%' }}
            />
          </Paper>
        ) : (
          <Swiper
            spaceBetween={12}
            slidesPerView={1}
            loop
            navigation={{}}
            modules={[Navigation, Pagination, Autoplay]}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false
            }}
            pagination={{ dynamicBullets: true, clickable: true }}
          >
            {fieldValue.imageList.map((o) => (
              <SwiperSlide key={o.url}>
                <Card
                  sx={{
                    mt: 2,
                    textAlign: 'center',
                    transition: `${theme.transitions.create([
                      'box-shadow',
                      'transform'
                    ])}`,
                    transform: 'translateY(0px)',

                    '&:hover': {
                      transform: 'translateY(-10px)',
                      boxShadow: `0 2rem 8rem 0 ${alpha(
                        theme.colors.alpha.black[100],
                        0.1
                      )}, 
                                0 0.6rem 1.6rem ${alpha(
                                  theme.colors.alpha.black[100],
                                  0.2
                                )}, 
                                0 0.2rem 0.2rem ${alpha(
                                  theme.colors.alpha.black[100],
                                  0.15
                                )}`
                    }
                  }}
                >
                  <CardActionAreaWrapper>
                    <CardMedia
                      component="img"
                      height="230"
                      image={cdnService.toImageUrl(o.url)}
                      alt="..."
                      sx={{ objectFit: 'cover' }}
                      onClick={
                        o.targetUrl
                          ? () => window.open(o.targetUrl, '_blank')
                          : () => {}
                      }
                    />
                  </CardActionAreaWrapper>
                </Card>
                {(o.title || o.subTitle) && (
                  <Box
                    sx={{
                      px: { md: 2, lg: 1.5, xl: 3 },
                      pt: 2,
                      textAlign: 'center'
                    }}
                  >
                    {o.title && (
                      <Link
                        lineHeight={1.5}
                        target="_blank"
                        href={o.targetUrl}
                        sx={{
                          transition: `${theme.transitions.create(['color'])}`,

                          '&:hover': {
                            color: `${theme.colors.primary.main}`
                          }
                        }}
                        color="text.primary"
                        variant="h3"
                        underline="none"
                      >
                        {o.title}
                      </Link>
                    )}
                    {o.subTitle && (
                      <Typography
                        variant="subtitle2"
                        sx={{
                          pb: 2
                        }}
                      >
                        {o.subTitle}
                      </Typography>
                    )}
                  </Box>
                )}
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </Box>
    </Card>
  );
}
