import {
    Autocomplete,
    Card,
    Divider,
    Box,
    Grid,
    Stack,
    styled,
    TextField,
    useTheme
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EmployeeListItem } from '../../services/apiService/response-models/data-views';

function MembersSelector(props: {
    items: EmployeeListItem[];
    onChange: (values: EmployeeListItem[]) => void;
    selectedIds?: number[];
    disabled?: boolean;
}) {
    const { t }: { t: any } = useTranslation();
    const theme = useTheme();
    const [selectedItems, setSelectedItems] = useState<EmployeeListItem[]>([]);

    useEffect(() => {
        if (!props.selectedIds) {
            setSelectedItems([]);
        } else {
            const matches = props.items.filter((o) => props.selectedIds.includes(o.id));
            setSelectedItems(matches);
        }
    }, [props.selectedIds]);

    return (
        <Autocomplete
            id="member-multi-select-comp"
            fullWidth
            multiple={true}
            disabled={props.disabled}
            value={selectedItems}
            isOptionEqualToValue={(option, value) => option.id === value?.id}
            options={props.items.filter((o) => !(selectedItems.map(itm=>itm.id).includes(o.id)))}
            // @ts-ignore
            getOptionLabel={(option: EmployeeListItem) =>
                option.fullName + '(' + option.email + ')'
            }
            onChange={(event: any, newValues: EmployeeListItem[] | null) => {
                if (newValues) {
                    setSelectedItems(newValues);
                }
                props.onChange(newValues);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: ''
                    }}
                    fullWidth
                    variant="outlined"
                    // label={t('Member(s)')}
                    placeholder={t('Select Member...')}
                />
            )}
        />
    );
}

export default MembersSelector;
