import { styled, Typography, Box, useTheme } from '@mui/material';
import PreField from '../../models/PreField';
import { getValueDesc } from '../utils/getValueDesc';

const DepartmentRenderer = ({
  field,
  themeColor
}: {
  field: PreField;
  themeColor: string;
}) => {
  const theme = useTheme();

  return (
    <Typography
      sx={{
        color:
          themeColor === 'light'
            ? `${theme.colors.alpha.black[100]}`
            : `${theme.colors.alpha.trueWhite[100]}`
      }}
      gutterBottom
      variant="subtitle2"
    >
      {getValueDesc(field)}
    </Typography>
  );
};

export default DepartmentRenderer;
