import axios, { AxiosResponse } from 'axios';
import { Dictionary } from 'lodash';
import {
  ReviewAppModelPaginatedResponse,
  ReviewSaveModel
} from 'src/BusinessApp/services/models/review-app-models';
import { ApiUrls, NEST_API_SECRET_KEY } from '../endpoints';
import {
  CreateCardRequest,
  EmployeeAccountSetting,
  LeadRequest,
  LogInRequest,
  PaymentIntentRequest,
  PlanProduct
} from './request-models';
import {
  AccountSignUp,
  AccountSignUpStatus,
  ActiveSubscription,
  CardApp,
  CardAppItem,
  CardFieldCategory,
  CodeMaster,
  Contact,
  ContactUploadItem,
  DigitalCard,
  DigitalCardAppItem,
  DigitalCardField,
  DigitalCardListItem,
  Employee,
  LeadComment,
  LogInResult,
  NfcCodeMap,
  NotificationSetting,
  PaymentIntentResult,
  RefData,
  ReviewComment,
  Team,
  User,
  UserContext
} from './response-models';
import { LeadItem, SelfReview } from './response-models/data-views';

export const getAllCardFieldCategories = (): Promise<
  AxiosResponse<CardFieldCategory[]>
> => {
  const dataUrl =
    ApiUrls.manageCardEndpoint + '/digital-card/field/category/all';
  return axios.get(dataUrl);
};

export const getAllCardFields = (): Promise<
  AxiosResponse<DigitalCardField[]>
> => {
  const dataUrl = ApiUrls.manageCardEndpoint + '/digital-card/field/all';
  return axios.get(dataUrl);
};

export const getCardItems = (): Promise<
  AxiosResponse<DigitalCardListItem[]>
> => {
  const dataUrl = ApiUrls.manageCardEndpoint + '/digital-card/list-view/all';
  return axios.get(dataUrl);
};

export const getCardAppItems = (includeFieldValues?: boolean): Promise<
  AxiosResponse<DigitalCardAppItem[]>
> => {
  const flag = includeFieldValues === true ? true : false;
  const dataUrl =
    ApiUrls.manageCardEndpoint + '/digital-card/card-app-items/all?fieldValue=' + flag;
  return axios.get(dataUrl);
};

export const getCardById = (
  cardId: number
): Promise<AxiosResponse<DigitalCard>> => {
  const dataUrl = ApiUrls.manageCardEndpoint + '/digital-card/id/' + cardId;
  return axios.get(dataUrl);
};

export const lookupNfcMap = (nfcCode): Promise<AxiosResponse<NfcCodeMap>> => {
  const dataUrl =
    ApiUrls.clientEndpoint +
    '/nfc-map/look-up?nfcCode=' +
    encodeURIComponent(nfcCode);
  return axios.get(dataUrl);
};

export const saveNfcMap = (
  nfcCode: string,
  cardId: number,
  cardAppId: number
): Promise<AxiosResponse<NfcCodeMap>> => {
  var map: NfcCodeMap = {
    isActive: true,
    nfcCode: nfcCode,
    cardId: cardId,
    cardAppId: cardAppId
  };

  const dataUrl = ApiUrls.manageCardEndpoint + '/nfc-code-map';
  return axios.post(dataUrl, map);
};

export const getUser = (): Promise<AxiosResponse<UserContext>> => {
  const dataUrl = ApiUrls.membershipEndpoint + '/user/session';
  return axios.get(dataUrl);
};

export const login = (
  userName: string,
  password: string
): Promise<AxiosResponse<LogInResult>> => {
  const data: LogInRequest = {
    userName: userName,
    password: password
  };
  const dataUrl = ApiUrls.membershipEndpoint + '/login';
  return axios.post(dataUrl, data);
};

export const logout = (token: string): Promise<AxiosResponse<boolean>> => {
  const dataUrl = ApiUrls.membershipEndpoint + '/logout';
  return axios.post(dataUrl, { token: token });
};

export const forgotPassword = (
  email: string
): Promise<AxiosResponse<boolean>> => {
  const dataUrl = ApiUrls.membershipEndpoint + '/forgot-password';
  return axios.post(dataUrl, { email: email });
};

export const deactivateAccount = (
  reason: string,
  token: string
): Promise<AxiosResponse> => {
  const dataUrl = ApiUrls.accountEndpoint + '/deactivate';
  return axios.post(
    dataUrl,
    {
      reasonCode: reason
    },
    {
      headers: {
        Authorization: 'Bearer ' + token
      }
    }
  );
};

export const changePassword = (
  newPwd: string
): Promise<AxiosResponse<boolean>> => {
  const dataUrl = ApiUrls.membershipEndpoint + '/change-password';
  return axios.post(dataUrl, { newPassword: newPwd });
};

export const resetPassword = (
  email: string,
  validationToken: string,
  password: string,
  newPassword: string
): Promise<AxiosResponse<boolean>> => {
  const dataUrl = ApiUrls.membershipEndpoint + '/reset-password';
  return axios.post(dataUrl, { email, validationToken, password, newPassword });
};

export const generatePassword = (
  email: string,
  additionalEmail?: string
): Promise<AxiosResponse<boolean>> => {
  const dataUrl = ApiUrls.membershipEndpoint + '/generate-password';
  return axios.post(dataUrl, { email, additionalEmail });
};

export const getTeamById = (id: number): Promise<AxiosResponse<Team>> => {
  const dataUrl = ApiUrls.accountEndpoint + '/team/id/' + id;
  return axios.get(dataUrl);
};

export const getTeams = (): Promise<AxiosResponse<Team[]>> => {
  const dataUrl = ApiUrls.accountEndpoint + '/team/all';
  return axios.get(dataUrl);
};

export const checkTeamExistsByName = (
  teamName: string
): Promise<AxiosResponse<boolean>> => {
  const dataUrl = ApiUrls.accountEndpoint + '/team/exists/name/' + teamName;
  return axios.get(dataUrl);
};

export const saveTeam = (req: Team): Promise<AxiosResponse<void>> => {
  const dataUrl = ApiUrls.accountEndpoint + '/team';
  if (req.id > 0) return axios.put(dataUrl, req);
  else return axios.post(dataUrl, req);
};

export const deleteTeamById = (
  id: number
): Promise<AxiosResponse<Employee>> => {
  const dataUrl = ApiUrls.accountEndpoint + '/team/' + id;
  return axios.delete(dataUrl);
};
//--
export const saveEmployee = (
  req: Employee
): Promise<AxiosResponse<Employee>> => {
  const dataUrl = ApiUrls.accountEndpoint + '/employee';
  if (req.id > 0) return axios.put(dataUrl, req);
  else return axios.post(dataUrl, req);
};

export const getEmployeeById = (
  id: number
): Promise<AxiosResponse<Employee>> => {
  const dataUrl = ApiUrls.accountEndpoint + '/employee/id/' + id;
  return axios.get(dataUrl);
};

export const getUserByToken = (token: string): Promise<AxiosResponse<User>> => {
  const dataUrl = ApiUrls.membershipEndpoint + '/user/token/' + token;
  return axios.get(dataUrl);
};

export const deleteEmployeeById = (
  id: number
): Promise<AxiosResponse<Employee>> => {
  const dataUrl = ApiUrls.accountEndpoint + '/employee/' + id;
  return axios.delete(dataUrl);
};

export const getEmployees = (): Promise<AxiosResponse<Employee[]>> => {
  const dataUrl = ApiUrls.accountEndpoint + '/employee/all';
  return axios.get(dataUrl);
};
/////

//--
export const saveContact = (req: Contact): Promise<AxiosResponse<void>> => {
  const dataUrl = ApiUrls.accountEndpoint + '/contact';
  if (req.id > 0) return axios.put(dataUrl, req);
  else return axios.post(dataUrl, req);
};

//--
export const accountSignup = (
  req: AccountSignUp
): Promise<AxiosResponse<void>> => {
  const dataUrl = ApiUrls.accountEndpoint + '/SignUp';
  return axios.post(dataUrl, req);
};

export const getContactById = (id: number): Promise<AxiosResponse<Contact>> => {
  const dataUrl = ApiUrls.accountEndpoint + '/contact/id/' + id;
  return axios.get(dataUrl);
};

export const deleteContactById = (
  id: number
): Promise<AxiosResponse<Contact>> => {
  const dataUrl = ApiUrls.accountEndpoint + '/contact/' + id;
  return axios.delete(dataUrl);
};

export const getContacts = (): Promise<AxiosResponse<Contact[]>> => {
  const dataUrl = ApiUrls.accountEndpoint + '/contact/all';
  return axios.get(dataUrl);
};
/////

export const getUsers = (): Promise<AxiosResponse<User[]>> => {
  const dataUrl = ApiUrls.membershipEndpoint + '/user/all';
  return axios.get(dataUrl);
};

export const getUserById = (id: number): Promise<AxiosResponse<User>> => {
  const dataUrl = ApiUrls.membershipEndpoint + '/user/id/' + id;
  return axios.get(dataUrl);
};

export const saveUser = (req: User): Promise<AxiosResponse<void>> => {
  const dataUrl = ApiUrls.membershipEndpoint + '/user';
  return axios.post(dataUrl, req);
};

export const deleteUserById = (id: number): Promise<AxiosResponse<boolean>> => {
  const dataUrl = ApiUrls.membershipEndpoint + '/user/' + id;
  return axios.delete(dataUrl);
};

export const checkAccountExistsByEmail = (
  email: string
): Promise<AxiosResponse<boolean>> => {
  const dataUrl = ApiUrls.accountEndpoint + '/account/exists/email/' + email;
  return axios.get(dataUrl);
};

type CodeTypeRef =
  | 'jobTitle'
  | 'department'
  | 'nameTitle'
  | 'socialMediaProvider'
  | 'contactGroup'
  | 'LeadStatus'
  | 'SelfReviewStatus';

export const getRefData = (...args: CodeTypeRef[]): Promise<RefData> => {
  const dataUrl = ApiUrls.accountEndpoint + '/ref-data/' + args.join(',');
  return axios
    .get<Dictionary<CodeMaster[]>>(dataUrl)
    .then((o) => {
      const refData: RefData = {
        jobTitles: o.data['jobTitle'],
        departments: o.data['department'],
        nameTitles: o.data['nameTitle'],
        socialMediaProviders: o.data['socialMediaProvider'],
        contactGroups: o.data['ContactGroup'],
        leadStatus: o.data['LeadStatus'],
        selfReviewStatus: o.data['SelfReviewStatus']
      };
      return refData;
    })
    .catch((ex) => {
      console.log('failed at getRefData', ex);
      return {};
    });
};

export const getReasonByToken = (ref, token): Promise<RefData> => {
  const dataUrl = ApiUrls.accountEndpoint + '/ref-data/' + ref;
  return axios
    .get<Dictionary<CodeMaster[]>>(dataUrl, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
    .then((o) => {
      const refData: RefData = {
        accountDelReasons: o.data['AccountDelReason']
      };
      return refData;
    })
    .catch((ex) => {
      console.log('failed at getRefData', ex);
      return {};
    });
};

export const saveCard = (
  model: DigitalCard
): Promise<AxiosResponse<DigitalCard>> => {
  const req: CreateCardRequest = {
    card: model
  };
  const dataUrl = ApiUrls.manageCardEndpoint + '/digital-card';
  if (req.card.id > 0) return axios.put(dataUrl, req);
  else return axios.post(dataUrl, req);
};

export const deleteCardById = (id: number): Promise<AxiosResponse<boolean>> => {
  const dataUrl = ApiUrls.manageCardEndpoint + '/digital-card/id/' + id;
  return axios.delete(dataUrl);
};

export const uploadContacts = (
  contacts: ContactUploadItem[]
): Promise<AxiosResponse<void>> => {
  const dataUrl = ApiUrls.accountEndpoint + '/contact/upload';
  return axios.post(dataUrl, contacts);
};

export const getCardApps = (): Promise<AxiosResponse<CardApp[]>> => {
  const dataUrl = ApiUrls.manageCardEndpoint + '/digital-card/card-apps/all';
  return axios.get(dataUrl);
};

export const addOrUpdateAppToCard = (
  model: CardAppItem
): Promise<AxiosResponse<CardAppItem>> => {
  const dataUrl = ApiUrls.manageCardEndpoint + '/digital-card/add-card-app';
  return axios.post(dataUrl, model);
};

export const removeCardApp = (
  cardId: number,
  appId: number
): Promise<AxiosResponse<CardAppItem>> => {
  const dataUrl = ApiUrls.manageCardEndpoint + '/digital-card/remove-card-app';
  return axios.post(dataUrl, { digitalCardId: cardId, cardAppId: appId });
};

export const getLeads = (): Promise<AxiosResponse<LeadItem[]>> => {
  const dataUrl = ApiUrls.manageCardEndpoint + '/leads';
  return axios.get(dataUrl);
};

export const getLeadById = (
  leadId: string
): Promise<AxiosResponse<LeadItem>> => {
  const dataUrl = ApiUrls.clientEndpoint + '/lead/id/' + leadId;
  return axios.get(dataUrl);
};

export const updateLead = (req: {
  leadId: string;
  employeeId: number;
  leadStatus: string;
}): Promise<AxiosResponse<void>> => {
  const dataUrl = ApiUrls.manageCardEndpoint + '/lead/update';
  return axios.put(dataUrl, {
    leadId: req.leadId,
    status: req.leadStatus,
    employeeId: req.employeeId
  });
};

export const createLead = (req: LeadRequest): Promise<AxiosResponse<void>> => {
  const dataUrl = ApiUrls.manageCardEndpoint + '/lead';
  return axios.post(dataUrl, req);
};

export const getLeadComments = (
  leadId: string
): Promise<AxiosResponse<LeadComment[]>> => {
  const dataUrl = ApiUrls.manageCardEndpoint + '/lead/comments/' + leadId;
  return axios.get(dataUrl);
};

export const addLeadComment = (
  model: LeadComment
): Promise<AxiosResponse<boolean>> => {
  const dataUrl = ApiUrls.manageCardEndpoint + '/lead/comment/add';
  return axios.post(dataUrl, model);
};

export const deleteLeadComment = (
  commentId: string
): Promise<AxiosResponse<boolean>> => {
  const dataUrl = ApiUrls.manageCardEndpoint + '/lead/comment/' + commentId;
  return axios.delete(dataUrl);
};

export const getSelfReviews = (): Promise<AxiosResponse<SelfReview[]>> => {
  const dataUrl = ApiUrls.manageCardEndpoint + '/self-review/all';
  return axios.get(dataUrl);
};

export const updateSelfReview = (req: {
  reviewId: number;
  reviewStatus: string;
}): Promise<AxiosResponse<void>> => {
  const dataUrl =
    ApiUrls.cardApi + '/listing-apps/review-app/updateReviewStatus';
  return axios.put(dataUrl, {
    reviewId: req.reviewId,
    status: req.reviewStatus
  });
};

export const getReviewComments = (
  leadId: string
): Promise<AxiosResponse<ReviewComment[]>> => {
  const dataUrl =
    ApiUrls.manageCardEndpoint + '/self-review/comments/' + leadId;
  return axios.get(dataUrl);
};

export const addReviewComment = (
  model: ReviewComment
): Promise<AxiosResponse<boolean>> => {
  const dataUrl = ApiUrls.manageCardEndpoint + '/self-review/comment/add';
  return axios.post(dataUrl, model);
};

export const deleteReviewComment = (
  commentId: string
): Promise<AxiosResponse<boolean>> => {
  const dataUrl =
    ApiUrls.manageCardEndpoint + '/self-review/comment/' + commentId;
  return axios.delete(dataUrl);
};

export const getReviewById = (
  reviewId: string
): Promise<AxiosResponse<SelfReview>> => {
  const dataUrl = ApiUrls.clientEndpoint + '/review/id/' + reviewId;
  return axios.get(dataUrl);
};

export const getProfileNameUnique = (
  cardId: number,
  profileName: string
): Promise<AxiosResponse<boolean>> => {
  const dataUrl =
    ApiUrls.manageCardEndpoint + `/digital-card/exists/qr-text/id/${cardId}/name/${profileName}`;
  return axios.get(dataUrl);
};

export const getMyNfcCodes = (): Promise<AxiosResponse<NfcCodeMap[]>> => {
  const dataUrl = ApiUrls.manageCardEndpoint + '/nfc-code-maps/my';
  return axios.get(dataUrl);
};

export const getMyNfcCodesForClient = (
  clientId: number
): Promise<AxiosResponse<NfcCodeMap[]>> => {
  //const dataUrl = ApiUrls.manageCardEndpoint + '/nfc-code-maps/client/' + clientId;
  const dataUrl = ApiUrls.manageCardEndpoint + '/nfc-code-maps/my';
  return axios.get(dataUrl);
};

export const getAppNotificationSetting = (
  clientId: number
): Promise<AxiosResponse<NotificationSetting>> => {
  const dataUrl =
    ApiUrls.cardApi + '/app/notification-setting/client/' + clientId;
  return axios.get(dataUrl, {
    headers: {
      apiKey: NEST_API_SECRET_KEY
    }
  });
};

export const saveAppNotificationSetting = (
  setting: NotificationSetting
): Promise<AxiosResponse<NotificationSetting>> => {
  const dataUrl = ApiUrls.cardApi + '/app/notification-setting';
  return axios.post(dataUrl, setting, {
    headers: {
      apiKey: NEST_API_SECRET_KEY
    }
  });
};

export const createPaymentIntent = (
  request: PaymentIntentRequest
): Promise<AxiosResponse<PaymentIntentResult>> => {
  const dataUrl = ApiUrls.cardApi + '/subscription/create-payment-intent';
  return axios.post(dataUrl, request, {
    headers: {
      apiKey: NEST_API_SECRET_KEY
    }
  });
};

export const getAccountSignupStatus = (
  client: string
): Promise<AxiosResponse<AccountSignUpStatus>> => {
  const dataUrl =
    ApiUrls.cardApi + '/subscription/account-signup-status?client=' + client;
  return axios.get(dataUrl, {
    headers: {
      apiKey: NEST_API_SECRET_KEY
    }
  });
};

interface paginatedResult {
  results: SelfReview[];
  totalCount: number;
}
export const getSelfReviewsPaginated = (
  page: number,
  pageSize: number
): Promise<AxiosResponse<paginatedResult>> => {
  const dataUrl =
    ApiUrls.manageCardEndpoint +
    '/self-review/page/' +
    page +
    '/page-size/' +
    pageSize;
  return axios.get(dataUrl);
};

export const getReviewAppsDataById = (
  reviewId: string
): Promise<AxiosResponse<ReviewSaveModel>> => {
  const dataUrl =
    ApiUrls.cardApi + '/listing-apps/review-app/review/' + reviewId;
  return axios.get(dataUrl);
};
export const getReviewAppsDataByClient = (
  clientId: number,
  page: number,
  pageSize: number
): Promise<AxiosResponse<ReviewAppModelPaginatedResponse>> => {
  const dataUrl =
    ApiUrls.cardApi +
    '/listing-apps/review-app/reviews/' +
    clientId +
    '?page=' +
    page +
    '&limit=' +
    pageSize;
  return axios.get(dataUrl);
};

export const getEmployeeAccountSetting = (): Promise<
  AxiosResponse<EmployeeAccountSetting>
> => {
  const dataUrl = ApiUrls.accountEndpoint + '/employee/account-setting';
  return axios.get(dataUrl);
};

export const saveEmployeeAccountSetting = (
  setting: EmployeeAccountSetting
): Promise<AxiosResponse<EmployeeAccountSetting>> => {
  const dataUrl = ApiUrls.accountEndpoint + '/employee/account-setting';
  return axios.put(dataUrl, setting);
};

export const createBusinessCardForNewEmployee = (
  employeeId: number
): Promise<AxiosResponse<DigitalCard>> => {
  const dataUrl =
    ApiUrls.accountEndpoint + '/employee/create-card/new/' + employeeId;
  return axios.post(dataUrl);
};

export const keepAlive = (): Promise<AxiosResponse> => {
  const dataUrl =
    ApiUrls.accountEndpoint + '/keep-alive';
  return axios.get(dataUrl);
};

export const getNfcMaps = (): Promise<AxiosResponse<NfcCodeMap[]>> => {
  const dataUrl =
    ApiUrls.manageCardEndpoint + '/digital-card/my-cards';
  return axios.get(dataUrl);
};

export const resetNfcCard = (
  nfcCode: string
): Promise<AxiosResponse<boolean>> => {
  const dataUrl =
    ApiUrls.manageCardEndpoint + '/digital-card/nfc-card/reset';
  return axios.post(dataUrl, { nfcCode: nfcCode });
};

export const getActiveSubscription = (
  token: string
): Promise<AxiosResponse<ActiveSubscription>> => {
  const dataUrl = ApiUrls.cardApi + '/subscription/active';
  return axios.get(dataUrl, {
    headers: {
      token
    }
  });
};

export const getPlanProducts = (): Promise<AxiosResponse<PlanProduct[]>> => {
  const dataUrl = ApiUrls.cardApi + '/subscription/all-products';
  return axios.get(dataUrl);
};