import {
  Card,
  CardHeader,
  Divider,
  IconButton,
  styled,
  Box,
  useTheme,
  alpha,
  CardMedia,
  CardActions,
  Link,
  Typography,
  Button,
  CardActionArea,
  Paper,
  Avatar,
  Grid,
  TextField,
  Switch,
  FormControlLabel,
  CardContent,
  Autocomplete,
  FormControl,
  InputLabel,
  Checkbox
} from '@mui/material';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import FieldRenderEditorBaseProp from '../_base/FieldRenderEditorBaseProp';
import readFieldValue from '../_base/readFieldValue';
import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone';
import { useDropzone } from 'react-dropzone';
import FooterActions from '../_base/FooterActions';
import cdnService from '../../../services/cdnService';
import PermMediaTwoToneIcon from '@mui/icons-material/PermMediaTwoTone';
import { CodeItem } from '../../../BusinessApp/services/models';
import { getCardById, getCardItems } from '../../../services/apiService';
import {
  CardFieldNames,
  CardTypes,
  DigitalCardListItem
} from '../../../services/apiService/response-models';
import parseMyJson from '../../../utility/parseMyJson';
import _ from 'lodash';
import CropPortraitIcon from '@mui/icons-material/CropPortrait';
import CropLandscapeIcon from '@mui/icons-material/CropLandscape';

const CardActionAreaWrapper = styled(CardActionArea)(
  () => `
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  .MuiTouchRipple-root {
      opacity: .3;
  }

  &:hover {
      .MuiCardActionArea-focusHighlight {
          opacity: .05;
      }
  }
`
);

const AvatarCloud = styled(Avatar)(
  ({ theme }) => `
    background: transparent;
    color: ${theme.colors.primary.main};
    width: ${'100%'};
    height: ${theme.spacing(7)};
`
);
export interface ImageSliderType {
  title?: string;
  subTitle?: string;
  isPortrait?: boolean;
  imageList: ImageSliderItem[];
  profileRef?: ImageSliderProfileItem;
}

export interface ImageSliderItem {
  url: string;
  title?: string;
  subTitle?: string;
  targetUrl?: string;
}
export interface ImageSliderProfileItem {
  active: boolean;
  profileId?: number;
  sliderTitle?: string;
}
export default function ImageSliderEditor(props: FieldRenderEditorBaseProp) {
  const theme = useTheme();
  const [isUseFromProfile, setIsUseFromProfile] = React.useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [fieldValueOriginal, setFieldValueOriginal] =
    useState<ImageSliderType>();
  const [fieldValue, setFieldValue] = useState<ImageSliderType>();
  const [cardItems, setCardItems] = useState<DigitalCardListItem[]>();
  const [acceptedDataUrls, setAcceptedDataUrls] = useState<CodeItem[]>([]);
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    const defaultVal: ImageSliderType = {
      imageList: []
    };
    const val = readFieldValue(props.field, defaultVal);
    setFieldValueOriginal(val);

    setFieldValue(_.cloneDeep(val));
    setIsUseFromProfile(val.profileRef?.active === true ? true : false);

    if (val.profileRef?.active === true) {
      getCardItems().then((res) => {
        setCardItems(res.data);
      });
    }
  }, [props.field]);

  const onSwitchChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsUseFromProfile(event.target.checked);
    if (!fieldValue.profileRef) {
      fieldValue.profileRef = {
        active: event.target.checked
      };
    } else {
      fieldValue.profileRef = {
        ...fieldValue.profileRef,
        active: event.target.checked
      };
    }
    if (!cardItems) {
      const res = await getCardItems();
      setCardItems(res.data);
    }
  };

  const onSave = async () => {
    if (!isFormValid || isUploading) return;

    if (acceptedDataUrls.length) {
      setIsUploading(true);
      const fileNames: string[] = [];
      await Promise.all(
        acceptedDataUrls.map(async (dataUrl) => {
          const res = await fetch(dataUrl.code);
          const blob = await res.blob();
          const fileName = await cdnService.uploadFileToCloud(
            dataUrl.value,
            blob
          );
          fileNames.push(fileName);
        })
      );
      setIsUploading(false);

      //remove old imagesList
      (fieldValue.imageList || []).forEach((o) =>
        cdnService.deleteFileFromCloud(o.url).then(() => {})
      );

      fieldValue.imageList = fileNames.map((o) => {
        return { url: o };
      });
    }

    props.onSave(
      props.field,
      {
        config: JSON.stringify(fieldValue)
      },
      true
    );
  };

  useEffect(() => {
    validateForm();
  }, [fieldValue, acceptedDataUrls]);

  const validateForm = () => {
    if (!fieldValue) return false;

    let isValid = false;
    if (!isUseFromProfile) {
      if (acceptedDataUrls.length) {
        isValid = true;
      } else {
        isValid = fieldValue.imageList && fieldValue.imageList.length > 0;
      }
    } else {
      isValid =
        Boolean(fieldValue.profileRef?.profileId) &&
        Boolean(fieldValue.profileRef?.sliderTitle);
    }
    setIsFormValid(isValid);
    return isValid;
  };

  if (!fieldValue) return <>Loading...</>;

  const onUploadFormChage = (e: ImageSliderType, dataUrls: CodeItem[]) => {
    const profileRef = fieldValue.profileRef;

    const val = _.cloneDeep(e);
    val.profileRef = {
      ...profileRef,
      active: false
    };
    setFieldValue({ ...val });
    setAcceptedDataUrls(_.cloneDeep(dataUrls));
  };

  return (
    <Card
      sx={{ overflow: 'hidden', textAlign: 'left', border: 'solid 1px #ccc' }}
    >
      <CardHeader
        title={props.field.fieldRef.displayName}
        action={
          <FormControlLabel
            control={
              <Switch checked={isUseFromProfile} onChange={onSwitchChange} />
            }
            label="Use from profile"
          />
        }
      />
      <Grid container>
        <Grid xs={12} item>
          <Box sx={{ pl: 1 }}>
            <FormControlLabel
              checked={fieldValue?.isPortrait == true}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue({
                  ...fieldValue,
                  isPortrait: e.target.checked
                });
                validateForm();
              }}
              control={
                <Checkbox
                  icon={<CropLandscapeIcon />}
                  checkedIcon={<CropPortraitIcon />}
                />
              }
              label={`Orientation (${
                fieldValue?.isPortrait == true ? 'Portrait' : 'Landscape'
              })`}
            />
          </Box>
        </Grid>
      </Grid>
      {!isUseFromProfile && (
        <UploadImageForm
          fieldValue={fieldValue}
          onChange={(e, o) => onUploadFormChage(e, o)}
        />
      )}
      {isUseFromProfile && (
        <ProfileSelectForm
          profileRef={fieldValue.profileRef}
          cardItems={cardItems}
          onChange={(e) => {
            setFieldValue({
              ...fieldValue,
              profileRef: {
                active: true,
                profileId: e.profileId,
                sliderTitle: e.sliderTitle
              }
            });
            validateForm();
          }}
        />
      )}
      <FooterActions
        isSaveDisabled={!isFormValid}
        isInProgress={isUploading}
        onDelete={() => props.onDelete(props.field)}
        onSave={onSave}
        onCancel={props.onCancel}
      />
    </Card>
  );
}

function UploadImageForm(props: {
  fieldValue: ImageSliderType;
  onChange: (e: ImageSliderType, dataUrls: CodeItem[]) => void;
}) {
  const theme = useTheme();
  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const [acceptedDataUrls, setAcceptedDataUrls] = useState<CodeItem[]>([]);

  const [fieldValue, setFieldValue] = useState<ImageSliderType>();

  useEffect(() => {
    setFieldValue(props.fieldValue);
  }, [props.fieldValue]);

  const readAsDataURL = (inputFile: any): Promise<CodeItem> => {
    const temporaryFileReader = new FileReader();

    return new Promise((resolve, reject) => {
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort();
        reject(new DOMException('Problem parsing input file.'));
      };

      temporaryFileReader.onload = () => {
        resolve({
          code: temporaryFileReader.result?.toString() || '',
          value: inputFile.name
        });
      };
      temporaryFileReader.readAsDataURL(inputFile);
    });
  };
  const onDropAccepted = async (files) => {
    setAcceptedFiles(files);

    const dataUrls: CodeItem[] = await Promise.all(
      files.map((file) => readAsDataURL(file))
    );

    //files.forEach(file => {
    //    const reader = new FileReader();
    //    reader.addEventListener('load', () => {
    //        dataUrls.push({ code: reader.result?.toString() || '', value: file.name });
    //    });
    //    reader.readAsDataURL(file);
    //});

    setAcceptedDataUrls(dataUrls);
    const updatedModel = _.cloneDeep(fieldValue);
    updatedModel.imageList = [];
    setFieldValue(updatedModel);
    props.onChange(updatedModel, dataUrls);
  };

  const {
    isDragActive,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps
  } = useDropzone({
    maxFiles: 100,
    maxSize: 5242880,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg']
      //   'image/heic': ['.heic'],
      //   'image/heif': ['.heif']
    },
    onDropAccepted
  });

  const onRemoveFiles = () => {
    setAcceptedFiles([]);
    setAcceptedDataUrls([]);

    const updatedModel = _.cloneDeep(fieldValue);
    updatedModel.imageList = [];

    setFieldValue(updatedModel);
    props.onChange(updatedModel, []);
  };

  if (!fieldValue) return <>Loading...</>;

  return (
    <React.Fragment>
      {!fieldValue.imageList.length && acceptedDataUrls.length == 0 && (
        <Box
          p={1}
          {...getRootProps()}
          component="div"
          className="feature-file-upload"
        >
          <input {...getInputProps()} />
          <label id="label-file-upload" htmlFor="input-file-upload">
            <div>
              <p>Drag and drop your file here or browse</p>
              <AvatarCloud variant="rounded">
                <CloudUploadTwoToneIcon />
              </AvatarCloud>
            </div>
          </label>
        </Box>
      )}
      <ImageSliderWrapper
        imageList={
          acceptedDataUrls.length > 0
            ? acceptedDataUrls.map((o) => {
                return { url: o.code };
              })
            : fieldValue.imageList
        }
        showRemove={true}
        onRemoveFiles={onRemoveFiles}
      />
      <Box m={1} mt={0} display="flex" flexDirection={'column'}>
        <form noValidate style={{ width: '100%' }}>
          <Grid sx={{ padding: 0 }} container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                autoFocus={false}
                id={'title'}
                required={false}
                name={'title'}
                label={'Title'}
                fullWidth
                value={fieldValue.title || ''}
                // error={!!validate(field, values[field.name])}
                // helperText={validate(field, values[field.name])}
                onChange={(e) => {
                  const updatedModel = {
                    ...fieldValue,
                    title: e.target.value
                  };
                  setFieldValue(updatedModel);
                  props.onChange(updatedModel, acceptedDataUrls);
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                autoFocus={false}
                id={'subTitle'}
                required={false}
                name={'subTitle'}
                label={'Subtitle'}
                fullWidth
                value={fieldValue.subTitle || ''}
                // error={!!validate(field, values[field.name])}
                // helperText={validate(field, values[field.name])}
                onChange={(e) => {
                  const updatedModel = {
                    ...fieldValue,
                    subTitle: e.target.value
                  };
                  setFieldValue(updatedModel);
                  props.onChange(updatedModel, acceptedDataUrls);
                }}
              />
            </Grid>
          </Grid>
        </form>
      </Box>
    </React.Fragment>
  );
}
function ProfileSelectForm(props: {
  profileRef?: ImageSliderProfileItem;
  cardItems?: DigitalCardListItem[];
  onChange: (e: ImageSliderProfileItem) => void;
}) {
  const [profileOptions, setProfileOptions] = useState<CodeItem[]>([]);
  const [selectedProfile, setSelectedProfile] = useState<CodeItem>();
  const [sliders, setSliders] = useState<ImageSliderType[]>([]);
  const [selectedSlider, setSelectedSlider] = useState<ImageSliderType>();

  useEffect(() => {
    (async () => {
      try {
        if (props.cardItems) {
          const items = props.cardItems
            .filter((o) => o.cardType == CardTypes.Business)
            .map((o) => {
              return {
                code: o.digitalCardId + '',
                value: o.label
              } as CodeItem;
            });
          let selectedItem = items.find(
            (o) => o.code == props.profileRef?.profileId + ''
          );

          setProfileOptions(items);
          // await loadOrginalProfile(props.profile.digitalCardId);
          if (selectedItem) {
            await onProfileSelectionChange({ ...selectedItem });
          }
        }
      } catch (ex) {
        console.error(ex);
      }
    })();
  }, [props.cardItems]);

  const onProfileSelectionChange = async (item: CodeItem) => {
    const cardRes = await getCardById(+item.code);
    if (cardRes && cardRes.data) {
      const card = cardRes.data;
      const fields = card.fields.filter(
        (o) => o.fieldName == CardFieldNames.ImageSlider
      );

      const values: ImageSliderType[] = [];

      fields.forEach((field) => {
        const config = field.formFieldValues?.find((o) => o.name == 'config');
        const val: ImageSliderType = parseMyJson(config?.value || '');
        if (val) {
          values.push(val);
        }
      });
      setSelectedProfile({ ...item });
      setSliders(values);
      setSelectedSlider(_.first(values));
      props.onChange({
        active: true,
        profileId: +item.code,
        sliderTitle: _.first(values)?.title || ''
      });
    }
  };

  const onSliderSelectionChange = (e: ImageSliderType) => {
    setSelectedSlider({ ...e });
    props.onChange({
      active: true,
      profileId: +selectedProfile?.code,
      sliderTitle: e?.title || ''
    });
  };

  return (
    <Box>
      {!selectedSlider && (
        <Box p={1} component="div" className="feature-file-upload">
          <label id="label-file-upload" htmlFor="input-file-upload">
            <div>
              <p>Please select company profile</p>
              <AvatarCloud variant="rounded">
                <PermMediaTwoToneIcon />
              </AvatarCloud>
            </div>
          </label>
        </Box>
      )}
      {selectedSlider && (
        <ImageSliderWrapper
          imageList={selectedSlider.imageList}
          showRemove={false}
          onRemoveFiles={() => {}}
        />
      )}
      <Box m={1} mt={0} display="flex" flexDirection={'column'}>
        <form noValidate style={{ width: '100%' }}>
          <Grid sx={{ padding: 0 }} container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <FormControl sx={{ mt: 1 }} fullWidth variant="standard">
                <Autocomplete
                  fullWidth
                  options={profileOptions}
                  value={selectedProfile || null}
                  isOptionEqualToValue={(option, value) =>
                    option.code === value?.code
                  }
                  getOptionLabel={(option: CodeItem) => option.value}
                  onChange={(e, newItem: CodeItem) => {
                    if (newItem) {
                      onProfileSelectionChange(newItem);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      {...params}
                      label="Select company profile"
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <FormControl fullWidth variant="standard">
                {/*<Typography variant="subtitle1" gutterBottom>*/}
                {/*    Slider*/}
                {/*</Typography>*/}
                <Autocomplete
                  fullWidth
                  options={sliders}
                  value={selectedSlider || null}
                  isOptionEqualToValue={(option, value) =>
                    option.title === value?.title
                  }
                  getOptionLabel={(option: ImageSliderType) => option.title}
                  onChange={(e, newItem: ImageSliderType) => {
                    if (newItem) {
                      onSliderSelectionChange(newItem);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField fullWidth {...params} label="Slider" />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Box>
  );
}

function ImageSliderWrapper(props: {
  imageList: ImageSliderItem[];
  showRemove: boolean;
  onRemoveFiles: () => void;
}) {
  const theme = useTheme();

  const renderSwiperSlide = (o: ImageSliderItem) => {
    return (
      <SwiperSlide key={o.url}>
        <Card
          sx={{
            mt: 2,
            textAlign: 'center',
            transition: `${theme.transitions.create([
              'box-shadow',
              'transform'
            ])}`,
            transform: 'translateY(0px)',

            '&:hover': {
              transform: 'translateY(-10px)',
              boxShadow: `0 2rem 8rem 0 ${alpha(
                theme.colors.alpha.black[100],
                0.1
              )}, 
                                0 0.6rem 1.6rem ${alpha(
                                  theme.colors.alpha.black[100],
                                  0.2
                                )}, 
                                0 0.2rem 0.2rem ${alpha(
                                  theme.colors.alpha.black[100],
                                  0.15
                                )}`
            }
          }}
        >
          <CardActionAreaWrapper>
            <CardMedia
              component="img"
              height="230"
              image={
                o.url && o.url.startsWith('data:image')
                  ? o.url
                  : cdnService.toImageUrl(o.url)
              }
              alt="..."
              sx={{ objectFit: 'fill' }}
              onClick={
                o.targetUrl
                  ? () => window.open(o.targetUrl, '_blank')
                  : () => {}
              }
            />
          </CardActionAreaWrapper>
        </Card>
        {(o.title || o.subTitle) && (
          <Box
            sx={{
              px: { md: 2, lg: 1.5, xl: 3 },
              pt: 2,
              textAlign: 'center'
            }}
          >
            {o.title && (
              <Link
                lineHeight={1.5}
                target="_blank"
                href={o.targetUrl}
                sx={{
                  transition: `${theme.transitions.create(['color'])}`,
                  color: `${theme.colors.alpha.black[100]}`,

                  '&:hover': {
                    color: `${theme.colors.primary.main}`
                  }
                }}
                color="text.primary"
                variant="h3"
                underline="none"
              >
                {o.title}
              </Link>
            )}
            {o.subTitle && (
              <Typography
                variant="subtitle2"
                sx={{
                  pb: 2
                }}
              >
                {o.subTitle}
              </Typography>
            )}
          </Box>
        )}
      </SwiperSlide>
    );
  };

  return (
    <Box
      px={1}
      pb={2}
      sx={{
        '.swiper-pagination-bullets': {
          bottom: '0 !important'
        }
      }}
    >
      <Swiper
        spaceBetween={12}
        slidesPerView={1}
        loop
        navigation={{}}
        modules={[Navigation, Pagination, Autoplay]}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false
        }}
        pagination={{ dynamicBullets: true, clickable: true }}
      >
        {props.imageList.map((o) => renderSwiperSlide(o))}
      </Swiper>
      {props.showRemove && props.imageList.length > 0 && (
        <Button
          onClick={props.onRemoveFiles}
          sx={{ mt: 1 }}
          size="small"
          variant="outlined"
          color="error"
        >
          Remove files ({props.imageList.length})
        </Button>
      )}
    </Box>
  );
}
