import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  LinearProgress,
  Paper,
  Typography,
  useTheme
} from '@mui/material';
import {
  Elements,
  LinkAuthenticationElement,
  PaymentElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import { loadStripe, StripePaymentElementOptions } from '@stripe/stripe-js';
import { useCallback, useEffect, useState } from 'react';
import numeral from 'numeral';
import useRefMounted from 'src/ui-bloom/hooks/useRefMounted';
import {
  AccountSignUp,
  PaymentIntentResult
} from 'src/services/apiService/response-models';
import { createPaymentIntent } from 'src/services/apiService';
import { useFormik, useFormikContext } from 'formik';
import { parseToApiErrorMessage } from 'src/utility/parseToApiErrorMessage';
import { useSnackbar } from 'notistack';
import PaymentStep from '../payment-step';
import ErrorPanel from 'src/components/error-panel';
// import ErrorPanel from '../../components/error-panel';

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || ''
);

const stripeAppearance = {
  theme: 'night',
  labels: 'floating'
};

function StripeElement(props) {
  const isMountedRef = useRefMounted();
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(null);

  const { values: _values } = useFormikContext<any>();

  //socialMediaLinks
  let socialMediaLinks = [];

  let codeId = props.smProviderId('facebook');
  if (codeId) {
    socialMediaLinks = socialMediaLinks.filter(
      (o) => codeId != o.socialMediaProviderId
    );
    socialMediaLinks.push({
      id: 0,
      link: _values.faceBookUrl,
      socialMediaProviderId: codeId
    });
  }

  codeId = props.smProviderId('twitter');
  if (codeId) {
    socialMediaLinks = socialMediaLinks.filter(
      (o) => codeId != o.socialMediaProviderId
    );
    socialMediaLinks.push({
      id: 0,
      link: _values.twitterUrl,
      socialMediaProviderId: codeId
    });
  }
  const model: AccountSignUp = {
    isIndividual: props.isBusiness ? false : true,
    firstName: _values.firstName,
    lastName: _values.lastName,
    email: _values.email,
    password: _values.password,
    contactNumber: _values.phone
      ? _values.countryCode + '-' + _values.phone
      : '',
    businessName: _values.businessName,
    additionalInfo: {
      description: _values.description,
      // headline: _values.headline,
      logo: _values.logo,
      website: _values.website,
      imageUrl: _values.imageUrl,
      dateOfBirth: _values?.dateOfBirthnew
        ? new Date(_values.dateOfBirth).toISOString()
        : null,
      socialMediaLinks: socialMediaLinks
    },
    // alternateContact: _values.alternateContact,
    // alternateEmail: _values.alternateEmail,
    terms: _values.terms
    // promo: _values.promo,
  };

  const [stripeClientSecret, setStripeClientSecret] =
    useState<PaymentIntentResult>(null); // 'pi_3Mpc6bGnAfDAtWC40MrCsdeW_secret_laz5pLlwdtzbumm9Bojh8RuMJ'
  const { enqueueSnackbar } = useSnackbar();
  const loadStripeClinetSecret = useCallback(
    async (
      callback: (hasError: boolean, data: PaymentIntentResult) => void
    ) => {
      if (isMountedRef.current) {
        try {
          const data = await createPaymentIntent({
            planName: props.planName,
            accountSignUp: model
          });
          setHasError(null);
          callback(false, data.data);
        } catch (ex) {
          const msg = parseToApiErrorMessage(ex, 'Failed to create intent');
          setHasError(msg);
          setIsLoading(false);
          //   enqueueSnackbar(msg, { variant: 'error' });
        }
      }
    },
    [isMountedRef]
  );

  useEffect(() => {
    setIsLoading(true);
    loadStripeClinetSecret((hasError, data) => {
      if (data) {
        setStripeClientSecret(data);
      }
      setIsLoading(false);
    });
  }, [loadStripeClinetSecret]);

  return !stripeClientSecret || isLoading || hasError ? (
    <Box px={4} py={8}>
      {isLoading && <LinearProgress />}
      {/* Show any error or success messages */}
      {hasError && (
        <div style={{ background: '#fff', margin: '1%' }}>
          {' '}
          <ErrorPanel
            title="Failed to load product details"
            message={hasError}
          />
        </div>
      )}
    </Box>
  ) : (
    <Elements
      stripe={stripePromise}
      options={{
        clientSecret: stripeClientSecret.clientSecret,
        appearance: stripeAppearance as any
      }}
    >
      <PaymentStep {...stripeClientSecret} />
    </Elements>
  );
}

export default StripeElement;
