import {
  useState,
  ReactElement,
  forwardRef,
  Ref,
  useCallback,
  useEffect,
  useRef
} from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Link as RouterLink,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom';
import {
  Box,
  Card,
  Link,
  TextField,
  Typography,
  Container,
  Alert,
  Slide,
  Dialog,
  Collapse,
  Button,
  Avatar,
  IconButton,
  styled,
  LinearProgress
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';

import { useTranslation } from 'react-i18next';
import Logo from 'src/ui-bloom/components/LogoSign';
import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';
import useIntervalAsync from 'src/utility/useIntervalAsync';
import { getAccountSignupStatus } from 'src/services/apiService';
import useRefMounted from 'src/ui-bloom/hooks/useRefMounted';

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
);

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const AvatarSuccess = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.success.main};
      color: ${theme.palette.success.contrastText};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};
      box-shadow: ${theme.colors.shadows.success};
      top: -${theme.spacing(6)};
      position: absolute;
      left: 50%;
      margin-left: -${theme.spacing(6)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

function PaymentSuccess() {
  const { t }: { t: any } = useTranslation();
  const isMountedRef = useRefMounted();
  const [openAlert, setOpenAlert] = useState(true);
  const [searchParams] = useSearchParams();
  const client = searchParams.get('payment_intent_client_secret');
  const navigate = useNavigate();
  const [userSignup, setUserSignup] = useState({
    status: 'InProgress',
    statusDesc: ''
  });
  const updateState = useCallback(async () => {
    const response = await getAccountSignupStatus(client);

    setUserSignup(response.data);
  }, []);
  const update = useIntervalAsync(updateState, 3000);

  //   useEffect(() => {
  //     return () => {
  //       if (
  //         (userSignup && userSignup.status === 'Completed') ||
  //         userSignup.status === 'Error'
  //       ) {
  //         update();
  //       }
  //     };
  //   }, [update, userSignup]);
  return (
    <>
      <MainContent>
        <Container maxWidth="sm">
          <Logo />
        </Container>
      </MainContent>

      <DialogWrapper
        open={true}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {}}
      >
        {userSignup && userSignup.status === 'InProgress' ? (
          <Box px={4} py={8}>
            <LinearProgress />
            <Typography
              align="center"
              sx={{
                py: 2,
                px: 1
              }}
              variant="h4"
            >
              {t('Please wait while we are setting up your account.')}
            </Typography>
          </Box>
        ) : (
          <Box
            sx={{
              px: 4,
              pb: 4,
              pt: 10
            }}
          >
            <AvatarSuccess>
              <CheckTwoToneIcon />
            </AvatarSuccess>

            <Collapse in={openAlert}>
              <Alert
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpenAlert(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                severity={
                  userSignup && userSignup.status === 'Error'
                    ? 'error'
                    : 'success'
                }
              >
                {userSignup && userSignup.status === 'Error'
                  ? 'Something went wrong, please contact @ support@kiengage.com'
                  : t('The account setup have been completed')}
              </Alert>
            </Collapse>

            <Typography
              align="center"
              sx={{
                py: 2,
                px: 1
              }}
              variant="h4"
            >
              {userSignup && userSignup.status === 'Error'
                ? userSignup.statusDesc
                : t('Thanks for the payment. You are all set to use our App.')}
            </Typography>

            <Button
              fullWidth
              size="small"
              variant="contained"
              onClick={() => {
                navigate('/app/login', { replace: true });
              }}
            >
              {t('Continue to login')}
            </Button>
          </Box>
        )}
      </DialogWrapper>
    </>
  );
}

export default PaymentSuccess;
