import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import PageHeader from './PageHeader';
import {
  Box,
  Drawer,
  Grid,
  useTheme,
  IconButton,
  styled,
  LinearProgress,
  Card,
  Button,
  CircularProgress
} from '@mui/material';
import Scrollbar from 'src/ui-bloom/components/Scrollbar';
import SaveIcon from '@mui/icons-material/Save';

import Sidebar from './Sidebar';

import ContactInfo from './ContactInfo';
import PersonalDetails from './PersonalDetails';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import useRefMounted from '../../../ui-bloom/hooks/useRefMounted';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import {
  RefData,
  EmailTypes,
  Contact,
  NameItem,
  Address
} from '../../../services/apiService/response-models';
import {
  getRefData,
  getContactById,
  saveContact,
  checkAccountExistsByEmail
} from '../../../services/apiService';
import { parseToApiErrorMessage } from '../../../utility/parseToApiErrorMessage';
import { Formik } from 'formik';
import * as Yup from 'yup';
import GroupInfo from './GroupInfo';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { useNavigate, useParams } from 'react-router-dom';
import extractNumber from 'src/utility/extractNumber';
import extractDialCode from 'src/utility/extractDialCode';

const DrawerWrapper = styled(Drawer)(
  ({ theme }) => `
    width: 400px;
    flex-shrink: 0;
    z-index: 3;

    & > .MuiPaper-root {
        width: 400px;
        height: calc(100% - ${theme.header.height});
        position: absolute;
        top: ${theme.header.height};
        right: 0;
        z-index: 3;
        background: ${theme.colors.alpha.white[10]};
    }
`
);

const DrawerWrapperMobile = styled(Drawer)(
  ({ theme }) => `
    width: 360px;
    flex-shrink: 0;

  & > .MuiPaper-root {
        width: 360px;
        z-index: 3;
        background: ${theme.colors.alpha.white[30]};
  }
`
);

const MainContentWrapper = styled(Box)(
  () => `
  flex-grow: 1;
`
);

const IconButtonToggle = styled(IconButton)(
  ({ theme }) => `
  width: ${theme.spacing(6)};
  height: ${theme.spacing(6)};
`
);

function CreateContact() {
  const theme = useTheme();
  const isMountedRef = useRefMounted();
  const [mobileOpen, setMobileOpen] = useState(false);
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { id: contactId } = useParams();

  const [isSaveInProgress, setIsSaveInProgress] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const [error, setError] = useState('');
  const [accountRefData, setAccountRefData] = useState<RefData>({
    contactGroups: [],
    nameTitles: [],
    socialMediaProviders: []
  });

  const { user } = useSelector((state: RootState) => state.app);

  const [model, setModel] = useState<Contact | null>(null);

  const getById = async (contactId: number) => {
    try {
      const response = await getContactById(contactId);
      setIsEdit(true);
      setModel(response.data);
    } catch (ex) {
      console.error(ex);
      const msg = ex.message ? ex.message : `Failed to load contact by id`;
      enqueueSnackbar(t(msg), { variant: 'error' });
      navigate('/app/contact/list');
    }
  };

  useEffect(() => {
    if (contactId && Number(contactId) > 0) {
      setIsLoading(true);
      getById(Number(contactId)).then(() => {
        setIsLoading(false);
      });
    } else {
      setModel(null);
      setIsEdit(false);
    }
  }, [contactId]);

  const loadRefData = useCallback(
    async (callback: (hasError: boolean, data: RefData) => void) => {
      if (isMountedRef.current) {
        const data = await getRefData(
          'contactGroup',
          'nameTitle',
          'socialMediaProvider'
        );
        callback(false, data);
      }
    },
    [isMountedRef]
  );

  useEffect(() => {
    setIsLoading(true);
    loadRefData((hasError, data) => {
      if (data) {
        setAccountRefData({
          contactGroups: data.contactGroups || [],
          nameTitles: data.nameTitles || [],
          socialMediaProviders: data.socialMediaProviders || []
        });
      }
      setIsLoading(false);
    });
  }, [loadRefData]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const onSave = async (updatedModel: Contact) => {
    let isSuccess = false;
    try {
      setIsSaveInProgress(true);
      await saveContact(updatedModel);
      enqueueSnackbar(
        t(`Contact was ${!isEdit ? 'created' : 'updated'} successfully`),
        { variant: 'success' }
      );
      isSuccess = true;
      navigate('/app/contact/list');
      // navigate('/app/menu/manage/food-item', { replace: true });
      setIsSaveInProgress(false);
    } catch (ex) {
      setIsSaveInProgress(false);
      console.log(ex);
      const msg = parseToApiErrorMessage(
        ex,
        `Failed to ${!isEdit ? 'created' : 'updated'} Contact`
      );
      enqueueSnackbar(t(msg), { variant: 'error' });
    }
    return isSuccess;
  };

  const smProviderId = (providerName: string): number | null => {
    const code = accountRefData.socialMediaProviders.find(
      (o) => o.code.toLowerCase() == providerName.toLowerCase()
    );
    return code ? code.id : null;
  };

  const onSaveClick = () => {
    setIsSubmit(true);
  };

  const sidebarContent = (
    <Scrollbar>
      <Sidebar isSubmit={isSubmit} />
    </Scrollbar>
  );
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const urlRegExp =
    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
  const isValidZipRegExp = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

  const address = (model?.addresses || []).find((o) => (o.isPrimary = true));

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          isActive: isEdit ? (model?.isActive === true ? true : false) : true,
          firstName: model?.firstName || '',
          pronouns: model?.pronouns || '',
          middleName: model?.middleName || '',
          lastName: model?.lastName || '',
          imageUrl: model?.imageUrl,
          email: model?.email || '',
          altEmail: model?.altEmail || '',
          contactNumberWork: extractNumber(model?.altContactNumber) || '',
          countryDialCodeWork: extractDialCode(model?.altContactNumber) || '1',
          contactNumberPersonal: extractNumber(model?.contactNumber) || '',
          countryDialCodePersonal: extractDialCode(model?.contactNumber) || '1',
          descriptionRich: model?.descriptionRich || '',
          dateOfBirth: model?.dateOfBirth || null,
          groupTags: model?.groupTags || [],
          linkedInUrl:
            (model?.socialMediaLinks || []).find(
              ({ socialMediaProviderId }) =>
                socialMediaProviderId === smProviderId('linkedIn')
            )?.link || '',
          twitterUrl:
            (model?.socialMediaLinks || []).find(
              ({ socialMediaProviderId }) =>
                socialMediaProviderId === smProviderId('twitter')
            )?.link || '',
          faceBookUrl:
            (model?.socialMediaLinks || []).find(
              ({ socialMediaProviderId }) =>
                socialMediaProviderId === smProviderId('facebook')
            )?.link || '',
          whatsAppUrl:
            (model?.socialMediaLinks || []).find(
              ({ socialMediaProviderId }) =>
                socialMediaProviderId === smProviderId('whatsApp')
            )?.link || '',
          address: {
            addressLine: address?.addressLine || '',
            addressLine2: address?.addressLine2 || '',
            countryCode: address?.countryCode || '',
            stateCode: address?.stateCode || '',
            zipCode: address?.zipCode || ''
          }
        }}
        validationSchema={Yup.object().shape({
          firstName: Yup.string().max(250).required(t('The field is required')),
          lastName: Yup.string().max(250).required(t('The field is required')),
          email: Yup.string()
            .max(250)
            .required(t('The email field is required'))
            .email('Not a proper email')
            .test('Unique Email', 'Email already in use', async (value) => {
              if (!value || isEdit) return true;
              const result = await checkAccountExistsByEmail(value);
              return !result.data;
            }),
          altEmail: Yup.string().max(250).email('Not a proper email'),
          contactNumberWork: Yup.string().matches(
            phoneRegExp,
            'Phone number is not valid'
          ),
          contactNumberPersonal: Yup.string().matches(
            phoneRegExp,
            'Phone number is not valid'
          ),
          countryDialCodeWork: Yup.string(),
          countryDialCodePersonal: Yup.string(),
          linkedInUrl: Yup.string().matches(urlRegExp, 'Enter correct url!'),
          twitterUrl: Yup.string().matches(urlRegExp, 'Enter correct url!'),
          faceBookUrl: Yup.string().matches(urlRegExp, 'Enter correct url!'),
          whatsAppUrl: Yup.string().matches(
            phoneRegExp,
            'Phone number is not valid'
          ),
          zipcode: Yup.string().matches(
            isValidZipRegExp,
            'Enter correct zipcode!'
          )
        })}
        onSubmit={async (
          _values,
          { resetForm, setErrors, setStatus, setSubmitting }
        ) => {
          try {
            const updatedModel: Contact = {
              id: isEdit ? +contactId : 0,
              ..._values,
              isActive: true,
              createdOn: new Date(),
              createdBy: 0
            };

            ///addresses
            let addresses = model?.addresses ? [...model.addresses] : [];

            addresses = addresses.filter((o) => !o.isPrimary);

            const addr: Address = {
              ..._values.address,
              isPrimary: true
            };

            updatedModel.addresses = [addr, ...addresses];
            /////////

            const dialCode = _values.countryDialCodePersonal
              ? '+' + _values.countryDialCodePersonal + ' '
              : '';
            updatedModel.contactNumber =
              dialCode + _values.contactNumberPersonal;

            const dialCode2 = _values.countryDialCodeWork
              ? '+' + _values.countryDialCodeWork + ' '
              : '';
            updatedModel.altContactNumber =
              dialCode2 + _values.contactNumberWork;
            //////

            //socialMediaLinks
            let socialMediaLinks = model?.socialMediaLinks
              ? [...model.socialMediaLinks]
              : [];

            let codeId = smProviderId('facebook');
            if (codeId) {
              socialMediaLinks = socialMediaLinks.filter(
                (o) => codeId != o.socialMediaProviderId
              );
              socialMediaLinks.push({
                id: 0,
                link: _values.faceBookUrl,
                socialMediaProviderId: codeId
              });
            }

            codeId = smProviderId('twitter');
            if (codeId) {
              socialMediaLinks = socialMediaLinks.filter(
                (o) => codeId != o.socialMediaProviderId
              );
              socialMediaLinks.push({
                id: 0,
                link: _values.twitterUrl,
                socialMediaProviderId: codeId
              });
            }

            codeId = smProviderId('whatsapp');
            if (codeId) {
              socialMediaLinks = socialMediaLinks.filter(
                (o) => codeId != o.socialMediaProviderId
              );
              socialMediaLinks.push({
                id: 0,
                link: _values.whatsAppUrl,
                socialMediaProviderId: codeId
              });
            }

            codeId = smProviderId('linkedin');
            if (codeId) {
              socialMediaLinks = socialMediaLinks.filter(
                (o) => codeId != o.socialMediaProviderId
              );
              socialMediaLinks.push({
                id: 0,
                link: _values.linkedInUrl,
                socialMediaProviderId: codeId
              });
            }
            updatedModel.socialMediaLinks = socialMediaLinks;
            //////

            const isSuccess = await onSave(updatedModel);
            if (isSuccess) {
              resetForm();
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err) {
            console.error(err);
            const msg = parseToApiErrorMessage(err, `Failed to save contact`);
            enqueueSnackbar(t(msg), { variant: 'error' });
            setStatus({ success: false });
            setSubmitting(false);
          }
        }}
      >
        {({ handleSubmit, isSubmitting, values }) => (
          <form onSubmit={handleSubmit}>
            <Box mb={3} display="flex">
              <MainContentWrapper>
                <Grid
                  sx={{
                    px: 4
                  }}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="stretch"
                  spacing={4}
                >
                  <Grid item xs={12}>
                    <Box
                      mt={3}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                <PageHeader isEdit={isEdit} contactId={Number(contactId)}/>
                      <Box
                        component="span"
                        sx={{
                          display: { lg: 'none', xs: 'inline-block' }
                        }}
                      >
                        <IconButtonToggle
                          sx={{
                            ml: 2
                          }}
                          color="primary"
                          onClick={handleDrawerToggle}
                          size="small"
                        >
                          <MenuTwoToneIcon />
                        </IconButtonToggle>
                      </Box>
                    </Box>
                  </Grid>
                  {isLoading && (
                    <Grid item xs={12}>
                      <LinearProgress />
                      loading...
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <PersonalDetails accountRefData={accountRefData} />
                  </Grid>
                  <Grid item xs={12}>
                    <GroupInfo accountRefData={accountRefData} />
                  </Grid>
                  <Grid item xs={12}>
                    <ContactInfo isEdit={isEdit} />
                  </Grid>
                  <Grid item xs={12}>
                    <Card
                      sx={{
                        p: 1
                      }}
                    >
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Button
                            type="submit"
                            onClick={() => onSaveClick()}
                            variant="contained"
                            startIcon={
                              isSubmitting || isSaveInProgress ? (
                                <CircularProgress size="1rem" />
                              ) : (
                                <SaveIcon />
                              )
                            }
                            disabled={
                              isLoading || isSubmitting || isSaveInProgress
                            }
                          >
                            Save
                          </Button>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item xs={12}></Grid>
                </Grid>
              </MainContentWrapper>
              <Box
                component="span"
                sx={{
                  display: { lg: 'none', xs: 'inline-block' }
                }}
              >
                <DrawerWrapperMobile
                  variant="temporary"
                  anchor={theme.direction === 'rtl' ? 'left' : 'right'}
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                >
                  {sidebarContent}
                </DrawerWrapperMobile>
              </Box>
              <Box
                component="span"
                sx={{
                  display: { xs: 'none', lg: 'inline-block' }
                }}
              >
                <DrawerWrapper
                  variant="permanent"
                  anchor={theme.direction === 'rtl' ? 'left' : 'right'}
                  open
                >
                  {sidebarContent}
                </DrawerWrapper>
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
}

export default CreateContact;
