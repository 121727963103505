import { Box, Button, Card, Typography, useTheme } from '@mui/material';
import { useRef } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

function PageHeader(props: {}) {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const refLink = useRef(null);
  const onClick = () => {
    // navigate('/app/cards/' + props.cardType + '/new');
  };

  return (
    <Box
      display="flex"
      alignItems={{ xs: 'stretch', md: 'center' }}
      flexDirection={{ xs: 'column', md: 'row' }}
      justifyContent="space-between"
    >
      <Box display="flex" alignItems="center">
        <Box>
          <Typography variant="h3" component="h3" gutterBottom>
            {t('Upload Contacts')}
          </Typography>
          <Typography variant="subtitle2">
            {t(
              'List all cards issues to you which are mapped to one ore more profile(s)'
            )}
          </Typography>
        </Box>
      </Box>
      <Card
        sx={{
          mt: { xs: 3, lg: 0 }
        }}
      >
        <Button
          sx={{
            mt: { xs: 2, sm: 0 }
          }}
          variant="contained"
          onClick={() => {
            refLink.current?.link?.click();
          }}
        >
          {t('Download template')}
        </Button>
        <CSVLink
          ref={refLink}
          data={[]}
          filename={'contacts.csv'}
          headers={[
            {
              key: 'fullName',
              label: 'fullName'
            },
            {
              key: 'email',
              label: 'email'
            },
            {
              key: 'descriptionRich',
              label: 'descriptionRich'
            },
            {
              key: 'contactNumber',
              label: 'contactNumber'
            }
          ]}
        />
      </Card>
    </Box>
  );
}

export default PageHeader;
