import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import {
  Accordion,
  AccordionDetails,
  Box,
  Grid,
  LinearProgress,
  Typography
} from '@mui/material';
import MuiAccordionSummary, {
  AccordionSummaryProps
} from '@mui/material/AccordionSummary';
import styled from '@mui/material/styles/styled';
import { formatDistance } from 'date-fns';
import { useContext, useState } from 'react';
import { Note } from 'src/CRM/models/note';
import { Task } from 'src/CRM/models/task';
import toLocDate from 'src/utility/toLocDate';
import ActivitiesContext from '../activities-context';
import NoteBlock from '../note-block';
import TaskBlock from '../task-block';

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    justifyContent: 'space-between'
  }
}));

interface Activities extends Task, Note {
  activityType: string;
}

function ActivitiesTab() {
    const { tasks, isLoading, notes, expandedActivityIds, onExpand } = useContext(ActivitiesContext);
  const handleChange =
    (id: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        onExpand('activity', id, isExpanded);
    };
  const sortedActivities: Partial<Activities>[] = [
    ...tasks.map((t) => ({ ...t, activityType: 'task' })),
    ...notes.map((n) => ({ ...n, activityType: 'note' }))
  ].sort((a, b) => {
    return new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime();
  });
  return (
    <Box p={1}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={12}>
          {isLoading && <LinearProgress />}
          {!isLoading && sortedActivities.length == 0 && (
            <Typography variant="subtitle1" gutterBottom>
              No activity to display.
            </Typography>
          )}
          {!isLoading && sortedActivities.length > 0 && (
            <Box>
              {sortedActivities.map((o) => (
                <Accordion
                  key={o.id}
                      expanded={expandedActivityIds.includes( o.id)}
                  onChange={handleChange(o.id)}
                >
                  <AccordionSummary
                    expandIcon={
                      <ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />
                    }
                    aria-controls="panel1bh-content"
                    sx={{ background: '#dfdfdf' }}
                    id={'panel' + o + 'bh-header'}
                  >
                    {o.activityType === 'task' ? (
                      <>
                        <Typography sx={{ marginLeft: 1 }}>
                          Task{' '}
                          {o.assignedTo
                            ? `assigned to ${o.assignedTo.name}`
                            : '(unassigned)'}
                        </Typography>

                        <Typography sx={{ color: 'text.secondary' }}>
                          Due on:{' '}
                          {formatDistance(toLocDate(o.dueDate), new Date(), {
                            addSuffix: true
                          })}
                        </Typography>
                      </>
                    ) : (
                      <>
                        {' '}
                        <Box sx={{ marginLeft: 1 }} display="flex">
                          <Typography variant="h6">Note&nbsp;</Typography>
                          <Typography variant="subtitle1">
                            by {o.createdBy.name}
                          </Typography>
                        </Box>
                        <Typography sx={{ color: 'text.secondary' }}>
                          Added:{' '}
                          {formatDistance(toLocDate(o.createdOn), new Date(), {
                            addSuffix: true
                          })}
                        </Typography>
                      </>
                    )}
                  </AccordionSummary>
                  <AccordionDetails>
                    {o.activityType === 'task' ? (
                      <TaskBlock hideActions={true} task={o as Task} />
                    ) : (
                      <NoteBlock hideActions={true} note={o as Note} />
                    )}
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default ActivitiesTab;
