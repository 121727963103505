import {
    Avatar,
    Box,
    Button,
    Card,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    IconButton,
    Radio,
    RadioGroup,
    Skeleton,
    Slide,
    Stack,
    styled,
    Switch,
    TextField,
    Typography
} from '@mui/material';
import { Field, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { forwardRef, ReactElement, Ref, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import {
    addReviewComment,
    deleteReviewComment,
    getReviewComments,
} from '../../../services/apiService';
import {
    CodeMaster,
    ReviewComment,
    RefData,
    Team
} from '../../../services/apiService/response-models';
import { parseToApiErrorMessage } from '../../../utility/parseToApiErrorMessage';
import SaveIcon from '@mui/icons-material/Save';
import { RootState, useSelector } from '../../../store';
import MemberSelector from 'src/components/member-selector/index';
import { EmployeeListItem } from 'src/services/apiService/response-models/data-views';
import ScheduleTwoToneIcon from '@mui/icons-material/ScheduleTwoTone';
import { format, formatDistance, subDays, subHours, subMinutes } from 'date-fns';
import Scrollbar from 'src/ui-bloom/components/Scrollbar';
import BottomBar from './BottomBar';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import { TransitionProps } from '@mui/material/transitions';
import InfoPanel from '../../../components/info-panel';
import _ from 'lodash';

const DividerWrapper = styled(Divider)(
    ({ theme }) => `
      .MuiDivider-wrapper {
        border-radius: ${theme.general.borderRadiusSm};
        text-transform: none;
        background: ${theme.palette.background.default};
        font-size: ${theme.typography.pxToRem(13)};
        color: ${theme.colors.alpha.black[50]};
      }
`
);

const CardWrapperPrimary = styled(Card)(
    ({ theme }) => `
      background: ${theme.colors.primary.main};
      color: ${theme.palette.primary.contrastText};
      padding: ${theme.spacing(2)};
      border-radius: ${theme.general.borderRadiusXl};
      border-top-right-radius: ${theme.general.borderRadius};
      max-width: 380px;
      display: inline-flex;
`
);

const CardWrapperSecondary = styled(Card)(
    ({ theme }) => `
      background: ${theme.colors.alpha.black[10]};
      color: ${theme.colors.alpha.black[100]};
      padding: ${theme.spacing(2)};
      border-radius: ${theme.general.borderRadiusXl};
      border-top-left-radius: ${theme.general.borderRadius};
      max-width: 380px;
      display: inline-flex;
`
);
const RootWrapper = styled(Box)(
    ({ theme }) => `
       height: calc(100vh - ${theme.header.height});
       display: flex;
`
);

function CommentReviewDialog(props: {
    selfReviewId: string;
    isOpen: boolean;
    onClose: () => void;
    onSave: () => void;
}) {
    const { t }: { t: any } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = useState(props.isOpen);
    const navigate = useNavigate();

    const { user } = useSelector((state: RootState) => state.app);
    const [model, setModel] = useState<ReviewComment[]>([]);
    const [isSaveInProgress, setIsSaveInProgress] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setOpen(props.isOpen);
    }, [props.isOpen]);

    useEffect(() => {
        setModel([]);
        setIsLoading(true);
        loadComments(props.selfReviewId).then(() => {

        });
    }, [props.selfReviewId]);

    const loadComments = async (id: string) => {
        try {
            const response = await getReviewComments(id);
            setModel(response.data);
        } catch (ex) {
            console.error(ex);
            const msg = ex.message ? ex.message : `Failed to load comments`;
            enqueueSnackbar(t(msg), { variant: 'error' });
        }
        setIsLoading(false);
    };



    const handleCreateClose = () => {
        setOpen(false);
        props.onClose();
    };

    const onCommentAdded = async (e: { comment: string, attachement?: string }) => {
        const cm: ReviewComment = {
            id: Date.now() + '',
            selfReviewId: props.selfReviewId,
            comment: e.comment,
            attachement: e.attachement,
            createdBy: '',
            createdOn: new Date()
        }
        setModel([...model, cm]);
        await addReviewComment(cm);
    }

    return (
        <Dialog scroll={'paper'} fullWidth={true} maxWidth={'md'} open={open} onClose={handleCreateClose}>

            <DialogTitle
                sx={{
                    p: 3
                }}
            >
                <Typography variant="h4" gutterBottom>
                    {t('Add Comment')}
                </Typography>
                <Typography variant="subtitle2">
                    {t('Add note or comments to the review')}
                </Typography>
                <IconButton sx={{ position: 'absolute', top: 10, right: 5 }} onClick={handleCreateClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers={true}>
                {isLoading && (<Stack spacing={1}>
                    <Skeleton variant="rectangular" width={'100%'} height={60} />
                    <Skeleton variant="rectangular" width={'100%'} height={60} />
                    <Skeleton variant="rectangular" width={'100%'} height={60} />
                </Stack>)}
                {!isLoading && <CommentContent comments={model} />}
                {!isLoading && model.length == 0 && <Typography
                    variant="subtitle1"
                    sx={{
                        mb: 5,
                        pt: 1,
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    No Comment. Please add your first comment.
                </Typography>}
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'space-between' }}>
                <BottomBar onAdd={onCommentAdded} />
            </DialogActions>
        </Dialog>
    );
}


const DialogWrapper = styled(Dialog)(
    () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);


const Transition = forwardRef(function Transition(
    props: TransitionProps & { children: ReactElement<any, any> },
    ref: Ref<unknown>
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const AvatarError = styled(Avatar)(
    ({ theme }) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

const ButtonError = styled(Button)(
    ({ theme }) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.error.dark};
     }
    `
);


function CommentContent(props: {comments:ReviewComment[]}) {
    const { t }: { t: any } = useTranslation();
    const [deleteCommentId, setDeleteCommentId] = useState('');
    const [comments, setComments] = useState<ReviewComment[]>([]);


    useEffect(() => {
        setComments(props.comments);
    }, [props.comments]);

    const groups = useMemo(() => {
        return _.groupBy(comments, (e => {
            const dt = new Date(e.createdOn);
            return dt.toLocaleString('default', { month: 'long' }) + ' ' + dt.getDate() + ' ' + dt.getFullYear();
        }));
    }, [comments])
    

    const closeConfirmDelete = () => {
        setDeleteCommentId('');
    };

    const handleDeleteCompleted = async () => {
        await deleteReviewComment(deleteCommentId);
        const cm = comments.filter(o => o.id !== deleteCommentId);
        setDeleteCommentId('');
        setComments([...cm]);
    };

    return (<><Box p={2}>
        {Object.keys(groups).map(group => <>
            <DividerWrapper>{group}</DividerWrapper>
            {groups[group].map(item => <Box
                display="flex"
                alignItems="flex-start"
                justifyContent="flex-end"
                py={3}
                key={item.id}
            >
                <Box
                    display="flex"
                    alignItems="flex-end"
                    flexDirection="column"
                    justifyContent="flex-end"
                    mr={2}
                >
                    <CardWrapperPrimary>
                        {item.comment}
                    </CardWrapperPrimary>
                    <Typography
                        variant="subtitle1"
                        sx={{
                            pt: 1,
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <ScheduleTwoToneIcon
                            sx={{
                                mr: 0.5
                            }}
                            fontSize="small"
                        />
                        {formatDistance(new Date(item.createdOn), new Date(), {
                            addSuffix: true
                        })}, by you
                    </Typography>
                </Box>
                <IconButton onClick={() => setDeleteCommentId(item.id)} aria-label="delete" color='error' size="large">
                    <DeleteIcon />
                </IconButton>
            </Box>)}
        </>)}
    </Box>
        <DialogWrapper
            open={Boolean(deleteCommentId.length)}
            maxWidth="sm"
            fullWidth
            TransitionComponent={Transition}
            keepMounted
            onClose={closeConfirmDelete}
        >
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                p={5}
            >
                <AvatarError>
                    <CloseIcon />
                </AvatarError>

                <Typography
                    align="center"
                    sx={{
                        py: 4,
                        px: 6
                    }}
                    variant="h3"
                >
                    {t('Are you sure you want to permanently delete this user account')}
                    ?
                </Typography>

                <Box>
                    <Button
                        variant="text"
                        size="large"
                        sx={{
                            mx: 1
                        }}
                        onClick={closeConfirmDelete}
                    >
                        {t('Cancel')}
                    </Button>
                    <ButtonError
                        onClick={handleDeleteCompleted}
                        size="large"
                        sx={{
                            mx: 1,
                            px: 3
                        }}
                        variant="contained"
                    >
                        {t('Delete')}
                    </ButtonError>
                </Box>
            </Box>
        </DialogWrapper>
        </>)
}

export default CommentReviewDialog;
