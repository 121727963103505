import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import 'react-quill/dist/quill.snow.css';

import {
  styled,
  Grid,
  Dialog,
  DialogTitle,
  Divider,
  Alert,
  Chip,
  DialogContent,
  Box,
  Zoom,
  ListItem,
  List,
  ListItemText,
  Typography,
  TextField,
  CircularProgress,
  Avatar,
  Autocomplete,
  Button,
  useTheme,
  FormControlLabel,
  Switch,
  LinearProgress
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { Settings } from '.';
import { EmployeeListItem } from 'src/services/apiService/response-models/data-views';
import { set } from 'nprogress';

function LeadCategoryEditorDialog(props: {
  open: boolean;
  onClose: () => void;
  onSaved: (settings: Settings) => Promise<void>;
  employeeListItems: EmployeeListItem[];
  isSaving: boolean;
  editLeadCategory: Settings;
}) {
  const { t }: { t: any } = useTranslation();
  const [open, setOpen] = useState(props.open);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const handleCreateDialogClose = () => {
    props.onClose();
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={handleCreateDialogClose}
      >
        {props.isSaving && (
          <LinearProgress
            sx={{
              width: '100%'
            }}
          />
        )}
        <DialogTitle
          sx={{
            p: 3
          }}
        >
          <Typography variant="h4" gutterBottom>
            {t('Create lead category')}
          </Typography>
          <Typography variant="subtitle2">
            {t('Use this dialog window to add a new lead category')}
          </Typography>
        </DialogTitle>
        <Formik
          initialValues={{
            category: props.editLeadCategory?.category || '',
            assignToAdmin: props.editLeadCategory?.assignToAdmin || false,
            assignToOwner: props.editLeadCategory?.assignToOwner || false,
            assignToMember: (props.editLeadCategory?.assignTo ||
              undefined) as any,
            submit: false
          }}
          validationSchema={Yup.object().shape({
            category: Yup.string()
              .max(255)
              .required(t('The title field is required')),
            assignToMember: Yup.object().when(
              ['assignToAdmin', 'assignToOwner'],
              {
                is: (assignToAdmin: boolean, assignToOwner: boolean) =>
                  !assignToAdmin && !assignToOwner,
                then: Yup.object().required(
                  t('The assign to field is required')
                )
              }
            )
          })}
          onSubmit={async (
            _values,
            { resetForm, setErrors, setStatus, setSubmitting }
          ) => {
            try {
              setStatus({ success: true });
              setSubmitting(false);
              await props.onSaved({
                category: _values.category,
                assignToAdmin: _values.assignToAdmin,
                assignToOwner: _values.assignToOwner,
                assignTo: Number(_values.assignToMember?.id) || null
              });
              resetForm();
            } catch (err) {
              console.error(err);
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setFieldValue
          }) => (
            <form onSubmit={handleSubmit}>
              <DialogContent
                dividers
                sx={{
                  p: 3
                }}
              >
                <Grid container spacing={0}>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={3}
                    justifyContent="flex-end"
                    textAlign={{ sm: 'right' }}
                  >
                    <Box
                      pr={3}
                      sx={{
                        pt: `${theme.spacing(2)}`,
                        pb: { xs: 1, md: 0 }
                      }}
                      alignSelf="center"
                    >
                      <b>{t('Category Name')}:</b>
                    </Box>
                  </Grid>
                  <Grid
                    sx={{
                      mb: `${theme.spacing(3)}`
                    }}
                    item
                    xs={12}
                    sm={8}
                    md={9}
                  >
                    <TextField
                      error={Boolean(touched.category && errors.category)}
                      fullWidth
                      helperText={touched.category && errors.category}
                      name="title"
                      placeholder={t('Category name here...')}
                      onBlur={handleBlur}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('category', e.target.value);
                      }}
                      value={values.category}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                    <Box
                      pr={3}
                      sx={{
                        pb: { xs: 1, md: 0 }
                      }}
                    >
                      <b>{t('Assign To Admin?')}:</b>
                    </Box>
                  </Grid>
                  <Grid
                    sx={{
                      mb: `${theme.spacing(3)}`
                    }}
                    item
                    xs={12}
                    sm={8}
                    md={9}
                  >
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          checked={values.assignToAdmin}
                        />
                      }
                      label={''}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('assignToAdmin', e.target.checked);
                        setFieldValue('assignToOwner', false);
                        setFieldValue('assignToMember', undefined);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                    <Box
                      pr={3}
                      sx={{
                        pb: { xs: 1, md: 0 }
                      }}
                    >
                      <b>{t('Assign To Lead Owner?')}:</b>
                    </Box>
                  </Grid>
                  <Grid
                    sx={{
                      mb: `${theme.spacing(3)}`
                    }}
                    item
                    xs={12}
                    sm={8}
                    md={9}
                  >
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          checked={values.assignToOwner}
                        />
                      }
                      label={''}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('assignToOwner', e.target.checked);
                        setFieldValue('assignToAdmin', false);
                        setFieldValue('assignToMember', undefined);
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={3}
                    justifyContent="flex-end"
                    textAlign={{ sm: 'right' }}
                  >
                    <Box
                      pr={3}
                      sx={{
                        pt: `${theme.spacing(2)}`,
                        pb: { xs: 1, md: 0 }
                      }}
                      alignSelf="center"
                    >
                      <b>{t('Assign Member')}:</b>
                    </Box>
                  </Grid>
                  <Grid
                    sx={{
                      mb: `${theme.spacing(3)}`
                    }}
                    item
                    xs={12}
                    sm={8}
                    md={9}
                  >
                    <Autocomplete
                      sx={{
                        m: 0
                      }}
                      onChange={(e: any, value: any) => {
                        setFieldValue('assignToMember', value);
                      }}
                      disabled={values.assignToAdmin || values.assignToOwner}
                      limitTags={1}
                      // @ts-ignore
                      getOptionLabel={(option) => option.fullName}
                      options={props.employeeListItems}
                      renderOption={(props, option) => (
                        <li {...props}>
                          <Avatar
                            sx={{
                              mr: 1
                            }}
                            src={
                              option.imageUrl || '/static/images/avatars/3.jpg'
                            }
                          />
                          {option.fullName} ({option.email})
                        </li>
                      )}
                      value={values.assignToMember}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          fullWidth
                          InputLabelProps={{
                            shrink: true
                          }}
                          placeholder={t('Select project members...')}
                          helperText={
                            touched.assignToMember && errors.assignToMember
                          }
                          error={Boolean(
                            touched.assignToMember && errors.assignToMember
                          )}
                        />
                      )}
                      renderTags={(members, getTagProps) =>
                        members.map((ev, index: number) => (
                          <Chip
                            key={ev.fullName}
                            label={ev.fullName}
                            {...getTagProps({ index })}
                            avatar={
                              <Avatar
                                src={
                                  ev.imageUrl || '/static/images/avatars/3.jpg'
                                }
                              />
                            }
                          />
                        ))
                      }
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={3}
                    textAlign={{ sm: 'right' }}
                  />
                  <Grid
                    sx={{
                      mb: `${theme.spacing(3)}`
                    }}
                    item
                    xs={12}
                    sm={8}
                    md={9}
                  >
                    <Button
                      sx={{
                        mr: 2
                      }}
                      type="submit"
                      startIcon={
                        props.isSaving ? <CircularProgress size="1rem" /> : null
                      }
                      disabled={props.isSaving}
                      variant="contained"
                      size="large"
                    >
                      {t('Save')}
                    </Button>
                    <Button
                      color="secondary"
                      size="large"
                      variant="outlined"
                      onClick={handleCreateDialogClose}
                    >
                      {t('Cancel')}
                    </Button>
                  </Grid>
                </Grid>
              </DialogContent>
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}

export default LeadCategoryEditorDialog;
