import { Typography, useTheme } from '@mui/material';
import PreField from '../../models/PreField';
import { getValueDesc } from '../utils/getValueDesc';

const HeadlineRenderer = ({
  field,
  themeColor
}: {
  field: PreField;
  themeColor: string;
}) => {
  const theme = useTheme();

  return (
    <Typography
      variant="subtitle2"
      sx={{
        fontSize: `${theme.typography.pxToRem(16)}`,
        py: 1,
        color:
          themeColor === 'light'
            ? `${theme.colors.alpha.black[70]}`
            : `${theme.colors.alpha.trueWhite[70]}`
      }}
    >
      {getValueDesc(field)}
    </Typography>
  );
};

export default HeadlineRenderer;
