import {
  alpha,
  Box,
  Card,
  Avatar,
  darken,
  Grid,
  CardMedia,
  Typography,
  IconButton,
  Button,
  styled,
  useTheme,
  Skeleton,
  Tooltip
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import AccountBoxTwoToneIcon from '@mui/icons-material/AccountBoxTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import getThemeColor from '../../utility/getThemeColor';
import { DigitalCardListItem } from '../../services/apiService/response-models';
import ProfileTypes from 'src/utility/profile-types';
import { convertCardToProfile } from 'src/utility/convertCardToProfile';
import cdnService from 'src/services/cdnService';
import QrCode2TwoToneIcon from '@mui/icons-material/QrCode2TwoTone';
import { FC } from 'react';
import { convertCardItemToCard } from 'src/utility/convertCardItemToCard';
import DescText from '../desc-text';

const BgComposed = styled(Box)(
  ({ theme }) => `
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transition: ${theme.transitions.create(['opacity'])};
    z-index: 6;
    opacity: 1;
   
  `
);

const CardWrapper = styled(Card)(
  ({ theme }) => `

    background: ${theme.colors.gradients.blue1};
    color:  ${theme.colors.alpha.trueWhite[100]};
    border-radius: ${theme.general.borderRadiusXl};
    padding: ${theme.spacing(1)};
    box-shadow: none;

    .MuiCardHeader-root {
      .MuiCardHeader-title {
          color: ${theme.colors.alpha.trueWhite[100]};;
          font-weight: normal;
          padding-bottom: ${theme.spacing(0.5)};
      }
    }
    
    .MuiCardHeader-subheader {
        color:  ${theme.colors.alpha.trueWhite[100]};;
    }

      .MuiActionButtons {
            background: ${alpha(theme.colors.alpha.white[100], 0.95)};
            border-radius: ${theme.general.borderRadius};
            opacity: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            visibility: hidden;
            top: 15%;
            margin-top: -${theme.spacing(3.5)};
            position: absolute;
            right: ${theme.spacing(1.5)};
            padding: ${theme.spacing(0.5, 1)};
            transition: ${theme.transitions.create(['visibility', 'opacity'])};
            z-index:100;
            .MuiIconButton-root {
                border-radius: 100px;
                width: ${theme.spacing(5)};
                height: ${theme.spacing(5)};
                margin: ${theme.spacing(0.5)};
            }
      }

      &:hover {
          .MuiActionButtons {
            visibility: visible;
            opacity: 1;
      }   
      }
  `
);

const CardActions = styled(Box)(
  ({ theme }) => `
    position: absolute;
    right: ${theme.spacing(2)};
    bottom: ${theme.spacing(2)};
    z-index: 7;
  `
);

const Label = styled(Box)(
  ({ theme }) => `
    background: ${theme.palette.success.main};
    color: ${theme.palette.success.contrastText};
    text-transform: uppercase;
    font-size: ${theme.typography.pxToRem(10)};
    font-weight: bold;
    line-height: 23px;
    height: 22px;
    padding: ${theme.spacing(0, 1.2)};
    border-radius: 50px;
  `
);

const DefaultCardThumb = (props: {
  item: DigitalCardListItem;
  onClick: (item: DigitalCardListItem) => void;
  showDelete?: boolean;
  actionLabel?: string;
  onDeleteClick?: (item: DigitalCardListItem) => void;
  onSelect?: (item: DigitalCardListItem) => void;
}) => {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();

  const profile = convertCardToProfile(convertCardItemToCard(props.item));

  const isCompany = props.item.cardType == 'Business';
  const tpTiltle = props.onSelect ? 'Click to select' : '';

  return (
    <CardWrapperThemed isCompany={isCompany}>
      {props.showDelete && (
        <Box className="MuiActionButtons">
          <Tooltip arrow placement="top" title={t('Delete profile')}>
            <IconButton
              onClick={() => props.onDeleteClick(props.item)}
              color="error"
            >
              <CloseTwoToneIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      )}
      <BgComposed
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        className="MuiBgComposed"
        sx={{ cursor: props.onSelect ? 'pointer' : 'inherit' }}
        onClick={() => (props.onSelect ? props.onSelect(props.item) : null)}
      >
        <Tooltip title={tpTiltle} placement="top">
          <Avatar
            sx={{
              width: 62,
              height: 62,
              mb: 1,
              border: `${theme.colors.alpha.trueWhite[100]} solid 3px`
            }}
            src={cdnService.toImageUrl(profile.image)}
            variant={isCompany ? 'rounded' : 'circular'}
          />
        </Tooltip>
        <Tooltip title={tpTiltle} placement="top">
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              color: `${theme.colors.alpha.trueWhite[100]}`
            }}
          >
            {profile.name}
          </Typography>
        </Tooltip>
        <Tooltip title={tpTiltle} placement="top">
          <Typography
            variant="subtitle2"
            sx={{
              mb: 1,
              color: `${theme.colors.alpha.trueWhite[70]}`
            }}
          >
            <DescText
              text={
                isCompany
                  ? profile.caption
                  : profile.caption || profile.jobTitle || profile.department
              }
              maxLen={70}
            />
          </Typography>
        </Tooltip>
        {profile.qrName && (
          <Tooltip title={tpTiltle || 'Qr code'} placement="top">
            <Box display="flex" alignItems="center" justifyContent="center">
              <Avatar
                sx={{
                  width: 40,
                  height: 40,
                  background: `${theme.colors.alpha.black[100]}`,
                  color: `${theme.colors.alpha.white[100]}`
                }}
              >
                <QrCode2TwoToneIcon fontSize="small" />
              </Avatar>

              <span style={{ marginLeft: 5 }}> {profile.qrName}</span>
            </Box>
          </Tooltip>
        )}
        {/* <Button
          sx={{
            px: 2.5,
            borderRadius: 10,
            transform: 'scale(1)',
            transition: `${theme.transitions.create(['all'])}`,
            boxShadow: `${theme.colors.shadows.info}`,

            '&:hover': {
              transform: 'scale(1.05)',
              boxShadow: `${theme.colors.shadows.info}`
            },
            '&:active': {
              boxShadow: 'none'
            }
          }}
          variant="contained"
          startIcon={<AccountBoxTwoToneIcon />}
          color="info"
          onClick={() => props.onClick(props.item)}
        >
          {t(props.actionLabel || 'View card')}
        </Button> */}
      </BgComposed>

      <CardActions
        sx={{
          bottom: 'auto',
          top: `${theme.spacing(2)}`,
          right: 'auto',
          left: `${theme.spacing(2)}`
        }}
      >
        <Label
          sx={{
            background: `${theme.palette.info.main}`,
            color: `${theme.palette.info.contrastText}`
          }}
        >
          {t(isCompany ? ProfileTypes.Business : ProfileTypes.Individual)}
        </Label>
      </CardActions>
    </CardWrapperThemed>
  );
};

const CardWrapperThemed: FC<{ isCompany: boolean }> = ({
  children,
  isCompany
}) => {
  const theme = useTheme();
  if (isCompany) {
    return <CardWrapperBlue>{children}</CardWrapperBlue>;
  }
  return <CardWrapperPink>{children}</CardWrapperPink>;
};

const CardWrapperBlue = ({ children }) => {
  const theme = useTheme();
  return (
    <CardWrapper
      sx={{
        height: '260px',
        textAlign: 'center',
        position: 'relative',
        transition: `${theme.transitions.create(['box-shadow', 'transform'])}`,
        background: getThemeColor(theme, 'blue'),

        transform: 'translateY(0px)',

        '&:hover': {
          transform: `translateY(-${theme.spacing(0.5)})`,
          boxShadow: `0 2rem 8rem 0 ${alpha(
            theme.colors.alpha.black[100],
            0.05
          )}, 
                        0 0.6rem 1.6rem ${alpha(
                          theme.colors.alpha.black[100],
                          0.15
                        )}, 
                        0 0.2rem 0.2rem ${alpha(
                          theme.colors.alpha.black[100],
                          0.1
                        )}`,

          '& .MuiBgComposed': {
            opacity: 1
          }
        }
      }}
    >
      {children}
    </CardWrapper>
  );
};

const CardWrapperPink = ({ children }) => {
  const theme = useTheme();
  return (
    <CardWrapper
      sx={{
        height: '260px',
        textAlign: 'center',
        position: 'relative',
        transition: `${theme.transitions.create(['box-shadow', 'transform'])}`,
        // background: getThemeColor(theme, card.theme),
        background:
          'linear-gradient(135deg, rgb(247, 97, 161) 0%, rgb(140, 27, 171) 100%)',
        transform: 'translateY(0px)',

        '&:hover': {
          transform: `translateY(-${theme.spacing(0.5)})`,
          boxShadow: `0 2rem 8rem 0 ${alpha(
            theme.colors.alpha.black[100],
            0.05
          )}, 
              0 0.6rem 1.6rem ${alpha(theme.colors.alpha.black[100], 0.15)}, 
              0 0.2rem 0.2rem ${alpha(theme.colors.alpha.black[100], 0.1)}`,

          '& .MuiBgComposed': {
            opacity: 1
          }
        }
      }}
    >
      {children}
    </CardWrapper>
  );
};
export default DefaultCardThumb;
