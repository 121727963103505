import ChatTwoToneIcon from '@mui/icons-material/ChatTwoTone';
import {
  default as EditTwoToneIcon,
  default as LaunchTwoToneIcon
} from '@mui/icons-material/EditTwoTone';
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';

import {
  Avatar,
  Box,
  Card,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  LinearProgress,
  Link,
  Menu,
  MenuItem,
  Select,
  TablePagination,
  TextField,
  Tooltip,
  Typography,
  styled
} from '@mui/material';
import type { ChangeEvent, FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import ShowMoreText from 'react-show-more-text';

import clsx from 'clsx';
import { differenceInDays, formatDistance } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReviewSaveModel } from 'src/BusinessApp/services/models/review-app-models';
import { getReviewAppsDataByClient } from 'src/services/apiService';
import {
  RefData,
  ReviewStatusType
} from 'src/services/apiService/response-models';
import { RootState } from 'src/store';
import Label from 'src/ui-bloom/components/Label';
import useRefMounted from 'src/ui-bloom/hooks/useRefMounted';
import { EmployeeListItem } from '../../../services/apiService/response-models/data-views';
import toLocDate from '../../../utility/toLocDate';
import CommentReviewDialog from '../comment-dialog';
import EditReviewDialog from '../edit-review-dialog';

const CardWrapper = styled(Card)(
  ({ theme }) => `

  position: relative;
  overflow: visible;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: inherit;
    z-index: 1;
    transition: ${theme.transitions.create(['box-shadow'])};
  }
      
    &.Mui-selected::after {
      box-shadow: 0 0 0 3px ${theme.colors.primary.main};
    }
  `
);

interface ResultsProps {
  isLoading: boolean;
  accountRefData: RefData;
  employeeListItems: EmployeeListItem[];
}

interface Filters {
  status?: ReviewStatusType;
}

export const getStatusLabel = (item: ReviewSaveModel): JSX.Element => {
  const map = {
    Open: {
      text: 'Open',
      color: 'warning'
    },
    InProgress: {
      text: 'In progress',
      color: 'primary'
    },
    Close: {
      text: 'Close',
      color: 'success'
    }
  };

  const { text, color }: any = map[item.status] ?? {};

  return (
    <Label color={color}>
      <b>{text}</b>
    </Label>
  );
};

const applyFilters = (
  items: ReviewSaveModel[],
  query: string,
  filters: Filters,
  queryLastUpdated: string
): ReviewSaveModel[] => {
  return items.filter((item) => {
    let matches = true;

    if (query) {
      const properties = [
        'name',
        'profileEmail',
        'profileName',
        'email',
        'contactNumber'
      ];
      let containsQuery = false;

      properties.forEach((property) => {
        if (item[property].toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      });

      if (!containsQuery) {
        matches = false;
      }
    }
    if (filters.status && item.status !== filters.status) {
      matches = false;
    }

    Object.keys(filters).forEach((key) => {
      const value = filters[key];

      if (value && item[key] !== value) {
        matches = false;
      }
    });

    if (queryLastUpdated) {
      const dy = differenceInDays(
        new Date(),
        toLocDate(item.updatedOn || item.createdOn)
      );
      if (dy < Number(queryLastUpdated) - 1) matches = false;
    }

    return matches;
  });
};

// const applyPagination = (
//   items: SelfReview[],
//   page: number,
//   limit: number
// ): SelfReview[] => {
//   return items.slice(page * limit, page * limit + limit);
// };

const Results: FC<ResultsProps> = (props) => {
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(props.isLoading);
  const { selfReviewStatus } = props.accountRefData;
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(50);
  const [total, setTotal] = useState<number>(0);
  const [query, setQuery] = useState<string>('');
  const [reviews, setReviews] = useState<ReviewSaveModel[]>([]);
  const [queryLastUpdated, setQueryLastUpdated] = useState<string>('');
  const [filters, setFilters] = useState<Filters>({
    status: null
  });
  const [commentDialogOpen, setCommentDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [reviewItemToEdit, setReviewItemToEdit] =
    useState<ReviewSaveModel | null>(null);
  const { user } = useSelector((state: RootState) => state.app);

  const isMountedRef = useRefMounted();

  const getReviews = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await getReviewAppsDataByClient(
        user.clientAccountId,
        page + 1,
        limit
      );
      if (isMountedRef.current) {
        const { docs, totalDocs } = response.data;
        setReviews(docs);
        setTotal(totalDocs);
      }
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  }, [isMountedRef, page, limit]);

  useEffect(() => {
    getReviews();
  }, [getReviews]);

  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
    event.persist();
    setQuery(event.target.value);
  };

  const handleStatusChange = (e: ChangeEvent<HTMLInputElement>): void => {
    let value = null;

    if (e.target.value !== 'all') {
      value = e.target.value;
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      status: value
    }));
  };

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const filteredItems = applyFilters(reviews, query, filters, queryLastUpdated);
  const paginatedItems = filteredItems; //applyPagination(filteredItems, page, limit);
  const selectedBulkActions = selectedItems.length > 0;

  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

  return (
    <>
      <Card
        sx={{
          p: 2,
          mb: 3
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          {!selectedBulkActions && (
            <Grid alignItems="center" container spacing={3}>
              <Grid item xs={12} lg={7} md={6}>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchTwoToneIcon />
                      </InputAdornment>
                    )
                  }}
                  sx={{
                    m: 0
                  }}
                  onChange={handleQueryChange}
                  placeholder={t(
                    'Search reviews by name, contact number, email ...'
                  )}
                  value={query}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} lg={3} md={3}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>{t('Status')}</InputLabel>
                  <Select
                    value={filters.status || 'all'}
                    onChange={handleStatusChange}
                    label={t('Status')}
                  >
                    <MenuItem value="all">{t('All')}</MenuItem>
                    {selfReviewStatus.map((statusOption) => (
                      <MenuItem
                        key={statusOption.code}
                        value={statusOption.code}
                      >
                        {statusOption.value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={2} md={3}>
                <TextField
                  sx={{
                    m: 0
                  }}
                  label="Last Updated"
                  type="number"
                  InputProps={{ inputProps: { min: 0, max: 9999 } }}
                  onChange={(e) => setQueryLastUpdated(e.target.value)}
                  placeholder={t('number of day ago')}
                  value={queryLastUpdated}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
            </Grid>
          )}
        </Box>
      </Card>

      <Card>
        {isLoading && <LinearProgress sx={{ width: '100%' }} />}

        {paginatedItems.length === 0 ? (
          <Typography
            sx={{
              py: 10
            }}
            variant="h3"
            fontWeight="normal"
            color="text.secondary"
            align="center"
          >
            {t(
              isLoading
                ? 'Loading reviews...'
                : "We couldn't find any reviews matching your search criteria"
            )}
          </Typography>
        ) : null}
      </Card>

      {paginatedItems.length > 0 ? (
        <Grid container spacing={3}>
          {paginatedItems.map((item) => {
            const isUserSelected = selectedItems.includes(item.id);
            return (
              <Grid item xs={12} sm={6} md={4} key={item.id}>
                <CardWrapper
                  className={clsx({
                    'Mui-selected': isUserSelected
                  })}
                >
                  <Box
                    sx={{
                      position: 'relative',
                      zIndex: '2'
                    }}
                  >
                    <Box
                      px={2}
                      pt={2}
                      display="flex"
                      alignItems="flex-start"
                      justifyContent="space-between"
                    >
                      {getStatusLabel(item)}
                      <Tooltip title={t('View')} arrow>
                        <IconButton
                          component={RouterLink}
                          to={
                            `/${
                              location.pathname.split('/')[1]
                            }/review/detail/` + item._id
                          }
                          color="primary"
                        >
                          <LaunchTwoToneIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      {/* <MoreActionsButton
                        onClick={(e) => {
                          setReviewItemToEdit(item);
                          if (e == 'comment') {
                            setCommentDialogOpen(true);
                          } else {
                            setEditDialogOpen(true);
                          }
                        }}
                      /> */}
                    </Box>

                    <Box p={2} display="flex" alignItems="flex-start">
                      <Avatar
                        sx={{
                          width: 50,
                          height: 50,
                          mr: 2
                        }}
                      />
                      <Box sx={{ overflow: 'hidden' }}>
                        {(item.name || item.email || item.contactNumber) && (
                          <Typography
                            sx={{
                              pt: 1
                            }}
                            variant="h6"
                          >
                            By: {item.name}, {item.email}, {item.contactNumber}
                          </Typography>
                        )}
                        <Link
                          variant="subtitle2"
                          underline="none"
                          component={RouterLink}
                          to={`/${location.pathname.split('/')[1]}/cards/edit/${
                            item.profileId
                          }`}
                        >
                          via: {item.profileName}
                        </Link>
                        <Typography
                          component="div"
                          variant="body2"
                          color="text.secondary"
                        >
                          on: {toLocDate(item.createdOn)?.toLocaleDateString()}
                        </Typography>
                        <Typography
                          sx={{
                            pt: 1
                          }}
                          variant="h6"
                        >
                          {'owner: '}
                          {item.profileEmail}
                        </Typography>
                        <Typography
                          sx={{
                            pt: 1
                          }}
                          variant="h6"
                        >
                          {'Review Template: '}
                          {item.reviewTemplate === 'classic'
                            ? 'Classic'
                            : 'Hotel'}
                        </Typography>
                        {!!item?.rating && (
                          <Typography
                            sx={{
                              pt: 1
                            }}
                            variant="h6"
                          >
                            {'Rating: '}
                            {item.rating}
                          </Typography>
                        )}
                        {item?.comment && (
                          <Typography>
                            Remarks:{' '}
                            <ShowMoreText
                              /* Default options */
                              lines={1}
                              more="Show more"
                              less="Show less"
                              className="content-css"
                              anchorClass="show-more-less-clickable"
                              truncatedEndingComponent={'... '}
                            >
                              {item.comment}
                            </ShowMoreText>
                          </Typography>
                        )}
                      </Box>
                    </Box>
                    <Divider />
                    <Box
                      pl={2}
                      py={1}
                      pr={1}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography>
                        last updated:{' '}
                        <b>
                          {formatDistance(
                            toLocDate(item?.updatedOn || item.createdOn),
                            new Date(),
                            {
                              addSuffix: true
                            }
                          )}
                        </b>
                      </Typography>

                      {/*<Checkbox*/}
                      {/*    checked={isUserSelected}*/}
                      {/*    onChange={(event) =>*/}
                      {/*        handleSelectOneInvoice(event, item.reviewId)*/}
                      {/*    }*/}
                      {/*    value={isUserSelected}*/}
                      {/*/>*/}
                    </Box>
                  </Box>
                </CardWrapper>
              </Grid>
            );
          })}
        </Grid>
      ) : null}

      <Card
        sx={{
          p: 2,
          mt: 3,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Box>
          <Typography component="span" variant="subtitle1">
            {t('Showing')}:
          </Typography>{' '}
          <b>{paginatedItems.length}</b> <b>{t('reviews')}</b>
        </Box>
        <TablePagination
          component="div"
          count={total}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[50, 100, 150]}
          showFirstButton
          showLastButton
        />
      </Card>

      {reviewItemToEdit && (
        <CommentReviewDialog
          selfReviewId={reviewItemToEdit.id}
          isOpen={commentDialogOpen}
          onClose={() => setCommentDialogOpen(false)}
          onSave={() => setCommentDialogOpen(false)}
        />
      )}
      {reviewItemToEdit && (
        <EditReviewDialog
          reviewId={reviewItemToEdit.id}
          members={props.employeeListItems}
          reviewStatusRefData={selfReviewStatus}
          employeeId={1} // reviewItemToEdit?.employeeId
          status={reviewItemToEdit.status}
          isOpen={editDialogOpen}
          onClose={() => setEditDialogOpen(false)}
          onSave={() => {
            setEditDialogOpen(false);
            getReviews();
          }}
        />
      )}
    </>
  );
};

function MoreActionsButton(props: { onClick: (e: string) => void }) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        onClick={handleClick}
        color="primary"
        sx={{
          p: 0.5
        }}
      >
        <MoreVertTwoToneIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => props.onClick('edit')}>
          <EditTwoToneIcon fontSize="small" />
          &nbsp;&nbsp;Edit
        </MenuItem>
        <MenuItem onClick={() => props.onClick('comment')}>
          <ChatTwoToneIcon fontSize="small" />
          &nbsp;&nbsp;Comment
        </MenuItem>
      </Menu>
    </>
  );
}
export default Results;
