import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardMedia,
  Divider,
  Grid,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
  alpha,
  styled,
  useMediaQuery,
  useTheme
} from '@mui/material';
import _, { Dictionary } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable
} from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import {
  CardFieldNames,
  CardTypes,
  FormFieldValue
} from 'src/services/apiService/response-models';
import FieldRenderBlockBaseProp from '../../../components/field-renderers/_base/FieldRenderBlockBaseProp';
import FieldsRegistry from '../../../components/field-renderers/_base/FieldsRegistry';
import cdnService from '../../../services/cdnService';
import getThemeColor from '../../../utility/getThemeColor';
import PreField from '../models/PreField';
import PreviewCardModel from '../models/PreviewCardModel';
import FieldPopper from './field-popper';
import AccreditationRenderer from './field-renderers/Accreditation-renderer';
import CompanyNameRenderer from './field-renderers/CompanyName-renderer';
import DepartmentRenderer from './field-renderers/Department-renderer';
import HeadlineRenderer from './field-renderers/Headline-renderer';
import JobTitleRenderer from './field-renderers/JobTitle-renderer';
import FullNameRenderer from './field-renderers/fullName-renderer';
import './style.css';
import convertFieldToPreField from './utils/convertFieldToPreField';
import { getFixedFields } from './utils/getFixedFields';
import { getScroll } from './utils/getScroll';
import { getTitleDesc } from './utils/getTitleDesc';
import { getValueDesc } from './utils/getValueDesc';
import { reorderPreFields } from './utils/reorderPreFields';
import { socialFieldNames } from './utils/socailFieldNames';

const LabelWrapper = styled(Box)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(13)};
    font-weight: bold;
    text-transform: uppercase;
    border-radius: ${theme.general.borderRadiusSm};
    padding: ${theme.spacing(0.9, 1.5, 0.7)};
    line-height: 1;
  `
);

const CardActions = styled(Box)(
  ({ theme }) => `
    position: absolute;
    right: ${theme.spacing(1.5)};
    top: ${theme.spacing(1.5)};
    z-index: 7;
  `
);

const FieldWrapper = styled('div')(
  ({ theme }) => `
    width:'100%';
    margin-top:${theme.spacing(1)};
    margin-bottom:${theme.spacing(1)};
 position: relative;

 .MuiActionButtons {
            background: ${alpha(theme.colors.alpha.white[100], 0.95)};
            border-radius: ${theme.general.borderRadius};
            opacity: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            visibility: hidden;
            top: 50%;
            margin-top: -${theme.spacing(3.5)};
            position: absolute;
            z-index:10;
            right: ${theme.spacing(1.5)};
            padding: ${theme.spacing(0.5, 1)};
            transition: ${theme.transitions.create(['visibility', 'opacity'])};
            .MuiIconButton-root {
                border-radius: 100px;
                width: ${theme.spacing(5)};
                height: ${theme.spacing(5)};
                margin: ${theme.spacing(0.5)};
            }
      }

      &:hover {
          background: ${alpha(theme.colors.secondary.main, 0.15)};

          .MuiActionButtons {
            visibility: visible;
            opacity: 1;
      }
    
`
);

const ListItemWrapper = styled(ListItem)(
  ({ theme }) => `
      position: relative;
      transition: ${theme.transitions.create(['background'])};

      .MuiActionButtons {
            background: ${alpha(theme.colors.alpha.white[100], 0.95)};
            border-radius: ${theme.general.borderRadius};
            opacity: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            visibility: hidden;
            top: 50%;
            margin-top: -${theme.spacing(3.5)};
            position: absolute;
            right: ${theme.spacing(1.5)};
            padding: ${theme.spacing(0.5, 1)};
            transition: ${theme.transitions.create(['visibility', 'opacity'])};

            .MuiIconButton-root {
                border-radius: 100px;
                width: ${theme.spacing(5)};
                height: ${theme.spacing(5)};
                margin: ${theme.spacing(0.5)};
            }
      }

      &:hover {
          background: ${alpha(theme.colors.secondary.main, 0.15)};

          .MuiActionButtons {
            visibility: visible;
            opacity: 1;
      }   
      }
  `
);

function PreviewCard(props: PreviewCardModel) {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const [themeColor, setThemeColor] = useState(props.theme || 'blue');
  const [fieldInEdit, setFieldInEdit] = useState<PreField | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [preFields, setPreFields] = useState<PreField[]>([]);
  const [listItemFields, setListItemFields] = useState<PreField[]>([]);

  useEffect(() => {
    const fields = _.sortBy([...props.selectedFields], (o) => o.order);

    //set ids
    const grouped = _.groupBy(fields, (o) => o.fieldName);
    _.keys(grouped).forEach((k) => {
      const items = grouped[k];
      if (items) {
        items.forEach((item, i) => {
          item.id = item.fieldName + '_' + (i + 1);
        });
      }
    });

    setPreFields(fields);
  }, []);

  useEffect(() => {
    if (props.editField) {
      let eField = convertFieldToPreField(props.editField);
      const matches = preFields.filter((o) => o.fieldName == eField.fieldName);
      eField.order = (_.max(preFields.map((o) => o.order)) || 0) + 1;
      if (matches.length == 0) {
        //if new field
        eField.id = eField.fieldName + '_1';
        const updatedFields = [...preFields, eField];
        setPreFields(updatedFields);
      } else {
        //existing field
        let isAddedAsMultiple = false;
        if (eField.fieldRef.multiple == true) {
          //check if allowed multiple for this preview-card
          //it is a Accreditation field or Fixed Field
          if (
            eField.fieldName == CardFieldNames.Accreditation ||
            listItemFields.find((o) => o.fieldName === eField.fieldName)
          ) {
            eField.id = eField.fieldName + '_' + (matches.length + 1);
            const updatedFields = [...preFields, eField];
            setPreFields(updatedFields);
            isAddedAsMultiple = true;
          }
        }
        if (!isAddedAsMultiple) {
          eField = matches[0];
        }
      }

      setTimeout(() => {
        setFieldInEdit(eField);

        const nodes = document.querySelectorAll('.field-wrapper.' + eField.id);
        if (nodes.length) {
          //  (nodes[0] as HTMLDivElement).click();

          //
          setAnchorEl(nodes[0] as HTMLElement);
          if (!mobile) {
            const [x, y] = getScroll();
            if (y > 300) {
              setTimeout(() => {
                const popper = document.getElementById('field-popper');
                popper?.scrollIntoView({ behavior: 'smooth', block: 'center' });
              });
            }
          }
        }
      });
    } else {
      setAnchorEl(null);
    }
  }, [props.editField]);

  const fieldHash = useMemo(() => {
    const fieldHash: Dictionary<PreField> = {};
    preFields.forEach((o) => (fieldHash[o.fieldName] = o));
    return fieldHash;
  }, [preFields]);

  const fixedFields = useMemo(
    () => getFixedFields(fieldHash, props.cardType == CardTypes.Individual),
    [preFields]
  );

  const socialFields = useMemo(
    () =>
      _.sortBy(
        preFields.filter((o) => socialFieldNames.includes(o.fieldName)),
        (o) => o.order
      ),
    [preFields]
  );

  useEffect(() => {
    let items = _.differenceWith(
      preFields,
      fixedFields,
      (one, two) => one.fieldName == two.fieldName
    );
    items = _.differenceWith(
      items,
      socialFields,
      (one, two) => one.fieldName == two.fieldName
    );
    items = _.sortBy(items, (o) => o.order);
    setListItemFields(items);
  }, [preFields]);

  const isPopperOpen = Boolean(anchorEl);

  const onPopperClose = () => {
    setAnchorEl(null);
    if (props.onEditCancel) {
      props.onEditCancel(fieldInEdit, preFields);
    }

    if (!fieldInEdit.fieldValues || !fieldInEdit.fieldValues.length) {
      const newFields = [...preFields.filter((o) => o.id != fieldInEdit.id)];
      setPreFields(newFields);
    }
    setFieldInEdit(null);
  };

  const onPopperOk = (e: PreField, formData: Dictionary<string>) => {
    const formValues: FormFieldValue[] = [];
    for (const [key, value] of Object.entries(formData)) {
      formValues.push({
        name: key,
        value: value
      });
    }

    const updatedField = { ...e, fieldValues: formValues };

    updatedField.fieldValues = formValues;

    const preFieldsClone = [...preFields];

    const index = preFieldsClone.findIndex((o) => o.id == e.id);
    preFieldsClone.splice(index, 1, updatedField);

    setPreFields(preFieldsClone);

    if (props.onEditComplete)
      props.onEditComplete(updatedField, preFieldsClone);

    if (props.onFieldsModified) props.onFieldsModified(preFieldsClone);

    setAnchorEl(null);
    setFieldInEdit(null);
  };

  const onPoppperValueChange = (field: PreField) => {};

  const onPopperDelete = (e: PreField) => {
    onPopperClose();
    let preFieldsClone = [...preFields];
    preFieldsClone = preFieldsClone.filter((o) => o.id != e.id);
    setPreFields(preFieldsClone);

    if (props.onDelete) props.onDelete(e, preFieldsClone);

    if (props.onFieldsModified) props.onFieldsModified(preFieldsClone);
  };

  const changeTheme = (color: string) => {
    setThemeColor(color);
    props.onChangeTheme(color);
  };

  const isActive = (e: PreField) => {
    return fieldInEdit?.id === e.id;
  };

  const onEditClick = (e: React.MouseEvent<HTMLElement>, field: PreField) => {
    setAnchorEl(null);
    setTimeout(() => {
      setFieldInEdit(field);
      setAnchorEl(
        (e.target as any).closest('.field-wrapper.' + field.id) as HTMLElement
      );
    });
  };

  const onDragEnd = ({ destination, source }: DropResult) => {
    // dropped outside the list
    if (!destination) return;

    const resultList = reorderPreFields(
      listItemFields,
      source.index,
      destination.index
    );
    // setListItemFields(resultList);
    const preFieldsClone = [...preFields];
    preFieldsClone.forEach((p) => {
      const match = resultList.find((o) => o.id == p.id);
      if (match) {
        p.order = match.order;
      }
    });
    // setListItemFields(listItemFields);
    setPreFields(preFieldsClone);
    props.onFieldsModified(preFieldsClone);
  };
  const renderFixedField = (field: PreField): JSX.Element => {
    if (!field) return <></>;

    const renderer = () => {
      switch (field.fieldName) {
        case CardFieldNames.FullName:
          return <FullNameRenderer field={field} themeColor={props.theme} />;
        case CardFieldNames.JobTitle:
          return <JobTitleRenderer field={field} themeColor={props.theme} />;
        case CardFieldNames.Department:
          return <DepartmentRenderer field={field} themeColor={props.theme} />;
        case CardFieldNames.BusinessName:
          return <FullNameRenderer field={field} themeColor={props.theme} />;
        case CardFieldNames.CompanyName:
          return <CompanyNameRenderer field={field} themeColor={props.theme} />;
        case CardFieldNames.Headline:
          return <HeadlineRenderer field={field} themeColor={props.theme} />;
        case CardFieldNames.Accreditation:
          return (
            <AccreditationRenderer field={field} themeColor={props.theme} />
          );
        default:
          return (
            <div className="error">Invalid renderer: {field.fieldName}</div>
          );
      }
    };

    return (
      <Grid item xs={12}>
        {
          <FieldWrapper
            className={
              'field-wrapper ' +
              field.id +
              (isActive(field) ? ' active-field' : '')
            }
          >
            {renderer()}
            <Box className="MuiActionButtons">
              <Tooltip
                arrow
                placement="top"
                title={t('Edit ' + field.fieldRef.displayName)}
              >
                <IconButton
                  color="primary"
                  onClick={(e) => onEditClick(e, field)}
                >
                  <EditTwoToneIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
          </FieldWrapper>
        }
      </Grid>
    );
  };

  const renderCoverPhotoField = (): JSX.Element => {
    const hasProfilePic = fieldHash[CardFieldNames.ProfilePic] ? true : false;
    const field = fieldHash[CardFieldNames.CoverPhoto];
    if (!field)
      return hasProfilePic ? (
        <>
          <div style={{ height: 80, width: '100%' }}></div>
        </>
      ) : (
        <></>
      );

    const valueRef = field.fieldValues?.find((o) => o.name == field.fieldName);

    const emptyUrl =
      'data: image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';

    return (
      <CardMedia
        className={
          'cardMediaBg field-wrapper ' +
          field.id +
          (isActive(field) ? ' active-field' : '')
        }
        component="img"
        height="222"
        sx={{
          borderRadius: 'inherit',
          position: 'relative',
          zIndex: 1
        }}
        onClick={(e) => onEditClick(e, field)}
        src={
          valueRef?.value ? cdnService.toImageUrl(valueRef?.value) : emptyUrl
        }
        alt="..."
      />
    );
  };

  const renderProfilePicField = (): JSX.Element => {
    const field = fieldHash[CardFieldNames.ProfilePic];
    if (!field) return <></>;

    const valueRef = field.fieldValues?.find((o) => o.name == field.fieldName);

    return (
      <FieldWrapper
        className={
          'field-wrapper ' + field.id + (isActive(field) ? ' active-field' : '')
        }
        style={{ borderWidth: 0 }}
      >
        {' '}
        <Avatar
          sx={{
            width: 100,
            height: 100,
            mb: 2,
            mx: 'auto',
            mt: `-${theme.spacing(10)}`,
            zIndex: 2,
            boxShadow: `0 .113rem .5rem ${theme.colors.alpha.black[10]}, 0 .126rem .225rem ${theme.colors.alpha.black[30]}`,
            border: `${theme.colors.alpha.white[100]} solid 3px`,
            borderRadius: `${
              props.cardType == CardTypes.Business ? '10px' : ''
            }`
          }}
          onClick={(e) => onEditClick(e, field)}
          src={cdnService.toImageUrl(valueRef?.value)}
        />
      </FieldWrapper>
    );
  };

  const isNewField = (e: PreField): boolean => {
    if (
      e.fieldName == CardFieldNames.MediaPlayer ||
      e.fieldName == CardFieldNames.ImageSlider ||
      e.fieldName == CardFieldNames.GoogleReview
    )
      return true;
    else return false;
  };

  const renderNewFieldBlock = (e: PreField) => {
    const inputProp: FieldRenderBlockBaseProp = {
      field: e,
      isReadOnly: false,
      onClick: () => {},
      themeColor: props.theme
    };
    return FieldsRegistry[e.fieldName].renderBlock(inputProp);
  };
  return (
    <Stack spacing={1}>
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center'
        }}
      >
        <Tooltip title="Change Theme" placement="top">
          <ButtonGroup size="medium">
            <Button
              onClick={() => changeTheme('light')}
              sx={{ background: '#EAEAEA' }}
            ></Button>
            <Button
              onClick={() => changeTheme('black')}
              sx={{ background: theme.colors.gradients.black2 }}
            >
              {' '}
            </Button>
            <Button
              onClick={() => changeTheme('blue')}
              sx={{ background: '#7c80ce' }}
            >
              {' '}
            </Button>
          </ButtonGroup>
        </Tooltip>
      </Box>
      <Card
        variant="outlined"
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          background: `${getThemeColor(theme, themeColor)}`,
          p: 3,
          textAlign: 'center'
        }}
      >
        {renderCoverPhotoField()}
        <CardActions>
          {/* {props.cardId && <MoreActionsButton cardId={props.cardId} />}*/}
        </CardActions>
        {renderProfilePicField()}
        <Grid
          container
          spacing={0}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          {renderFixedField(fieldHash[CardFieldNames.FullName])}
          {renderFixedField(fieldHash[CardFieldNames.JobTitle])}
          {renderFixedField(fieldHash[CardFieldNames.Department])}
          {renderFixedField(fieldHash[CardFieldNames.CompanyName])}
          {renderFixedField(fieldHash[CardFieldNames.BusinessName])}
          {renderFixedField(fieldHash[CardFieldNames.Headline])}
          {preFields
            .filter((o) => o.fieldName == CardFieldNames.Accreditation)
            .map((o) => (
              <React.Fragment key={o.id}>{renderFixedField(o)}</React.Fragment>
            ))}
        </Grid>
        {socialFields.length > 0 && (
          <Box display="flex" justifyContent="center">
            {socialFields.map((field) => (
              <FieldWrapper
                key={field.id}
                className={
                  'field-wrapper ' +
                  field.id +
                  (isActive(field) ? ' active-field' : '')
                }
              >
                {' '}
                <Tooltip
                  arrow
                  placement="top"
                  title={t(field.fieldRef.displayName)}
                >
                  <IconButton
                    onClick={(e) => onEditClick(e, field)}
                    sx={{
                      width: 60,
                      height: 60,
                      color: `${theme.colors.alpha.trueWhite[70]}`,
                      background: `${theme.colors.alpha.trueWhite[10]}`,
                      transition: `${theme.transitions.create(['all'])}`,
                      '&:hover': {
                        color: `${theme.colors.alpha.trueWhite[100]}`,
                        background: `${alpha(
                          theme.colors.alpha.trueWhite[100],
                          0.2
                        )}`
                      },
                      borderRadius: 50,
                      marginRight: '4px'
                    }}
                    size="large"
                  >
                    <Icon>
                      <img
                        src={cdnService.toCdnUrl(field.fieldRef.icon, 'icons')}
                        height={25}
                        width={25}
                      />
                    </Icon>
                  </IconButton>
                </Tooltip>
              </FieldWrapper>
            ))}
          </Box>
        )}
        <List sx={{ width: '100%', marginTop: 2 }} disablePadding>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable-list">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {listItemFields.map((field, index) => (
                    <Draggable
                      key={field.id}
                      draggableId={field.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <FieldWrapper
                            key={field.id}
                            className={
                              'field-wrapper ' +
                              field.id +
                              (isActive(field) ? ' active-field' : '')
                            }
                          >
                            {!isNewField(field) ? (
                              <ListItemWrapper
                                sx={{
                                  minWidth: 0
                                }}
                              >
                                <Box className="MuiActionButtons">
                                  <Tooltip
                                    arrow
                                    placement="top"
                                    title={t(
                                      'Edit ' + field.fieldRef.displayName
                                    )}
                                  >
                                    <IconButton
                                      color="primary"
                                      onClick={(e) => onEditClick(e, field)}
                                    >
                                      <EditTwoToneIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                                <ListItemAvatar
                                  sx={{
                                    mr: 2,
                                    display: 'flex',
                                    alignItems: 'center',
                                    minWidth: 0
                                  }}
                                >
                                  <Avatar
                                    variant="rounded"
                                    sx={{
                                      background: `${theme.colors.alpha.white[10]}`,
                                      color: `${theme.colors.gradients.pink2}`,
                                      width: 64,
                                      height: 64
                                    }}
                                  >
                                    <Icon>
                                      <img
                                        src={cdnService.toCdnUrl(
                                          field.fieldRef.icon,
                                          'icons'
                                        )}
                                        height={25}
                                        width={25}
                                      />
                                    </Icon>
                                  </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                  sx={{
                                    color:
                                      themeColor === 'light'
                                        ? `${theme.colors.alpha.black[70]}`
                                        : `${theme.colors.alpha.trueWhite[70]}`,
                                    wordWrap: 'break-word'
                                  }}
                                  primary={
                                    <Typography gutterBottom variant="h4">
                                      {getTitleDesc(field)}
                                    </Typography>
                                  }
                                  secondary={
                                    <Typography
                                      sx={{
                                        color:
                                          themeColor === 'light'
                                            ? `${theme.colors.alpha.black[70]}`
                                            : `${theme.colors.alpha.trueWhite[70]}`
                                      }}
                                      variant="subtitle2"
                                    >
                                      {getValueDesc(field)}
                                    </Typography>
                                  }
                                />
                              </ListItemWrapper>
                            ) : (
                              <ListItemWrapper
                                sx={{
                                  minWidth: 0
                                }}
                              >
                                <Box className="MuiActionButtons">
                                  <Tooltip
                                    arrow
                                    placement="top"
                                    title={t(
                                      'Edit ' + field.fieldRef.displayName
                                    )}
                                  >
                                    <IconButton
                                      color="primary"
                                      onClick={(e) => onEditClick(e, field)}
                                    >
                                      <EditTwoToneIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                                {renderNewFieldBlock(field)}
                              </ListItemWrapper>
                            )}
                            <Divider />
                          </FieldWrapper>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </List>
        {anchorEl && fieldInEdit && isPopperOpen && (
          <FieldPopper
            open={isPopperOpen}
            field={fieldInEdit}
            anchorEl={anchorEl}
            onCancel={onPopperClose}
            onOk={onPopperOk}
            onChange={onPoppperValueChange}
            onDelete={onPopperDelete}
          />
        )}
      </Card>
    </Stack>
  );
}

export default PreviewCard;
