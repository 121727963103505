import { useState, ReactElement, Ref, forwardRef, useEffect } from 'react';
import type { FC, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import numeral from 'numeral';
import ShowMoreText from 'react-show-more-text';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Grid,
  Slide,
  Divider,
  Tooltip,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableContainer,
  TableRow,
  TextField,
  Button,
  Typography,
  Dialog,
  FormControl,
  Select,
  InputLabel,
  Zoom,
  InputAdornment,
  styled,
  LinearProgress,
  Link,
  Menu,
  alpha,
  useTheme
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
import Label from 'src/ui-bloom/components/Label';
import BulkActions from './BulkActions';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { useSnackbar } from 'notistack';
import { format, formatDistance, subDays, differenceInDays } from 'date-fns';
import {
  EmployeeListItem,
  LeadItem
} from '../../../services/apiService/response-models/data-views';
import {
  CodeMaster,
  LeadStatusType,
  RefData
} from 'src/services/apiService/response-models';
import clsx from 'clsx';
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import ChatTwoToneIcon from '@mui/icons-material/ChatTwoTone';
import cdnService from '../../../services/cdnService';
import toLocDate from '../../../utility/toLocDate';
import EditLeadDialog from '../edit-lead-dialog';

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const AvatarError = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

const CardWrapper = styled(Card)(
  ({ theme }) => `

  position: relative;
  overflow: visible;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: inherit;
    z-index: 1;
    transition: ${theme.transitions.create(['box-shadow'])};
  }
      
    &.Mui-selected::after {
      box-shadow: 0 0 0 3px ${theme.colors.primary.main};
    }
  `
);

const ButtonError = styled(Button)(
  ({ theme }) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.error.dark};
     }
    `
);

interface ResultsProps {
  isLoading: boolean;
  leads: LeadItem[];
  accountRefData: RefData;
    employeeListItems: EmployeeListItem[];
    onLeadEdited: (id: string) => void;
}

interface LeadEditInfo {
    leadId: string;
    status: string;
    employeeId: number;
    employeeListItems: EmployeeListItem[];
    leadStatusRefData: CodeMaster[];
}

interface Filters {
  status?: LeadStatusType;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const getleadstatusLabel = (lead: LeadItem): JSX.Element => {
  const map = {
    Open: {
      text: 'Open',
      color: 'warning'
    },
    Won: {
      text: 'Won',
      color: 'success'
    },
    InProgress: {
      text: 'In progress',
      color: 'primary'
    },
    Lost: {
      text: 'Lost',
      color: 'error'
    },
    Complete: {
      text: 'Won',
      color: 'success'
    }
  };

  const { text, color }: any = map[lead.status];

  return (
    <Label color={color}>
      <b>{text}</b>
    </Label>
  );
};

const applyFilters = (
  leads: LeadItem[],
  query: string,
  filters: Filters,
  queryLastUpdated: string
): LeadItem[] => {
  return leads.filter((lead) => {
    let matches = true;

    if (query) {
      const properties = [
        'contactName',
        'employeeName',
        'cardLabel',
        'contactName'
      ];
      let containsQuery = false;

      properties.forEach((property) => {
        if (lead[property].toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      });

      if (filters.status && lead.status !== filters.status) {
        matches = false;
      }

      if (!containsQuery && lead.contactMail) {
        if (lead.contactMail.toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      }

      if (!containsQuery) {
        matches = false;
      }
    }

    Object.keys(filters).forEach((key) => {
      const value = filters[key];

      if (value && lead[key] !== value) {
        matches = false;
      }
    });

    if (queryLastUpdated) {
      const dy = differenceInDays(
        new Date(),
        toLocDate(lead.updatedOn || lead.createdOn)
      );
      if (dy < Number(queryLastUpdated) - 1) matches = false;
    }

    return matches;
  });
};

const applyPagination = (
  leads: LeadItem[],
  page: number,
  limit: number
): LeadItem[] => {
  return leads.slice(page * limit, page * limit + limit);
};

const Results: FC<ResultsProps> = (props) => {
  const [selectedItems, setSelectedleads] = useState<string[]>([]);
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(props.isLoading);
  const [leads, setLeads] = useState<LeadItem[]>(props.leads);
  const { leadStatus } = props.accountRefData;
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(50);
  const [query, setQuery] = useState<string>('');
  const [queryLastUpdated, setQueryLastUpdated] = useState<string>('');
  const [filters, setFilters] = useState<Filters>({
    status: null
  });
  const [leadIdToEdit, setLeadIdToEdit] = useState('');
    const [editInfo, setEditInfo] = useState<LeadEditInfo | null>(null);

  useEffect(() => {
    setLeads(props.leads);
    setIsLoading(props.isLoading);
  }, [props.isLoading, props.leads]);

  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
    event.persist();
    setQuery(event.target.value);
  };

  const getEmployeeName = (employeeId: number): string => {
    const employee = props.employeeListItems.find((o) => o.id === employeeId);
    return employee ? employee.fullName : '';
  };

  const handleStatusChange = (e: ChangeEvent<HTMLInputElement>): void => {
    let value = null;

    if (e.target.value !== 'all') {
      value = e.target.value;
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      status: value
    }));
  };

  const handleSelectAllleads = (event: ChangeEvent<HTMLInputElement>): void => {
    setSelectedleads(event.target.checked ? leads.map((o) => o.leadId) : []);
  };


  const handleSelectOneInvoice = (
    event: ChangeEvent<HTMLInputElement>,
    invoiceId: string
  ): void => {
    if (!selectedItems.includes(invoiceId)) {
      setSelectedleads((prevSelected) => [...prevSelected, invoiceId]);
    } else {
      setSelectedleads((prevSelected) =>
        prevSelected.filter((id) => id !== invoiceId)
      );
    }
  };

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const filteredleads = applyFilters(leads, query, filters, queryLastUpdated);
  const paginatedleads = applyPagination(filteredleads, page, limit);
  const selectedBulkActions = selectedItems.length > 0;
  const selectedSomeleads =
    selectedItems.length > 0 && selectedItems.length < leads.length;
  const selectedAllleads = selectedItems.length === leads.length;

  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

  const handleConfirmDelete = () => {
    setOpenConfirmDelete(true);
  };

  const closeConfirmDelete = () => {
    setOpenConfirmDelete(false);
  };

  const handleDeleteCompleted = () => {
    setOpenConfirmDelete(false);

    enqueueSnackbar(t('Delete action completed successfully'), {
      variant: 'success',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
      },
      TransitionComponent: Zoom
    });
  };

    const openLeadEditDialog = (e: LeadEditInfo) => {
        setEditInfo({ ...e });
    }
    
  return (
    <>
      <Card
        sx={{
          p: 2,
          mb: 3
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          {!selectedBulkActions && (
            <Grid alignItems="center" container spacing={3}>
              <Grid item xs={12} lg={7} md={6}>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchTwoToneIcon />
                      </InputAdornment>
                    )
                  }}
                  sx={{
                    m: 0
                  }}
                  onChange={handleQueryChange}
                  placeholder={t(
                    'Search leads by name, contact number, email ...'
                  )}
                  value={query}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} lg={3} md={3}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>{t('Status')}</InputLabel>
                  <Select
                    value={filters.status || 'all'}
                    onChange={handleStatusChange}
                    label={t('Status')}
                  >
                    <MenuItem value="all">{t('All')}</MenuItem>
                    {leadStatus.map((statusOption) => (
                      <MenuItem
                        key={statusOption.code}
                        value={statusOption.code}
                      >
                        {statusOption.value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={2} md={3}>
                <TextField
                  sx={{
                    m: 0
                  }}
                  label="Last Updated"
                  type="number"
                  InputProps={{ inputProps: { min: 0, max: 9999 } }}
                  onChange={(e) => setQueryLastUpdated(e.target.value)}
                  placeholder={t('number of day ago')}
                  value={queryLastUpdated}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
            </Grid>
          )}
        </Box>
      </Card>

      <Card>
        {isLoading && <LinearProgress sx={{ width: '100%' }} />}

        {paginatedleads.length === 0 ? (
          <Typography
            sx={{
              py: 10
            }}
            variant="h3"
            fontWeight="normal"
            color="text.secondary"
            align="center"
          >
            {t(
              isLoading
                ? 'Loading leads...'
                : "We couldn't find any leads matching your search criteria"
            )}
          </Typography>
        ) : null}
      </Card>

      {paginatedleads.length > 0 ? (
        <Grid container spacing={3}>
          {paginatedleads.map((item) => {
            const isUserSelected = selectedItems.includes(item.leadId);
            return (
              <Grid item xs={12} sm={6} md={4} key={item.leadId}>
                <CardWrapper
                  className={clsx({
                    'Mui-selected': isUserSelected
                  })}
                >
                  <Box
                    sx={{
                      position: 'relative',
                      zIndex: '2'
                    }}
                  >
                    <Box
                      px={2}
                      pt={2}
                      display="flex"
                      alignItems="flex-start"
                      justifyContent="space-between"
                    >
                      {getleadstatusLabel(item)}
                      <MoreActionsButton
                        onClick={(e) => {
                          if (e == 'comment') {
                            setLeadIdToEdit(item.leadId);
                          } else {
                            openLeadEditDialog(
                                {
                                    leadId: item.leadId,
                                    status: item.status,
                                    employeeId: item.employeeId,
                                    employeeListItems: props.employeeListItems,
                                    leadStatusRefData: leadStatus
                                }
                            );
                          }
                        }}
                      />
                    </Box>
                    <Box p={2} display="flex" alignItems="flex-start">
                      <Avatar
                        sx={{
                          width: 50,
                          height: 50,
                          mr: 2
                        }}
                        src={cdnService.toImageUrl(item.contactImage)}
                      />
                      <Box>
                        <Box>
                          <Link
                            variant="h5"
                            component={RouterLink}
                            to={
                                `/${location.pathname.split('/')[1]}/lead/detail/` +
                                item.leadId
                            }
                          >
                            {item.contactName}
                          </Link>
                        </Box>

                        <Typography
                          sx={{
                            pt: 1
                          }}
                          variant="h6"
                        >
                          {item.contactMail}
                        </Typography>
                        <Link
                          variant="subtitle2"
                          underline="none"
                          component={RouterLink}
                          to={`/${location.pathname.split('/')[1]}/cards/edit/${
                            item.cardId
                          }`}
                        >
                          for: {item.cardLabel}
                        </Link>
                        <Typography
                          component="div"
                          variant="body2"
                          color="text.secondary"
                        >
                          on: {new Date(item.createdOn).toLocaleDateString()}
                        </Typography>
                        <Typography
                          sx={{
                            pt: 1
                          }}
                          variant="h6"
                        >
                          {'owner: '}
                          {item.employeeName}
                        </Typography>
                        {
                          <Typography
                            sx={{
                              pt: 1
                            }}
                            variant="h6"
                          >
                            {'Remarks: '}
                            <ShowMoreText
                              /* Default options */
                              lines={1}
                              more="Show more"
                              less="Show less"
                              className="content-css"
                              anchorClass="show-more-less-clickable"
                              truncatedEndingComponent={'... '}
                            >
                              {item.formData?.Remark}
                            </ShowMoreText>
                          </Typography>
                        }
                      </Box>
                    </Box>
                    <Divider />
                    <Box
                      pl={2}
                      py={1}
                      pr={1}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography>
                        last updated:{' '}
                        <b>
                          {formatDistance(
                            toLocDate(item.updatedOn || item.createdOn),
                            new Date(),
                            {
                              addSuffix: true
                            }
                          )}
                        </b>
                      </Typography>
                      {/*<Checkbox*/}
                      {/*    checked={isUserSelected}*/}
                      {/*    onChange={(event) =>*/}
                      {/*        handleSelectOneInvoice(event, item.leadId)*/}
                      {/*    }*/}
                      {/*    value={isUserSelected}*/}
                      {/*/>*/}
                    </Box>
                  </Box>
                </CardWrapper>
              </Grid>
            );
          })}
        </Grid>
      ) : null}

      <Card
        sx={{
          p: 2,
          mt: 3,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Box>
          <Typography component="span" variant="subtitle1">
            {t('Showing')}:
          </Typography>{' '}
          <b>{paginatedleads.length}</b> <b>{t('leads')}</b>
        </Box>
        <TablePagination
          component="div"
          count={filteredleads.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[50, 100, 150]}
        />
      </Card>

          {editInfo && <EditLeadDialog
              leadId={editInfo.leadId}
              employeeId={editInfo.employeeId}
              status={editInfo.status}
              isOpen={true}
              onClose={() => setEditInfo(null)}
              onSave={() => props.onLeadEdited(editInfo.leadId)}
              members={editInfo.employeeListItems}
              leadStatusRefData={editInfo.leadStatusRefData}
          />}
    </>
  );
};

Results.propTypes = {
  leads: PropTypes.array.isRequired
};

Results.defaultProps = {
  leads: []
};

function MoreActionsButton(props: { onClick: (e: string) => void }) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        onClick={handleClick}
        color="primary"
        sx={{
          p: 0.5
        }}
      >
        <MoreVertTwoToneIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => props.onClick('edit')}>
          <EditTwoToneIcon fontSize="small" />
          &nbsp;&nbsp;Edit
        </MenuItem>
      </Menu>
    </>
  );
}
export default Results;
