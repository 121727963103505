import { useState } from 'react';
import {
  Typography,
  Container,
  Button,
  Card,
  Box,
  styled,
  CardContent,
  CircularProgress,
  InputAdornment,
  IconButton
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import * as Yup from 'yup';

import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import Logo from 'src/ui-bloom/components/LogoSign';
import { forgotPassword, resetPassword } from 'src/services/apiService';
import { RefData } from 'src/services/apiService/response-models';
import { useSnackbar } from 'notistack';
import { parseToApiErrorMessage } from 'src/utility/parseToApiErrorMessage';
import { useNavigate, useParams } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const MainContent = styled(Box)(
  () => `
      height: 100%;
      overflow: auto;
      flex: 1;
  `
);

function ResetPassword() {
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { email } = useParams();

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  return (
    <>
      <Helmet>
        <title>Reset password @ KiEngage</title>
      </Helmet>
      <MainContent>
        <Container
          sx={{
            my: 4
          }}
          maxWidth="md"
        >
          <Logo />
          <Card
            sx={{
              mt: 3,
              pt: 4
            }}
          >
            <Box px={4}>
              <Typography
                variant="h3"
                sx={{
                  mb: 1
                }}
              >
                {t('Reset password for KiEngage')}
              </Typography>
              <Typography
                variant="h4"
                color="text.secondary"
                fontWeight="normal"
                sx={{
                  mb: 3
                }}
              >
                {t('Check your mail for password recovery code.')}
              </Typography>
            </Box>

            <CardContent>
              <Box>
                <Formik
                  initialValues={{
                    validationToken: '',
                    password: '',
                    newPassword: ''
                  }}
                  validationSchema={Yup.object().shape({
                    validationToken: Yup.string()
                      .max(
                        10,
                        t(
                          'Password recovery code must be at most 10 characters'
                        )
                      )
                      .required(t('This field is required')),
                    password: Yup.string()
                      .min(8)
                      .max(255)
                      .required(t('The password field is required')),
                    newPassword: Yup.string()
                      .oneOf(
                        [Yup.ref('password')],
                        t('Both password fields need to be the same')
                      )
                      .required(t('This field is required'))
                  })}
                  onSubmit={async (values, { setSubmitting }) => {
                    setSubmitting(true);
                    try {
                      const response = await resetPassword(
                        email,
                        values.validationToken,
                        values.password,
                        values.newPassword
                      );
                      if (response.status === 200) {
                        enqueueSnackbar(t('Password reset successfully'), {
                          variant: 'success'
                        });
                        navigate('/app/login');
                      }
                    } catch (err) {
                      const message = parseToApiErrorMessage(
                        err,
                        `Failed to reset password`
                      );
                      enqueueSnackbar(message, {
                        variant: 'error'
                      });
                    } finally {
                      setSubmitting(false);
                    }
                  }}
                >
                  {({ errors, handleSubmit, isSubmitting, touched }) => (
                    <Form onSubmit={handleSubmit}>
                      <Box sx={{ mb: 3 }}>
                        <Field
                          fullWidth
                          name="validationToken"
                          component={TextField}
                          label={t('Recovery Code')}
                          helperText="Code sent to your registered email."
                          placeholder={t(
                            'Enter password recovery code here...'
                          )}
                        />
                      </Box>
                      <Box sx={{ mb: 3 }}>
                        <Field
                          fullWidth
                          type={showPassword ? 'text' : 'password'}
                          name="password"
                          component={TextField}
                          label={t('Password')}
                          placeholder={t('Enter new password here...')}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                      </Box>
                      <Box sx={{ mb: 3 }}>
                        <Field
                          fullWidth
                          type={showPassword ? 'text' : 'password'}
                          name="newPassword"
                          component={TextField}
                          label={t('Confirm password')}
                          placeholder={t('Confirm password...')}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                      </Box>
                      <Box sx={{ py: 2 }}>
                        <Button
                          size="large"
                          variant="outlined"
                          color="secondary"
                          onClick={() => navigate('/app/forgot-password')}
                          sx={{
                            mx: 1
                          }}
                        >
                          {t('Cancel')}
                        </Button>
                        <Button
                          color="primary"
                          startIcon={
                            isSubmitting ? (
                              <CircularProgress size="1rem" />
                            ) : null
                          }
                          disabled={
                            isSubmitting ||
                            (!!Object.keys(errors).length &&
                              !!Object.keys(touched).length)
                          }
                          size="large"
                          type="submit"
                          variant="contained"
                          sx={{
                            mx: 1,
                            px: 3
                          }}
                        >
                          {t('Reset password')}
                        </Button>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </Box>
            </CardContent>
          </Card>
        </Container>
      </MainContent>
    </>
  );
}

export default ResetPassword;
