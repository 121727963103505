import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Avatar,
  IconButton,
  ListItemIcon,
  ListItemText,
  Zoom
} from '@mui/material';
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import {
  closeDrawerPanel,
  openDrawerPanel,
  setNoteToEdit
} from '../../../../store/slices/app';
import { useDispatch } from 'react-redux';
import { Note } from 'src/CRM/models/note';
import DeleteConfirm from 'src/components/delete-confirm';
import { deleteNote as deleteNoteApi } from 'src/services/nestApi';
import ActivitiesContext from '../activities-context';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export default function ActionMenuButton(props: { note?: Note }) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { deleteNote } = React.useContext(ActivitiesContext);
  const open = Boolean(anchorEl);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t }: { t: any } = useTranslation();

  const dispatch = useDispatch();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    dispatch(setNoteToEdit({ note: props.note }));
    dispatch(openDrawerPanel());
    handleClose();
  };
  const handleDelete = () => {
    setIsDeleteConfirmOpen(true);
    handleClose();
  };

  const onDeleteConfirm = async () => {
    try {
      const res = await deleteNoteApi(props.note.id);
      deleteNote(props.note);
      enqueueSnackbar(t('The note has been successfully deleted'), {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center'
        },
        TransitionComponent: Zoom
      });
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div>
      <DeleteConfirm
        open={isDeleteConfirmOpen}
        message="note"
        onDeleteConfirm={onDeleteConfirm}
        onClose={() => setIsDeleteConfirmOpen(false)}
      />
      <IconButton size="small" color="primary" onClick={handleClick}>
        <MoreVertTwoToneIcon />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))'
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleEdit}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleDelete}>
          <ListItemIcon>
            <DeleteIcon color="error" fontSize="small" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
}
