import axios, { AxiosError, AxiosResponse } from 'axios';
import { ApiUrls } from '../endpoints';
import { Employee } from './response-models';
import { AnalyticsView, CardVM, EmployeeListItem } from './response-models/data-views';

export const getAllCards = (): Promise<AxiosResponse<CardVM[]>> => {
  const dataUrl = ApiUrls.manageCardEndpoint + '/digital-card/all';
  return axios.get(dataUrl);
};

export const getEmployeesBySearch = (
  searchKey: string
): Promise<AxiosResponse<EmployeeListItem[]>> => {
  const dataUrl = ApiUrls.accountEndpoint + '/employee/search/' + searchKey;
  return axios.get(dataUrl);
};

export const getEmployeeListItems = (): Promise<
  AxiosResponse<EmployeeListItem[]>
> => {
  const dataUrl = ApiUrls.accountEndpoint + '/employee/list-items/';
  return axios.get(dataUrl);
};


export const getAnalytics = (): Promise<
    AxiosResponse<AnalyticsView>
> => {
    const dataUrl = ApiUrls.accountEndpoint + '/analytics';
    return axios.get(dataUrl);
}
