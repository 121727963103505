import { RouteObject } from 'react-router';
import { Navigate } from 'react-router-dom';
import AccountDeletion from 'src/pages/account-deletion';
import ForgotPassword from 'src/pages/forgot-password';
import PaymentSuccess from 'src/pages/payment-success';
import ResetPassword from 'src/pages/reset-password';
import NotFound from '../components/not-found';
import SigninOidc from '../components/signin-oidc';
import UnAuthorized from '../components/un-authorized';
import ActivationSuccess from '../pages/activation-success';
import LoginCover from '../pages/login';
import SignUpWizard from '../pages/sign-up';

const safeRouter: RouteObject[] = [
  {
    path: '',
    element: <Navigate to="app/login" />
  },
  {
    path: 'app/login',
    element: <LoginCover />
  },
  {
    path: 'app/sign-up',
    element: <SignUpWizard />
  },
  {
    path: 'app/activation-success/:status',
    element: <ActivationSuccess />
  },
  {
    path: 'app/forgot-password',
    element: <ForgotPassword />
  },
  {
    path: 'app/delete-account',
    element: <AccountDeletion />
  },
  {
    path: 'app/payment-success',
    element: <PaymentSuccess />
  },
  {
    path: 'app/reset-password/:email',
    element: <ResetPassword />
  },
  {
    path: 'not-found',
    element: <NotFound />
  },
  {
    path: 'un-authorized',
    element: <UnAuthorized />
  },
  {
    path: 'signin-oidc',
    element: <SigninOidc />
  },
  {
    path: '*',
    element: <Navigate to="app/login" />
  }
];

export default safeRouter;
