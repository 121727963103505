import {
  Avatar,
  Box,
  Grid,
  LinearProgress,
  Paper,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import QRCodeStyling from 'qr-code-styling';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import cdnService from 'src/services/cdnService';
import { getQrCodeConfig, saveQrCodeConfig } from 'src/services/nestApi';
import { QRConfig } from 'src/services/nestApi/responseModels';
import Footer from 'src/ui-bloom/components/Footer';
import PageTitleWrapper from 'src/ui-bloom/components/PageTitleWrapper';
import blobToBase64 from 'src/utility/blobToBase64';
import usePrompt from 'src/utility/usePrompt';
import QrCardForApp from '../../../BusinessApp/apps/shared/components/QrCardForApp';
import ListingAppNames from '../../../BusinessApp/enums/ListingAppNames';
import ErrorPanel from '../../../components/error-panel';
import {
  getAllCardFieldCategories,
  getCardApps,
  getCardById,
  getProfileNameUnique,
  saveCard
} from '../../../services/apiService';
import {
  CardApp,
  CardAppItem,
  CardAppNames,
  CardFieldCategory,
  CardFieldValue,
  CardTypes,
  DigitalCard,
  DigitalCardField,
  NfcCodeMap
} from '../../../services/apiService/response-models';
import { RootState, useSelector } from '../../../store';
import { parseToApiErrorMessage } from '../../../utility/parseToApiErrorMessage';
import PreField from '../models/PreField';
import PreviewCard from '../preview-card';
import convertCardFieldsToPreFields from '../preview-card/utils/convertCardFieldsToPreFields';
import PageHeader from './PageHeader';
import { ConnectedApps } from './connected-apps';
import FieldListing from './field-listing';
import SettingFloat from './setting-float';
const PROFILE_URL_PREFIX = 'kicard.co/';

function CardEditor() {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const navigate = useNavigate();
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  // const [fieldsList, handleFieldListUpdate] = useState([]);
  const [selectedPreFields, setSelectedPreFields] = useState<PreField[]>([]);
  const [modifiedPreFields, setModifiedPreFields] = useState<PreField[]>([]);

  const [isPreFieldsModified, setIsPreFieldsModified] = useState(false);
  const [isThemeModified, setIsThemeModified] = useState(false);
  const [isQrConfigModified, setIsQrConfigModified] = useState(false);

  const [fieldInEdit, setFieldInEdit] = useState<DigitalCardField | null>(null);
  const { user } = useSelector((state: RootState) => state.app);

  const [fieldCategories, setFieldCategories] = useState<CardFieldCategory[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [card, setCard] = useState<DigitalCard>(null);
  const [cardAppItems, setCardAppItems] = useState<CardAppItem[]>([]);

  const { cardType, id } = useParams();

  const cardId: number | null = id && Number(id) > 0 ? Number(id) : null;
  const [isEdit, setIsEdit] = useState(false);
  const [isSaveInProgress, setIsSaveInProgress] = useState(false);
  const [isQrConfigLoading, setIsQrConfigLoading] = useState(true);
  const [cardLabel, setCardLabel] = useState('My ' + cardType + ' card');
  const [profileName, setCardProfileName] = useState('');
  const [qrConfig, setQrConfig] = useState<QRConfig | null>(null);
  const [profileNameError, setCardProfileNameError] = useState('');
  const [isActiveCard, setIsActiveCard] = useState(false);
  const [linkedNfcMap, setLinkedNfcMap] = useState<NfcCodeMap | null>(null);

  const [cardApps, setCardApps] = useState<CardApp[] | null>(null);

  const getData = async (
    callback: (hasError: boolean, data: CardFieldCategory[]) => void
  ) => {
    try {
      const res = await getAllCardFieldCategories();
      callback(false, res.data);
    } catch (ex) {
      const msg = ex.message ? ex.message : `Failed to load field data`;
      enqueueSnackbar(t(msg), { variant: 'error' });
      setError(msg);
      callback(true, []);
    }
  };

  const getById = async (cardId: number) => {
    try {
      getQrCodeConfig(cardId, 'Profile')
        .then((o) => {
          setQrConfig(o.data);
          setIsQrConfigLoading(false);
        })
        .catch((o) => setIsQrConfigLoading(false));

      const response = await getCardById(cardId);

      setCard(response.data);
      response.data.cardAppItems.map((o) => (o.cardName = response.data.name));

      ///card item qr image urls
      const urlRequests = response.data.cardAppItems.map((o) => {
        let q = '';
        if (o.cardApp.isExternal) {
          q = encodeURIComponent(o.appConfig?.url);
        } else if (o.cardApp.name === CardAppNames.PhoneQRApp) {
          q = o.appConfig?.phoneNumber;
        }

        return {
          appName: o.cardApp.name,
          cardIdOrName: response.data.name,
          q: q
        };
      });

      // const qrUrlRes = await getQrImageUrls(urlRequests);

      // response.data.cardAppItems.forEach((item) => {
      //   item.qrUrl = qrUrlRes.data.find(
      //     (o) => o.appName == item.cardApp.name
      //   )?.qrImageUrl;
      // });
      ////
      setLinkedNfcMap(
        (response.data.nfcCodeMaps || []).find(
          (o) => o.cardId == response.data.id && o.cardAppId == 1
        )
      );

      setCardAppItems(response.data.cardAppItems);
      setCardLabel(response.data.label);
      setCardProfileName(response.data.name + '');
      setIsActiveCard(response.data.isActiveCard == true);
      setIsEdit(true);
      return response.data;
    } catch (ex) {
      console.error(ex);
      const msg = ex.message ? ex.message : `Failed to load card by id`;
      enqueueSnackbar(t(msg), { variant: 'error' });
      navigate('/app/cards/' + cardType);
    }
    return null;
  };

  const getNewCard = (): DigitalCard => {
    return {
      id: 0,
      cardType:
        cardType == 'business' ? CardTypes.Business : CardTypes.Individual,
      description: '',
      fields: [],
      isActive: true,
      isOnline: false,
      label: cardLabel,
      name: profileName,
      parentId: user.employeeIds[0],
      cardAppItems: []
    };
  };
  const cardTypeMatches = (cType: string): boolean => {
    for (const value in CardTypes) {
      if (cType == value.toLowerCase()) return true;
    }
    return false;
  };

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      loadCardApps();

      //edit card
      getData((hasError, fieldCategories) => {
        let cType =
          cardType == 'business' ? CardTypes.Business : CardTypes.Individual;
        if (cardType == 'service') cType = CardTypes.Service;

        setFieldCategories(
          fieldCategories.filter(
            (o) => o.cardType == cType || o.cardType == CardTypes.All
          )
        );

        if (id && Number(id) > 0) {
          setIsLoading(true);
          getById(Number(id)).then((card) => {
            let allFields: DigitalCardField[] = [];
            fieldCategories.forEach((cat) => {
              allFields = allFields.concat(cat.digitalCardFields);
            });

            const results = convertCardFieldsToPreFields(
              allFields,
              card.fields
            );

            setSelectedPreFields(results);

            setIsLoading(false);
          });
        } else {
          setCard(getNewCard());
          setIsLoading(false);
        }
      });
    })();
  }, [id]);

  const loadCardApps = async () => {
    try {
      const res = await getCardApps();
      setCardApps(res.data);
    } catch (ex) {
      setCardApps(null);
      console.error(ex);
      enqueueSnackbar(t('Failed to load card apps'), { variant: 'error' });
    }
  };

  const handleFieldClick = (field: DigitalCardField) => {
    setFieldInEdit(field);
  };

  const onPreFieldsModified = (allFields: PreField[]) => {
    setFieldInEdit(null);

    ////remove if already in list
    //const updatedFields = [...modifiedPreFields].filter(
    //    (o) => o.id != field.id
    //);
    setModifiedPreFields([...allFields]);
    setIsPreFieldsModified(true);
  };

  const onChangeTheme = (color: string) => {
    setCard({ ...card, theme: color });
    setIsThemeModified(true);
  };

  const hasPendingChanges = (): boolean => {
    let hasChanges = false;

    if (isSaveInProgress || isLoading) return false;

    if (!isEdit) {
      if (
        isPreFieldsModified ||
        profileName?.length > 0 ||
        isThemeModified ||
        isQrConfigModified
      ) {
        hasChanges = true;
      }

      return hasChanges;
    }

    if (isPreFieldsModified) hasChanges = true;
    // else if (card && cardLabel != card.label) return true;
    else if (card && profileName != card.name) return true;
    else if (card && isActiveCard != card.isActiveCard) return true;
    else if (isThemeModified) return true;
    else if (isQrConfigModified) return true;

    return hasChanges;
  };

  const iOwnThisCard = user.employeeIds.includes(card?.parentId) ? true : false;

  const onSave = async () => {
    if (!modifiedPreFields.length && !selectedPreFields.length) {
      enqueueSnackbar(t('At least one profile field is required'), {
        variant: 'error'
      });
      return;
    }

    if (!!profileNameError) {
      enqueueSnackbar(t('Invalid profile name!!'), { variant: 'error' });
      return;
    }

    if (!hasPendingChanges()) {
      enqueueSnackbar(t('No changes to save'), { variant: 'warning' });
      return;
    }

    if (isSaveInProgress) return;

    try {
      setIsSaveInProgress(true);
      let updatedModel: DigitalCard = null;

      if (!isEdit) {
        updatedModel = getNewCard();
      } else {
        updatedModel = { ...card };
        updatedModel.label = cardLabel;
        updatedModel.name = profileName;
        updatedModel.isActiveCard = isActiveCard;
      }

      //apply updates
      const previewUpdatedFields = isPreFieldsModified
        ? modifiedPreFields
        : selectedPreFields;

      const newFields: CardFieldValue[] = [];

      previewUpdatedFields.forEach((updatedField) => {
        const fieldValue: CardFieldValue = {
          fieldId: updatedField.fieldRef.id,
          fieldName: updatedField.fieldName,
          id: '',
          order: updatedField.order,
          formFieldValues: updatedField.fieldValues
        };

        newFields.push(fieldValue);
      });

      updatedModel.fields = newFields;

      const res = await saveCard(updatedModel);

      if (isQrConfigModified) {
        saveQrCodeConfig({
          appName: 'Profile',
          cardId: res.data.id,
          color: qrConfig?.color,
          logo: qrConfig?.logo
        }).then((o) => {});
      }
      enqueueSnackbar(
        t(`Card was ${!isEdit ? 'created' : 'updated'} successfully`),
        { variant: 'success' }
      );

      if (!isEdit) {
        navigate('/app/cards/' + cardType + '/edit/' + res.data.id);
        setTimeout(() => {
          setIsSaveInProgress(false);
        });
      } else {
        setIsSaveInProgress(false);
        afterSaveResetCard(res.data);
      }
    } catch (ex) {
      setIsSaveInProgress(false);
      console.log(ex);
      const msg = parseToApiErrorMessage(
        ex,
        `Failed to ${!isEdit ? 'created' : 'updated'} Card`
      );
      enqueueSnackbar(t(msg), { variant: 'error' });
    }
  };

  const afterSaveResetCard = (updatedCard: DigitalCard): void => {
    setIsPreFieldsModified(false);
    setIsThemeModified(false);
    setModifiedPreFields([]);
    setIsQrConfigModified(false);
    setCardLabel(updatedCard.label);
    setCardProfileName(updatedCard.name);
    setIsActiveCard(updatedCard.isActiveCard);
    setCard(updatedCard);
    setCardAppItems([
      ...cardAppItems.map((o) => ({ ...o, cardName: updatedCard.name }))
    ]);
  };

  usePrompt(
    'Are you sure you want to leave, changes you made will be lost?',
    hasPendingChanges()
  );
  const onCardAppItemsChange = (items: CardAppItem[]) => {
    setCardAppItems(items);
  };

  const onCardAppItemClick = (e: CardAppItem) => {
    //  setAllAppsEditTriggerAppId(e.cardAppId);
  };

  // usePrompt(
  //   'Are you sure you want to leave, changes you made will be lost?',
  //   hasPendingChanges()
  // );

  return (
    <>
      <PageTitleWrapper>
        <PageHeader cardType={cardType} onSave={onSave} />
      </PageTitleWrapper>
      {isSaveInProgress && (
        <Box sx={{ width: '100%', p: 1 }}>
          <LinearProgress />
          saving...
        </Box>
      )}
      {isLoading && (
        <Box sx={{ width: '100%', p: 1 }}>
          <LinearProgress />
          loading...
        </Box>
      )}
      <Grid
        sx={{
          px: 4
        }}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
      >
        <Grid item md={4} xs={12}>
          <Stack spacing={2} sx={{ width: '100%' }}>
            {!isLoading &&
              !isQrConfigLoading &&
              (!linkedNfcMap ? (
                <QrCardForApp
                  appName={ListingAppNames.BusinessProfile}
                  onChange={(e) => {
                    setCardProfileName(e.value);
                    setQrConfig(e.qrConfig);
                    if (!_.isEqual(qrConfig, e.qrConfig)) {
                      setIsQrConfigModified(true);
                    }
                  }}
                  qrInfo={{
                    value: profileName,
                    qrText: '',
                    qrConfig: qrConfig
                  }}
                  buildQR={async (value, path) => {
                    return path + value;
                  }}
                  validate={async (value) => {
                    let msg = '';
                    if (value?.length) {
                      try {
                        const exists = (
                          await getProfileNameUnique(cardId || 0, value)
                        ).data;
                        msg = exists ? 'Qr text is already taken' : msg;
                      } catch (ex) {
                        console.log(ex);
                      }
                    }

                    return {
                      hasError: msg.length ? true : false,
                      message: msg
                    };
                  }}
                />
              ) : (
                <QrFromNfcMap map={linkedNfcMap} />
              ))}

            {!isLoading && card && (
              <PreviewCard
                cardId={cardId}
                theme={card.theme}
                cardType={card.cardType}
                selectedFields={selectedPreFields}
                editField={fieldInEdit}
                onFieldsModified={onPreFieldsModified}
                onChangeTheme={onChangeTheme}
                onEditCancel={() => setFieldInEdit(null)}
              />
            )}
            {isLoading && (
              <Skeleton sx={{ mt: 2 }} height={800} variant="rectangular" />
            )}
          </Stack>
        </Grid>
        {!error && (
          <>
            <Grid item md={8} xs={12}>
              <Stack spacing={2}>
                {isLoading && <Skeleton height={40} variant="rectangular" />}
                {/* {!isLoading && (
                  <>
                    {iOwnThisCard && cardType != 'business' && (
                      <FormControl component="fieldset" variant="standard">
                        <FormLabel component="legend">Active card</FormLabel>
                        <FormGroup>
                          <Switch
                            checked={isActiveCard}
                            onChange={(ev) => {
                              setIsActiveCard(ev.target.checked);
                            }}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        </FormGroup>
                        <FormHelperText>
                          Active card will be linked to NFC code
                        </FormHelperText>
                      </FormControl>
                    )}
                  </>
                )} */}
                {!isLoading && (
                  <div>
                    {' '}
                    <Box display="flex" mb={1} alignItems="center">
                      <Typography variant="h3" component="h3" gutterBottom>
                        {t('Business Apps')}
                      </Typography>
                      <Typography sx={{ ml: 1 }} variant="subtitle2">
                        {t('Connected apps')}
                      </Typography>
                    </Box>
                    <Box>{<ConnectedApps digitalCardId={cardId} />}</Box>
                  </div>
                )}
                <FieldListing
                  isLoading={isLoading}
                  fieldCategories={fieldCategories}
                  handleFieldClick={handleFieldClick}
                />
              </Stack>
            </Grid>
          </>
        )}
        {error && (
          <Grid item md={8} xs={12}>
            <ErrorPanel />
          </Grid>
        )}
      </Grid>
      <SettingFloat cardId={cardId || 0} />
      <Footer />
    </>
  );
}

function QrFromNfcMap(props: { map: NfcCodeMap }) {
  const theme = useTheme();
  const [qrDataImg, setQrDataImg] = useState('');

  useEffect(() => {
    (async () => {
      const qr = new QRCodeStyling({
        width: 300,
        height: 300,
        data: cdnService.toNfcUrl(props.map.nfcCode),
        image: '',
        dotsOptions: {
          type: 'square'
        },

        imageOptions: {
          crossOrigin: 'anonymous',
          margin: 1
        }
      });
      const imgData = await blobToBase64(await qr.getRawData());
      setQrDataImg(imgData);
    })();
  }, [props.map]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Paper sx={{ p: 2, width: theme.spacing(34) }} elevation={3}>
            <Avatar
              sx={{
                mx: 'auto',
                mb: 0,
                width: theme.spacing(30),
                height: theme.spacing(30)
              }}
              variant="rounded"
              alt="Qr Code"
              src={qrDataImg}
              onContextMenu={() =>
                window.open(cdnService.toNfcUrl(props.map.nfcCode), '_blank')
              }
            ></Avatar>
          </Paper>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center" justifyContent="center">
          This profile is mapped to card: {props.map.nfcCode}
        </Box>
      </Grid>
    </Grid>
  );
}
export default CardEditor;
