import {
  Button,
  Grid,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  FormControlLabel,
  Switch,
  Typography,
  TextField,
  LinearProgress
} from '@mui/material';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ErrorPanel from '../../components/error-panel';
import MembersSelector from '../../components/members-selector';
import {
  getAppNotificationSetting,
  saveAppNotificationSetting
} from '../../services/apiService';
import { getEmployeeListItems } from '../../services/apiService/data-view-service';
import { NotificationSetting } from '../../services/apiService/response-models';
import { EmployeeListItem } from '../../services/apiService/response-models/data-views';
import { RootState } from '../../store';
import { parseToApiErrorMessage } from '../../utility/parseToApiErrorMessage';

function NotificationView() {
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useSelector((state: RootState) => state.app);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState('');
  const [memberItems, setMemberItems] = useState<EmployeeListItem[]>([]);
  const [setting, setSetting] = useState<NotificationSetting>();
  const [settingOrginal, setSettingOrginal] = useState<NotificationSetting>();

  const loadData = async () => {
    try {
      setIsLoading(true);
      const res = await getEmployeeListItems();
      const settingRes = await getAppNotificationSetting(user.clientAccountId);
      setSettingOrginal(settingRes.data);
      setSetting(settingRes.data);
      setMemberItems(res.data);
    } catch (ex) {
      setError('Failed to load data');
    }
  };

  useEffect(() => {
    loadData()
      .then((o) => {
        setIsLoading(false);
      })
      .catch((ex) => {
        setIsLoading(false);
      });
  }, []);

  const onReset = async () => {
    setSetting(settingOrginal);
  };
  const onSave = async () => {
    try {
      setIsSaving(true);
      const res = await saveAppNotificationSetting(setting);
      setSetting(res.data);
      enqueueSnackbar('Save successfully', {
        variant: 'success'
      });
    } catch (ex) {
      console.log(ex);
      const msg = parseToApiErrorMessage(ex, 'Failed to save');
      enqueueSnackbar(t(msg), {
        variant: 'error'
      });
    }
    setIsSaving(false);
  };
  if (isLoading || !setting) return <LinearProgress sx={{ width: '100%' }} />;

  if (!isLoading && error.length) return <ErrorPanel title={error} />;

  return (
    <>
      <Card>
        <CardHeader title="Lead Email Notification" />
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={12} md={2}></Grid>
            <Grid item xs={12} md={10}>
              <FormControlLabel
                value
                control={
                  <Switch
                    color="primary"
                    checked={setting.leadEmailSetting.leadCreateMailToOwner}
                    onChange={(e) => {
                      const settingCopy = _.cloneDeep(setting);
                      settingCopy.leadEmailSetting.leadCreateMailToOwner =
                        e.target.checked;
                      setSetting(settingCopy);
                    }}
                  />
                }
                label={t('Send lead creation mail to lead owner')}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography variant="body1" gutterBottom>
                Send lead creation mail to member(s)
              </Typography>
            </Grid>
            <Grid item xs={12} md={10}>
              <MembersSelector
                items={memberItems}
                selectedIds={setting.leadEmailSetting.leadCreateMailToMemberIds}
                onChange={(e) => {
                  const settingCopy = _.cloneDeep(setting);
                  settingCopy.leadEmailSetting.leadCreateMailToMemberIds =
                    e.map((o) => o.id);
                  setSetting(settingCopy);
                }}
              />
            </Grid>
            <Grid item xs={12} md={2}></Grid>
            <Grid item xs={12} md={10}>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={
                      setting.leadEmailSetting.leadStatusChangeMailToOwner
                    }
                    onChange={(e) => {
                      const settingCopy = _.cloneDeep(setting);
                      settingCopy.leadEmailSetting.leadStatusChangeMailToOwner =
                        e.target.checked;
                      setSetting(settingCopy);
                    }}
                  />
                }
                label={t('Send lead status-change mail to lead owner')}
              />
            </Grid>

            <Grid item xs={12} md={2}>
              <Typography variant="body1" gutterBottom>
                Send lead status-change mail to member(s)
              </Typography>
            </Grid>
            <Grid item xs={12} md={10}>
              <MembersSelector
                items={memberItems}
                selectedIds={
                  setting.leadEmailSetting.leadStatusChangeMailToMemberIds
                }
                onChange={(e) => {
                  const settingCopy = _.cloneDeep(setting);
                  settingCopy.leadEmailSetting.leadStatusChangeMailToMemberIds =
                    e.map((o) => o.id);
                  setSetting(settingCopy);
                }}
              />
            </Grid>

            <Grid item xs={12} md={2}></Grid>
            <Grid item xs={12} md={10}>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={
                      setting.leadEmailSetting.leadNoStatusChangeMailToOwner
                    }
                    onChange={(e) => {
                      const settingCopy = _.cloneDeep(setting);
                      settingCopy.leadEmailSetting.leadNoStatusChangeMailToOwner =
                        e.target.checked;
                      setSetting(settingCopy);
                    }}
                  />
                }
                label={t('Send no status-change reminder to lead owner')}
              />
            </Grid>
            <Grid item xs={12} md={2}></Grid>
            <Grid item xs={12} md={10}>
              <TextField
                label="No status-change reminder (in days)"
                fullWidth
                type="number"
                value={
                  setting.leadEmailSetting.leadNoStatusChangeReminderInDay || 0
                }
                onChange={(e) => {
                  const settingCopy = _.cloneDeep(setting);
                  settingCopy.leadEmailSetting.leadNoStatusChangeReminderInDay =
                    +e.target.value;
                  setSetting(settingCopy);
                }}
                variant="filled"
                size="medium"
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography variant="body1" gutterBottom>
                Send no status-change reminder to member(s)
              </Typography>
            </Grid>
            <Grid item xs={12} md={10}>
              <MembersSelector
                items={memberItems}
                selectedIds={
                  setting.leadEmailSetting.leadNoStatusChangeMailToMemberIds
                }
                onChange={(e) => {
                  const settingCopy = _.cloneDeep(setting);
                  settingCopy.leadEmailSetting.leadNoStatusChangeMailToMemberIds =
                    e.map((o) => o.id);
                  setSetting(settingCopy);
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card>
        <CardHeader title="Review Email Notification" />
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={12} md={2}></Grid>
            <Grid item xs={12} md={10}>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={
                      setting.reviewEmailSetting.reviewReceiveMailToOwner
                    }
                    onChange={(e) => {
                      const settingCopy = _.cloneDeep(setting);
                      settingCopy.reviewEmailSetting.reviewReceiveMailToOwner =
                        e.target.checked;
                      setSetting(settingCopy);
                    }}
                  />
                }
                label={t('Send review receive mail to card owner')}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography variant="body1" gutterBottom>
                Send review receive mail to member(s)
              </Typography>
            </Grid>
            <Grid item xs={12} md={10}>
              <MembersSelector
                items={memberItems}
                selectedIds={
                  setting.reviewEmailSetting.reviewReceiveMailToMemberIds
                }
                onChange={(e) => {
                  const settingCopy = _.cloneDeep(setting);
                  settingCopy.reviewEmailSetting.reviewReceiveMailToMemberIds =
                    e.map((o) => o.id);
                  setSetting(settingCopy);
                }}
              />
            </Grid>
            <Grid item xs={12} md={2}></Grid>
            <Grid item xs={12} md={10}>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={
                      setting.reviewEmailSetting.reviewStatusChangeMailToOwner
                    }
                    onChange={(e) => {
                      const settingCopy = _.cloneDeep(setting);
                      settingCopy.reviewEmailSetting.reviewStatusChangeMailToOwner =
                        e.target.checked;
                      setSetting(settingCopy);
                    }}
                  />
                }
                label={t('Send review status-change mail to card owner')}
              />
            </Grid>

            <Grid item xs={12} md={2}>
              <Typography variant="body1" gutterBottom>
                Send review status-change mail to member(s)
              </Typography>
            </Grid>
            <Grid item xs={12} md={10}>
              <MembersSelector
                items={memberItems}
                selectedIds={
                  setting.reviewEmailSetting.reviewStatusChangeMailToMemberIds
                }
                onChange={(e) => {
                  const settingCopy = _.cloneDeep(setting);
                  settingCopy.reviewEmailSetting.reviewStatusChangeMailToMemberIds =
                    e.map((o) => o.id);
                  setSetting(settingCopy);
                }}
              />
            </Grid>

            <Grid item xs={12} md={2}></Grid>
            <Grid item xs={12} md={10}>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={
                      setting.leadEmailSetting.leadNoStatusChangeMailToOwner
                    }
                    onChange={(e) => {
                      const settingCopy = _.cloneDeep(setting);
                      settingCopy.leadEmailSetting.leadNoStatusChangeMailToOwner =
                        e.target.checked;
                      setSetting(settingCopy);
                    }}
                  />
                }
                label={t('Send no status-change reminder to card owner')}
              />
            </Grid>
            <Grid item xs={12} md={2}></Grid>
            <Grid item xs={12} md={10}>
              <TextField
                label="No status-change reminder (in days)"
                fullWidth
                type="number"
                value={
                  setting.reviewEmailSetting
                    .reviewNoStatusChangeReminderInDay || 0
                }
                onChange={(e) => {
                  const settingCopy = _.cloneDeep(setting);
                  settingCopy.reviewEmailSetting.reviewNoStatusChangeReminderInDay =
                    +e.target.value;
                  setSetting(settingCopy);
                }}
                variant="filled"
                size="medium"
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography variant="body1" gutterBottom>
                Send no status-change reminder to member(s)
              </Typography>
            </Grid>
            <Grid item xs={12} md={10}>
              <MembersSelector
                items={memberItems}
                selectedIds={
                  setting.reviewEmailSetting.reviewNoStatusChangeMailToMemberIds
                }
                onChange={(e) => {
                  const settingCopy = _.cloneDeep(setting);
                  settingCopy.reviewEmailSetting.reviewNoStatusChangeMailToMemberIds =
                    e.map((o) => o.id);
                  setSetting(settingCopy);
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions disableSpacing sx={{ justifyContent: 'center' }}>
          <Button
            sx={{
              m: 2
            }}
            type="button"
            color="secondary"
            size="medium"
            variant="contained"
            onClick={() => onReset()}
          >
            {t('Reset')}
          </Button>
          <Button
            sx={{
              m: 2
            }}
            color="primary"
            startIcon={isSaving ? <CircularProgress size="1rem" /> : null}
            onClick={() => onSave()}
            disabled={isSaving}
            type="button"
            size="medium"
            variant="contained"
          >
            {t('Save')}
          </Button>
        </CardActions>
      </Card>
    </>
  );
}

export default NotificationView;
