import PreField from "../../models/PreField";

export const reorderPreFields = (list: PreField[], startIndex: number, endIndex: number): PreField[] => {
    const results = [...list];
    const [removed] = results.splice(startIndex, 1);
    results.splice(endIndex, 0, removed);
    results.forEach((o, i) => {
        o.order = i + 1;
    });
    return results;
};