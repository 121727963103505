import BarChartTwoToneIcon from '@mui/icons-material/BarChartTwoTone';
import { Box, CircularProgress, Fab, Tooltip, Typography } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';
import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { getConnectedBusinessApps } from '../../../../services/nestApi';
import { ListingAppInstance } from '../../../../BusinessApp/services/models';
import { parseToApiErrorMessage } from '../../../../utility/parseToApiErrorMessage';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

export function ConnectedApps(props: { digitalCardId: number }) {
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [appInstances, setAppInstances] = useState<ListingAppInstance[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        if (props.digitalCardId) {
          const res = await getConnectedBusinessApps(props.digitalCardId);
          setAppInstances(res.data);
        }
      } catch (ex) {
        console.error(ex);
        const msg = parseToApiErrorMessage(ex, `Failed to load connected apps`);
        enqueueSnackbar(t(msg), { variant: 'error' });
        setError(msg);
      }
      setIsLoading(false);
    })();
  }, [props.digitalCardId]);

  const goToApp = (e: ListingAppInstance) => {
    navigate(
      '/app/business-apps/' +
        e.app.name +
        '/edit/' +
        e.id +
        '?ret=' +
        encodeURIComponent(location.pathname)
    );
  };

  return (
    <Box
      px={0}
      pb={1}
      sx={{
        '.swiper-pagination-bullets': {
          bottom: '0 !important'
        }
      }}
    >
      {isLoading && (
        <Box sx={{ display: 'flex' }}>
          <CircularProgress />
        </Box>
      )}
      {!isLoading && !error && !appInstances.length && (
        <Box sx={{ display: 'flex' }}>
          <Typography variant="subtitle2" gutterBottom>
            No connnected apps
          </Typography>
        </Box>
      )}
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        navigation={{
          nextEl: '.MuiSwipe-right',
          prevEl: '.MuiSwipe-left'
        }}
        breakpoints={{
          500: {
            slidesPerView: 5,
            spaceBetween: 12
          },
          768: {
            slidesPerView: 7,
            spaceBetween: 12
          },
          1200: {
            slidesPerView: 10,
            spaceBetween: 24
          }
        }}
        modules={[Navigation]}
      >
        {appInstances.map((item, i) => (
          <React.Fragment key={item.id}>
            <SwiperSlide key={item.id}>
              <Tooltip title={item.app.displayName}>
                <Fab
                  onClick={() => goToApp(item)}
                  color="primary"
                  aria-label="add"
                >
                  <BarChartTwoToneIcon />
                </Fab>
              </Tooltip>
              {item.app.displayName}
            </SwiperSlide>
          </React.Fragment>
        ))}
        {!isLoading && (
          <React.Fragment>
            {/*<SwiperSlide >*/}
            {/*    <Fab aria-label="add">*/}
            {/*        <AddIcon />*/}
            {/*    </Fab>*/}
            {/*</SwiperSlide>*/}
          </React.Fragment>
        )}
      </Swiper>
    </Box>
  );
}
