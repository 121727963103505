import {
  Autocomplete,
  Box,
  FormControl,
  FormHelperText,
  TextField
} from '@mui/material';
import { ErrorMessage, FieldProps } from 'formik';
import { countryCodes } from 'src/utility/countryCode';

export const CountryCodeSlector = ({ field, form }: FieldProps) => {
  return (
    <FormControl variant="filled" fullWidth>
      <Autocomplete
        id="country-select-demo"
        options={countryCodes}
        autoHighlight
        value={
          field?.value
            ? countryCodes.find((o) => o.dial_code === field.value)
            : null
        }
        onChange={(
          _event,
          value: {
            name: string;
            code: string;
            dial_code: string;
          }
        ) => {
          form.setFieldValue(field.name, value?.dial_code || '');
        }}
        getOptionLabel={(option: {
          name: string;
          code: string;
          dial_code: string;
        }) => `+${option.dial_code} (${option.name})`}
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
            {...props}
          >
            <img
              loading="lazy"
              width="20"
              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
              alt=""
            />
            {option.name}({option.dial_code})
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Choose a country code"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password' // disable autocomplete and autofill
            }}
          />
        )}
      />
      {form.touched[field.name] && Boolean(form.errors[field.name]) && (
        <FormHelperText
          error={form.touched[field.name] && Boolean(form.errors[field.name])}
        >
          {form.errors[field.name]}
        </FormHelperText>
      )}
    </FormControl>
  );
};
