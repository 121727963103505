import { Dictionary } from 'lodash';
import PreField from '../../models/PreField';
import {
  CardFieldNames,
  DigitalCardField
} from '../../../../services/apiService/response-models';

export const getFixedFields = (
  fieldHash: Dictionary<PreField>,
  isIndividualCard: boolean
) => {
    const results: PreField[] = [];

  results.push(fieldHash[CardFieldNames.ProfilePic]);
    results.push(fieldHash[CardFieldNames.CoverPhoto]);
  results.push(fieldHash[CardFieldNames.FullName]);
  results.push(fieldHash[CardFieldNames.JobTitle]);
  results.push(fieldHash[CardFieldNames.Department]);
  results.push(fieldHash[CardFieldNames.CompanyName]);
  results.push(fieldHash[CardFieldNames.BusinessName]);
  results.push(fieldHash[CardFieldNames.Accreditation]);
  results.push(fieldHash[CardFieldNames.Headline]);

  //filter out null
  return results.filter((o) => o);
};
