import SaveIcon from '@mui/icons-material/Save';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { EmployeeListItem } from 'src/services/apiService/response-models/data-views';
import { updateSelfReview } from '../../../services/apiService';
import { CodeMaster, Team } from '../../../services/apiService/response-models';
import { RootState, useSelector } from '../../../store';
import { parseToApiErrorMessage } from '../../../utility/parseToApiErrorMessage';

function EditReviewDialog(props: {
  members: EmployeeListItem[];
  reviewStatusRefData: CodeMaster[];
  reviewId?: string;
  status: string;
  isOpen: boolean;
  employeeId?: number;
  onClose: () => void;
  onSave: () => void;
}) {
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(props.isOpen);
  const navigate = useNavigate();

  const { user } = useSelector((state: RootState) => state.app);
  const [selectedEmployee, setSelectedEmployee] =
    useState<EmployeeListItem | null>(null);
  const [model, setModel] = useState<Team | null>(null);
  const [isSaveInProgress, setIsSaveInProgress] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(true);

  useEffect(() => {
    setOpen(props.isOpen);
  }, [props.isOpen]);

  // const getById = async (teamId: number) => {
  //   try {
  //     const response = await getTeamById(teamId);
  //     setIsEdit(true);
  //     setModel(response.data);
  //   } catch (ex) {
  //     console.error(ex);
  //     const msg = ex.message ? ex.message : `Failed to load team by id`;
  //     enqueueSnackbar(t(msg), { variant: 'error' });
  //     navigate('/app/team/list');
  //   }
  // };

  // useEffect(() => {
  //   if (props.reviewId && Number(props.reviewId) > 0) {
  //     setIsLoading(true);
  //     getById(Number(props.reviewId)).then(() => {
  //       setIsLoading(false);
  //     });
  //   } else {
  //     setModel(null);
  //     setIsEdit(false);
  //   }
  // }, [props.reviewId]);

  const onSave = async (updatedModel) => {
    let isSuccess = false;
    try {
      setIsSaveInProgress(true);
      await updateSelfReview(updatedModel);
      enqueueSnackbar(
        t(`Review was ${!isEdit ? 'created' : 'updated'} successfully`),
        { variant: 'success' }
      );
      isSuccess = true;
      // navigate('/app/review/list');
      setIsSaveInProgress(false);
      handleCreateClose();
      props.onSave();
    } catch (ex) {
      setIsSaveInProgress(false);
      console.log(ex);
      const msg = parseToApiErrorMessage(
        ex,
        `Failed to ${!isEdit ? 'created' : 'updated'} Review`
      );
      enqueueSnackbar(t(msg), { variant: 'error' });
    }
    return isSuccess;
  };

  const handleCreateClose = () => {
    setOpen(false);
    props.onClose();
  };

  const onMemberSelectionChange = (member: EmployeeListItem) => {
    if (member) {
      setSelectedEmployee(member);
    } else {
      setSelectedEmployee(null);
    }
  };

  return (
    <Dialog open={open} onClose={handleCreateClose}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <DialogTitle
            sx={{
              p: 3
            }}
          >
            <Typography variant="h4" gutterBottom>
              {t('Edit Review')}
            </Typography>
            <Typography variant="subtitle2">
              {isEdit
                ? t('Edit the fields below to update review')
                : t('Fill in the fields below to create and add a new review')}
            </Typography>
          </DialogTitle>

          <Formik
            initialValues={{
              reviewId: props?.reviewId,
              reviewStatus: props?.status
              // employeeId: props?.employeeId
            }}
            onSubmit={async (
              _values,
              { resetForm, setStatus, setSubmitting }
            ) => {
              try {
                var isSuccess = await onSave(_values);
                if (isSuccess) {
                  resetForm();
                  setStatus({ success: true });
                  setSubmitting(false);
                }
              } catch (err) {
                console.error(err);
                setStatus({ success: false });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <DialogContent
                  dividers
                  sx={{
                    p: 3
                  }}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Grid container spacing={3}>
                        {/* <Grid item xs={12}>
                          <FormControl>
                            <FormLabel>Review Owner</FormLabel>
                          </FormControl>
                          <MemberSelector
                            items={props.members}
                            onChange={(emp) => {
                              if (emp) {
                                setFieldValue('employeeId', emp.id);
                              }
                              onMemberSelectionChange(emp);
                            }}
                            selectedId={values.employeeId}
                          />
                          <FormHelperText>
                            Reassign review by selecting another member
                          </FormHelperText>
                        </Grid> */}

                        <Grid item xs={12}>
                          <FormControl>
                            <FormLabel>Review Status</FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="status-radio-buttons-group-label"
                              name="reviewStatus"
                              onChange={(e) => {
                                setFieldValue('reviewStatus', e.target.value);
                              }}
                              value={values.reviewStatus}
                            >
                              {props.reviewStatusRefData.map(
                                ({ value, code }) => (
                                  <FormControlLabel
                                    key={code}
                                    value={code}
                                    control={<Radio />}
                                    label={value}
                                  />
                                )
                              )}
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions
                  sx={{
                    p: 3
                  }}
                >
                  <Button color="secondary" onClick={handleCreateClose}>
                    {t('Cancel')}
                  </Button>
                  <Button
                    type="submit"
                    startIcon={
                      isSubmitting || isSaveInProgress ? (
                        <CircularProgress size="1rem" />
                      ) : (
                        <SaveIcon />
                      )
                    }
                    disabled={isSubmitting || isSaveInProgress}
                    variant="contained"
                  >
                    {t('Save')}
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </>
      )}
    </Dialog>
  );
}

export default EditReviewDialog;
