import { Grid, Skeleton } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  AccountTypes,
  DigitalCardListItem
} from 'src/services/apiService/response-models';
import { getReviewsByClientId } from 'src/services/nestApi';
import { RootState } from 'src/store';
import Footer from 'src/ui-bloom/components/Footer';
import PageTitleWrapper from 'src/ui-bloom/components/PageTitleWrapper';
import { getCardItems } from '../../../services/apiService';
import { getAnalytics } from '../../../services/apiService/data-view-service';
import { AnalyticsView } from '../../../services/apiService/response-models/data-views';
import { parseToApiErrorMessage } from '../../../utility/parseToApiErrorMessage';
import CardsBlock from './CardsBlock';
import ContactsBlock from './ContactsBlock';
import LeadsBlock from './LeadsBlock';
import PageHeader from './PageHeader';
import ReviewsBlock from './ReviewsBlock';
import TotalViewCard from './TotalViewCard';

const CACHE_KEY = 'get_analytics_data';
function DashboardAnalytics() {
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const user = useSelector((state: RootState) => state.app.user);
  const [data, setData] = useState<AnalyticsView | null>(null);
  const [cardItems, setCardItems] = useState<DigitalCardListItem[]>([]);

  const getData = async (
    callback: (hasError: boolean, data: AnalyticsView) => void
  ) => {
    try {
      //  const cachedData = getWithExpiry(CACHE_KEY);
      // if (cachedData) {
      //   callback(false, cachedData);
      // } else {
      const res = await getAnalytics();

      // const minutes = 10;
      // setWithExpiry(CACHE_KEY, res.data, 60 * 1000 * minutes);
      callback(false, res.data);
      // }
    } catch (ex) {
      const msg = parseToApiErrorMessage(ex, `Failed to load data`);
      enqueueSnackbar(msg, { variant: 'error' });
      setError(msg);
      callback(true, null);
    }
    const res2 = await getCardItems();
    setCardItems(res2.data);
  };

  useEffect(() => {
    getData((hasError, data) => {
      setData(data);
      setIsLoading(false);
      if (user) {
        getReviewsByClientId(user.clientAccountId).then((o) => {
          setData({ ...data, reviewCount: o.data.totalDocs });
        });
      }
    });
  }, []);

  return (
    <>
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>
      <Grid
        sx={{
          px: 4
        }}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
      >
        {isLoading && (
          <Grid item>
            <Grid
              container
              spacing={4}
              direction="row"
              justifyContent="center"
              alignItems="stretch"
            >
              <Grid item sm={6} xs={12}>
                <Skeleton variant="rectangular" width={310} height={200} />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Skeleton variant="rectangular" width={310} height={200} />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Skeleton variant="rectangular" width={310} height={200} />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Skeleton variant="rectangular" width={310} height={200} />
              </Grid>
            </Grid>
          </Grid>
        )}
        {!isLoading && (
          <>
            <Grid item>
              <Grid
                container
                spacing={4}
                direction="row"
                justifyContent="center"
                alignItems="stretch"
              >
                <Grid item sm={6} xs={12}>
                  <CardsBlock count={data?.cardCount ?? 0} />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <ContactsBlock count={data?.contactCount ?? 0} />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <LeadsBlock count={data?.leadCount ?? 0} />
                </Grid>
                <Grid item sm={6} xs={12}>
                  {user.accountType == AccountTypes.Business && (
                    <ReviewsBlock count={data?.reviewCount ?? 0} />
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              {cardItems?.length > 0 && <TotalViewCard cardItems={cardItems} />}
            </Grid>
          </>
        )}
      </Grid>
      <Footer />
    </>
  );
}

export default DashboardAnalytics;
