import { useEffect, useState } from 'react';

import Footer from 'src/ui-bloom/components/Footer';
import PageTitleWrapper from 'src/ui-bloom/components/PageTitleWrapper';
import PageHeader from './PageHeader';

import { Grid } from '@mui/material';

import { BusinessProfileRef } from 'src/BusinessApp/services/models';
import { getEmployeeListItems } from 'src/services/apiService/data-view-service';
import { RefData } from 'src/services/apiService/response-models';
import { convertCardItemToProfile } from 'src/utility/convertCardItemToCard';
import {
  getCardItems,
  getLeads,
  getRefData
} from '../../../services/apiService';
import {
  EmployeeListItem,
  LeadItem
} from '../../../services/apiService/response-models/data-views';
import CreateLeadDialog from '../create-lead-dialog';
import Results from './Results';

function LeadsList() {
  const [leadDataRefreshTriggerId, setLeadDataRefreshTriggerId] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [error, setError] = useState('');
  const onRefreshData = () => {
    setLeadDataRefreshTriggerId(Date.now() + '');
  };

  const [leads, setLeads] = useState<LeadItem[]>([]);
  const [accountRefData, setAccountRefData] = useState<RefData>({
    leadStatus: []
  });
  const [employeeListItems, setEmployeeListItems] = useState<
    EmployeeListItem[]
  >([]);
  const [profiles, setProfiles] = useState<BusinessProfileRef[]>([]);

  const getAllLeads = async () => {
    try {
      const response = await getLeads();
      setLeads(response.data);
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  };

  const loadRefData = async (
    callback: (
      hasError: boolean,
      data: RefData,
      employeeListItems: EmployeeListItem[]
    ) => void
  ) => {
    const [leadStatusData, empListItems, cards] = await Promise.all([
      getRefData('LeadStatus'),
      getEmployeeListItems(),
      getCardItems()
    ]);
    setProfiles(cards.data.map((o) => convertCardItemToProfile(o)));
    callback(false, leadStatusData, empListItems.data);
  };

  useEffect(() => {
    loadRefData((hasError, data, empListItems) => {
      if (data) {
        setAccountRefData({
          leadStatus: data.leadStatus || []
        });
      }
      if (empListItems) {
        setEmployeeListItems(empListItems || []);
      }
    });
    getAllLeads();
  }, []);

  useEffect(() => {
    if (leadDataRefreshTriggerId) {
      getAllLeads();
    }
  }, [leadDataRefreshTriggerId]);

  const onAdd = () => {
    setIsCreateOpen(true);
  };
  return (
    <>
      <PageTitleWrapper>
        <PageHeader onAdd={onAdd} />
      </PageTitleWrapper>

      <Grid
        sx={{
          px: 4
        }}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
      >
        <Grid item xs={12}>
          <Results
            isLoading={isLoading}
            leads={leads}
            accountRefData={accountRefData}
            employeeListItems={employeeListItems}
            onLeadEdited={() => {
              onRefreshData();
            }}
          />
        </Grid>
      </Grid>
      {isCreateOpen && (
        <CreateLeadDialog
          profiles={profiles}
          onClose={() => setIsCreateOpen(false)}
          onSave={() => {
            setIsCreateOpen(false);
            onRefreshData();
          }}
        />
      )}
      <Footer />
    </>
  );
}

export default LeadsList;
