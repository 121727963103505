import PreField from '../../models/PreField';

export const getTitleDesc = (field: PreField): string => {
    if (!field.fieldValues || !field.fieldValues.length)
        return '';

    if (!field.fieldRef.titleDescriptor)
        return field.fieldRef.displayName;

    //repalce all '{Title} ' with title value
    let title = field.fieldRef.titleDescriptor;
    field.fieldRef.formFields.forEach(o => {
        const value = field.fieldValues.find(fv => fv.name == o.name)?.value || '';
        title = title.replaceAll(new RegExp("\{" + o.name + "\}", "gi"), value);
    });

    return title;
}