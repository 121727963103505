import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import FileDownloadTwoToneIcon from '@mui/icons-material/FileDownloadTwoTone';
import QrCodeTwoToneIcon from '@mui/icons-material/QrCodeTwoTone';
import {
  Avatar,
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  Paper,
  TextField,
  useTheme
} from '@mui/material';
import { debounce } from 'lodash';
import { useSnackbar } from 'notistack';
import QRCodeStyling from 'qr-code-styling';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import blobToBase64 from 'src/utility/blobToBase64';
import QrEditorDialog from '../../../../../components/qr-customize-button/qr-editor-dialog';
import { QRConfig } from '../../../../../services/nestApi/responseModels';
import textToEllipsis from '../../../../../utility/textToEllipsis';
import ListingAppNames from '../../../../enums/ListingAppNames';
import { QrInfo } from '../../../../services/models';

const VIEWER_URL_PREFIX = 'https://kicard.co/';

export interface QrCardForAppProp {
  appName: ListingAppNames;
  qrInfo?: QrInfo;
  placeholder?: string;
  label?: string;
  buildQR: (value: string, viewerPrefix: string) => Promise<string>;
  validate?: (
    value: string
  ) => Promise<{ hasError: boolean; message?: string }>;
  onChange: (e: QrInfo) => void;
}

function QrCardForApp(props: QrCardForAppProp) {
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [value, setValue] = useState(props.qrInfo?.value || '');
  const [qrText, setQrText] = useState(props.qrInfo?.qrText || '');
  const [qrDataImg, setQrDataImg] = useState('');
  const [valueError, setValueError] = useState('');
  const [qrConfig, setQrConfig] = useState<QRConfig | null>(
    props.qrInfo?.qrConfig || null
  );
  const [qrCodeStylingRef, setQrCodeStylingRef] = useState(
    new QRCodeStyling({
      width: 300,
      height: 300,
      data: '',
      image: '',
      dotsOptions: {
        color: '#ec2727',
        type: 'square'
      },

      imageOptions: {
        crossOrigin: 'anonymous',
        margin: 1
      }
    })
  );

  useEffect(() => {
    // if (!props.qrInfo?.value) {
    //   setValueError('Qr text is required');
    // }
  }, []);

  const debouncedChangeCallback = useCallback(
    debounce(async (e: QrInfo) => {
      props.onChange(e);
    }, 500),
    []
  );

  useEffect(() => {
    (async () => {
      if (value) {
        let qdata = await props.buildQR(value, VIEWER_URL_PREFIX);

        //if (props.appName == ListingAppNames.ScanToCallApp) {
        //    qdata = 'tel:' + value;
        //}

        qrCodeStylingRef.update({
          data: qdata,
          image: qrConfig?.logo
            ? 'data:image/png;base64,' + qrConfig?.logo
            : '',
          dotsOptions: {
            color: qrConfig?.color || '#000'
          }
        });
        const imgData = await blobToBase64(await qrCodeStylingRef.getRawData());
        setQrDataImg(imgData);
        debouncedChangeCallback({
          qrText: qdata,
          value: value,
          qrConfig: qrConfig
        });
      } else {
        setQrDataImg('');
      }

      if (!value || valueError.length) {
        debouncedChangeCallback({
          qrText: '',
          value: '',
          qrConfig: qrConfig
        });
      }

      props.buildQR(value, VIEWER_URL_PREFIX).then((o) => setQrText(o));
    })();
  }, [value, qrConfig, valueError]);

  // const blobToBase64 = async (blob: Blob): Promise<string> => {
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.onload = () => resolve(reader.result as string);
  //     reader.onerror = (err) => reject(err);
  //     reader.readAsDataURL(blob);
  //   });
  // };

  const onCustomizeClick = () => {
    setIsOpen(true);
  };

  const debouncedUniqueProfileCheck = useCallback(
    debounce(async (value) => {
      if (props.validate) {
        const res = await props.validate(value);
        if (res.hasError) {
          setValueError(res.message || 'Invalid value');
        } else {
          setValueError('');
        }
      } else if (!value.trim().length) {
        setValueError('');
        return;
      } else {
        setValueError('');
      }
    }, 500),
    []
  );

  const onRightClick = async () => {
    let url = await props.buildQR(value, VIEWER_URL_PREFIX);
    window.open(url, '_blank');
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Paper sx={{ p: 2, width: theme.spacing(34) }} elevation={3}>
            <Avatar
              sx={{
                mx: 'auto',
                mb: 0,
                width: theme.spacing(30),
                height: theme.spacing(30)
              }}
              variant="rounded"
              alt="Qr Code"
              src={qrDataImg}
              onContextMenu={() => onRightClick()}
            >
              <BrokenImageIcon />
            </Avatar>
          </Paper>
        </Box>
      </Grid>
      {/*<Typography align="center" variant="h4" gutterBottom>*/}
      {/*    Craig Donin*/}
      {/*</Typography>*/}
      <Grid item xs={12}>
        <FormControl sx={{ p: 5, pb: 1, width: '100%', pt: 1 }}>
          <FormLabel title={qrText}>
            {props.label || 'Viewer url: ' + textToEllipsis(qrText)}
          </FormLabel>
          <TextField
            error={!!valueError}
            // label="Profile url"
            placeholder={props.placeholder || 'Your qr text here...'}
            id="Label-size-small"
            value={value ?? ''}
            onChange={(e) => {
              const val = e.target.value;
              debouncedUniqueProfileCheck(val);
              setValue(val);
              //if (!val) {
              //    setValueError('Qr text is required');
              //}
              //else if (!validateProfileText(val)) {
              //    setValueError(
              //        'Qr text can only contain letters, numbers, dashes("-"), underscores ("_") or periods (".").'
              //    );
              //} else {
              //    debouncedUniqueProfileCheck(val);
              //}
            }}
            fullWidth
            inputProps={{ maxLength: 100 }}
            helperText={valueError || ''}
          />
        </FormControl>
      </Grid>
      {/*<Typography align="center" variant="subtitle2" gutterBottom>*/}
      {/*    {t('kicard.co/app/price-catalog/123')}*/}
      {/*</Typography>*/}
      <Grid item xs={12}>
        <Box
          sx={{ mt: 1 }}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Button
            onClick={() =>
              qrCodeStylingRef.download({
                name: props.appName + '-qr',
                extension: 'png'
              })
            }
            size="small"
            variant="contained"
            startIcon={<FileDownloadTwoToneIcon />}
          >
            Download
          </Button>
          <Button
            onClick={onCustomizeClick}
            size="small"
            sx={{ ml: 2 }}
            variant="contained"
            startIcon={<QrCodeTwoToneIcon />}
          >
            Customize QR
          </Button>
          {/*<Tooltip arrow placement="top" title={t('Customize QR')}>*/}
          {/*    <IconButton*/}
          {/*        color="primary"*/}
          {/*        sx={{*/}
          {/*            mx: 0.5*/}
          {/*        }}*/}
          {/*        onClick={onCustomizeClick}*/}
          {/*    >*/}
          {/*        <QrCodeTwoToneIcon />*/}
          {/*    </IconButton>*/}
          {/*</Tooltip>*/}
          {isOpen && (
            <QrEditorDialog
              isOpen={isOpen}
              onClose={() => setIsOpen(false)}
              title={'Customize QR'}
              qrConfig={qrConfig}
              onSave={(e) => {
                setIsOpen(false);
                setQrConfig(e);
              }}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  );
}

export default QrCardForApp;
