import PhotoCameraBackTwoToneIcon from '@mui/icons-material/PhotoCameraBackTwoTone';
import {
  Avatar,
  Box,
  Card,
  Typography,
  alpha,
  lighten,
  styled,
  useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const AvatarPageTitle = styled(Avatar)(
  ({ theme }) => `
      width: ${theme.spacing(8)};
      height: ${theme.spacing(8)};
      color: ${theme.colors.primary.main};
      margin-right: ${theme.spacing(2)};
      background: ${
        theme.palette.mode === 'dark'
          ? theme.colors.alpha.trueWhite[10]
          : theme.colors.alpha.white[50]
      };
      box-shadow: ${
        theme.palette.mode === 'dark'
          ? '0 1px 0 ' +
            alpha(lighten(theme.colors.primary.main, 0.8), 0.2) +
            ', 0px 2px 4px -3px rgba(0, 0, 0, 0.3), 0px 5px 16px -4px rgba(0, 0, 0, .5)'
          : '0px 2px 4px -3px ' +
            alpha(theme.colors.alpha.black[100], 0.4) +
            ', 0px 5px 16px -4px ' +
            alpha(theme.colors.alpha.black[100], 0.2)
      };
`
);

function PageHeader(props: {}) {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  const onClick = () => {
    // navigate('/app/cards/' + props.cardType + '/new');
  };

  return (
    <Box
      display="flex"
      alignItems={{ xs: 'stretch', md: 'center' }}
      flexDirection={{ xs: 'column', md: 'row' }}
      justifyContent="space-between"
    >
      <Box display="flex" alignItems="center">
        <AvatarPageTitle variant="rounded">
          <PhotoCameraBackTwoToneIcon fontSize="large" />
        </AvatarPageTitle>
        <Box>
          <Typography variant="h3" component="h3" gutterBottom>
            {t('My Card')}
          </Typography>
          <Typography variant="subtitle2">
            {t(
              'List all cards issues to you which are mapped to one ore more profile(s)'
            )}
          </Typography>
        </Box>
      </Box>
      <Card
        sx={{
          mt: { xs: 3, lg: 0 }
        }}
      >
        {/* <Button
          onClick={onClick}
          variant="contained"
          startIcon={<PhotoCameraBackTwoToneIcon />}
        >
          {t('New profile')}
        </Button> */}
      </Card>
    </Box>
  );
}

export default PageHeader;
