const parseMyJson = (value: string | undefined | null, defaultValue: any = {}): any => {
    if (value) {
        try {
            return JSON.parse(value);
        } catch (e) {
            console.log('invalid json');
        }
    }
    return defaultValue;
};

export default parseMyJson;