import {
  Autocomplete,
  Card,
  Divider,
  Box,
  Grid,
  Stack,
  styled,
  TextField,
  useTheme
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EmployeeListItem } from '../../services/apiService/response-models/data-views';

function MemberSelector(props: {
  items: EmployeeListItem[];
  onChange: (values: EmployeeListItem) => void;
  selectedId: number;
    disabled?: boolean;
    placeholder?: string;
}) {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const [item, setItem] = useState<EmployeeListItem>(null);

  useEffect(() => {
    setItem(props.items.find((o) => props.selectedId === o.id));
  }, []);

  return (
    <Autocomplete
      id="member-select-comp"
      fullWidth
      multiple={false}
      limitTags={1}
      disabled={props.disabled}
      value={item}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      options={props.items.filter((o) => !(item?.id == o.id))}
      // @ts-ignore
      getOptionLabel={(option: EmployeeListItem) =>
        option.fullName + '(' + option.email + ')'
      }
      onChange={(event: any, newValues: EmployeeListItem | null) => {
        if (newValues) {
          setItem(newValues);
        }
        props.onChange(newValues);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: ''
          }}
          fullWidth
          variant="outlined"
          // label={t('Member(s)')}
              placeholder={t(props.placeholder || 'Select Member...')}
        />
      )}
    />
  );
}

export default MemberSelector;
