import { FC, useRef, useState, MouseEvent } from 'react';
import {
  styled,
  Button,
  Box,
  Tooltip,
  useTheme,
  Dialog,
  DialogActions,
  DialogContent,
  CircularProgress,
  LinearProgress
} from '@mui/material';
import Fab from '@mui/material/Fab';
import { useTranslation } from 'react-i18next';
import RemoveRedEyeTwoToneIcon from '@mui/icons-material/RemoveRedEyeTwoTone';
import Iframe from 'react-iframe';
import { ApiUrls } from '../../../../services/endpoints';

const ThemeSettingsButton = styled(Box)(
  ({ theme }) => `
          position: fixed;
          z-index: 9999;
          right: ${theme.spacing(4)};
          bottom: ${theme.spacing(4)};
          
          &::before {
              width: 30px;
              height: 30px;
              content: ' ';
              position: absolute;
              border-radius: 100px;
              left: 13px;
              top: 13px;
              background: ${theme.colors.primary.main};
              animation: ripple 1s infinite;
              transition: ${theme.transitions.create(['all'])};
          }

          .MuiSvgIcon-root {
            animation: pulse 1s infinite;
            transition: ${theme.transitions.create(['all'])};
          }
  `
);

const ThemeToggleWrapper = styled(Box)(
  ({ theme }) => `
          padding: ${theme.spacing(2)};
          min-width: 220px;
  `
);

const ButtonWrapper = styled(Box)(
  ({ theme }) => `
        cursor: pointer;
        position: relative;
        border-radius: ${theme.general.borderRadiusXl};
        padding: ${theme.spacing(0.8)};
        display: flex;
        flex-direction: row;
        align-items: stretch;
        min-width: 80px;
        box-shadow: 0 0 0 2px ${theme.colors.primary.lighter};

        &:hover {
            box-shadow: 0 0 0 2px ${theme.colors.primary.light};
        }

        &.active {
            box-shadow: 0 0 0 2px ${theme.palette.primary.main};

            .colorSchemeWrapper {
                opacity: .6;
            }
        }
  `
);

const ColorSchemeWrapper = styled(Box)(
  ({ theme }) => `

    position: relative;

    border-radius: ${theme.general.borderRadiusXl};
    height: 28px;
    
    &.colorSchemeWrapper {
        display: flex;
        align-items: stretch;
        width: 100%;

        .primary {
            border-top-left-radius: ${theme.general.borderRadiusXl};
            border-bottom-left-radius: ${theme.general.borderRadiusXl};
        }

        .secondary {
            border-top-right-radius: ${theme.general.borderRadiusXl};
            border-bottom-right-radius: ${theme.general.borderRadiusXl};
        }

        .primary,
        .secondary,
        .alternate {
            flex: 1;
        }
    }

    &.pureLight {
        .primary {
            background: #5569ff;
        }
    
        .secondary {
            background: #f2f5f9;
        }
    }

    &.greyGoose {
        .primary {
            background: #2442AF;
        }
    
        .secondary {
            background: #F8F8F8;
        }
    }
    
    &.purpleFlow {
        .primary {
            background: #9b52e1;
        }
    
        .secondary {
            background: #00b795;
        }
    }
  `
);

const CheckSelected = styled(Box)(
  ({ theme }) => `
    background: ${theme.palette.success.main};
    border-radius: 50px;
    height: 26px;
    width: 26px;
    color: ${theme.palette.success.contrastText};
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -13px 0 0 -13px;
    z-index: 5;

    .MuiSvgIcon-root {
        height: 16px;
        width: 16px;
    }

  `
);

const ButtonWrapper2 = styled(Button)(
  ({ theme }) => `
    transform: translateY(0px);
    box-shadow: 0 .113rem .5rem rgba(7,9,25,.3), 0 .126rem .225rem rgba(7,9,25,.1);
    transition: ${theme.transitions.create(['transform', 'box-shadow'])};

    &:hover {
        transform: translateY(-2px);
        box-shadow: 0 .413rem .9rem rgba(7,9,25,.3), 0 .126rem .225rem rgba(7,9,25,.1);
    }
    
    &:active {
        transform: translateY(-2px);
        box-shadow: none;
    }
  `
);
const SettingFloat: FC<{ cardId: number }> = (props) => {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();

  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const openMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ThemeSettingsButton>
        <Tooltip arrow title={t('Preview')}>
          <Fab ref={ref} onClick={handleOpen} color="primary" aria-label="add">
            <RemoveRedEyeTwoToneIcon />
          </Fab>
        </Tooltip>
        {/*<Popover*/}
        {/*    disableScrollLock*/}
        {/*    anchorEl={ref.current}*/}
        {/*    onClose={handleClose}*/}
        {/*    open={isOpen}*/}
        {/*    anchorOrigin={{*/}
        {/*        vertical: 'bottom',*/}
        {/*        horizontal: 'left'*/}
        {/*    }}*/}
        {/*    transformOrigin={{*/}
        {/*        vertical: 'bottom',*/}
        {/*        horizontal: 'right'*/}
        {/*    }}*/}
        {/*>*/}
        {/*    <Box p={2}>*/}
        {/*        <Typography*/}
        {/*            sx={{*/}
        {/*                mb: 2,*/}
        {/*                textAlign: 'center',*/}
        {/*                fontWeight: 'bold',*/}
        {/*                textTransform: 'uppercase'*/}
        {/*            }}*/}
        {/*            variant="body1"*/}
        {/*        >*/}
        {/*            More Options*/}
        {/*        </Typography>*/}
        {/*        <ButtonWrapper2*/}
        {/*            size="small"*/}
        {/*            variant="contained"*/}
        {/*            sx={{*/}
        {/*                background: `${theme.colors.info.main}`,*/}
        {/*                color: `${theme.palette.getContrastText(theme.colors.info.dark)}`,*/}
        {/*                ml: 1,*/}

        {/*                '&:hover': {*/}
        {/*                    background: `${theme.colors.info.main}`,*/}
        {/*                    color: `${theme.palette.getContrastText(*/}
        {/*                        theme.colors.info.dark*/}
        {/*                    )}`*/}
        {/*                }*/}
        {/*            }}*/}
        {/*        >*/}
        {/*            {t('Preview')}*/}
        {/*        </ButtonWrapper2>*/}
        {/*    </Box>*/}
        {/*    <Divider />*/}
        {/*</Popover>*/}
      </ThemeSettingsButton>
      {isOpen && (
        <ResponsiveDialog
          cardId={props.cardId}
          onClose={() => setOpen(false)}
        />
      )}
    </>
  );
};

function ResponsiveDialog(props: { cardId: number; onClose: () => void }) {
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const fullScreen = true;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    props.onClose();
  };
  const handleIframeLoad = () => {
    setLoading(false);
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogContent sx={{ p: 0, m: 0 }}>
        {loading && (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        )}
        <Iframe
          url={ApiUrls.viewerAppUrl + '/app/viewer/' + props.cardId}
          width="100%"
          loading="auto"
          height="100%"
          id=""
          className=""
          display="block"
          frameBorder={0}
          position="relative"
          onLoad={handleIframeLoad}
        />
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SettingFloat;
