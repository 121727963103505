import CloseIcon from '@mui/icons-material/Close';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import GridViewTwoToneIcon from '@mui/icons-material/GridViewTwoTone';
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import TableRowsTwoToneIcon from '@mui/icons-material/TableRowsTwoTone';
import {
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  Dialog,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  Link,
  Slide,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  Zoom,
  styled
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import {
  ChangeEvent,
  FC,
  MouseEvent,
  ReactElement,
  Ref,
  SyntheticEvent,
  forwardRef,
  useEffect,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import Label from 'src/ui-bloom/components/Label';
import { deleteContactById } from '../../../services/apiService';
import { Contact } from '../../../services/apiService/response-models';
import cdnService from '../../../services/cdnService';
import { parseToApiErrorMessage } from '../../../utility/parseToApiErrorMessage';
import BulkActions from './BulkActions';

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const AvatarError = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

const CardWrapper = styled(Card)(
  ({ theme }) => `

  position: relative;
  overflow: visible;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: inherit;
    z-index: 1;
    transition: ${theme.transitions.create(['box-shadow'])};
  }
      
    &.Mui-selected::after {
      box-shadow: 0 0 0 3px ${theme.colors.primary.main};
    }
  `
);

const ButtonError = styled(Button)(
  ({ theme }) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.error.dark};
     }
    `
);

const TabsWrapper = styled(Tabs)(
  ({ theme }) => `
    @media (max-width: ${theme.breakpoints.values.md}px) {
      .MuiTabs-scrollableX {
        overflow-x: auto !important;
      }

      .MuiTabs-indicator {
          box-shadow: none;
      }
    }
    `
);

interface ResultsProps {
  isLoading: boolean;
  contacts: Contact[];
}

interface Filters {
  group?: string;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const getContactGroupLabel = (groups: string[]): JSX.Element => {
  const map = {
    admin: {
      text: 'Administrator',
      color: 'error'
    },
    customer: {
      text: 'Customer',
      color: 'info'
    },
    subscriber: {
      text: 'Subscriber',
      color: 'warning'
    }
  };

  if (!groups || !groups.length) return <span></span>;

  const { text, color }: any = map.customer;

  return <Label color={color}>{groups.join(',')}</Label>;
};

const applyFilters = (
  contacts: Contact[],
  query: string,
  filters: Filters
): Contact[] => {
  query = query.toLowerCase();
  return contacts.filter((user) => {
    let matches = true;

    if (query) {
      const properties = [
        'email',
        'firstName',
        'middleName',
        'lastName',
        'contactNumber'
      ];
      let containsQuery = false;

      properties.forEach((property) => {
        if (user[property]?.toLowerCase().includes(query)) {
          containsQuery = true;
        }
      });

      if (!containsQuery && user.groupTags) {
        containsQuery = user.groupTags.join('').toLowerCase().includes(query);
      }
      // if (
      //   filters.group &&
      //   user.groupTags &&
      //   !user.groupTags.includes(filters.group)
      // ) {
      //   matches = false;
      // }

      if (!containsQuery) {
        matches = false;
      }
    }

    Object.keys(filters).forEach((key) => {
      const value = filters[key];

      if (value && user[key] !== value) {
        matches = false;
      }
    });

    return matches;
  });
};

const applyPagination = (
  contacts: Contact[],
  page: number,
  limit: number
): Contact[] => {
  return contacts.slice(page * limit, page * limit + limit);
};

const Results: FC<ResultsProps> = (props) => {
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(props.isLoading);
  const [contacts, setContacts] = useState<Contact[]>(props.contacts);

  useEffect(() => {
    setContacts(props.contacts);
    setIsLoading(props.isLoading);
  }, [props.isLoading, props.contacts]);

  const tabs = [
    {
      value: 'all',
      label: t('All contacts')
    }
  ];

  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(50);
  const [query, setQuery] = useState<string>('');
  const [filters, setFilters] = useState<Filters>({
    group: null
  });
  const handleTabsChange = (_event: SyntheticEvent, tabsValue: unknown) => {
    let value = null;

    if (tabsValue !== 'all') {
      value = tabsValue;
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      role: value
    }));

    setSelectedItems([]);
  };

  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
    event.persist();
    setQuery(event.target.value);
  };

  const handleSelectAllUsers = (event: ChangeEvent<HTMLInputElement>): void => {
    setSelectedItems(event.target.checked ? contacts.map((o) => o.id) : []);
  };

  const handleSelectOneContact = (
    _event: ChangeEvent<HTMLInputElement>,
    contactId: number
  ): void => {
    if (!selectedItems.includes(contactId)) {
      setSelectedItems((prevSelected) => [...prevSelected, contactId]);
    } else {
      setSelectedItems((prevSelected) =>
        prevSelected.filter((id) => id !== contactId)
      );
    }
  };

  const handlePageChange = (_event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const filteredItems = applyFilters(contacts, query, filters);
  const paginatedItems = applyPagination(filteredItems, page, limit);
  const selectedBulkActions = selectedItems.length > 0;
  const selectedSomeItems =
    selectedItems.length > 0 && selectedItems.length < contacts.length;
  const selectedAllItems = selectedItems.length === contacts.length;
  const [idsToDelete, setIdsToDelete] = useState([]);

  const [toggleView, setToggleView] = useState<string | null>('table_view');

  const handleViewOrientation = (
    _event: MouseEvent<HTMLElement>,
    newValue: string | null
  ) => {
    setToggleView(newValue);
  };

  const onBulkDelete = () => {
    setIdsToDelete([...selectedItems]);
  };

  const handleDeleteClick = (id: number) => {
    setIdsToDelete([id]);
  };

  const closeConfirmDelete = () => {
    setIdsToDelete([]);
  };

  const handleDeleteCompleted = async () => {
    const ids = [...idsToDelete];
    setIdsToDelete([]);

    if (ids.length > 1) {
      const successIds: number[] = [];

      const deleteAll = async () => {
        for (let index = 0; index < ids.length; index++) {
          const id = ids[index];
          try {
            const success = await deleteContactById(id);
            if (success) {
              successIds.push(id);
            }
          } catch (ex) {
            console.log(ex);
            const msg = parseToApiErrorMessage(ex, `Failed to delete Contact`);
            enqueueSnackbar(t(msg), { variant: 'error' });
          }
        }
      };
      await deleteAll();

      const items = [...contacts].filter((o) => !successIds.includes(o.id));
      setContacts(items);
      if (successIds.length) {
        enqueueSnackbar(t('Contact(s) have been deleted'), {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
          },
          TransitionComponent: Zoom
        });
      }
    } else {
      try {
        const id = ids[0];
        const success = await deleteContactById(id);
        if (success) {
          const items = [...contacts].filter((o) => o.id != id);
          setContacts(items);
          enqueueSnackbar(t('Contact has been deleted'), {
            variant: 'success',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right'
            },
            TransitionComponent: Zoom
          });
        }
      } catch (ex) {
        console.log(ex);
        const msg = parseToApiErrorMessage(ex, `Failed to delete Contact`);
        enqueueSnackbar(t(msg), { variant: 'error' });
      }
    }
  };

  const getLocationDesc = (e: Contact) => {
    if (!e.addresses) return '';

    let address = e.addresses.find((o) => o.isPrimary);
    if (!address && e.addresses.length) address = e.addresses[0];

    if (address) {
      if (address.city) return address.city;
      if (address.cityCode) return address.cityCode;
      if (address.addressLine) return address.addressLine;
      if (address.state) return address.state;
      return (
        address.stateCode +
        '' +
        (address.countryCode ? ', ' + address.countryCode : '')
      );
    }

    return '';
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        flexDirection={{ xs: 'column', sm: 'row' }}
        justifyContent={{ xs: 'center', sm: 'space-between' }}
        pb={3}
      >
        <TabsWrapper
          onChange={handleTabsChange}
          scrollButtons="auto"
          textColor="secondary"
          value={filters.group || 'all'}
          variant="scrollable"
        >
          {tabs.map((tab) => (
            <Tab key={tab.value} value={tab.value} label={tab.label} />
          ))}
        </TabsWrapper>
        <ToggleButtonGroup
          sx={{
            mt: { xs: 2, sm: 0 }
          }}
          value={toggleView}
          exclusive
          onChange={handleViewOrientation}
        >
          <ToggleButton disableRipple value="table_view">
            <TableRowsTwoToneIcon />
          </ToggleButton>
          <ToggleButton disableRipple value="grid_view">
            <GridViewTwoToneIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      {isLoading && <LinearProgress sx={{ width: '100%' }} />}
      {toggleView === 'table_view' && (
        <Card>
          <Box p={2}>
            {!selectedBulkActions && (
              <TextField
                sx={{
                  m: 0
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchTwoToneIcon />
                    </InputAdornment>
                  )
                }}
                onChange={handleQueryChange}
                placeholder={t('Search by name, email or username...')}
                value={query}
                size="small"
                fullWidth
                margin="normal"
                variant="outlined"
              />
            )}
            {selectedBulkActions && <BulkActions onDelete={onBulkDelete} />}
          </Box>

          <Divider />

          {paginatedItems.length === 0 ? (
            <>
              <Typography
                sx={{
                  py: 10
                }}
                variant="h3"
                fontWeight="normal"
                color="text.secondary"
                align="center"
              >
                {t("We couldn't find any users matching your search criteria")}
              </Typography>
            </>
          ) : (
            <>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedAllItems}
                          indeterminate={selectedSomeItems}
                          onChange={handleSelectAllUsers}
                        />
                      </TableCell>
                      <TableCell>{t('Name')}</TableCell>
                      <TableCell>{t('Email')}</TableCell>
                      <TableCell>{t('Contact Number')}</TableCell>
                      <TableCell>{t('Location')}</TableCell>
                      <TableCell>{t('Group')}</TableCell>
                      <TableCell>{t('Created On')}</TableCell>
                      <TableCell align="center">{t('Actions')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedItems.map((contact) => {
                      const isUserSelected = selectedItems.includes(contact.id);
                      return (
                        <TableRow
                          hover
                          key={contact.id}
                          selected={isUserSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isUserSelected}
                              onChange={(event) =>
                                handleSelectOneContact(event, contact.id)
                              }
                              value={isUserSelected}
                            />
                          </TableCell>
                          <TableCell>
                            <Box display="flex" alignItems="center">
                              <Avatar
                                sx={{
                                  mr: 1
                                }}
                                src={cdnService.toImageUrl(contact.imageUrl)}
                              />
                              <Box>
                                <Link
                                  variant="h5"
                                  component={RouterLink}
                                  to={
                                    `/${
                                      location.pathname.split('/')[1]
                                    }/contact/detail/` + contact.id
                                  }
                                >
                                  {contact.fullName}
                                </Link>
                              </Box>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Typography>{contact.email}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{contact.contactNumber}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{getLocationDesc(contact)}</Typography>
                          </TableCell>
                          <TableCell>
                            {getContactGroupLabel(contact.groupTags)}
                          </TableCell>
                          <TableCell>
                            {new Date(contact.createdOn).toLocaleDateString()}
                          </TableCell>
                          <TableCell align="center">
                            <Typography noWrap>
                              <Tooltip title={t('View')} arrow>
                                <IconButton
                                  component={RouterLink}
                                  to={
                                    `/${
                                      location.pathname.split('/')[1]
                                    }/contact/detail/` + contact.id
                                  }
                                  color="primary"
                                >
                                  <LaunchTwoToneIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title={t('Delete')} arrow>
                                <IconButton
                                  onClick={() => handleDeleteClick(contact.id)}
                                  color="primary"
                                >
                                  <DeleteTwoToneIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box p={2}>
                <TablePagination
                  component="div"
                  count={filteredItems.length}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  page={page}
                  rowsPerPage={limit}
                  rowsPerPageOptions={[50, 100, 150]}
                />
              </Box>
            </>
          )}
        </Card>
      )}
      {toggleView === 'grid_view' && (
        <>
          <Card
            sx={{
              p: 2,
              mb: 3
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              {paginatedItems.length !== 0 && (
                <>
                  <Box display="flex" alignItems="center">
                    <Tooltip
                      arrow
                      placement="top"
                      title={t('Select all contacts')}
                    >
                      <Checkbox
                        checked={selectedAllItems}
                        indeterminate={selectedSomeItems}
                        onChange={handleSelectAllUsers}
                      />
                    </Tooltip>
                  </Box>
                  {selectedBulkActions && (
                    <Box flex={1} pl={2}>
                      <BulkActions onDelete={onBulkDelete} />
                    </Box>
                  )}
                </>
              )}
              {!selectedBulkActions && (
                <TextField
                  sx={{
                    my: 0,
                    ml: paginatedItems.length !== 0 ? 2 : 0
                  }}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchTwoToneIcon />
                      </InputAdornment>
                    )
                  }}
                  onChange={handleQueryChange}
                  placeholder={t('Search by name, email or group...')}
                  value={query}
                  size="small"
                  margin="normal"
                  variant="outlined"
                />
              )}
            </Box>
          </Card>
          {paginatedItems.length === 0 ? (
            <Typography
              sx={{
                py: 10
              }}
              variant="h3"
              fontWeight="normal"
              color="text.secondary"
              align="center"
            >
              {t("We couldn't find any users matching your search criteria")}
            </Typography>
          ) : (
            <>
              <Grid container spacing={3}>
                {paginatedItems.map((contact) => {
                  const isUserSelected = selectedItems.includes(contact.id);

                  return (
                    <Grid item xs={12} sm={6} md={4} key={contact.id}>
                      <CardWrapper
                        className={clsx({
                          'Mui-selected': isUserSelected
                        })}
                      >
                        <Box
                          sx={{
                            position: 'relative',
                            zIndex: '2'
                          }}
                        >
                          <Box
                            px={2}
                            pt={2}
                            display="flex"
                            alignItems="flex-start"
                            justifyContent="space-between"
                          >
                            {getContactGroupLabel(contact.groupTags)}
                            <IconButton
                              color="primary"
                              sx={{
                                p: 0.5
                              }}
                            >
                              <MoreVertTwoToneIcon />
                            </IconButton>
                          </Box>
                          <Box p={2} display="flex" alignItems="flex-start">
                            <Avatar
                              sx={{
                                width: 50,
                                height: 50,
                                mr: 2
                              }}
                              src={cdnService.toImageUrl(contact.imageUrl)}
                            />
                            <Box>
                              <Box>
                                <Link
                                  variant="h5"
                                  component={RouterLink}
                                  to={`/${
                                    location.pathname.split('/')[1]
                                  }/contact/detail/${contact.id}`}
                                >
                                  {contact.fullName}
                                </Link>{' '}
                                <Typography
                                  component="span"
                                  variant="body2"
                                  color="text.secondary"
                                ></Typography>
                              </Box>
                              <Typography
                                sx={{
                                  pt: 0.3
                                }}
                                variant="subtitle2"
                              >
                                {contact.email}
                              </Typography>
                              <Typography
                                sx={{
                                  pt: 1
                                }}
                                variant="h6"
                              >
                                {contact.contactNumber}
                              </Typography>
                            </Box>
                          </Box>
                          <Divider />
                          <Box
                            pl={2}
                            py={1}
                            pr={1}
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Typography>{getLocationDesc(contact)}</Typography>
                            <Checkbox
                              checked={isUserSelected}
                              onChange={(event) =>
                                handleSelectOneContact(event, contact.id)
                              }
                              value={isUserSelected}
                            />
                          </Box>
                        </Box>
                      </CardWrapper>
                    </Grid>
                  );
                })}
              </Grid>
              <Card
                sx={{
                  p: 2,
                  mt: 3,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <Box>
                  <Typography component="span" variant="subtitle1">
                    {t('Showing')}
                  </Typography>{' '}
                  <b>{limit}</b> {t('of')} <b>{filteredItems.length}</b>{' '}
                  <b>{t('users')}</b>
                </Box>
                <TablePagination
                  component="div"
                  count={filteredItems.length}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  page={page}
                  rowsPerPage={limit}
                  labelRowsPerPage=""
                  rowsPerPageOptions={[50, 100, 150]}
                />
              </Card>
            </>
          )}
        </>
      )}
      {!toggleView && (
        <Card
          sx={{
            textAlign: 'center',
            p: 3
          }}
        >
          <Typography
            align="center"
            variant="h4"
            fontWeight="normal"
            color="text.secondary"
            sx={{
              my: 5
            }}
            gutterBottom
          >
            {t(
              'Choose between table or grid views for displaying the contacts list.'
            )}
          </Typography>
        </Card>
      )}

      <DialogWrapper
        open={idsToDelete.length > 0}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        onClose={closeConfirmDelete}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          p={5}
        >
          <AvatarError>
            <CloseIcon />
          </AvatarError>

          <Typography
            align="center"
            sx={{
              py: 4,
              px: 6
            }}
            variant="h3"
          >
            {t(
              'Are you sure you want to permanently delete ' +
                (idsToDelete.length > 1 ? 'selected contacts' : ' this contact')
            )}
            ?
          </Typography>

          <Box>
            <Button
              variant="text"
              size="large"
              sx={{
                mx: 1
              }}
              onClick={closeConfirmDelete}
            >
              {t('Cancel')}
            </Button>
            <ButtonError
              onClick={handleDeleteCompleted}
              size="large"
              sx={{
                mx: 1,
                px: 3
              }}
              variant="contained"
            >
              {t('Delete')}
            </ButtonError>
          </Box>
        </Box>
      </DialogWrapper>
    </>
  );
};

Results.propTypes = {
  contacts: PropTypes.array.isRequired
};

Results.defaultProps = {
  contacts: []
};

export default Results;
