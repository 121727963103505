import { AccountTypes } from "src/services/apiService/response-models";

export interface PaymentPlan {
  id: string;
  displayName: string;
  accountType: AccountTypes;
}

const paymentPlans: PaymentPlan[] = [{
  id: 'kicard_pro_individual',
  displayName: 'Individual Pro',
  accountType: AccountTypes.Individual
}, {
  id: 'kicard_business',
  displayName: 'Business Pro',
  accountType: AccountTypes.Business
}];

export default paymentPlans;