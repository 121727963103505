import { useTranslation } from 'react-i18next';

import {
  Grid,
  Typography,
  useTheme,
} from '@mui/material';

function PageHeader() {
  const { t }: { t: any } = useTranslation();

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h3" component="h3" gutterBottom>
            {t('Reviews')}
          </Typography>
          <Typography variant="subtitle2">
            {t('All reviews received from self-review forms')}
          </Typography>
        </Grid>
        <Grid item></Grid>
      </Grid>
    </>
  );
}

export default PageHeader;
