import PreField from '../../models/PreField';

export const getValueDesc = (field: PreField): string => {
    if (!field.fieldValues || !field.fieldValues.length)
        return '';

    if (!field.fieldRef.valueDescriptor)
        return field.fieldValues.find(o => o.name == field.fieldName)?.value || '';

    //repalce all '{Title} ' with title value
    let label = field.fieldRef.valueDescriptor;
    field.fieldRef.formFields.forEach(o => {
        const value = field.fieldValues.find(fv => fv.name == o.name)?.value || '';
        label = label.replaceAll(new RegExp("\{" + o.name + "\}", "gi"), value);
    });

    return label;
}