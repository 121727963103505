const ApiBaseUrl = process.env.REACT_APP_KIEAZY_API_ENDPOINT || '';

export const ApiUrls = {
  base: ApiBaseUrl,
  accountEndpoint: ApiBaseUrl + '/api/v1/account',
  manageCardEndpoint: ApiBaseUrl + '/api/v1/manage-card',
  membershipEndpoint: ApiBaseUrl + '/api/v1/membership',
  viewerAppUrl: process.env.REACT_APP_VIEWERAPP_URL || '',
  clientEndpoint: ApiBaseUrl + '/api/v1/client-api',
  nfcKieazyUrl: 'https://kicard.co/kieazy',
  cardApi: process.env.REACT_APP_CARD_API_NEST || '',
  stripePaymentSuccessReturnUrl: process.env.REACT_APP_STRIPE_RETURN_URL || ''
};

export const NEST_API_SECRET_KEY =
  process.env.REACT_APP_NEST_API_SECRET_KEY || '';
