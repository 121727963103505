import {
  TextField,
  Autocomplete,
  Box,
  Card,
  Grid,
  styled,
  CardHeader,
  Divider,
  useTheme,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';
import { Field, FieldProps, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { TextField as FmTextFiled, Switch } from 'formik-mui';
import DropdownMultiSelect from '../../../components/dropdown-multi-select';
import { RefData } from 'src/services/apiService/response-models';
import { convertToCodeItems } from '../../../utility/convertToCodeItem';
import _ from 'lodash';

function GroupInfo(props: { accountRefData: RefData }) {
  const groupNames = props.accountRefData.contactGroups.map((o) => o.value);

  const { t }: { t: any } = useTranslation();
  const theme = useTheme();

  return (
    <Card>
      <CardHeader title={t("Contact's Group Info")} />
      <Divider />

      <Grid sx={{ padding: theme.spacing(2) }} container spacing={1}>
              <Grid item xs={12} md={6}>
                  <Field name="groupTags">
                      {({ field, form: { isSubmitting, setFieldValue, setFieldTouched, setFieldError }, meta }: FieldProps) =>
                          <Autocomplete
                              sx={{
                                  m: 0
                              }}
                              freeSolo
                              multiple
                              limitTags={10}
                              value={field.value}
                              autoSelect
                              onChange={(event: any, newValues: string[] | null) => {
                                  const vals = newValues.filter(o => o.trim().length);
                                  setFieldValue(field.name, vals);
                                  setFieldTouched(field.name, true);
                              }}
                              options={groupNames}
                              renderInput={(params) => (
                                  <TextField
                                      {...params}
                                      fullWidth
                                      variant="outlined"
                                      placeholder={t('Enter or Select contact group...')}
                                  />
                              )}
                          />}
                  </Field>
        </Grid>
      </Grid>
    </Card>
  );
}

export default GroupInfo;
