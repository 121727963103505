import { AccountTypes, UserContext } from "src/services/apiService/response-models";
import { MenuItems, menuItemsForAdmin, menuItemsForIndividualUser, menuItemsForNonAdminUser } from "src/ui-bloom/layouts/BoxedSidebarLayout/Sidebar/SidebarMenu/items";

const restrictedMenuByRole = {
    [AccountTypes.Business]: {
        member: ["leads", "contacts", "shopping"],
        admin: ["leads", "contacts", "shopping", "business-cards", "users", "settings"],
    },
    [AccountTypes.Individual]: ["leads", "contacts", "shopping"],
    
}

export const getMenuItemsByUser = (user: UserContext): MenuItems[] => {
    const { accountType, isAdmin } = user;
    if (accountType === AccountTypes.Business && isAdmin) {
        return menuItemsForAdmin;
    } else if (accountType === AccountTypes.Business && !isAdmin) {
        return menuItemsForNonAdminUser;
    } else if (accountType === AccountTypes.Individual) {
        return menuItemsForIndividualUser;
    }
    return [];
}

export const checkUserHasAccessToModule = (user: UserContext, moduleName: string): boolean => {
    const { accountType, isAdmin, userRoles } = user;
    if (accountType === AccountTypes.Business && isAdmin && userRoles.includes("admin")) {
        return true;
    } else if (accountType === AccountTypes.Business && !isAdmin && userRoles.includes("member")) {
        return restrictedMenuByRole[accountType].member.includes(moduleName);
    } else if (accountType === AccountTypes.Individual) {
        return restrictedMenuByRole[accountType].includes(moduleName);
    }
}